import { useContext, useEffect } from "react";
import { ThemeContext } from "../../ThemeContext";
import ModalCss from "./ModalCustom.module.css";
import { FaTimes } from "react-icons/fa";

const ModalCustom = (props) => {
  const themeCtx = useContext(ThemeContext);
  let theme = themeCtx[0];
  const closeModal = (e) => {
    props.onClose(e);
  };
  useEffect(() => {
    //When the modal renders, focus the button, to avoid bad keyboard navigation experience
    const firstFocusableElement = document.querySelector(
      ".modal-content button"
    );
    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }
  });
  let okText = "OK";
  let fstBtnAriaLabelTxt = props.firstOpAriaLabel ? props.firstOpAriaLabel : "Press enter to close modal.";
  let ariaLabelHeader = "";
  let ariaLabelBodyMsg = "";
  if(props.modal_msg !== undefined && typeof props.modal_msg === "string"){
    ariaLabelHeader = props.modal_msg;
    ariaLabelBodyMsg = props.modal_msg;
  };
  const idOrClass = (classOrId) =>{
    //Adds an id or class name from the props.
    //If the prop is not defined, it returns an empty string.
    if(classOrId !== undefined){
      return classOrId;
    } else return "";
  }
  if (props.hasOwnProperty("firstOptText")) {
    okText = props.firstOptText.length > 0 ? props.firstOptText : "OK";
  }
  const handleXbtnKeydown = (e) => {
    if (e.code === "Enter" || e.code === "Space" || e.code === "Escape") {
      closeModal(e);
    }
  }

  const handleFstBtn = (e) => {
    if(e.code !== "Tab"){
      if(props.hasOwnProperty("firstBtnAction")){
        props.firstBtnAction(e);
      } else  {
        closeModal(e);
      }
    }
  }
  return (
    <div
      className={"modal d-block "+ (props.zindex ? "zindex-11" : "")}
      tabIndex="-1"
      id="custom_modal"
      data-testid="modal"
      aria-modal="true"
    >
      <div className={ModalCss.modal_bg_class}>
        <div className={`modal-dialog ${idOrClass(props.modalSize)} modal-fade ${idOrClass(props.align)}`}
              role="dialog">
          <div
            className={`modal-content ${
              theme === "dark" ? "dark-content" : ""
            } ${idOrClass(props.modalBorders)}`}
            data-testid={idOrClass(props.testId)}
          >
            {props.noHeader ? (
              <></>
            ) : (
              <div className="modal-header">
                <h5
                  className="modal-title"
                  aria-label={`${props.modal_title}. ${ariaLabelHeader}`}
                  role="heading"
                >
                  {props.modal_title}
                </h5>
                {props.xClose && <button data-testid="close-modal" aria-label="Close this modal" onKeyDown={handleXbtnKeydown} onClick={closeModal}><FaTimes className="close-btn"></FaTimes></button>}
              </div>
            )}
            <div className="modal-body"> 
              <div aria-label={ariaLabelBodyMsg} aria-live="polite">{props.modal_msg}</div>
              {props.bodyIcon &&
              (<div className={props.bodyIcon.className}>
                {props.bodyIcon.icon}
                <p>{props.bodyIcon.str}</p>
               </div>
              )}
            </div>
            {props.noFooter ? <></> : <div className="modal-footer">
              <button
                id="first-button"
                onMouseDown={handleFstBtn}
                onKeyDown={handleFstBtn}
                type="button"
                data-testid="first-button"
                className={`modal-button ${idOrClass(props.btn_1_class)}`}
                aria-label={fstBtnAriaLabelTxt}
                disabled={props.firstBtnDisabled ? props.firstBtnDisabled : false}
              >
                {okText}
              </button>
              {props.secondOption ? (
                <button
                  onClick={props.onClickSecondOpt}
                  type="button"
                  className={`modal-button-second ${idOrClass(props.btn_2_class)}`}
                >
                  {props.secondOptText}
                </button>
              ) : (
                <></>
              )}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCustom;
