import TopicCardTooltips from "./TopicCardTooltips";
import "../TCForm.css";
import TopicCardPills from "./TopicCardPills";
import TopicCardDropdowns from "./TopicCardDropdowns";

const Location = ({
  locationInput,
  selectedLocation,
  selectedKeywords,
  selectedRefinerPage,
  keywordsForReview,
  updateValue,
  setInputState,
  disableField,
  requestStatus,
  tooltipFor
}) => {
  let location = {
    tooltip:
      "Topic Card can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations.",
    alt: "Location Info. Topic Card can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations",
    defaultOption: "Select target location(s) for Topic Card",
    ariaLabel: "Select a target location(s) for Topic Card"
  };
  const addLocation = (event) => {
    const location = event.target.value.trim();
    if (selectedLocation.indexOf(location) === -1) {
      if (location === "Global" || selectedLocation.includes("Global")) {
        updateValue("countries", ["Global"]);
      } else {
        updateValue("countries", [...selectedLocation, location]);
      }
    }
    document.getElementById("location-tc").selectedIndex = 0;
  };
  return (
    <>
      <div className="mb-3 target">
        <TopicCardTooltips
          tooltipFor={tooltipFor}
          tooltipAlt={location.alt}
          tooltipText={location.tooltip}
        />
        <TopicCardDropdowns
          optionDefault={location.defaultOption}
          options={locationInput}
          ariaLabel={location.ariaLabel}
          labelText="Location"
          onChangeDropdown={addLocation}
          elementId="location-tc"
          field="countries"
          setState={setInputState}
          updateValue={updateValue}
          inputValue={selectedLocation}
          disableField={disableField}
        />
        <TopicCardPills
          selectedTargets={selectedLocation}
          setSelectedTarget={updateValue}
          field="countries"
          arrayForReview={keywordsForReview}
          keywords={selectedKeywords}
          validateKeywords={{}}
          selectedRefinerPage={selectedRefinerPage}
          hasReviewArray={false}
          disableField={disableField}
          requestStatus={requestStatus}
        />
      </div>
    </>
  );
};

export default Location;
