import { useState, useEffect } from "react";

const INPUT_ELEM_ID = "ctc-url-input";

const UrlField = (props) => {
  const [cssState, setCssState] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [timeoutID, setTimeoutID] = useState();

  const validateField = () => {
    clearTimeout(timeoutID);

    let newTimeoutID = setTimeout(async () => {
      let htmlEl = document.querySelector(`#${INPUT_ELEM_ID}`);
      let urlValue = htmlEl.value;
      htmlEl.value = urlValue;

      let validationResult = await props.validateUrl(
        urlValue,
        props.urlService
      );

      props.updateFormState(validationResult.status, "url");

      let state = "";
      if (validationResult.status === null) {
        state = "";
      } else {
        state = validationResult.status ? " is-valid " : " is-invalid ";
      }

      setStatusMessage(validationResult.msg);
      setCssState(state);
    }, 500);

    setTimeoutID(newTimeoutID);
  };

  return (
    <div className={""}>
      <input
        id={INPUT_ELEM_ID}
        data-testid={"testid-" + INPUT_ELEM_ID}
        type="text"
        className={"form-control" + cssState}
        placeholder="Enter a URL for this Custom Topic Card (optional)"
        aria-label="Enter a URL for this Custom Topic Card (optional)"
        onInput={validateField}
        onBlur={validateField}
        disabled={props.readOnlyMode}
      />
      <div className={"invalid-feedback " + props.adjust_error_msg}>
        {statusMessage}
      </div>
    </div>
  );
};

export default UrlField;
