import { useContext, useEffect, useState } from "react";
import { Tooltip } from "bootstrap";
import { ThemeContext } from "../../../../ThemeContext";
import Toast from "../../../Toast/Toast";
import { triggerToast } from "../../../Toast/TriggerToast";

const BBPills = (props) => {
  const [greenPills, setGreenPills] = useState(props.greenPills);
  const [redPills, setRedPills] = useState(props.redPills);
  const [theme] = useContext(ThemeContext);
  const tooltipClass = theme === "dark" ? "bb-tooltip-dark" : "";
  const isGreyPill = props.displayGrayPills;
  let disableField = props.canEditForm
    ? props.disableFieldProp
    : !(props.disableFieldProp && !props.displayGrayPills);
  let disableCloseBtn = props.canEditForm
    ? !props.disableFieldProp
    : !(props.disableFieldProp && !props.displayGrayPills);

  if (
    (disableCloseBtn && props.formReqType === "Update") ||
    props.formStatus === "Not Approved" ||
    props.formStatus === "Cancelled"
  ) {
    disableCloseBtn = false;
  }

  if (!isGreyPill && !props.disableFieldProp && !props.displayGrayPills) {
    disableField = false;
    disableCloseBtn = true;
  }

  useEffect(() => {
    setGreenPills(props.greenPills);
    setRedPills(props.redPills);
  }, [props.greenPills, props.redPills]);
  useEffect(() => {
    //initialize tooltips for synonyms
    const initializeSynonymTooltips = () => {
      let tooltipElements = document.querySelectorAll(
        '[data-bs-toggle="synonym-tooltip"]'
      );
      [...tooltipElements].map(
        (ttp) =>
          new Tooltip(ttp, {
            trigger: "hover focus",
            customClass: tooltipClass
          })
      );
    };
    if (props.group === "synonyms") {
      initializeSynonymTooltips();
    }
  }, [greenPills, theme]);
  useEffect(() => {
    if (redPills) {
      let tooltipElements = document.querySelectorAll(
        '[data-bs-toggle="red-pills"]'
      );
      [...tooltipElements].map(
        (ttp) =>
          new Tooltip(ttp, {
            trigger: "hover focus",
            customClass: props.redPillsTooltip
          })
      );
    }
  }, [redPills]);
  const handleRemove = (pill) => {
    if (props.group === "synonyms") {
      const tooltip = document.querySelector(".tooltip.show");
      tooltip.remove();
      (async () => {
        const addedAsKeyword = await props.removeGreen(pill);
        if (!addedAsKeyword) {
          setGreenPills([...greenPills, pill]);
        }
      })();
    } else {
      props.removeGreen(pill);
    }
  };
  const handleClick = (e) =>{
    props.onCopyToClipBoard(e);
    triggerToast(1000,0,"copyToClipboard")
  }
  const handleRemoveRed = (pill) => {
    props.removeRed(pill);
  };
  const tooltipmsg =
    "Another Best Bet is already associated with this keyword. It will be considered, but may not be implemented.";

  const btnGreenPillStyle = isGreyPill
    ? "btn btn-gray-class"
    : "btn btn-success";
  const btnRedPillStyle = isGreyPill ? "btn btn-gray-class" : "btn btn-danger";
  return (
    <div className="form-group pills-container">
      {greenPills.map((pill, index) => {
        return (
          <div key={index + "_div"} className="btn-group pill-group">
             <button
                aria-label={pill + " bb-pill"}
                onClick={handleClick}
                data-testid={`pill-${pill}`}
                key={pill + " pill"}
                id={props.group + "_pill_" + index}
                className={btnGreenPillStyle}
                type="button"
              >
                {pill}
              </button>

            {disableCloseBtn && (
              <button
                onClick={() => handleRemove(pill)}
                data-testid={`remove-${pill}`}
                data-bs-toggle={
                  props.group === "synonyms" ? "synonym-tooltip" : undefined
                }
                data-bs-placement="top"
                data-bs-title="Add as keyword"
                key={"remove_" + props.group + "_pill"}
                id={"remove_" + props.group + "_pill_" + index}
                className="btn btn-success"
                type="button"
                aria-label={"remove " + pill + " pill"}
              >
                {props.group !== "synonyms" ? "x" : "+"}
              </button>
            )}
          </div>
        );
      })}
      {redPills ? (
        redPills.map((pill, index) => {
          return (
            <div key={index + "_div"} className="btn-group pill-group">
              <button
                aria-label={pill + " bb-red-pill"}
                onClick={handleClick}
                role="tooltip"
                alt={tooltipmsg}
                data-bs-toggle="red-pills"
                data-bs-placement="left"
                data-bs-title={tooltipmsg}
                data-testid={`red-pill-${pill}`}
                key={pill + "red pill"}
                id={props.group + "_red_pill_" + index}
                className={btnRedPillStyle}
                type="button"
              >
                {pill}
              </button>
              {disableCloseBtn && (
                <button
                  onClick={() => handleRemoveRed(pill)}
                  data-testid={`remove-${pill}`}
                  key={"remove_" + props.group + "_pill"}
                  id={"remove_" + props.group + "_pill"}
                  className="btn btn-danger"
                  type="button"
                  aria-label={"remove " + pill + " pill"}
                >
                  x
                </button>
              )}
            </div>
          );
        })
      ) : (
        <></>
      )}
      <Toast toastText="Keyword copied to the clipboard!" toastKey="copyToClipboard"/>
    </div>
  );
};

export default BBPills;
