import "../TCForm.css";
import TopicCardDropdowns from "./TopicCardDropdowns";
import TopicCardPills from "./TopicCardPills";
import TopicCardTooltips from "./TopicCardTooltips";

const RefinerPage = ({
  refinerPageInput,
  selectedRefinerPage,
  selectedKeywords,
  selectedLocation,
  keywordsForReview,
  updateValue,
  setInputState,
  disableField,
  requestStatus,
  tooltipFor
}) => {
  let refinerPage = {
    tooltip:
      "One or many Search pages (refiner pages) can be selected to display the Topic Card based on the site content and/or where the content resides (e.g., KX Documents, myLearning).",
    alt: "Refiner Page Info. One or many Search pages Refiner Page's can be selected to display the Topic Card based on the site content and or where the content resides for example, KX Documents, myLearning",
    defaultOption: "Select Search Refiner page where to display Topic Card",
    ariaLabel:
      "Refiner Page dropdown. Press Enter to select Refiner Page to display your Topic Card"
  };

  const addRefinerPage = (event) => {
    const refinerPage = event.target.value.trim();
    if (selectedRefinerPage.indexOf(refinerPage) === -1) {
      updateValue("aid", [...selectedRefinerPage, refinerPage]);
    }
    document.getElementById("refiner-page-tc").selectedIndex = 0;
  };
  return (
    <>
      <div className="mb-3 target">
        <TopicCardTooltips
          tooltipFor={tooltipFor}
          tooltipAlt={refinerPage.alt}
          tooltipText={refinerPage.tooltip}
        />
        <TopicCardDropdowns
          optionDefault={refinerPage.defaultOption}
          options={refinerPageInput}
          ariaLabel={refinerPage.ariaLabel}
          labelText="Refiner Page"
          onChangeDropdown={addRefinerPage}
          elementId={"refiner-page-tc"}
          field="aid"
          setState={setInputState}
          updateValue={updateValue}
          inputValue={selectedRefinerPage}
          disableField={disableField}
        />
        <TopicCardPills
          selectedTargets={selectedRefinerPage}
          setSelectedTarget={updateValue}
          arrayForReview={keywordsForReview}
          field="aid"
          keywords={selectedKeywords}
          selectedLocation={selectedLocation}
          hasReviewArray={false}
          disableField={disableField}
          requestStatus={requestStatus}
        />
      </div>
    </>
  );
};

export default RefinerPage;
