import { useState, useEffect } from "react";
import TopicCardValidations, {
  validateRefinerPage
} from "../../../../validationClasses/TopicCardValidations";

const TopicCardDropdowns = ({
  optionDefault,
  options,
  ariaLabel,
  labelText,
  onChangeDropdown,
  elementId,
  field,
  setState,
  updateValue,
  inputValue,
  disableField
}) => {
  const [validInputClass, setValidInputClass] = useState("");
  const inputValidation = {
    aid: (input) => validateRefinerPage(input),
    countries: (input) => validateLocation(input)
  };

  useEffect(() => {
    inputValidation[field](inputValue);
  }, [inputValue]);
  const validateRefinerPage = () => {
    const isInputValid = TopicCardValidations.validateRefinerPage(inputValue);
    validateDropdown(isInputValid);
  };
  const validateLocation = () => {
    const isInputValid = TopicCardValidations.validateLocation(inputValue);
    validateDropdown(isInputValid);
  };
  const validateDropdown = (isInputValid) => {
    updateValue(field, inputValue);
    if (inputValue.length === 0) {
      setState(field, false);
      setValidInputClass("");
    } else {
      setState(field, isInputValid.isValid);
      if (isInputValid.isValid) {
        setValidInputClass("is-valid");
      } else {
        setValidInputClass("is-invalid");
      }
    }
  };
  return (
    <>
      <label data-testid="topic-card-label" className="label-text required">
        {labelText}
      </label>
      <select
        id={elementId}
        data-testid="dropdown-tc"
        className={`form-select light-mode-purple ${validInputClass}`}
        aria-label={ariaLabel}
        defaultValue={optionDefault}
        onChange={(event) => onChangeDropdown(event)}
        disabled={disableField}
      >
        <option value={null} disabled data-testid="default-option">
          {optionDefault}
        </option>
        {options}
      </select>
    </>
  );
};
export default TopicCardDropdowns;
