import StoreContext from "../../store/storecontext";
import { useContext, useEffect, useState } from "react";
import ModalCustom from "./ModalCustom";
import { ThemeContext } from "../../ThemeContext";

const ValidateInputModal = ({ searchTerm, setSearchTerm }) => {
  const store = useContext(StoreContext);
  const urlCheckerService = store.services.urlCheckerService;
  const [theme] = useContext(ThemeContext);

  const [charsErrorModal, setCharsErrorModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const validateInput = () => {
    let regex = urlCheckerService.textRegex;
    if (searchTerm?.match(regex)) {
      setModalMessage('Please do not include the special characters \\ and "');
      setCharsErrorModal(true);
    } else {
      setCharsErrorModal(false);
    }
  };

  const onClickCharsErrorModal = () => {
    setCharsErrorModal(false);
    setSearchTerm("");
  };

  useEffect(() => {
    validateInput(searchTerm);
  }, [searchTerm]);

  return (
    <>
      {charsErrorModal && (
        <ModalCustom
          darkMode={theme === "dark"}
          onClose={onClickCharsErrorModal}
          validateInput={validateInput}
          modal_msg={modalMessage}
          btn_1_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
          modalSize=""
          noHeader={true}
        />
      )}
    </>
  );
};

export default ValidateInputModal;
