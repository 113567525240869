import { React, useContext } from "react";
import { Pie } from "react-chartjs-2";
import "../Metrics.css";
import { ThemeContext } from "../../../ThemeContext";

const PieChart = ({ feedData }) => {
  const theme = useContext(ThemeContext);

  const isDarkMode = () => {
    return theme[0] === "dark";
  };
  const fontColor = isDarkMode() ? "white" : "black";

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 15
          },
          color: fontColor
        }
      }
    }
  };

  return (
    <div className="pie-container">
      <div id="metrics-pie-chart-div" className="pie-chart">
        <Pie id="metrics-pie-chart" data={feedData} options={options} />
      </div>
    </div>
  );
};

export default PieChart;
