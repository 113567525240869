import "../SynonymsForm.css";

const SynonymsPills = ({
  pills,
  onDelete,
  disableFieldProp,
  displayGrayPills
}) => {
  return (
    <div className="synonyms-pills-list">
      {pills?.map((pill, index) => (
        <div className=" btn-group synonyms-pills" key={index}>
          <button
            className="btn btn-success"
            id="pill"
            data-testid="syn-pill"
            disabled={disableFieldProp}
          >
            {pill}
          </button>
          {!disableFieldProp && (
            <button
              className="btn btn-success"
              id="delete-pill"
              data-testid="syn-delete-pill"
              disabled={disableFieldProp}
              onClick={() => onDelete(index)}
            >
              x
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default SynonymsPills;
