import { useEffect, useState } from "react";

const CompareTable = (props) => {
  const [firstTableRows, setFirstTableRows] = useState();
  const [secondTableRows, setSecondTableRows] = useState();

  const getComparedData = (
    propertyToCompare,
    formPropertyName,
    isArray = null
  ) => {
    let originalData = null;
    let currentReqData = null;

    if (props.originalReqData.bbData !== null) {
      originalData = { ...props.originalReqData.bbData };
    }

    currentReqData = { ...props.originalReqData.bbReqData };

    let data_before = originalData[propertyToCompare];
    let data_after = "";
    let valueOld = isArray
      ? currentReqData[propertyToCompare].toString()
      : currentReqData[propertyToCompare];

    let valueNew = isArray
      ? props.currentData[formPropertyName].toString()
      : props.currentData[formPropertyName];

    data_after = valueOld === valueNew ? valueOld : valueNew;

    let newData = { before: data_before, after: data_after };
    return newData;
  };

  const getAddedOrRemovedData = (propertyToCompare, formPropertyName) => {
    let originalData = null;
    let currentReqData = null;

    if (props.originalReqData.bbData !== null) {
      originalData = { ...props.originalReqData.bbData };
    }

    currentReqData = { ...props.originalReqData.bbReqData };

    let addedData = props.currentData[formPropertyName].filter((kw) => {
      let newVal = "";
      if (!originalData[propertyToCompare].includes(kw)) {
        newVal = kw;
        return kw;
      }
    });

    addedData = addedData.toString();

    let removedData = originalData[propertyToCompare].filter((kw) => {
      let newVal = "";
      if (!props.currentData[formPropertyName].includes(kw)) {
        newVal = kw;
        return kw;
      }
    });

    removedData = removedData.toString();

    let comparedData = { added: addedData, removed: removedData };

    return comparedData;
  };

  useEffect(() => {
    //close modal with esc key
    let tableDiv = document.querySelector("#bb-comparison-table-main-div");

    tableDiv.addEventListener("keydown", (e) => {
      if (e.code === "Escape") {
        props.closeComparisonTable();
      }
    });

    tableDiv.focus();

    // generate data for tables
    // compare implemented data vs request data, if data on the form changes,
    // compare implemented data against the new changes

    // first table data (before/after)
    let titleData = getComparedData("cleantitle", "title");
    let urlData = getComparedData("cleanurl", "url");
    let descData = getComparedData("cleandescription", "description");
    let keywordsData = getComparedData("cleankeywordsraw", "keywords", true);
    let locationsData = getComparedData("cleancountry", "countries", true);
    let aidData = getComparedData("aid", "acronyms", true);

    let trClass = "wrap-text-table-row";

    let firstTableRows = [
      <tr className={trClass} key={"title_before_after"} tabIndex="0">
        <td tabIndex="0">Title</td>
        <td tabIndex="0">{titleData.before}</td>
        <td tabIndex="0">{titleData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"url_before_after"}>
        <td tabIndex="0">URL</td>
        <td tabIndex="0">{urlData.before}</td>
        <td tabIndex="0">{urlData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"desc_before_after"}>
        <td tabIndex="0">Description</td>
        <td tabIndex="0">{descData.before}</td>
        <td tabIndex="0">{descData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"loc_before_after"}>
        <td tabIndex="0">Location</td>
        <td tabIndex="0">{locationsData.before.join(",")}</td>
        <td tabIndex="0">{locationsData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"aid_before_after"}>
        <td tabIndex="0">Refiner Page</td>
        <td tabIndex="0">{aidData.before.join(",")}</td>
        <td tabIndex="0">{aidData.after}</td>
      </tr>,
      <tr tabIndex="0" className={trClass} key={"kw_before_after"}>
        <td tabIndex="0">Keywords</td>
        <td tabIndex="0">{keywordsData.before.join(",")}</td>
        <td tabIndex="0">{keywordsData.after}</td>
      </tr>
    ];

    // second table data (added/removed)
    let keywordChanges = getAddedOrRemovedData("cleankeywordsraw", "keywords");
    let locationChanges = getAddedOrRemovedData("cleancountry", "countries");
    let aidChanges = getAddedOrRemovedData("aid", "acronyms");

    let secondTableRows = (
      <tr tabIndex="0">
        <td tabIndex="0">{keywordChanges.added}</td>
        <td tabIndex="0">{keywordChanges.removed}</td>
        <td tabIndex="0">{locationChanges.added}</td>
        <td tabIndex="0">{locationChanges.removed}</td>
        <td tabIndex="0">{aidChanges.added}</td>
        <td tabIndex="0">{aidChanges.removed}</td>
      </tr>
    );

    setFirstTableRows(firstTableRows);
    setSecondTableRows(secondTableRows);
  }, []);

  return (
    <div
      id="bb-comparison-table-main-div"
      className="table-holder"
      tabIndex="0"
    >
      <div
        id="bb-comparison-tables-div"
        className="container-fluid bb-comparison-tables-div"
      >
        <h2 className="bb-comparison-tables-title">Best Bet Comparison</h2>

        <div id="bb-comp-table-1-div" className="table_1_div">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className={"bb-table-fields-col"}>Fields</th>
                <th className={"bb-table-before-col"}>Before</th>
                <th className={"bb-table-after-col"}>After</th>
              </tr>
            </thead>
            <tbody className={""}>{firstTableRows}</tbody>
          </table>
        </div>
        <div id="bb-comp-table-2-div" className="table_2_div">
          <table className="table table-bordered">
            <thead>
              <tr className={""}>
                <th>Added Keywords</th>
                <th>Removed Keywords</th>
                <th>Added Locations</th>
                <th>Removed Locations</th>
                <th>Added Refiner Pages</th>
                <th>Removed Refiner Pages</th>
              </tr>
            </thead>
            <tbody className={""}>{secondTableRows}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompareTable;
