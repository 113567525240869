import { useState } from "react";
import tooltipIcon from "../../../../assets/images/info.svg";
import BB_Validations from "../../../../validations/BB_Validations";

const BBTextArea = ({
  label,
  placeholder,
  aria,
  tooltip,
  required,
  field,
  classname = "",
  disableFieldProp,
  inputState,
  cssGapClass,
  createTooltip
}) => {
  const { input, storeInput, isValid, setValid = () => {} } = inputState;
  const [errorMessage, setErrorMessage] = useState("");
  const validateField = {
    description: (inputValue) => validateDescription(inputValue),
    comments: (inputValue) => validateComment(inputValue)
  };
  const inputValidatedClass = () => {
    if (input === "") {
      return "";
    }
    if (input.trim() === "" && field == "comments") {
      return "";
    }
    return input && isValid ? "is-valid" : "is-invalid";
  };
  const validateComment = async (comment) => {
    const valid = await BB_Validations.validateComment(comment);

    setErrorMessage(valid.errorMsg);
    setValid(valid.state);
  };
  const validateDescription = async (desc) => {
    const valid = await BB_Validations.validateDescription(desc);

    setErrorMessage(valid.errorMsg);
    setValid(valid.state);
  };
  const handleChange = async (event) => {
    const newInput = event.target.value;

    storeInput(newInput);
    setValid(validateField[field](newInput));
  };
  const showError = () => {
    if (input === "") {
      return false;
    }

    return !input || !isValid;
  };
  return (
    <div className={`form-group ${cssGapClass}`}>
      <img
        id={"bb-form-tooltip-icon-" + label}
        src={tooltipIcon}
        alt={tooltip}
        role="tooltip"
        data-bs-toggle="bb-tooltip"
        data-bs-placement="left"
        data-bs-title={tooltip}
        tabIndex="0"
        onMouseEnter={(e) => {
          createTooltip(e);
        }}
      />
      <label
        className={"label-text " + (required ? "required" : "")}
        htmlFor={label}
        data-testid="bb-field-label"
      >
        {label}
      </label>
      <textarea
        type="text"
        id={"bb-" + field}
        className={`form-control ${classname} ${inputValidatedClass()}`}
        onChange={handleChange}
        data-testid="bb-text-area"
        placeholder={placeholder}
        value={input}
        wrap="soft"
        aria-label={aria}
        disabled={disableFieldProp}
      />
      {showError() ? (
        <div className="bb-invalid-feedback">{errorMessage}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BBTextArea;
