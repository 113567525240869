import { useEffect, useState } from "react";
import TopicCardTooltips from "./TopicCardTooltips";

const TopicTitle = ({
  value,
  inputState,
  setValue,
  titleErrorMessage,
  validateTitle,
  disableField,
  tooltipFor
}) => {
  let topicTitle = {
    tooltip:
      "Topic Titles must be unique, make sure your topic is not already created.",
    alt: "Title Info. For better Search results relevancy for this requested Topic Card, enter the site title as it appears when navigating to the entered URL"
  };

  const [validInputClass, setValidInputClass] = useState("");
  const [errorMessage, setErrorMessage] = useState(titleErrorMessage);
  var titleTimeout;

  useEffect(() => {
    titleTimeout = setTimeout(async () => {
      const isInputValid = await validateTitle(value);
      if (!isInputValid?.isValid) {
        setErrorMessage(isInputValid?.errorMessage);
      }
    }, 480);
    return () => {
      clearTimeout(titleTimeout);
    };
  }, [value]);

  useEffect(() => {
    var finalClass;

    finalClass = value.length > 0 ? "is-valid" : "";
    if (inputState["title"] !== null) {
      finalClass = inputState["title"] ? "is-valid" : "is-invalid";
    }

    setValidInputClass(finalClass);
  }, [inputState]);

  const errorMsg = () => {
    if (validInputClass == "is-invalid") {
      return (
        <div id="validate-input" className="invalid">
          {errorMessage}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const onTitleChangeHandler = (event) => {
    let value = event.target.value;
    setValue("title", value);
  };

  const onBlurChangeHandler = () => {
    validateTitle(value);
  };

  return (
    <>
      <div className="mb-3 title">
        <TopicCardTooltips
          tooltipFor={tooltipFor}
          tooltipAlt={topicTitle.alt}
          tooltipText={topicTitle.tooltip}
        />
        <label
          data-testid="topic-card-label"
          className="label-text required"
          htmlFor="validate-tc-title"
        >
          Topic Title
        </label>
        <input
          id="tc-form-title-input"
          type="text"
          data-testid="tc-input"
          className={`form-control ${validInputClass}`}
          value={value}
          placeholder={"Enter a unique Topic Card title"}
          onChange={onTitleChangeHandler}
          onBlur={onBlurChangeHandler}
          disabled={disableField}
        />
        {errorMsg()}
      </div>
    </>
  );
};

export default TopicTitle;
