import BaseService from "./BaseService";

class GenIAService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }

  
  GetGenIADescription(characterLimit, previousResult, context){
    let self = this;
    return self.axios.post(`${self.baseUrl}getaisummary`, 
        {
            character_limit: characterLimit, 
            previous_result: previousResult, 
            context: context
        },
        this.config);
  }

  GetCrawledData(url) {
    return this.axios.post(
      `${this.baseUrl}getcrawledcontent`,
      { url: url },
      this.config
    );
  }
}
export default GenIAService;