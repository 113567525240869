import info from "../../../../assets/images/info.svg";
import HowToInput from "./HowToInput";
import "../HowToForm.css";

const HowToTitleField = ({
  updateValue,
  inputState,
  setInputState,
  value,
  validateTitle,
  disableField,
  tooltipFor
}) => {
  return (
    <div className="mb-3">
      <img
        id={"ht-form-tooltip-icon-" + tooltipFor}
        role="tooltip"
        src={info}
        alt="tooltip"
        className="ht_tooltip"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        tabIndex={0}
        title="How-To Titles must be unique, make sure your How-To card is not already created"
      />
      <label
        aria-label="How-To Title Field"
        htmlFor="howToTitle"
        className="ht_label required"
      >
        How-To
      </label>
      <HowToInput
        data-testid="ht_title"
        id="ht-form-title-input"
        type="text"
        className="form-control"
        placeholder="Enter a search term or document title"
        field="title"
        state={inputState}
        setState={setInputState}
        updateValue={updateValue}
        value={value}
        validateTitleProp={validateTitle}
        disableField={disableField}
      />
    </div>
  );
};

export default HowToTitleField;
