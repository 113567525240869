import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import tooltipIcon from "../../../../assets/images/info.svg";
import plusIcon from "../../../../assets/images/plus_icon.svg";
import { BsPlusSquare } from "react-icons/bs";
import StoreContext from "../../../../store/storecontext";
import BB_Validations from "../../../../validations/BB_Validations";
import { Tooltip } from "bootstrap";
import { ThemeContext } from "../../../../ThemeContext";
import RocketButton from "./GenAIDescription/RocketBtn";

const BBInput = ({
  label,
  placeholder,
  aria,
  tooltip,
  required,
  field,
  inputState,
  suggestions,
  greenKeywords,
  redKeywords,
  greenTakenKeywords,
  showRepeatedKeywordModal,
  compareTableTrigger,
  disableFieldProp,
  handleFocusOut = () => {},
  cssGapClass,
  createTooltip,
  genIADescription
}) => {
  const [keyword, setKeyword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const store = useContext(StoreContext);
  const urlCheckerService = store.services.urlCheckerService;
  const plusTooltipMsg = "Add keyword(s)";
  const [theme] = useContext(ThemeContext);
  const [tempKeywords, setTempKeywords] = useState({
    green: greenKeywords,
    red: redKeywords
  });
  const tooltipClass = theme === "dark" ? "bb-tooltip-dark" : "";
  useEffect(() => {
    try {
      let plusBtn = document.querySelector('[data-bs-toggle="plus-btn"]');
      new Tooltip(plusBtn, {
        trigger: "hover focus",
        customClass: tooltipClass
      });
    } catch (error) {
      console.log("tooltips may not be intantiated");
    }
  }, [theme]);
  useEffect(() => {
    (async () => {
      if (field == "keywords") {
        for (let index = 0; index < tempKeywords.green.length; index++) {
          if (!inputState.form.keywords.includes(tempKeywords.green[index])) {
            await inputState.storeInput("green", tempKeywords.green[index]);
          }
        }
      }
    })();
  }, [tempKeywords.green]);

  useEffect(() => {
    (async () => {
      if (field == "keywords") {
        for (let index = 0; index < tempKeywords.red.length; index++) {
          if (!inputState.form.keywords.includes(tempKeywords.red[index])) {
            await inputState.storeInput("red", tempKeywords.red[index]);
          }
        }
      }
    })();
  }, [tempKeywords.red]);

  const validateField = {
    //anonymous function because inputValue must be explicit
    url: async (inputValue) => await validateURL(inputValue),
    title: async (inputValue) => await validateTitle(inputValue)
  };
  const validateURL = async (url) => {
    const result = await BB_Validations.validateURL(url, urlCheckerService);
    inputState.setValid(result.urlState);
    setErrorMessage(result.errorMsg);
    return result.urlState;
  };
  const validateTitle = async (title) => {
    const valid = await BB_Validations.validateTitle(title);
    setErrorMessage(valid.errorMsg);
    inputState.setValid(valid.state);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (field === "keywords") {
        newKeyword(keyword);
        setKeyword("");
      }
    }
    if (suggestions && suggestions.show && event.key === "Escape") {
      suggestions.closeSuggestions();
    }
  };
  const handleChange = (event) => {
    const newInput = event.target.value;
    if (field !== "keywords") {
      inputState.storeInput(newInput);
      inputState.setValid(validateField[field](newInput));
    } else {
      setKeyword(newInput);
    }
  };
  const arraysHaveSameElements = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const arr1Set = new Set(arr1);

    for (let i = 0; i < arr2.length; i++) {
      if (!arr1Set.has(arr2[i])) {
        return false;
      }
    }

    return true;
  };

  const newKeyword = async () => {
    if (keyword !== "") {
      let regex = urlCheckerService.textRegex;
      if (keyword.match(regex)) {
        inputState.setKeywordWarningModal({
          show: true,
          msg: 'Please do not include the special characters \\ and "'
        });
        setKeyword("");
        return;
      } else {
        inputState.setKeywordWarningModal({
          show: false,
          msg: ""
        });
      }
      const keywordList = keyword.split(",");
      setKeyword("");
      for (let index = 0; index < keywordList.length; index++) {
        if (keywordList[index] !== "") {
          const allKeywords = [...greenKeywords, ...redKeywords];
          if (
            allKeywords.some(
              (kw) => kw.toLowerCase() === keywordList[index].toLowerCase()
            )
          ) {
            showRepeatedKeywordModal();
          } else {
            const valid = await BB_Validations.validateKeyword(
              keywordList[index],
              inputState.form.countries,
              inputState.form.acronyms
            );
            if (
              !valid.isKeywordValid &&
              !greenTakenKeywords.includes(keyword)
            ) {
              setTempKeywords({
                green: tempKeywords.green,
                red: [...tempKeywords.red, keywordList[index]]
              });
              inputState.setKeywordWarningModal({
                msg: `Keyword for the current Refiner Page: ${valid.refPage}, and Location: ${valid.location} is repeated in more than 3 Best Bets. Consider changing the keyword to a synonym or related term and try again.`,
                show: true
              });
            } else {
              setTempKeywords({
                green: [...tempKeywords.green, keywordList[index]],
                red: tempKeywords.red
              });
            }
          }
        }
      }
    }
  };
  useEffect(() => {
    if (
      field == "keywords" && //only validate if there are changes
      (!arraysHaveSameElements(greenKeywords, tempKeywords.green) ||
        !arraysHaveSameElements(redKeywords, tempKeywords.red))
    ) {
      (() => {
        const valid = BB_Validations.validateKeywordField(
          tempKeywords.green.length,
          tempKeywords.red.length
        );
        setErrorMessage(valid.errorMsg);
      })();
    }
  }, [tempKeywords.green, tempKeywords.red]);
  useEffect(() => {
    if (field == "keywords") {
      if (arraysHaveSameElements(tempKeywords.green, greenKeywords)) {
        if (!arraysHaveSameElements(tempKeywords.red, redKeywords)) {
          setTempKeywords({ green: tempKeywords.green, red: redKeywords });
        }
      } else {
        setTempKeywords({ green: greenKeywords, red: tempKeywords.red });
      }
    }
  }, [greenKeywords, redKeywords]);
  const inputValidatedClass = () => {
    if (inputState.input === "") {
      return "errorMsg";
    }
    if (field === "keywords") {
      let regex = urlCheckerService.textRegex;

      if (keyword.match(regex)) {
        return inputState.isValid === false
          ? 'Please do not include the special characters \\ and "'
          : !inputState.isValid
          ? "is-invalid"
          : "is-valid";
      }
      return inputState.isValid == undefined
        ? ""
        : inputState.isValid
        ? "is-valid"
        : "is-invalid";
    }
    return inputState.isValid ? "is-valid" : "is-invalid";
  };
  const showError = () => {
    if (field === "keywords") {
      return inputValidatedClass() == "is-invalid";
    }
    if (inputState.input === "") {
      return false;
    }

    return !inputState.input || !inputState.isValid;
  };

  return (
    <div className={"form-group " + cssGapClass}>
      <img
        id={"bb-form-tooltip-icon-" + label}
        src={tooltipIcon}
        role="tooltip"
        alt={tooltip}
        data-bs-toggle="bb-tooltip"
        data-bs-placement="left"
        data-bs-title={tooltip}
        tabIndex="0"
        onMouseEnter={(e) => {
          createTooltip(e);
        }}
      />
      <label
        className={`label-text ${required ? "required" : ""}`}
        htmlFor={label}
        data-testid="bb-field-label"
      >
        {label}
      </label>
      <input
        type="text"
        autoComplete="off"
        id={"bb-" + field}
        className={`form-control ${inputValidatedClass()}`}
        data-testid={"bb-input-"+field}
        placeholder={placeholder}
        aria-label={aria}
        value={field === "keywords" ? keyword : inputState.input}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleFocusOut}
        key={`${field}-input`}
        disabled={disableFieldProp}
      />
      {showError() && <div className="bb-invalid-feedback">{errorMessage}</div>}
      {field === "keywords" && !compareTableTrigger && !disableFieldProp ? (
        <BsPlusSquare
          id={"bb-" + { label } + "-input-plus-btn"}
          data-testid="bb-plus-btn"
          tabIndex="0"
          role="tooltip"
          data-bs-toggle="plus-btn"
          data-bs-placement="top"
          data-bs-title={plusTooltipMsg}
          id="kw_plus_icon"
          aria-label="Add step"
          type="button"
          className={`plus-icon-bb ${showError() ? "error" : ""}`}
          alt="plus butn"
          src={plusIcon}
          onClick={newKeyword}
          onKeyDown={newKeyword}
        />
      ) : 
      field == "url" ? <RocketButton url={inputState.input} genIADescription={genIADescription} isValidInput={inputState.isValid}/> : 
          (
        <></>
      )}
    </div>
  );
};

export default BBInput;
