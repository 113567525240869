import "./BBPreview.css";
const BBPreview = ({ title, url, description }) => {
  return (
    <span className="preview-bb" data-testid="test-preview-bb">
      <span>
        <a id="preview-title-bb" data-testid="preview-title-bb" rel="noopener" href={url} className="preview-title-bb">
          {title}
        </a>
      </span>
      <span>
        <span id="preview-url-bb" data-testid="preview-url-bb" className="preview-url-bb">
          {url}
        </span>
      </span>
      <span>
        <span id="preview-desc-bb" data-testid="preview-desc-bb" className="preview-desc-bb">
          {description}
        </span>
      </span>
    </span>
  );
};

export default BBPreview;