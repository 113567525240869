class BaseService {
  constructor(axios, apiUrl) {
    this.app_id = process.env.REACT_APP_ID;
    this.axios = axios;
    this.baseUrl = `${apiUrl}`;
    this.config = {
      headers: {
        searchtoolsauth:
          "Bearer " +
          sessionStorage.getItem("searchtools." + this.app_id + ".token")
      }
    };
  }

  setToken(token) {
    this.config.headers.searchtoolsauth = "Bearer " + token;
  }
}

export default BaseService;
