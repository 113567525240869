import BaseService from "./BaseService";

class YammerService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }
  delegatesToPost = "";
  //---------------------------------------------------------------------------------------------------------------------------
  PostToYammer(requestId, index, type, isI, rqstBody, eid = null) {
    let self = this;
    return self.axios.post(
      `${self.baseUrl}request/${requestId}/yammerpost?i=${index}&t=${type}&isI=${isI}&eid=${eid}`,
      rqstBody,
      this.config
    );
  }
  PostYammerComment(eventid, rqstBody) {
    let self = this;
    return self.axios.post(
      `${self.baseUrl}yammer/${eventid}/comment`,
      rqstBody,
      this.config
    );
  }
  async GetUserIdYammer(eid) {
    let self = this;
    return await self.axios.get(
      `${self.baseUrl}yammer/${eid}/userid`,
      this.config
    );
  }
  //---------------------------------------------------------------------------------------------------------------------------
  async GetOwnerUserIdForYammer(owner) {
    if (owner === "localhost") {
      return "localhost";
    } else {
      const r = await this.GetUserIdYammer(owner);
      return "[[user:" + r.data + "]]";
    }
  }
  AddDelegatesUserIdForYammer(selfRef) {
    for (let i = 0; i < selfRef.form.selectedeids.length; i++) {
      this.GetUserIdYammer(selfRef.form.selectedeids[i]).then((r) => {
        selfRef.form.delegates.push({
          eid: selfRef.form.selectedeids[i],
          userid: r.data
        });
      });
    }
  }
  PostBestBetToYammer(
    bestbetrequest,
    isAdmin,
    reqId,
    ownerYammerId,
    msg = null
  ) {
    let self = this;
    let streamBody = [];
    let adminCreation =
      !isAdmin && bestbetrequest.cleanrequesttype === "Creation" ? "new" : "";
    let implementedAdmin = isAdmin ? " and implemented" : ",";
    let requesttype =
      bestbetrequest.cleanrequesttype === "Creation"
        ? ""
        : `${bestbetrequest.cleanrequesttype.toLowerCase()} `;
    let initialMsg = `A ${adminCreation} Best Bet ${requesttype}request was submitted${implementedAdmin}`;
    let postMsg;
    let msgBody = ` (${reqId} -  ${bestbetrequest.cleantitle.replace(
      '"',
      "&quot;"
    )}). ${
      process.env.REACT_APP_SEARCHTOOLS_URL
    }/promote/bestbet/request/${reqId}. `;
    if (msg === null) {
      postMsg = initialMsg + msgBody + ownerYammerId;
    } else {
      postMsg = msg;
    }

    streamBody.push(postMsg);
    return self
      .PostToYammer(
        reqId,
        process.env.REACT_APP_BBREQUEST_IDX,
        "_doc",
        false,
        streamBody
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log("error in PostBestBetToYammer : " + e);
      });
  }
  PostFeedbackOnVivaEngage(feedbackData, feedbackId) {
    try {
      let feedbackMsg = "";
      let feedback = [];

      if (feedbackData.rating === "good") {
        feedbackMsg = "Good experience 🙂";
      } else {
        feedbackMsg = "Bad experience 🙁";
      }

      feedbackMsg = `${feedbackMsg} \n\n${feedbackData.feedback || ""}`;
      feedback.push(feedbackMsg);

      this.PostToYammer(
        feedbackId,
        process.env.REACT_APP_FEEDBACK_IDX,
        "_doc",
        false,
        feedback,
        feedbackData.eid
      );
    } catch (error) {
      console.log("Error on posting Feedback in Viva Engage");
    }
  }
  TopicCardComments(action,commentsData) {
    let postMsg;
    const tagging =
      commentsData.tagOwnerOnYammer + " " + commentsData.delegatesToPost;
    let initialMessage = "A new Topic Card request was submitted";
    const requestData =
      ", " +
      process.env.REACT_APP_SEARCHTOOLS_URL +
      "/promote/topiccard/request/" +
      commentsData.tcrequestid +
      " (" +
      commentsData.tcrequestid +
      " - " +
      commentsData.title.replace('"', "&quot;") +
      "). " +
      tagging;

    switch (action) {
      case "implement":
        postMsg = "Your request has been implemented. " + tagging;
        break;
      case "admin-implement":
        postMsg = initialMessage + " and implemented" + requestData;
        break;
      case "initial":
        postMsg = initialMessage + requestData;
        break;
        case "save-changes":
        postMsg = "This request was modified. " + tagging;
        break;
        case "cancel":
        postMsg = "This request was cancelled. " + tagging;
        break;
        case "remove":
          postMsg = "This request was removed. " + tagging;
          break;
          case "reject":
        postMsg = "Your request was not approved. Please review it for more details. " + tagging;
        break;
      default:
        break;
    }
    return postMsg;
  }
}
export default YammerService;
