import TopicCardTextareaInput from "./TopicCardTextareaInput";
import TopicCardTooltips from "./TopicCardTooltips";

const CommentsInput = ({
  updateValue,
  inputState,
  setInputState,
  tooltipFor
}) => {
  const comments = {
    tooltip:
      "This comment field can also be used to provide additional information requested by the Search CoE team as part of the Topic Card request process.",
    alt: "Comments Info. This comment field can also be used to provide additional information requested by the Search C o E team as part of the Topic Card request process.",
    placeholder:
      "If needed, provide additional comments/details to be considered as part of this Topic Card request."
  };

  return (
    <>
      <TopicCardTooltips
        tooltipFor={tooltipFor}
        tooltipAlt={comments.alt}
        tooltipText={comments.tooltip}
      />
      <TopicCardTextareaInput
        placeholder={comments.placeholder}
        labelText="Comments"
        required={false}
        field="comments"
        state={inputState}
        setState={setInputState}
        updateValue={updateValue}
      />
    </>
  );
};

export default CommentsInput;
