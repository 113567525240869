import React from "react";

function FilterOption({ value, selectOption }) {
  let idValue = `header-filter-option-${value}`;
  return (
    <li role="presentation" className="option-li">
      <a
        id={idValue}
        tabIndex={1}
        value={value}
        target="_self"
        role="menuitem"
        onClick={() => selectOption(value)}
        onKeyDown={(e) => e.key === "Enter" && selectOption(value)}
        data-testid={`option-${value.toLowerCase()}`}
        className="dropdown-item"
      >
        {value}
      </a>
    </li>
  );
}

export default FilterOption;
