export const createVivaEngageMessage = (request) => {
  const action = request.action;
  const requestId = request.id;
  const term = request.term;
  const urlToRequest = request.urlToRequest;
  const ownerYammerId = request.ownerYammerId;

  switch (action) {
    case "Submit for Removal":
      return `A Synonym removal request was submitted, (${requestId} - ${term}) \n ${urlToRequest} - ${ownerYammerId}`;
    case "Remove":
      return `A Synonym removal request was submitted and implemented, (${requestId} - ${term}) \n ${urlToRequest} - ${ownerYammerId}`;
    case "Implement":
      return `Your removal request has been implemented. Please review it for more details. ${ownerYammerId}`;
    case "Cancel Request":
      return `This request was cancelled. ${ownerYammerId}`;
    case "Reject":
      return `Your request was not approved. Please review it for more details. ${ownerYammerId}`;
    default:
      return `This request was modified. ${ownerYammerId}`;
  }
};
