import "./Synonyms.css";
import TopicCardTooltips from "../TopicCardTooltips";
import { useEffect, useState, useContext } from "react";
import TopicCardPills from "../TopicCardPills";
import StoreContext from "../../../../../store/storecontext";
import TopicCardValidations from "../../../../../validationClasses/TopicCardValidations";

const Synonyms = (props) => {
  const [synonymsList, setSynonymsList] = useState([]);
  const [displaySynonyms, setDisplaySynonyms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const store = useContext(StoreContext);

  useEffect(() => {
    const abortFetchSynonyms = new AbortController();

    if (props.keywords.length > 0 || props.keywordsForReview.length > 0) {
      (async () => {
        try {
          let totalKeywords = props.keywords.concat(props.keywordsForReview);
          let newSynonyms = await fetchSynonyms(
            totalKeywords,
            abortFetchSynonyms
          );

          setSynonymsList(newSynonyms);
          setIsLoading(false);
        } catch (error) {
          if (error.code === "ERR_CANCELED") {
            console.log("There was an interruption in getting synonyms");
          } else {
            console.log("Error in getting synonyms");
            setIsLoading(false);
            if (synonymsList.length === 0) {
              setDisplaySynonyms(false);
            }
          }
        }
      })();
    } else {
      setSynonymsList([]);
      setDisplaySynonyms(false);
    }
    return () => {
      abortFetchSynonyms.abort();
    };
  }, [props.keywords, props.keywordsForReview]);

  useEffect(() => {
    let totalKeywords = props.keywords.concat(props.keywordsForReview);
    if (
      synonymsList.length > 0 &&
      !props.disableField &&
      totalKeywords.length > 0
    ) {
      setDisplaySynonyms(true);
    } else {
      setDisplaySynonyms(false);
    }
  }, [synonymsList]);

  const fetchSynonyms = async (kwsArray, abortController) => {
    let totalKeywords = props.keywords.concat(props.keywordsForReview);
    let synonyms = [];
    synonyms = await store.services.synonymsService.GetSynonymsByKeywords(
      kwsArray,
      abortController
    );

    try {
      synonyms = JSON.parse(synonyms.data);
    } catch {
      synonyms = synonyms.data;
    }

    if (synonyms.length === 0) {
      return synonyms;
    } else {
      setIsLoading(true);
      setDisplaySynonyms(true);

      synonyms = synonyms.filter((synonym) => !totalKeywords.includes(synonym));
      let newSynonyms = synonyms.sort();
      newSynonyms = await validateSynonyms(newSynonyms);

      return newSynonyms;
    }
  };

  const validateSynonyms = async (synonyms) => {
    let validSynonyms = [];
    try {
      let promises = [];
      for (let i = 0; i < synonyms.length; i++) {
        let keyword = {
          keyword: synonyms[i],
          location: props.selectedLocation,
          refinerPage: props.selectedRefinerPage,
          tcrequestid: props.tcrequest,
          abortController: null,
          service: store.services.topicCardService
        };
        promises.push(TopicCardValidations.validateKeywords(keyword));
      }
      const results = await Promise.all(promises);
      results.forEach((isValid, i) => {
        if (isValid.isValid) {
          validSynonyms.push(synonyms[i]);
        }
      });
      return validSynonyms;
    } catch (error) {
      if (error.code === "ERR_CANCELED") {
        console.log("Synonyms validation was interrupted");
      } else {
        console.log("Synonyms couldn't be validated");
        setIsLoading(false);
        if (synonymsList.length === 0) {
          setDisplaySynonyms(false);
        }
      }
    }
    return validSynonyms;
  };
  const synonymsTitle = () => {
    return (
      <>
        <TopicCardTooltips
          tooltipAlt={"Suggestions to the entered keywords"}
          tooltipText={"Suggestions to the entered keywords"}
        />
        <h6
          alt="Related keyword suggestions"
          className="label-text tc-synonyms-box-title"
        >
          Related keyword suggestions
        </h6>
        {isLoading ? (
          <div
            className="spinner-border spinner-border-sm tc-synonym-spinner"
            role="status"
          ></div>
        ) : (
          <></>
        )}
      </>
    );
  };
  return (
    <>
      <div data-testid="tc-synonyms">
        {displaySynonyms ? (
          <>
            {synonymsTitle()}
            {!isLoading ? (
              <div className="card" id="synonyms-box">
                <TopicCardPills
                  selectedTargets={synonymsList}
                  setSelectedTarget={props.setKeywords}
                  setSynonyms={setSynonymsList}
                  keywords={props.keywords}
                  validate={() => {}}
                  arrayForReview={props.keywordsForReview}
                  field="synonyms"
                  hasReviewArray={false}
                  disableField={false}
                  revalidateForm={() => {}}
                  setModalMessage={props.setModalMessage}
                  setOpenKeywordsModal={props.setOpenKeywordsModal}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Synonyms;
