import { useEffect, useState, useContext } from "react";
import BBPills from "../Forms/BBForm/FormComponents/BBPills";
import HowToPills from "../Forms/HowToForm/FormComponents/HowToPills";
import "./Synonyms.css";
import { Tooltip } from "bootstrap";
import tooltipIcon from "../../assets/images/info.svg";
import StoreContext from "../../store/storecontext";
import { ThemeContext } from "../../ThemeContext";

const Synonyms = ({
  keywords,
  keywordsForReview,
  isFormReadOnly,
  countries,
  acronyms,
  addKeyword,
  Validations,
  formName,
  reqid
}) => {
  const [synonymsList, setSynonymsList] = useState([]);
  const [showSynonymBox, setShowSynonymBox] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const store = useContext(StoreContext);
  const [theme] = useContext(ThemeContext);
  const tooltipClass = theme === "dark" ? "bb-tooltip-dark" : "";
  const labelTooltip = "Suggestions to the entered keywords";

  const initializeLabelTooltip = () => {
    if (showLabel()) {
      const labelTooltip = document.querySelector(
        '[data-bs-toggle="label-tooltip"]'
      );
      if(labelTooltip!==null){
        new Tooltip(labelTooltip, {
          trigger: "hover focus",
          customClass: tooltipClass
        });
      }
    }
  };
 
  const showLabel = () => {
    return isDataLoading || showSynonymBox;
  };
  const isAlreadyAKeyword = (synonym) => {
    return keywords.includes(synonym) || keywordsForReview.includes(synonym);
  };
  const isAlreadyASynonym = (synonym) => {
    return synonymsList.includes(synonym);
  };
  const fetchSynonyms = async (kwsArray, abortController) => {
    let totalKeywords = keywords.concat(keywordsForReview);
    let synonyms = [];
    synonyms = await store.services.synonymsService.GetSynonymsByKeywords(
      kwsArray,
      abortController
      );
    if(typeof(synonyms.data)==="string"){
      synonyms = JSON.parse(synonyms.data);
    } else {
      synonyms = synonyms.data;
    }
    

    if (synonyms.length === 0) {
      return synonyms;
    } else {
      setIsDataLoading(true);
      setShowSynonymBox(true);

      synonyms = synonyms.filter((synonym) => !totalKeywords.includes(synonym));
      let sortedSynonyms = synonyms.sort();
      const newSynonyms = await validateSynonyms(sortedSynonyms);
      return newSynonyms;
    }
  };
  const validateSynonyms =  async (synonyms) => {
    let validSynonyms = [];
    try {
      var promises = [];
      for (let i = 0; i < synonyms.length; i++) {
        let isValid = Validations.validateKeyword(
          synonyms[i],
          countries,
          acronyms,
          reqid
        );
        promises.push(isValid);
      }
       const results= await Promise.all(promises);
       results.forEach((isValid,i)=>{
          if (isValid.isKeywordValid && !isAlreadyAKeyword(synonyms[i] && !isAlreadyASynonym(synonyms[i]))) {
            validSynonyms.push(synonyms[i]);
          }
        })
      return validSynonyms;
    } catch (error) {
      if (error.code === "ERR_CANCELED") {
        console.log("Synonyms validation was interrupted");
      } else {
        console.log("Synonyms couldn't be validated");
        setIsDataLoading(false);
        if (synonymsList.length === 0) {
          setShowSynonymBox(false);
        }
        throw error;
      }
    }
  };
  const addSynonymAsKeyword = async (synonym) => {
    await setSynonymsList((prevstate)=>synonymsList.filter((s) => s !== synonym));
    const added = await addKeyword(synonym);
    return added;
  };
  

  useEffect(() => {
    const abortFetchSynonyms = new AbortController();

    if (keywords.length > 0 || keywordsForReview.length > 0) {
            let totalKeywords = keywords.concat(keywordsForReview);
             fetchSynonyms(
              totalKeywords,
              abortFetchSynonyms
            ).then(newSynonyms=>{
              setSynonymsList(newSynonyms);
              setIsDataLoading(false);
            }).catch(error=>{
              if (error.code === "ERR_CANCELED") {
                console.log("There was an interruption in getting synonyms");
                if (totalKeywords.length === 0) {
                  setSynonymsList([]);
                }
              } else {
                console.log("Error in getting synonyms");
                setIsDataLoading(false);
                if (synonymsList.length === 0) {
                  setShowSynonymBox(false);
                  setSynonymsList([]);
                }
              }
            });
      } else {
      setSynonymsList([]);
    }
    return () => {
      abortFetchSynonyms.abort();
    };
  }, [keywords, keywordsForReview]);
  useEffect(() => {
    if (synonymsList.length > 0 && !isFormReadOnly) {
      setShowSynonymBox(true);
    } else setShowSynonymBox(false);
  }, [synonymsList]);
  useEffect(() => {
    initializeLabelTooltip();
  }, [isDataLoading, showSynonymBox]);
  useEffect(() => {
    initializeLabelTooltip();
  }, [theme]);
  return (
    <>
    {
      !isFormReadOnly && 
      <div id={`${formName}-synonymsContainer`}>
      {showLabel() && (
        <>
          <img
            src={tooltipIcon}
            className="label-tooltip"
            role="tooltip"
            alt={labelTooltip}
            data-bs-toggle="label-tooltip"
            data-bs-placement="left"
            data-bs-title={labelTooltip}
            tabIndex="0"
          />
          <p className="synonyms-box-title label-text">
            Related keyword suggestions
          </p>
        </>
      )}
      {isDataLoading && (
        <div
          className="spinner-synonyms spinner-border spinner-border-sm"
          role="status"
        ></div>
      )}
      {showSynonymBox && (
        <div className="card form-control" id="synonyms-box">
          {
            formName ==="howto" ? 
            <HowToPills 
              selectedTargets={synonymsList}
              field="synonyms"
              removeGreen={async (pill) =>await addSynonymAsKeyword(pill)}
              setSelectedTarget={setSynonymsList}
            /> :
            
            <BBPills
              greenPills={synonymsList}
              group="synonyms"
              removeGreen={async (pill) =>await addSynonymAsKeyword(pill)}
            />
          }
          
        </div>
      )}
    </div>
    }
    </>
  );
};

export default Synonyms;
