import "./EidSuggestion.css";

import { useEffect, useState } from "react";

const EidSuggestion = (props) => {
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [eidResults, setEidResults] = useState([]);
  const [timeId, setTimeId] = useState(0);

  const getEmployeesByEID = async (eidValue) => {
    let delegate = eidValue;

    let r = await props.searchToolsService.GetEmployeesByEID(
      delegate.toLowerCase(),
      false
    );
    let queryResults = null;
    try {
      queryResults = r.data.hits.hits;
      if (queryResults.length > 0) {
        let listToDisplay = [];

        queryResults.map((emp) => {
          let currEmp = emp._source;
          let empEid = currEmp.enterpriseid;
          let allEmpData = `${currEmp.firstname} ${currEmp.lastname} (${empEid})`;
          let empObj = {
            allEmpData: allEmpData,
            eid: empEid
          };
          listToDisplay.push(empObj);
        });

        setEidResults(listToDisplay);
        setShowSuggestionList(true);
      }
    } catch (error) {
      console.log("error on eidsuggestion");
    }
  };

  const addUser = (event) => {
    let eidToAdd = event.target.dataset.eid;
    props.addUserMethod(event, eidToAdd);
    setShowSuggestionList(false);
  };

  useEffect(() => {
    clearTimeout(timeId);
    let currId = setTimeout(() => {
      try {
        let elemRef = document.getElementById(`${props.inputID}`);

        let delegateValue = elemRef.value;

        if (delegateValue.length > 3) {
          getEmployeesByEID(delegateValue);
        } else {
          setShowSuggestionList(false);
        }
      } catch (error) {
        console.log("element not loaded on the dom yet!");
      }
    }, 700);

    if (currId !== timeId) {
      setTimeId(currId);
    }
  }, [props.eidSearchTrigger]);

  useEffect(() => {
    setInterval(() => {
      try {
        let elemRef = document.getElementById(`${props.inputID}`);

        let delegateValue = elemRef.value;
        if (delegateValue.length < 3) {
          setShowSuggestionList(false);
        }
      } catch (error) {}
    }, 500);
  }, []);

  return (
    <div className="eid-suggestion-list-div">
      {showSuggestionList && (
        <ul
          className="eid-suggestion-results-ul"
          aria-label="Choose a delegate from the list"
        >
          {eidResults.map((emp, i) => {
            return (
              <li
                id={"eid_suggestion_" + i}
                key={"eid_suggestion_" + i}
                className="eid-suggestion-results-li"
                data-eid={emp.eid}
                onClick={addUser}
              >
                {emp.allEmpData}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const setInpuntValueWithJs = (newValue, inputID) => {
  let elemRef = document.getElementById(`${inputID}`);
  elemRef.value = "";
  // for ui
  elemRef.value = newValue;
};

const selectSuggestionResult = (e, inputID) => {
  try {
    let elemRefs = document.querySelectorAll(`${".eid-suggestion-results-li"}`);

    let isElemSelected = false;
    let currIndexVal = -1;

    for (let i = 0; i < elemRefs.length; i++) {
      const el = elemRefs[i];
      if (
        el.classList.contains("is-active") &&
        el.classList.contains("to-select") &&
        !isElemSelected
      ) {
        isElemSelected = true;
        let id = el.id.replace("eid_suggestion_", "");
        currIndexVal = parseInt(id);
      }
    }

    if (e.code === "ArrowDown") {
      if (currIndexVal >= 0) {
        elemRefs[currIndexVal].classList.remove("is-active");
        elemRefs[currIndexVal].classList.remove("to-select");
        elemRefs[currIndexVal + 1].classList.add("is-active");
        elemRefs[currIndexVal + 1].classList.add("to-select");
      } else if (currIndexVal === -1) {
        elemRefs[currIndexVal + 1].classList.add("is-active");
        elemRefs[currIndexVal + 1].classList.add("to-select");
      }

      if (currIndexVal === -1) {
        setInpuntValueWithJs(elemRefs[0].dataset.eid, inputID);
      } else {
        setInpuntValueWithJs(elemRefs[currIndexVal + 1].dataset.eid, inputID);
      }
    } else if (e.code === "ArrowUp") {
      if (currIndexVal >= 0) {
        elemRefs[currIndexVal].classList.remove("is-active");
        elemRefs[currIndexVal].classList.remove("to-select");
        elemRefs[currIndexVal - 1].classList.add("is-active");
        elemRefs[currIndexVal - 1].classList.add("to-select");
      } else if (currIndexVal === -1) {
        elemRefs[0].classList.add("is-active");
        elemRefs[0].classList.add("to-select");
      }

      if (currIndexVal === -1) {
        setInpuntValueWithJs(elemRefs[0].dataset.eid, inputID);
      } else {
        setInpuntValueWithJs(elemRefs[currIndexVal - 1].dataset.eid, inputID);
      }
    }
  } catch (error) {}
};

export { EidSuggestion, selectSuggestionResult };
