import { useState, useEffect } from "react";
import TopicCardValidations from "../../../../validationClasses/TopicCardValidations";

const TopicCardTextareaInput = ({
  placeholder,
  labelText,
  required,
  field,
  state,
  setState,
  updateValue
}) => {
  const [inputValue, setInputValue] = useState("");
  const [validInputClass, setValidInputClass] = useState("");
  const [textAreaErrorMessage, setTextAreaErrorMessage] = useState("");

  const inputValidation = {
    description: (input) => validateDescription(input),
    comments: (input) => validateComments(input)
  };

  useEffect(() => {
    inputValidation[field](inputValue);
  }, [inputValue]);

  const validateDescription = () => {
    const isInputValid = TopicCardValidations.validateDescription(inputValue);
    validateTextArea(isInputValid);
  };
  const validateComments = () => {
    const isInputValid = TopicCardValidations.validateComments(inputValue);
    state[field]=isInputValid.isValid;
    setState(state);
    validateTextArea(isInputValid);
  };
  const validateTextArea = (isInputValid) => {
    updateValue(field, inputValue);
    if (inputValue.length === 0) {
      setValidInputClass("");
    } else {
      state[field]=isInputValid.isValid;
      setState(state);
      setTextAreaErrorMessage(isInputValid.errorMessage);
      if (isInputValid.isValid) {
        setValidInputClass("is-valid");
      } else {
        setValidInputClass("is-invalid");
        errorMsg();
      }
    }
  };
  const errorMsg = () => {
    if (state[field] || state[field] === null) {
      return <></>;
    } else {
      return (
        <div id="validate-input" className="invalid">
          {textAreaErrorMessage}
        </div>
      );
    }
  };

  const onChangeTextArea = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <label
        data-testid="topic-card-label"
        className={`label-text ${required ? "required" : ""}`}
      >
        {labelText}
      </label>
      <textarea
        className={`form-control ${validInputClass}`}
        data-testid="textarea-input"
        id="description"
        rows="4"
        max-rows="8"
        value={inputValue}
        placeholder={placeholder}
        onChange={(event) => onChangeTextArea(event)}
      ></textarea>
      {!state.comments && errorMsg()}
    </>
  );
};

export default TopicCardTextareaInput;
