import services from "../store/services";

const htService = services.howToService;
const stService = services.searchToolsService;
const urlCheckerService = services.urlCheckerService;

const titleTooLong = (title) =>{
  let titleCharLimit = process.env.REACT_APP_TITLE_LIMIT;
  if(title.length > titleCharLimit){
    return{
      isValid: false,
      errorMessage: `Enter a title that is ${titleCharLimit} characters or less.`
    }
  } else return {isValid:true};
}

const invalidChars = (title) =>{
  let regex = urlCheckerService.textRegex;
  if (title.match(regex)) {
    return {
      isValid: false,
      errorMessage: 'Please do not include the special characters \\ and "'
    };
  } else return {isValid:true};
}

const validateTitle = async (title) => {
  if(!invalidChars(title).isValid){
    return invalidChars(title);
  }
  if (!titleTooLong(title).isValid) {
    return titleTooLong(title);
  }
  if (title) {
    let usedTitle = await htService.GetHowToByTitle(title);
    let totalHits = usedTitle.data.hits.total.value;
    if (totalHits > 0) {
      const results = usedTitle.data.hits.hits;
      const isInvalid = results.some((res) => {
        return decodeURI(res._source.cleantitle) === title;
      });
      return ({
        isValid: !isInvalid,
        errorMessage:
          "This title is already taken; please enter a unique How-To title"
      });
    } else {
      return ({
        isValid: true
      });
    }
  }
};

const validateStep = async (step) => {
  let stepsCharLimit = process.env.REACT_APP_STEPS_LIMIT;
  let errorMessage = "";
  let isValid = step.length <= stepsCharLimit;
  let regex = urlCheckerService.textRegex;
  if (step.match(regex)) {
    return {
      isValid: false,
      errorMessage: 'Please do not include the special characters \\ and "'
    };
  }
  if (step.length > stepsCharLimit) {
    return {
      errorMessage: `Enter a step that is ${stepsCharLimit} characters or less.`
    };
  }
  errorMessage = isValid ? "" : errorMessage;
  return {
    isValid: isValid,
    errorMessage: errorMessage
  };
};

const validateURL = async (url, urlCheckerServRef) => {
  url = url || "";
  let cleanurlLowercase = url.toLowerCase();
  let regex = urlCheckerServRef.urlRegex;
  if (!url.match(regex)) {
    return {
      isValid: false,
      errorMessage: "This is not a valid url format"
    };
  }
  let myLearningRx = urlCheckerServRef.myLearningRx;
  let collectionsRx = urlCheckerServRef.collectionsRx;
  let kxContributionRx = urlCheckerServRef.kxContributionRx;
  let internalSiteRx = urlCheckerServRef.internalSiteRx;
  let URLRegExp2 = urlCheckerServRef.URLRegExp2;
  let URLRegExp = urlCheckerServRef.URLRegExp;

  const invalidInternalSiteUrl =
    "Please enter a live/active site URL for this How-To Card.";
  const invalidMyLrnUrlMsg =
    "Please add an online training that is globally available.";
  //------
  if (cleanurlLowercase && myLearningRx.test(cleanurlLowercase)) {
    const myLearning = await urlCheckerServRef.GetMyLearningHits(url);
    const totalHits = myLearning.data.hits.total.value;
    if (totalHits === 0) {
      return { isValid: false, errorMessage: invalidMyLrnUrlMsg };
    } else {
      return { isValid: true, errorMessage: "" };
    }
  } else if (cleanurlLowercase && collectionsRx.test(cleanurlLowercase)) {
    const collections = await urlCheckerServRef.GetCollectionHits(url);
    let collectionsErrorMessage =
      "Please enter an official collection URL for this How-To Card; for assistance, please contact the KX team.";
    const totalHits = collections.data.hits.total.value;
    if (totalHits === 0) {
      return { isValid: false, errorMessage: collectionsErrorMessage };
    } else {
      return { isValid: true, errorMessage: "" };
    }
  } else if (cleanurlLowercase && kxContributionRx.test(cleanurlLowercase)) {
    const kx = await urlCheckerServRef.GetKXContributionHits(url);
    const kxContributionErrorMessage =
      "Please enter an approved KX contribution URL for this How-To Card; for approval assistance, please contact the KX team.";
    const totalHits = kx.data.hits.total.value;
    if (totalHits === 0) {
      return { isValid: false, errorMessage: kxContributionErrorMessage };
    } else {
      return { isValid: true, errorMessage: "" };
    }
  } else if (cleanurlLowercase && internalSiteRx.test(cleanurlLowercase)) {
    const internalSiteHome = getHomeUrl(cleanurlLowercase).toLowerCase();
    const internalSites = await urlCheckerServRef.GetInternalSitesData(
      url,
      internalSiteHome
    );
    const totalHits = internalSites.data.hits.total.value;
    if (totalHits === 0) {
      return {
        isValid: false,
        errorMessage: invalidInternalSiteUrl,
        internalSitesStatus: false,
        showDisclaimerUrl: false
      };
    } else {
      return {
        isValid: true,
        errorMessage: "",
        internalSitesStatus: true,
        showDisclaimerUrl: true
      };
    }
  } else if (
    cleanurlLowercase &&
    URLRegExp.test(cleanurlLowercase) &&
    URLRegExp2.test(cleanurlLowercase)
  ) {
    return { isValid: true, errorMessage: "" };
  } else {
    return {
      isValid: false,
      errorMessage: "This is not a valid url format"
    };
  }
};

const validateRefinerPage = (refinerPage) => {
  return { isValid: refinerPage.length > 0, errorMessage: "" };
};
const validateLocation = (countries) => {
  return { isValid: countries.length > 0, errorMessage: "" };
};

const validateKeyword = async (
  keyword,
  countries,
  aid,
  howto_req_id = null
) => {
  let repeatedKeywords = await htService.GetHowToCardsByKeyword(
    keyword,
    aid,
    countries
  );

  let totalHits = repeatedKeywords.data.hits.total.value;
  if (totalHits === 0) {
    return { isKeywordValid: true, errorMessage: "" };
  } else {
    let isTheSameRequest = false;
    let idArr = [];

    if (howto_req_id !== null && howto_req_id > 0) {
      let kwHits = repeatedKeywords.data.hits.hits;
      for (let i = 0; i < kwHits.length; i++) {
        const hitID = kwHits[i]._source.howto_req_id;
        idArr.push(parseInt(hitID));
      }

      isTheSameRequest = idArr.includes(howto_req_id);
    }

    return {
      isKeywordValid: isTheSameRequest,
      errorMessage:
        "Please enter at least one valid, relevant keyword in order to successfully submit this How-To Card."
    };
  }
};

const validateDelegate = async (delegate, delegates, field) => {
  let isValid;
  let currentUser = localStorage.getItem("searchtools.eid");
  let modalErrorMessage = "";
  let eid = await stService.GetEmployeesByEID(delegate, true);
  let totalHits = eid.data.hits.total;
  if (totalHits === 0) {
    modalErrorMessage = "Please enter a valid EID";
    isValid = false;
  } else {
    eid = eid.data.hits.hits[0]._source.enterpriseid;
    if (delegate === currentUser && field === "delegates") {
      modalErrorMessage = "Please enter an EID other than yours";
      isValid = false;
    } else if (delegates.includes(delegate)) {
      modalErrorMessage =
        "You have entered the same EID twice. Please enter a unique value per EID.";
      isValid = false;
    } else {
      modalErrorMessage = "";
      isValid = true;
    }
  }
  return { isValid: isValid, errorMessage: modalErrorMessage };
};
const ValidateComment = (comment) => {
  comment = comment.trim();
  let regex = urlCheckerService.textRegex;
  let commentLimit = process.env.REACT_APP_COMMENT_LIMIT;
  let result = {};

  if (comment.length === 0) {
    result = { status: null, msg: "" };
    return result;
  } else if (comment.match(regex)) {
    return {
      status: false,
      msg: 'Please do not include the special characters \\ and "'
    };
  } else if (comment && comment.length > commentLimit) {
    let invalidDescMessage = `Comments must be ${commentLimit} characters or less.`;
    result = { status: false, msg: invalidDescMessage };
    return result;
  } else {
    result = { status: true, msg: "" };
    return result;
  }
};

function getHomeUrl(url) {
  let newUrl = url.split("/");
  let internalSiteHome = "https://in.accenture.com/".concat(newUrl[3]);
  return internalSiteHome;
}

const HowToValidations = {
  titleTooLong,
  invalidChars,
  validateTitle,
  validateURL,
  validateStep,
  validateRefinerPage,
  validateLocation,
  validateKeyword,
  validateDelegate,
  ValidateComment
};

export default HowToValidations;
