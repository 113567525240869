import { Outlet } from "react-router-dom";

import PromoteMyContentTabs from "./PromoteMyContentTabs";

const PromoteMyContent = () => {
  return (
    <div>
      <PromoteMyContentTabs />
      <Outlet />
    </div>
  );
};

export default PromoteMyContent;
