import React, { useState, useEffect, useRef } from 'react';
import ModalCustom from '../Modal/ModalCustom';
import { IoAlarmOutline } from 'react-icons/io5';

const TimerModal = ({ onClick, theme, onTimeout, timeLeft}) => {
    const Ref = useRef();
    const [timer, setTimer] = useState(timeLeft); 
    
    const getTimeRemaining = (e) =>{
        const total =
            Date.parse(e) - Date.parse(new Date());
        
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor(
            (total / 1000 / 60) % 60
        );
        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, minutes, seconds } =
            getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            setTimer(
                (minutes > 9
                    ? minutes
                    : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const clearTimer = (e) => {
        // Initial countdown value    
        setTimer(timeLeft);
 
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();
        const [minutes, seconds] = timeLeft.split(":");
        deadline.setSeconds(deadline.getSeconds() + parseInt(seconds));
        deadline.setMinutes(deadline.getMinutes() + parseInt(minutes));
        return deadline;
    };

    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);
    const onClickContinue = () => {
        clearTimer(getDeadTime());
        onClick();
    };
    useEffect(()=>{
        if(timer === "00:00"){
            setTimeout(() => {
                onTimeout();
            }, 1000);
        }
    },[timer]);
    return (
        <div>
            <ModalCustom
              darkMode={theme === "dark"}
              onClose={onClickContinue}
              modal_title={"Your session is about to expire"}
              modal_msg={`Are you still there?\nPlease press continue to extend your session\n`}
              firstOptText={"Continue"}
              secondOption={false}
              modalBorders={"purple-borders"}
              btn_1_class={
                theme === "dark"
                  ? "btn btn-dark-mode-purple"
                  : "btn btn-light-mode-purple"
              }
              align={"modaltext-center"}
              bodyIcon={{
                icon: <IoAlarmOutline size={"1.8em"}/>,
                str: `  ${timer}`,
                className: "modal-alarm-timer"
              }}
              testId={"timer-modal"}
            />
        </div>
    );
};

export default TimerModal;