import HowToValidations from "../../../../validationClasses/HowToValidations";
import { useState, useEffect } from "react";
import {
  BsPlusSquare,
  BsArrowDownSquare,
  BsArrowUpSquare,
  BsTrash
} from "react-icons/bs";

const HowToStep = (props) => {
  const [inputStepValue, setInputStepValue] = useState("");
  const [validInputClass, setValidInputClass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    validateStep(props.item.step);
  }, [props.item.step]);

  const handleStepsChange = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      return;
    }
    setInputStepValue(event.target.value);
    props.steps[props.index].step = event.target.value;
    props.setStep(props.steps);
    props.setStepsArray(props.steps);
    validateStep(props.item.step);
  };

  const handleInputChange = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const validateStep = async (step) => {
    if (step === "") {
      setErrorMessage(null);
      setValidInputClass("");
      props.setState(null, props.index);
    } else {
      const isInputValid = await HowToValidations.validateStep(step, true);
      if (isInputValid.isValid) {
        setErrorMessage(null);
        setValidInputClass("is-valid");
        props.setState(true, props.index);
      } else {
        setErrorMessage(isInputValid.errorMessage);
        setValidInputClass("is-invalid");
        props.setState(false, props.index);
      }
    }
  };
  return (
    <>
      <div key={props.index} className="input_step">
        <input
          id={"ht-step-" + props.index + "-input"}
          data-testid="steps"
          type="text"
          value={props.item.step}
          className={`form-control ${validInputClass}`}
          placeholder="Enter a step"
          onChange={(event) => handleStepsChange(event)}
          onKeyDown={handleInputChange}
          disabled={props.disableField}
        />
        {!props.disableField && (
          <div key={props.index} className="step_htIcons">
            {props.steps.length < 5 && (
              <button
                className="btn-icon-container"
                onClick={props.handleAddStep}
              >
                <BsPlusSquare
                  data-testid="plus-steps-icon"
                  id="plus-steps-icon"
                  className="plus_icon"
                />
              </button>
            )}
            <button
              className="btn-icon-container"
              onClick={(event) => props.moveUp("Up", props.index)}
            >
              <BsArrowUpSquare
                data-testid="up-steps-icon"
                id="up-steps-icon"
                className="arrow_up"
              />
            </button>
            <button
              className="btn-icon-container"
              onClick={(event) => props.moveUp("Down", props.index)}
            >
              <BsArrowDownSquare
                data-testid="down-steps-icon"
                id="down-steps-icon"
                className="arrow_down"
              />
            </button>

            {props.steps.length > 3 && (
              <button
                className="btn-icon-container"
                onClick={(event) => props.removeStep(props.index)}
              >
                <BsTrash
                  data-testid="trash-steps-icon"
                  id="trash-steps-icon"
                  className="trash_icon"
                />
              </button>
            )}
          </div>
        )}
      </div>
      {errorMessage ? (
        <div id="step-item" className="invalid step-item">
          {errorMessage}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default HowToStep;
