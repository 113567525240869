import "../HowToForm.css";
import info from "../../../../assets/images/info.svg";
import HowToInput from "./HowToInput";

const HowToURLField = ({
  updateValue,
  inputState,
  setInputState,
  value,
  disableField,
  tooltipFor
}) => {
  return (
    <div className="mb-3 ">
      <img
        id={"ht-form-tooltip-icon-" + tooltipFor}
        role="tooltip"
        src={info}
        alt="tooltip"
        className="ht_tooltip"
        data-bs-placement="left"
        data-bs-toggle="tooltip"
        tabIndex={0}
        title="Every How-To must provide a main URL source to display"
      />
      <label
        aria-label="URL field"
        htmlFor="ht_url"
        className="ht_label required"
      >
        Source URL
      </label>
      <HowToInput
        data-testid="ht_url"
        type="url"
        className="form-control"
        id="ht-form-url-input"
        placeholder="Enter a valid URL from your How-To's source"
        field="url"
        state={inputState}
        setState={setInputState}
        updateValue={updateValue}
        value={value}
        disableField={disableField}
      />
    </div>
  );
};

export default HowToURLField;
