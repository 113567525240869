import { AiOutlineInfoCircle } from "react-icons/ai";
import { ThemeContext } from "../../../../ThemeContext";
import { useContext, useEffect } from "react";
import { Tooltip } from "bootstrap";

const TopicCardTooltips = ({ tooltipAlt, tooltipText, tooltipFor = "" }) => {
  const [theme] = useContext(ThemeContext);
  useEffect(() => {
    const tooltipClass = theme === "dark" ? "tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, [theme]);

  return (
    <>
      <AiOutlineInfoCircle
        id={"tc-form-tooltip-icon-" + tooltipFor}
        role="tooltip"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        alt={tooltipAlt}
        className="info-icon"
        data-testid="tooltip-icon"
        data-bs-title={tooltipText}
      />
    </>
  );
};

export default TopicCardTooltips;
