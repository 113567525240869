import "../HowToForm.css";
import info from "../../../../assets/images/info.svg";
import { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../../ThemeContext";
import ModalCustom from "../../../Modal/ModalCustom";
import HowToPills from "./HowToPills";
import HowToValidations from "../../../../validationClasses/HowToValidations";
import { copyToClipboard } from "../../../CopyToClipboard/CopyToClipboard";
import services from "../../../../store/services";
import { Tooltip } from "bootstrap";

const HowToKwField = ({
  keywordInput,
  selectedKeywords,
  setKeywordInput,
  updateFormField,
  selectedRefinerPage,
  selectedLocation,
  inputState,
  setInputState,
  keywordsForReview,
  disableField,
  revalidateForm,
  requestStatus,
  htrequest,
  changeClipboardPillType,
  tooltipFor
}) => {
  const [openKeywordsModal, setOpenKeywordsModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [keywordsErrorMessage, setKeywordsErrorMessage] = useState("");
  const [validInputClass, setValidInputClass] = useState("");
  const theme = useContext(ThemeContext);
  const urlCheckerService = services.urlCheckerService;

  useEffect(() => {
    if (selectedKeywords.length > 0) {
      setInputState("keyword", true);
      setKeywordsErrorMessage("");
      setValidInputClass("is-valid");
    } else if (
      keywordsForReview.length === 0 &&
      selectedKeywords.length === 0
    ) {
      setInputState("keyword", false);
      setKeywordsErrorMessage("");
      setValidInputClass("");
    } else if (selectedKeywords.length === 0) {
      setInputState("keyword", false);
      setKeywordsErrorMessage(
        "Please enter at least one valid, relevant keyword in order to successfully submit this How-To Card request."
      );
      setValidInputClass("is-invalid");
      errorMsg();
    }
  }, [selectedKeywords, keywordsForReview]);

  const onChangeKeyword = (event) => {
    setKeywordInput(event.target.value);
  };
  const addKeyword = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    const keyword = keywordInput.trim();
    let regex = urlCheckerService.textRegex;

    if (keywordInput.match(regex)) {
      setInputState("keyword", false);
      setKeywordsErrorMessage(
        'Please do not include the special characters \\ and "'
      );
      setValidInputClass("is-invalid");
    } else {
      setInputState("keyword", null);
      setKeywordsErrorMessage("");
      setValidInputClass("");
    }

    let enteredKeywords = selectedKeywords.concat(keywordsForReview);
    const allowedEvents = event.key === "Enter" || event.type === "click";
    if (allowedEvents && keyword !== "") {
      let regex = urlCheckerService.textRegex;

      if (keyword.match(regex)) {
        setKeywordInput("");
        setModalMessage(
          'Please do not include the special characters \\ and "'
        );
        setOpenKeywordsModal(!openKeywordsModal);
        setKeywordsErrorMessage("");
        setValidInputClass("");
        return;
      }
      if (selectedRefinerPage.length === 0 || selectedLocation.length === 0) {
        setModalMessage("Please select Location and Refiner Page first.");
        setOpenKeywordsModal(!openKeywordsModal);
      } else if (enteredKeywords.length < 20) {
        await validateKeywords(keyword, selectedLocation, selectedRefinerPage);
      } else {
        setModalMessage("Only 20 keywords are allowed");
        setOpenKeywordsModal(!openKeywordsModal);
      }
      setKeywordInput("");
    }
  };
  const validateKeywords = async (
    keywordInput,
    selectedLocation,
    selectedRefinerPage
  ) => {
    if (selectedKeywords.indexOf(keywordInput) !== -1) {
      setModalMessage("You have already added this keyword");
      setOpenKeywordsModal(!openKeywordsModal);
    } else {
      let isInputValid = await HowToValidations.validateKeyword(
        keywordInput,
        selectedLocation,
        selectedRefinerPage,
        htrequest
      );
      if (isInputValid.isKeywordValid) {
        let keywordsArray = [...selectedKeywords];
        keywordsArray = [...keywordsArray, keywordInput];
        if (keywordsArray.length > 0) {
          updateFormField("keyword", keywordsArray.sort());
          setInputState("keyword", isInputValid.isKeywordValid);
          setValidInputClass("is-valid");
        }
      } else {
        let keywordReview = [...keywordsForReview];
        keywordReview = [...keywordReview, keywordInput];
        updateFormField("keywordsForReview", keywordReview.sort());
        setKeywordsErrorMessage(isInputValid.errorMessage);
        if (selectedKeywords.length === 0) {
          setInputState("keyword", isInputValid.isKeywordValid);
          setValidInputClass("is-invalid");
          errorMsg();
        }
      }
    }
  };

  const onClickKeywordsModal = () => {
    setOpenKeywordsModal(!openKeywordsModal);
  };

  const errorMsg = () => {
    if (inputState.keyword || inputState.keyword === null) {
      return <></>;
    } else {
      return (
        <div id="validate-input" className="invalid">
          {keywordsErrorMessage}
        </div>
      );
    }
  };

  useEffect(() => {
    const tooltipClass = theme === "dark" ? "ht-tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, []);

  const copyToClipboardHandler = (event) => {
    changeClipboardPillType("Keyword");
    copyToClipboard(event);
  };

  const plusButton = (
    <div
      onClick={(event) => addKeyword(event)}
      onKeyDown={(event) => addKeyword(event)}
      className={"plus_icon_kw_ht_div"}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-title={"Add Keyword(s)"}
    >
      <svg
        data-testid="ht-kw-plus-icon"
        className={"plus_icon_kw_ht"}
        viewBox="0 0 16 16"
        width={"1.4em"}
        height={"1.4em"}
        role="img"
        alt="icon"
        id="plus-icon"
        aria-label="Add Users(s)"
        value=""
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "2px" }}
        tabIndex="0"
      >
        <g>
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
          ></path>
          <path
            fillRule="evenodd"
            d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          ></path>
        </g>
      </svg>
    </div>
  );
  return (
    <div className="mb-3">
      <img
        id={"ht-form-tooltip-icon-" + tooltipFor}
        role="tooltip"
        src={info}
        alt="tooltip"
        tabIndex={0}
        className="ht_tooltip"
        data-bs-placement="left"
        data-bs-toggle="tooltip"
        title='Keyword recommendations:&nbsp;
            &nbsp;&bull;&nbsp;&nbsp;At least 1 related keyword based on your site content is required; Maximum of 20 keywords can be entered.
            &nbsp;&bull;&nbsp;&nbsp;Avoid generic terms that can be associated with various site content within Accenture, such as "Accenture", "skills", "process", or "analysis"
            &nbsp;&bull;&nbsp;&nbsp;Avoid single keywords unless it is highly specific, such as an acronym
            &nbsp;&bull;&nbsp;&nbsp;Avoid keywords that have more than three words as it is very unlikely to be entered as a search term'
      />
      {openKeywordsModal && (
        <ModalCustom
          darkMode={theme === "dark"}
          onClose={onClickKeywordsModal}
          modal_msg={modalMessage}
          btn_1_class={
            theme === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
          modalSize=""
          noHeader={true}
        />
      )}

      <label className="ht_label required">Keywords</label>
      <div className="kw_del_ht_field">
        <input
          type="text"
          data-testid="ht-kw-field"
          id="ht-kw-field"
          className={`form-control ${validInputClass}`}
          placeholder="Enter at least 1 related keyword (maximum of 20 )"
          aria-label="Keyword input. Enter at least 1 related keyword (maximum of 20) and press Enter."
          value={keywordInput}
          onChange={(event) => onChangeKeyword(event)}
          onKeyDown={(event) => addKeyword(event)}
          disabled={disableField}
        />
        {!disableField && plusButton}
      </div>
      {errorMsg()}
      <HowToPills
        selectedTargets={selectedKeywords}
        setSelectedTarget={updateFormField}
        validate={() => {}}
        arrayForReview={keywordsForReview}
        field="keyword"
        hasReviewArray={true}
        disableField={disableField}
        revalidateForm={revalidateForm}
        onCopyToClipBoard={copyToClipboardHandler}
        requestStatus={requestStatus}
      />
    </div>
  );
};

export default HowToKwField;
