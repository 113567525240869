import services from "../store/services";

const TITLE_CHAR_LIMIT = parseInt(process.env.REACT_APP_CTC_TITLE_LIMIT);
const DESCRIPTION_CHAR_LIMIT = parseInt(
  process.env.REACT_APP_DESCRIPTION_LIMIT
);
const urlCheckerService = services.urlCheckerService;

const COMMENT_CHAR_LIMIT = parseInt(process.env.REACT_APP_COMMENT_LIMIT);
class CTCFormValidations {
  constructor() {}

  ValidateTitle(titleToValidate, tcService, IDFromUrl) {
    return new Promise((resolve, reject) => {
      let ctcTitle = titleToValidate.trim();
      let regex = urlCheckerService.textRegex;

      if (ctcTitle.length === 0) {
        resolve({
          status: null,
          msg: ""
        });
      }

      if (ctcTitle.match(regex)) {
        resolve({
          status: false,
          msg: 'Please do not include the special characters \\ and "'
        });
      }

      if (ctcTitle.length > TITLE_CHAR_LIMIT) {
        resolve({
          status: false,
          msg: "Enter a title that is 65 characters or less"
        });
      }

      if (ctcTitle.length > 0 && ctcTitle.length < TITLE_CHAR_LIMIT) {
        tcService
          .GetTopicCardByTitle(ctcTitle)
          .then((res) => {
            if (res.data.hits.hits.length > 0) {
              if (parseInt(IDFromUrl) > 0) {
                let cnt = 0;
                for (let q = 0; q < res.data.hits.hits.length; q++) {
                  const currentUrl = res.data.hits.hits[q]._source.tcrequestid;
                  if (parseInt(IDFromUrl) === parseInt(currentUrl)) {
                    cnt++;
                  }
                }

                if (cnt === 0) {
                  resolve({
                    status: false,
                    msg: "This title is already taken; please enter a unique Custom Topic Card title."
                  });
                }
              } else {
                resolve({
                  status: false,
                  msg: "This title is already taken; please enter a unique Custom Topic Card title."
                });
              }
            } else {
              resolve({ status: true, msg: "" });
            }
            resolve(res);
          })
          .catch((error) => {
            console.log("error on ctc title validation : ", error);
            reject(error);
          });
      }
    });
  }

  ValidateUrl(url, urlService) {
    const getHomeUrl = (url) => {
      let newUrl = url.split("/");
      let internalSiteHome = "https://in.accenture.com/".concat(newUrl[3]);
      return internalSiteHome;
    };

    return new Promise((resolve) => {
      if (url !== null && url.length === 0) {
        resolve({ status: null, msg: "" });
      }

      let regex = urlService.urlRegex;
      if (!url.match(regex)) {
        resolve({
          status: false,
          msg: "This is not a valid url format"
        });
      }

      let reqType = "Custom Topic Card";

      url = url || "";
      let cleanurlLowercase = url.toLowerCase();

      let myLearningRx = urlService.myLearningRx;
      let collectionsRx = urlService.collectionsRx;
      let kxContributionRx = urlService.kxContributionRx;
      let internalSiteRx = urlService.internalSiteRx;
      let URLRegExp2 = urlService.URLRegExp2;
      let URLRegExp = urlService.URLRegExp;

      //------
      if (cleanurlLowercase && myLearningRx.test(cleanurlLowercase)) {
        urlService.GetMyLearningHits(url).then((r) => {
          let invalidMyLrnUrlMsg =
            "Please add an online training that is globally available.";
          const totalHits =
            r.data.hits.total.value !== undefined
              ? r.data.hits.total.value
              : r.data.hits.total;
          if (totalHits === 0) {
            resolve({ status: false, msg: invalidMyLrnUrlMsg });
          } else {
            resolve({ status: true, msg: "" });
          }
        });
      } else if (cleanurlLowercase && collectionsRx.test(cleanurlLowercase)) {
        urlService.GetCollectionHits(url).then((r) => {
          let collectionsErrorMessage =
            "Please enter an official collection URL for this " +
            reqType +
            "; for assistance, please contact the KX team.";
          const totalHits =
            r.data.hits.total.value !== undefined
              ? r.data.hits.total.value
              : r.data.hits.total;
          if (totalHits === 0) {
            resolve({ status: false, msg: collectionsErrorMessage });
          } else {
            resolve({ status: true, msg: "" });
          }
        });
      } else if (
        cleanurlLowercase &&
        kxContributionRx.test(cleanurlLowercase)
      ) {
        urlService.GetKXContributionHits(url).then((r) => {
          let kxContributionErrorMessage =
            "Please enter an approved KX contribution URL for this " +
            reqType +
            "; for approval assistance, please contact the KX team.";
          const totalHits =
            r.data.hits.total.value !== undefined
              ? r.data.hits.total.value
              : r.data.hits.total;
          if (totalHits === 0) {
            resolve({ status: false, msg: kxContributionErrorMessage });
          } else {
            resolve({ status: true, msg: "" });
          }
        });
      } else if (cleanurlLowercase && internalSiteRx.test(cleanurlLowercase)) {
        let internalSiteHome = getHomeUrl(cleanurlLowercase).toLowerCase();
        urlService.GetInternalSitesData(url, internalSiteHome).then((r) => {
          let invalidInternalSiteMsg =
            "Please enter a live/active site URL for this ";
          let invalidMessage = invalidInternalSiteMsg + reqType;
          const totalHits =
            r.data.hits.total.value !== undefined
              ? r.data.hits.total.value
              : r.data.hits.total;
          if (totalHits === 0) {
            resolve({
              status: false,
              msg: invalidMessage
            });
          } else {
            resolve({
              status: true,
              msg: ""
            });
          }
        });
      } else if (
        cleanurlLowercase &&
        URLRegExp.test(cleanurlLowercase) &&
        URLRegExp2.test(cleanurlLowercase)
      ) {
        resolve({ status: true, msg: "" });
      } else {
        resolve({
          status: false,
          msg: "This is not a valid url format"
        });
      }
    });
  }

  ValidateDescription(descriptionText) {
    let result = {};

    let regex = urlCheckerService.textRegex;

    if (descriptionText.match(regex)) {
      result = {
        status: false,
        msg: 'Please do not include the special characters \\ and "'
      };
      return result;
    }
    if (descriptionText.length === 0) {
      let invalidDescMessage = "Please enter a description.";
      result = { status: false, msg: invalidDescMessage };
      return result;
    }

    if (descriptionText.length <= DESCRIPTION_CHAR_LIMIT) {
      result = { status: true, msg: "" };
      return result;
    } else {
      let invalidDescMessage =
        "The description must be " +
        DESCRIPTION_CHAR_LIMIT +
        " characters or less.";
      result = { status: false, msg: invalidDescMessage };
      return result;
    }
  }

  ValidateComment(value) {
    let comment = value.trim();
    let result = {};

    if (comment.length === 0) {
      result = { status: null, msg: "" };
      return result;
    }
    let regex = urlCheckerService.textRegex;
    if (comment.match(regex)) {
      return {
        status: false,
        msg: 'Please do not include the special characters \\ and "'
      };
    }
    if (comment && comment.length <= COMMENT_CHAR_LIMIT) {
      return { status: true, msg: "" };
    }
    if (comment && comment.length > COMMENT_CHAR_LIMIT) {
      let invalidDescMessage = `Comments must be ${COMMENT_CHAR_LIMIT} characters or less.`;
      return { status: false, msg: invalidDescMessage };
    } else {
      return { status: null, msg: "" };
    }
  }

  async ValidateDelegate(eid, searchToolsService) {
    let result = {};

    // changed to search for id, because some users dont have yammer id
    // so even if the user eid entered is valid, the result will be invalid
    //if relying on yammer id
    let r = await searchToolsService.GetEmployeesByEID(eid, true);

    let delResults = r.data.hits.hits;

    if (delResults.length > 0) {
      // pos [0] because the search is for exact result,
      // so, only 1 result will be present if the user eid exists
      result = {
        status: true,
        msg: "",
        data: delResults[0]._source.enterpriseid
      };
      return result;
    } else {
      result = {
        status: false,
        msg: "Please enter at least one delegate in order to successfully submit this Custom Topic Card request."
      };
      return result;
    }
  }
}

export default CTCFormValidations;
