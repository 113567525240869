import ModalCustom from "../../../../Modal/ModalCustom";
import successLight from "../../../../../assets/images/success-light.svg";
import successDark from "../../../../../assets/images/success-dark.svg";
import "./Modal&Button.css";
import { useContext, useEffect, useState } from "react";
import StoreContext from "../../../../../store/storecontext";
import BB_Validations from "../../../../../validations/BB_Validations";
import { ThemeContext } from "../../../../../ThemeContext";
import Spinner from "../../../../Spinner/Spinner";

const DescriptionModal = (props) => {
    const store = useContext(StoreContext);
    const [requestLoading, setRequestLoading] = useState(false);
    const [savedDescription, setSavedDescription] = useState(false);
    const [theme] = useContext(ThemeContext);
    const [isDataLoading, setIsDataLoading] = useState(false);
    

    useEffect(()=>{
        if(savedDescription){
            setTimeout(() => {
                props.closeModal();
            }, 3000);
        }
    },[savedDescription])

    const generateDescription = (e) => {
        e?.preventDefault();
        if(e.code === "Enter" || (e.type === "mousedown" && e.button === 0)){
        setIsDataLoading(true);
        const bbDescriptionLimit = process.env.REACT_APP_BB_DESCRIPTION_LIMIT;
        setRequestLoading(true);
            store.services.genIAService
            .GetGenIADescription(bbDescriptionLimit,props.lastGeneratedDescription, props.context)
                .then((response) => {
                    props.setLastGeneratedDescription(response.data.result);                
                }).catch((e) => {
                    console.log("Error generating description: ", e)
                }).finally(() => {
                    setIsDataLoading(false);
                    setRequestLoading(false);
                });
        }
    }

    const handleAccept = () => {
        props.replaceDescription(props.lastGeneratedDescription);
        const isValid = BB_Validations.validateDescription(props.lastGeneratedDescription);
        props.validateDescription(isValid.state);
        setSavedDescription(true);
    }


    const modalBody = (
        <>
            <h2 id="description-title" aria-live="polite" aria-label="Description"><b>Description</b></h2>
            <p id="generated-description" aria-live="polite" aria-label={props.lastGeneratedDescription}>{props.lastGeneratedDescription}</p>
            <p className="disclaimer" id="ai-disclaimer" aria-live="polite" aria-label="Response generated by AI. Verify accuracy and privacy restrictions before use">Response generated by AI. Verify accuracy and privacy restrictions before use.</p>
        </>
    );

    const successBody = (
        <div className="success-body">
            <img src={theme === "dark" ? successDark : successLight} className="success-icon" alt="Confetti cone" />
            <p id="success-message" aria-live="polite">Description generated successfully with Gen Ai!</p>
        </div>
    );

    return  (<>
        {isDataLoading && <Spinner/>}
        <ModalCustom
            xClose={true}
            onClose={props.closeModal}
            modal_msg={savedDescription ? successBody : modalBody}
            modal_title="Gen AI on Search Tools"
            firstOptText="Regenerate"
            secondOption={true}
            secondOptText="Accept"
            modalSize={"description-modal " + (savedDescription ? "success" : "")}
            firstBtnAction={generateDescription}
            firstBtnDisabled={requestLoading}
            onClickSecondOpt={handleAccept}
            noFooter={savedDescription}
            zindex={11}
            firstOpAriaLabel="Regenerate description"
        />
    </>);
};

export default DescriptionModal;
