import "../TCForm.css";
import TopicCardPills from "./TopicCardPills";
import { useState, useContext, useEffect } from "react";
import TopicCardTooltips from "./TopicCardTooltips";
import TopicCardValidations from "../../../../validationClasses/TopicCardValidations";
import ModalCustom from "../../../Modal/ModalCustom";
import {
  EidSuggestion,
  selectSuggestionResult
} from "../../../EidSuggestion/EidSuggestion";
import { ThemeContext } from "../../../../ThemeContext";
import StoreContext from "../../../../store/storecontext";
import { BsPlusSquare } from "react-icons/bs";
import { Tooltip } from "bootstrap";
import { copyToClipboard } from "../../../CopyToClipboard/CopyToClipboard";
const INPUT_ELEM_ID = "tc-delegate-input";
const INPUT_EXPERTS_ELEM_ID = "tc-experts-input";

const PeopleInput = ({
  selectedDelegates,
  contacts,
  placeholderText,
  field,
  updateValue,
  setInputState,
  contactArray,
  updateDelegateField,
  disableField,
  requestStatus,
  changeClipboardPillType,
  tooltipFor,
  checkDraftBtn
}) => {
  const delegates = {
    tooltip:
      "If needed, enter at least one Enterprise ID that can serve as a delegate to edit the Topic Card and receive related notifications.",
    alt: "Enter an Enterprise ID to search someone on your team that can contribute to edit your Topic Card request and will receive the notifications."
  };
  const [displayInputs, setDisplayInput] = useState(false);
  const [openDelegatesModal, setDelegatesModal] = useState(false);
  const [delegatesErrorMessage, setDelegatesErrorMessage] = useState("");
  const [delegatesModalMessage, setDelegatesModalMessage] = useState("");
  const [validInputClass, setValidInputClass] = useState("");
  const theme = useContext(ThemeContext);
  const store = useContext(StoreContext);
  const urlCheckerService = store.services.urlCheckerService;
  const [eidSearchTrigger, setEidSearchTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let regex = urlCheckerService.textRegex;

  const onChangeDelegate = () => {
    setEidSearchTrigger(!eidSearchTrigger);
  };

  const getContactsData = async (delegateValue) => {
    let newContact;
    if (contacts) {
      let delegatesData =
        await store.services.searchToolsService.GetEmployeesByEID(
          delegateValue,
          true
        );

      delegatesData = delegatesData.data.hits.hits[0]._source;

      newContact = {
        eid: delegatesData.enterpriseid,
        fullname: `${delegatesData.firstname} ${delegatesData.lastname}`,
        role: delegatesData.roledescr || "",
        url: delegatesData.workdayurl
      };

      let newGetInTouchArr = [];
      contactArray.map((el) => {
        newGetInTouchArr.push(el);
      });
      newGetInTouchArr.push(newContact);

      return [...newGetInTouchArr];
    } else {
      let delegateUserID = await store.services.yammerService.GetUserIdYammer(
        delegateValue
      );
      delegateUserID = delegateUserID.data;
      newContact = {
        eid: delegateValue,
        userid: delegateUserID
      };

      let newDelegateArr = [];
      contactArray.map((el) => {
        newDelegateArr.push(el);
      });
      newDelegateArr.push(newContact);

      return [...newDelegateArr];
    }
  };

  const validateDelegates = async (delegInput, selectedDelegates) => {
    try {
      let delegates = {
        delegate: delegInput,
        delegates: selectedDelegates,
        field: field,
        service: store.services.searchToolsService
      };
      const isInputValid = await TopicCardValidations.validateDelegate(
        delegates
      );

      setInputState(field, isInputValid.isValid);
      
      if (!isInputValid.isValid) {
        setDelegatesModalMessage(isInputValid.errorMessage);
        setDelegatesModal(true);
        if (selectedDelegates.length > 0) {
          setValidInputClass("is-valid");
        }
      } else {
        let newArr = await getContactsData(delegInput);
        updateValue(field, [...selectedDelegates, delegInput]);
        updateValue(field, newArr);
        setValidInputClass("is-valid");
      }

      if(isInputValid.isValid){
        checkDraftBtn(isInputValid.isValid, field);  
      }
    } catch (error) {
      console.log("Error in validateDelegates", error);
    } finally {
      await setIsLoading(false);
    }
  };

  const addDelegate = async (event, eidToAdd = null) => {
    let delegate = "";

    let elemRef = {};

    if (displayInputs) {
      elemRef = document.getElementById(`${INPUT_EXPERTS_ELEM_ID}`);
    } else {
      elemRef = document.getElementById(`${INPUT_ELEM_ID}`);
    }
    if (eidToAdd) {
      delegate = eidToAdd;
    } else {
      delegate = elemRef.value.trim();
    }
    if (delegate !== "") {
      await setIsLoading(true);
    }

    elemRef.value = "";
    setEidSearchTrigger(!eidSearchTrigger);

    const allowedEvents = event.key === "Enter" || event.type === "click";

    if (allowedEvents && delegate !== "") {
      if (delegate.match(regex)) {
        setDelegatesModalMessage(
          'Please do not include the special characters \\ and "'
        );
        setDelegatesModal(true);
      } else {
        validateDelegates(delegate, selectedDelegates);
      }
    }
  };
  const displayInput = (event) => {
    if (contacts) {
      setDisplayInput(event.target.checked);
      contactsField();
    }
  };
  const onClickDelegatesModal = () => {
    setDelegatesModal(!openDelegatesModal);
  };

  const updateValueOnForm = (target) => {
    let fieldValue = field === "getInTouch" ? "getInTouch" : "delegates";
    let updatedData = [];
    contactArray.map((el, i) => {
      if (el.eid !== target) {
        updatedData.push(el);
      }
    });
    updateValue(fieldValue, [...updatedData]);
    checkDraftBtn(updatedData.length > 0, field);
  };

  useEffect(() => {
    try {
      if (selectedDelegates.length > 0) {
        setValidInputClass("is-valid");
      } else if (selectedDelegates.length === 0) {
        setValidInputClass("delegate", null);
      }
    } catch (error) {}
  }, [selectedDelegates]);

  const errorMsg = () => {
    return (
      <div id="validate-input" className="invalid">
        {delegatesErrorMessage}
      </div>
    );
  };

  const checkInputAction = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      addDelegate(event);
    } else {
      let elemId = event.target.id;
      selectSuggestionResult(event, elemId);
    }
  };

  const initToolTipForField = () => {
    const tooltipClass = theme[0] === "dark" ? "tooltip-dark" : "";
    let tooltipElements = document.querySelectorAll(
      '[data-bs-toggle="plus-icon"]'
    );
    [...tooltipElements].map(
      (ttp) =>
        new Tooltip(ttp, {
          tooltipElements,
          trigger: "hover",
          customClass: tooltipClass
        })
    );
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const copyToClipboardHandler = (event) => {
    changeClipboardPillType("EID");
    copyToClipboard(event);
  };

  const contactsField = () => {
    return (
      <>
        {openDelegatesModal ? (
          <ModalCustom
            darkMode={theme === "dark"}
            onClose={onClickDelegatesModal}
            modal_msg={delegatesModalMessage}
            btn_1_class={
              theme === "dark"
                ? "btn btn-dark-mode-purple"
                : "btn btn-light-mode-purple"
            }
            modalSize=""
            noHeader={true}
          />
        ) : (
          <div></div>
        )}
        <div className="tc-delegates-input-div">
          <input
            id={INPUT_EXPERTS_ELEM_ID}
            type="text"
            className={`form-control ${validInputClass}`}
            placeholder={placeholderText}
            aria-label="Enterprise ID input."
            data-testid="tc-input-experts"
            onChange={onChangeDelegate}
            onKeyDown={checkInputAction}
            disabled={disableField}
          />
          {!disableField && (
            <BsPlusSquare
              tabIndex={0}
              role="tooltip"
              data-bs-toggle="plus-icon"
              data-bs-placement="top"
              data-bs-title={"Add Contact Expert"}
              className="plus_icon_delegate"
              onClick={addDelegate}
              onKeyDown={addDelegate}
              onMouseEnter={initToolTipForField}
              onMouseLeave={deleteOldTooltips}
            />
          )}
        </div>
        <EidSuggestion
          inputID={INPUT_EXPERTS_ELEM_ID}
          eidSearchTrigger={eidSearchTrigger}
          searchToolsService={store.services.searchToolsService}
          addUserMethod={addDelegate}
        />
        <TopicCardPills
          selectedTargets={selectedDelegates}
          setSelectedTarget={updateValue}
          field={field}
          arrayForReview={[]}
          updateGetInTouch={updateValueOnForm}
          updateDelegates={updateValueOnForm}
          disableField={disableField}
          requestStatus={requestStatus}
          onCopyToClipBoard={copyToClipboardHandler}
          isLoading={isLoading}
        />
      </>
    );
  };
  const delegateInput = () => {
    return (
      <>
        {openDelegatesModal ? (
          <ModalCustom
            darkMode={theme === "dark"}
            onClose={onClickDelegatesModal}
            modal_msg={delegatesModalMessage}
            btn_1_class={
              theme === "dark"
                ? "btn btn-dark-mode-purple"
                : "btn btn-light-mode-purple"
            }
            modalSize=""
            noHeader={true}
          />
        ) : (
          <div></div>
        )}
        <div className="tc-delegates-input-div">
          <input
            id={INPUT_ELEM_ID}
            type="text"
            className={`form-control ${validInputClass}`}
            placeholder={placeholderText}
            aria-label="Enterprise ID input."
            data-testid="tc-input"
            onChange={onChangeDelegate}
            onKeyDown={checkInputAction}
            disabled={disableField}
          />
          {!disableField && (
            <BsPlusSquare
              tabIndex={0}
              role="tooltip"
              data-bs-toggle="plus-icon"
              data-bs-placement="top"
              data-bs-title={"Add Delegate"}
              className="plus_icon_delegate"
              onClick={addDelegate}
              onKeyDown={addDelegate}
              onMouseEnter={initToolTipForField}
              onMouseLeave={deleteOldTooltips}
            />
          )}
        </div>
        <EidSuggestion
          inputID={INPUT_ELEM_ID}
          eidSearchTrigger={eidSearchTrigger}
          searchToolsService={store.services.searchToolsService}
          addUserMethod={addDelegate}
        />
        <TopicCardPills
          selectedTargets={selectedDelegates}
          setSelectedTarget={updateValue}
          field={field}
          arrayForReview={[]}
          updateGetInTouch={updateValueOnForm}
          updateDelegates={updateValueOnForm}
          disableField={disableField}
          requestStatus={requestStatus}
          onCopyToClipBoard={copyToClipboardHandler}
          isLoading={isLoading}
        />
      </>
    );
  };

  useEffect(() => {
    if (contacts && contactArray.length > 0) {
      let el = document.querySelector(".checkbox-to-toggle");

      el.addEventListener("click", () => {
        setDisplayInput(true);
        contactsField();
      });

      if (!el.checked) {
        el.click();
      }
    }
  }, [contactArray]);

  let fieldGap = "";
  if (field === "delegates") {
    fieldGap = "tc-delegates-field-gap";
  }
  useEffect(() => {
    if (selectedDelegates.length > 0 && field === "getInTouch") {
      document.getElementById("line-item-checkbox-contacts").checked = true;
      setDisplayInput(true);
      contactsField();
    }
  }, [selectedDelegates]);
  return (
    <>
      <div className={"mb-3 target " + fieldGap}>
        {contacts ? (
          <div className="checkbox check-primary ">
            <input
              id="line-item-checkbox-contacts"
              type="checkbox"
              className="checkbox-to-toggle form-check-input"
              onClick={(event) => displayInput(event)}
              disabled={disableField}
            />
            <label
              data-testid="line-item-label"
              htmlFor="line-item-checkbox"
              className="label-text"
            >
              Contact Experts
            </label>
          </div>
        ) : (
          <>
            <TopicCardTooltips
              tooltipFor={tooltipFor}
              tooltipAlt={delegates.alt}
              tooltipText={delegates.tooltip}
            />

            <label data-testid="topic-card-label" className="label-text people">
              Delegates
            </label>
          </>
        )}
        {contacts ? (
          <>{displayInputs ? contactsField() : <></>}</>
        ) : (
          delegateInput()
        )}
        {errorMsg()}
      </div>
    </>
  );
};

export default PeopleInput;
