import React from "react";
import { useEffect, useState } from "react";
import "./CTCPreview.css";

const CTCPreview = (props) => {

  return (
    <div
        dangerouslySetInnerHTML={{ __html: props.descriptionData }}
      ></div>
  );
};

export default CTCPreview;
