import { useState, useEffect, useContext } from "react";
import ManageRoleCss from "./ManageRoles.module.css";
import EidInput from "./EidInput";
import ModalCustom from "../Modal/ModalCustom";
import NotAuthorizedPage from "../NotAuthorizedPage/NotAuthorizedPage";
import { ThemeContext } from "../../ThemeContext";

import { Tooltip } from "bootstrap";
import StoreContext from "../../store/storecontext";

// ---------------------------------------------------------

const ManageRoles = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [displayNotAuth, setDisplayNotAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [displayUserList, setDisplayUserList] = useState([]);
  const successMsg = (action) => {
    return `The EID has been ${action} the list`;
  };
  const [displayNotAuthAfterModal, setDisplayNotAuthAfterModal] =
    useState(false);

  const store = useContext(StoreContext);
  const themeCtx = useContext(ThemeContext);

  const loadingSpinner = (
    <div
      className={
        "spinner-border text-light-mode-purple " +
        ManageRoleCss.spinner_position
      }
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  const displayModal = (modalTitle, modalMsg, isFromNotAuth = null) => {
    setModalTitle(modalTitle);
    setModalMsg(modalMsg);
    setShowModal(true);

    if (isFromNotAuth) {
      setDisplayNotAuthAfterModal(true);
    }
  };

  const closeModal = () => {
    setModalTitle("");
    setModalMsg("");
    setShowModal(false);
    if (displayNotAuthAfterModal) {
      setDisplayNotAuth(true);
      window.location.href = "/"; //redirect to home if user is not authorized
    }
  };

  const getUsersList = () => {
    return new Promise((resolve) => {
      if (store.hasOwnProperty("services")) {
        store.services.searchToolsService
          .GetSearchtoolsUsers()
          .then((r) => {
            let displayResults = [];
            let decodedData = window.atob(r.data);
            let parsedData = JSON.parse(decodedData);

            let admData = parsedData.adm;

            // ----------------------------
            for (let key in admData) {
              if (admData.hasOwnProperty(key)) {
                let canEd = true;

                if (
                  admData[key].canEdit === "True" ||
                  admData[key].canEdit === "true"
                ) {
                  canEd = true;
                } else {
                  canEd = false;
                }

                displayResults.push({
                  pk: key,
                  eid: admData[key].eid,
                  canEdit: canEd
                });
              }
            }

            resolve(displayResults);
          })
          .catch((error) => {
            if (error.name === "SyntaxError") {
              store.state.isSuperUser = false;
              store.state.isAdmin = false;
              displayModal(
                "Info",
                "Your account is not authorized to see this information.",
                true
              );
            } else {
              displayModal("Error", "Something went wrong, please try again.");
            }
            hideMenuAndIcon();
            console.log("error in getUsersList manageRole :");
          });
      }
    });
  };

  const setCurrentData = async () => {
    let currData = await getUsersList();
    checkCurrUser(currData);
    setUsersList(currData);
  };

  const hideMenuAndIcon = () => {
    let home_hidden_id = "home-menu-hidden";
    let icon_id = "hidden-role-icon";

    try {
      let menuElem = document.getElementById(home_hidden_id);
      let icon_el = document.getElementById(icon_id);

      if (store.state.isSuperUser) {
        menuElem.classList.remove("hide-div");
        icon_el.classList.remove("hide-div");
      } else {
        menuElem.classList.add("hide-div");
        icon_el.classList.add("hide-div");
      }
    } catch (err) {
      console.log("Error on hideMenuAndIcon.");
    }
  };

  const checkCurrUser = (admData) => {
    for (var key in admData) {
      if (admData.hasOwnProperty(key)) {
        if (
          parseInt(key) === parseInt(sessionStorage.getItem("searchtools.pk"))
        ) {
          let currUser = admData[key];

          setDisplayNotAuth(false);

          // call set account (change storage home value (1, 0,-1)
          let homeValue = currUser.canEdit ? 1 : 0;
          if (homeValue >= 0) {
            store.state.isSuperUser = true;
            store.state.isAdmin = homeValue === 1;
          } else {
            store.state.isSuperUser = false;
            store.state.isAdmin = false;
            setDisplayNotAuth(true);
          }
          return;
        }
      }
    }
  };

  useEffect(() => {
    setCurrentData();
  }, []);

  useEffect(() => {
    setIsDataLoading(true);
    checkCurrUser(usersList);
    hideMenuAndIcon();
    renderUserList();
  }, [usersList]);

  const getUserData = (user_eid) => {
    return new Promise((resolve) => {
      store.services.searchToolsService
        .GetEmployeesByEID(user_eid, true)
        .then((res) => {
          let userArray = res.data.hits.hits;
          if (userArray.length > 0) {
            // check user is not being added again
            let currUser = userArray[0]._source;
            let currUserPk = currUser.peoplekey.toString();

            let isUserOnList = false;
            usersList.map((user) => {
              if (user.pk === currUserPk) {
                isUserOnList = true;
              }
            });

            if (isUserOnList) {
              displayModal("Info", "EID is already present on the list!");
              resolve({ alreadyOnList: true });
            } else {
              // if not, add user to list
              let newUser = {
                pk: currUserPk,
                eid: currUser.enterpriseid,
                canEdit: true,
                alreadyOnList: false
              };

              resolve(newUser);
            }
          } else {
            displayModal("Info", "Please enter a valid EID!");
            resolve({ alreadyOnList: false });
          }
        })
        .catch((error) => {
          displayModal("Error", "Something went wrong, please try again!");
          console.log(
            "Error on manageroles,  addUserToList , check user is valid : ",
            error
          );
        });
    });
  };

  const addUserToList = async (event) => {
    event.preventDefault();
    // enable loading, disable input
    setIsDataLoading(true);
    let inputEl = document.getElementById("user-eid-input");
    let inputValue = inputEl.value;
    if (inputValue.length <= 0 || inputValue === "") return;

    // add multiple users at the same time
    inputValue = inputValue.replaceAll(" ", "");
    let allEids = inputValue.split(",");

    let newUsersList = [...usersList];
    let addedUsers = [...usersToAdd];

    for (let i = 0; i < allEids.length; i++) {
      // check if eid is valid to add
      let currEid = allEids[i].trim();
      // prevent unwanted characters on eid
      currEid = currEid.replaceAll(/[^a-zA-Z.-]/g, "");
      if (currEid.length <= 0 || currEid === "") return;
      let tempUser = await getUserData(currEid);
      if (!tempUser.alreadyOnList) {
        addedUsers.push(tempUser);
        newUsersList.push(tempUser);
      }
    }

    setUsersToAdd(addedUsers);
    setUsersList(newUsersList);
    inputEl.value = "";
    setIsDataLoading(false);
    setModalMsg(successMsg("added to"));
  };

  const removeUserFromList = (event) => {
    let tempRemove = [...usersToRemove];
    let newUsersList = usersList.filter((user) => {
      let currPk = event.target.id.replace("_remove_btn", "");
      currPk = parseInt(currPk);
      let intPk = parseInt(user.pk);
      if (currPk !== intPk) {
        return user;
      } else if (currPk === intPk) {
        tempRemove.push(user);
      }
    });

    setUsersToRemove(tempRemove);
    setUsersList(newUsersList);
    setModalMsg(successMsg("removed from"));
  };

  const saveUserList = async () => {
    setIsDataLoading(true);
    let dbData = await getUsersList();

    let removeDataPkList = usersToRemove.map((user) => {
      return user.pk;
    });

    let dataToSend = [];

    // remove users from the values to save (if they were not already removed)
    dataToSend = dbData.filter((user) => {
      if (!removeDataPkList.includes(user.pk)) {
        return user;
      }
    });

    // add the new users
    for (let i = 0; i < usersToAdd.length; i++) {
      dataToSend.push(usersToAdd[i]);
    }

    // update user info before saving
    for (let i = 0; i < dataToSend.length; i++) {
      const tempUser = dataToSend[i];
      for (let j = 0; j < usersList.length; j++) {
        if (tempUser.pk === usersList[j].pk) {
          dataToSend[i] = {
            pk: usersList[j].pk,
            eid: usersList[j].eid,
            canEdit: usersList[j].canEdit,
            alreadyOnList: true
          };
        }
      }
    }

    let reqBody = [];
    reqBody.push(dataToSend);
    reqBody.push(usersToRemove);

    //save data on index
    store.services.searchToolsService
      .SetSearchtoolsUsers(reqBody)
      .then(() => {
        renderUserList();
        if (modalMsg === "") {
          window.location.assign(window.location);
        }
      })
      .catch((e) => {
        console.log("Error in SetSearchtoolsUsers managerole :", e);
      })
      .finally(() => {
        const currentUser = localStorage.getItem("searchtools.eid");
        const userStillAdmin = usersList
          .map((user) => user.eid)
          .find((user) => user === currentUser);
        if (modalMsg !== "" && userStillAdmin) {
          displayModal("Success!", modalMsg);
        } else if (!userStillAdmin) {
          window.location.assign(window.location);
        }
      });
  };

  const renderUserList = () => {
    let newUserList = usersList.map((user) => {
      let btnColor = "btn-success";

      return (
        <div
          id="user-on-list"
          data-testid="testid-user-on-list"
          aria-label="user-on-list"
          key={user.pk + "_div"}
          className={ManageRoleCss.eid_btn_div}
        >
          <button
            aria-label="user name"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            id={user.pk}
            key={user.pk + "_btn_role_key"}
            className={"btn " + btnColor + " " + ManageRoleCss.eid_btn}
            type="button"
          >
            {user.eid}
          </button>
          <button
            id={user.pk + "_remove_btn"}
            onClick={removeUserFromList}
            key={user.pk + "_remove_btn_key"}
            className={"btn " + btnColor + " " + ManageRoleCss.x_btn}
            type="button"
            aria-label="remove user from list"
          >
            x
          </button>
        </div>
      );
    });

    setDisplayUserList(newUserList);

    // initalize tooltips (requires importing the Tooltip class from bootstrap)
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );

    let isDarkMode =
      themeCtx[0] === "dark" ? ManageRoleCss.darkMode_tooltip : "";
    let tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) =>
        new Tooltip(tooltipTriggerEl, {
          trigger: "hover focus",
          customClass: isDarkMode
        })
    );

    //necessary to remove unused divs from dom when data changes
    for (let i = 0; i < tooltipTriggerList.length; i++) {
      tooltipTriggerList[i].addEventListener("mouseup", (t) => {
        for (let j = 0; j < t.target.attributes.length; j++) {
          if (t.target.attributes[j].name === "aria-describedby") {
            let tooltipToDelete = document.getElementById(
              t.target.attributes[j].value
            );
            tooltipToDelete.remove();
          }
        }
      });
    }

    setIsDataLoading(false);
  };

  const titleLabelClass =
    themeCtx[0] === "dark"
      ? ManageRoleCss.darkMode_title_label
      : ManageRoleCss.title_label;

  // -----------------
  // -----------------
  return (
    <div
      id="manage-roles-container"
      data-testid="testid-manage-roles-container"
      aria-label="Manage users of the site"
      className={"container " + ManageRoleCss.main_container_div}
    >
      {showModal ? (
        <ModalCustom
          darkMode={false}
          onClick={closeModal}
          onClose={closeModal}
          modal_title={modalTitle}
          modal_msg={modalMsg}
          secondOption={false}
          btn_1_class={
            themeCtx[0] === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
        />
      ) : (
        <></>
      )}
      {displayNotAuth ? (
        <NotAuthorizedPage />
      ) : (
        <div className={ManageRoleCss.title_container_div}>
          <div className={ManageRoleCss.title_div}>
            <label
              id="title-label"
              data-testid="testid-title-label"
              htmlFor="user-eid-input"
              className={"form-label " + titleLabelClass}
            >
              Administrators
            </label>
          </div>
          <div className={"mb-3 " + ManageRoleCss.manage_role_main_div}>
            <EidInput
              ManageRoleCss={ManageRoleCss}
              icon_width={"1.8em"}
              icon_height={"1.8em"}
              onAddUser={addUserToList}
              disableElements={isDataLoading}
            />
            <div
              id="manage-role-users-list"
              data-testid="testid-manage-role-users-list"
              className={ManageRoleCss.users_list_div}
            >
              {isDataLoading ? loadingSpinner : displayUserList}
            </div>
            <div>
              <button
                id="save-changes-button"
                data-testid="testid-save-changes-button"
                aria-label="save changes on list"
                type="button"
                onClick={saveUserList}
                className={
                  "btn btn-light-mode-purple " + ManageRoleCss.save_changes_btn
                }
                disabled={isDataLoading}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageRoles;
