import "./ToggleButton.css";
const ToggleButton = (props) => {
  const executeAction = () => {
    props.btnAction();
  };
  return (
    <label className="switch-label">
      <input
        id={props.elemID}
        type="checkbox"
        defaultChecked={false}
        className={"switch-input"}
        onChange={executeAction}
      />
      <span className="toggle-span" />
      <strong className="switch-strong-el"> </strong>
    </label>
  );
};

export default ToggleButton;
