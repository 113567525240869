import React, { useContext } from "react";
import WarningIcon from "../../assets/images/warning_icon.svg";
import { ThemeContext } from "../../ThemeContext";
import { formatDate } from "../FormatDate/DateFormat";
import "./Maintenance.css";
const Maintenance = () => {
  const theme = useContext(ThemeContext);

  const maintenanceDate = () => {
    let todayDate = new Date();
    todayDate.setHours(18);
    todayDate.setMinutes(0);

    return formatDate(todayDate);
  };
  return (
    <>
      <div
        data-testid="maintenance-page"
        id="main-content"
        aria-label="Page Not Found"
        role="main"
        className="mt15p maintenance-page"
      >
        <p className="h1 mb-2 cross">
          <img
            className={"triangle_div"}
            src={WarningIcon}
            alt="warning-icon"
          />
        </p>
        <h1 className="mantainance">Site Maintenance</h1>
        <h3 className="message">Our Website is scheduled for</h3>
        <h3 className="message">maintenance and upgrades on</h3>
        <h3 className="message date">{maintenanceDate()}</h3>
        <br></br>
        <h4 className="message">The site will return shortly.</h4>
        <h4 className="message">Thank you for your patience.</h4>
      </div>
    </>
  );
};

export default Maintenance;
