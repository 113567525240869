import "../HowToForm.css";
import info from "../../../../assets/images/info.svg";
import { useState, useEffect } from "react";
import HowToValidations from "../../../../validationClasses/HowToValidations";

const HowToCommentsField = ({
  disableField,
  updateValue,
  setInputState,
  tooltipFor
}) => {
  const [enteredComment, setEnteredComment] = useState("");
  const [validInputClass, setValidInputClass] = useState("");
  const [commentsErrorMessage, setComentsErrorMessage] = useState("");

  const errorMsg = () => {
    return (
      <div id="validate-input" className="invalid_char_ht">
        {commentsErrorMessage}
      </div>
    );
  };

  const handleChange = (event) => {
    setEnteredComment(event.target.value);
    updateValue("comments", event.target.value);
    validateComment(event.target.value);
  };

  const validateComment = (comment) => {
    let isCommentValid = HowToValidations.ValidateComment(comment);

    setInputState("comment", isCommentValid.status);

    if (isCommentValid.status === null) {
      setValidInputClass("");
      setComentsErrorMessage("");
    } else if (isCommentValid.status) {
      setValidInputClass("is-valid");
      setComentsErrorMessage("");
    } else {
      setValidInputClass("is-invalid");
      setComentsErrorMessage(isCommentValid.msg);
    }
  };
  return (
    <div className="mb-3">
      <img
        id={"ht-form-tooltip-icon-" + tooltipFor}
        role="tooltip"
        src={info}
        alt="tooltip"
        className="ht_tooltip"
        data-bs-placement="left"
        data-bs-toggle="tooltip"
        tabIndex={0}
        title="This comment field can also be used to provide additional information requested by the Search CoE team as part of the How-To Card request process."
      />
      <label
        aria-label="Comments Field"
        htmlFor="ht_comments"
        className="ht_label"
      >
        Comments
      </label>
      <textarea
        data-testid="ht_comments"
        className={`form-control ${validInputClass}`}
        id="ht_comments"
        rows="3"
        placeholder="Comment field"
        value={enteredComment}
        onChange={handleChange}
        disabled={disableField}
      />
      {errorMsg()}
    </div>
  );
};
export default HowToCommentsField;
