import { useState, useEffect, useContext } from "react";
import HowToValidations from "../../../../validationClasses/HowToValidations";
import StoreContext from "../../../../store/storecontext";

const HowToInput = ({
  placeholder,
  field,
  state,
  setState,
  updateValue,
  validateTitleProp,
  value = null,
  disableField,
  id
}) => {
  const [inputValue, setInputValue] = useState(
    value !== undefined ? value : ""
  );
  const [validInputClass, setValidInputClass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const store = useContext(StoreContext);
  const urlCheckerService = store.services.urlCheckerService;

  const inputValidation = {
    title: async (input) => await validateTitle(input),
    url: async (input) => await validateURL(input)
  };
  var titleTimeout;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    titleTimeout = setTimeout(() => {
      inputValidation[field](inputValue);
    }, 480);
    return () => {
      clearTimeout(titleTimeout);
    };
  }, [inputValue]);

  const onChangeInput = (event) => {
    setInputValue(event.target.value);
  };

  const validateTitle = async (title) => {
    updateValue(field, inputValue);
    if (inputValue.length === 0) {
      setState(field, null);
      setErrorMessage("");
      setValidInputClass("");
    } else {
      const isInputValid = await validateTitleProp(title);
      setState(field, isInputValid.isValid);
      setErrorMessage(isInputValid.errorMessage);
      if (isInputValid.isValid) {
        setValidInputClass("is-valid");
        setErrorMessage("");
      } else {
        setValidInputClass("is-invalid");
        errorMsg();
      }
    }
  };
  const validateURL = async (url) => {
    updateValue(field, inputValue);
    if (inputValue.length === 0) {
      setState(field, null);
      setValidInputClass("");
      setErrorMessage("");
    } else {
      const isInputValid = await HowToValidations.validateURL(
        url,
        urlCheckerService
      );
      setState(field, isInputValid.isValid);
      setErrorMessage(isInputValid.errorMessage);
      if (isInputValid.isValid) {
        setValidInputClass("is-valid");
      } else {
        setValidInputClass("is-invalid");
        errorMsg();
      }
    }
  };

  const errorMsg = () => {
    if (state[field] || state[field] === null) {
      return <></>;
    } else {
      return (
        <div id="validate-input" className="invalid">
          {errorMessage}
        </div>
      );
    }
  };
  return (
    <>
      <input
        id={id}
        type="text"
        className={`form-control ${validInputClass}`}
        value={inputValue}
        placeholder={placeholder}
        onChange={(event) => onChangeInput(event)}
        disabled={disableField}
      />
      {errorMsg()}
    </>
  );
};

export default HowToInput;
