import { useState, useEffect } from "react";
import info from "../../../../assets/images/info.svg";
import "../HowToForm.css";
import HowToDropdowns from "./HowToDropdowns";
import HowToPills from "./HowToPills";

const HowToLocationField = ({
  locationInput,
  selectedLocation,
  selectedKeywords,
  selectedRefinerPage,
  keywordsForReview,
  updateValue,
  setInputState,
  disableField,
  requestStatus,
  tooltipFor
}) => {
  let location = {
    tooltip:
      "How-To can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations.",
    alt: "Location Info. How-To can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations",
    defaultOption: "Select target location(s) for How-To",
    ariaLabel: "Select a target location(s) for How-To"
  };
  const [locationOption, setLocationOption] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("searchtools.config")) {
      let cfg = JSON.parse(sessionStorage.getItem("searchtools.config"));

      const htCountries = cfg[1].topiccard.countries.map((option) => {
        return (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        );
      });
      setLocationOption(htCountries);
    }
  }, []);

  const addLocation = (event) => {
    const location = event.target.value.trim();
    if (selectedLocation.indexOf(location) === -1) {
      if (location === "Global" || selectedLocation.includes("Global")) {
        updateValue("countries", ["Global"]);
      } else {
        updateValue("countries", [...selectedLocation, location]);
      }
    }
    document.getElementById("ht_location").selectedIndex = 0;
  };

  return (
    <div className="mb-3">
      <img
        id={"ht-form-tooltip-icon-" + tooltipFor}
        role="tooltip"
        src={info}
        alt="tooltip"
        className="ht_tooltip"
        data-bs-placement="left"
        data-bs-toggle="tooltip"
        tabIndex={0}
        title="How-To Card can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations."
      />
      <label htmlFor="ht_location" className=" ht_label required">
        Location
      </label>
      <div className="ht_drops">
        <HowToDropdowns
          elementId="ht_location"
          key="location-selector"
          optionDefault={location.defaultOption}
          options={locationInput}
          ariaLabel={location.ariaLabel}
          labelText="Location"
          onChangeDropdown={addLocation}
          field="countries"
          setState={setInputState}
          updateValue={updateValue}
          inputValue={selectedLocation}
          disableField={disableField}
        >
          <option data-testid="ht-location">
            Select target location(s) for How-To Card
          </option>
          {locationOption}
        </HowToDropdowns>

        <HowToPills
          selectedTargets={selectedLocation}
          setSelectedTarget={updateValue}
          field="countries"
          arrayForReview={keywordsForReview}
          keywords={selectedKeywords}
          validateKeywords={{}}
          selectedRefinerPage={selectedRefinerPage}
          hasReviewArray={false}
          disableField={disableField}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default HowToLocationField;
