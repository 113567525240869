import services from "../store/services";
const titleLimit = 65;
const descriptionLimit = 1000;
const lineItemTitleLimit = 150;
const tcService = services.topicCardService;
const urlCheckerService = services.urlCheckerService;
const URLRegExp = urlCheckerService.URLRegExp;
const URLRegExp2 = urlCheckerService.URLRegExp2;

const urlCheckerValidation = (urlCheckerService) => {
  return [
    {
      regex: urlCheckerService.internalSiteRx,
      method: async (url, postHomeURL) =>
        await urlCheckerService.GetInternalSitesData(url, postHomeURL),
      errorMessage: "Please enter a live/active site URL for this Topic Card."
    },
    {
      regex: urlCheckerService.kxContributionRx,
      method: async (url) => await urlCheckerService.GetKXContributionHits(url),
      errorMessage:
        "Please enter an approved KX contribution URL for this Topic Card; for approval assistance, please contact the KX team."
    },
    {
      regex: urlCheckerService.collectionsRx,
      method: async (url) => await urlCheckerService.GetCollectionHits(url),
      errorMessage:
        "Please enter an official collection URL for this Topic Card; for assistance, please contact the KX team."
    },
    {
      regex: urlCheckerService.myLearningRx,
      method: async (url) => await urlCheckerService.GetMyLearningHits(url),
      errorMessage: "Please add an online training that is globally available."
    }
  ];
};
//--------------------------------------------------Validations--------------------------------------------------
const titleLengthValidation = (title, isLineItem) => {
  let titleCharactersLimit = isLineItem ? lineItemTitleLimit : titleLimit;
  if (title.length > titleCharactersLimit) {
    return {
      isValid: false,
      errorMessage: `Enter a title that is ${titleCharactersLimit} characters or less.`
    };
  } else {
    return { isValid: true };
  }
};

const specialCharsValidation = (title) => {
  let regex = urlCheckerService.textRegex;

  if (title.match(regex)) {
    return {
      isValid: false,
      errorMessage: 'Please do not include the special characters \\ and "'
    };
  } else {
    return { isValid: true };
  }
};

const validateTitle = async (title, isLineItem, tcrequestid = null) => {
  if (!titleLengthValidation(title, isLineItem).isValid) {
    return titleLengthValidation(title, isLineItem);
  }
  if (!specialCharsValidation(title).isValid) {
    return specialCharsValidation(title, isLineItem);
  }

  let errorMessage = "";
  let isValid = true;
  if (!isLineItem) {
    isValid = await validateDuplicateTitles(title, tcrequestid, tcService);
  }
  if (!isValid && !isLineItem) {
    errorMessage =
      "This title is already taken; please enter a unique Topic Card title";
  }
  errorMessage = isValid ? "" : errorMessage;
  return {
    isValid: isValid,
    errorMessage: errorMessage
  };
};
const validateDuplicateTitles = async (
  title,
  tcrequestid = null,
  tcService
) => {
  let isValid;
  let hasId = tcrequestid !== 0 && tcrequestid !== null;

  let usedTitle = await tcService.GetTopicCardByTitle(title);
  let totalHits = usedTitle.data.hits.total.value;
  let requests = usedTitle.data.hits.hits;

  if (totalHits > 0) {
    let titles = requests.map((request) =>
      request._source.title.trim().toLowerCase()
    );
    let requestIds = requests.map((request) =>
      parseInt(request._source.tcrequestid)
    );
    isValid = !titles.includes(title.toLowerCase());

    if (!isValid) {
      return (isValid = hasId
        ? requestIds.includes(parseInt(tcrequestid))
        : isValid);
    }
  } else {
    isValid = true;
  }
  return isValid;
};
const validateURL = async (url) => {
  const isValid = validateURLFormat(url,urlCheckerService);

  if (isValid) {
    return isValid;
  } else {
    const urlValidations = urlCheckerValidation(urlCheckerService);
    return validateURLCheckers(url, urlValidations);
  }
};
function getHomeUrl(url) {
  let newUrl = url.split("/");
  let internalSiteHome = "https://in.accenture.com/".concat(newUrl[3]);
  return internalSiteHome;
}
const validateURLFormat = (url,urlCheckerService) => {
  let regex = urlCheckerService.urlRegex;
  if (!url.match(regex)) {
    return {
      isValid: false,
      errorMessage: "This is not a valid url format"
    };
  }
};
const validateURLCheckers = async (url, urlCheckerValidation) => {
  let postHomeURL = getHomeUrl(url);
  const matchingRegex = urlCheckerValidation.filter((reg) =>
    reg.regex.test(url)
  )[0];
  if (matchingRegex === undefined) {
    return {
      isValid: URLRegExp.test(url) && URLRegExp2.test(url),
      errorMessage: "This is not a valid url format"
    };
  } else {
    let isURLCheckerValid = await matchingRegex.method(url, postHomeURL);
    let totalHits = isURLCheckerValid.data.hits.total.value;
    return {
      isValid: totalHits === 0 ? false : true,
      errorMessage: matchingRegex.errorMessage
    };
  }
};

const validateDescription = (description) => {
  let isValidDesc = false;
  let msg = `The description field must be ${descriptionLimit} characters or less.`;
  let regex = urlCheckerService.textRegex;

  if (description.match(regex)) {
    return {
      isValid: false,
      errorMessage: 'Please do not include the special characters \\ and "'
    };
  }

  if (description.trim().length === 0) {
    isValidDesc = null;
  }
  if (description.trim().length > 0 && description.length <= descriptionLimit) {
    isValidDesc = true;
    msg = "";
  }
  return { isValid: isValidDesc, errorMessage: msg };
};

const validateRefinerPage = (refinerPage) => {
  return { isValid: refinerPage.length > 0, errorMessage: "" };
};
const validateLocation = (location) => {
  return { isValid: location.length > 0, errorMessage: "" };
};
const validateKeywords = async (keyword) => {
  let repeatedKeywords = await keyword.service.GetTopicCardsByKeyword(
    keyword.keyword,
    keyword.refinerPage,
    keyword.location,
    (keyword.abortController = null)
  );
  let totalHits = repeatedKeywords.data.hits.total.value;
  if (totalHits === 0) {
    return { isValid: true, errorMessage: "" };
  } else {
    let isTheSameRequest = false;
    let idArr = [];

    if (keyword.tcrequestid !== null && keyword.tcrequestid > 0) {
      let kwHits = repeatedKeywords.data.hits.hits;
      for (let i = 0; i < kwHits.length; i++) {
        const hitID = kwHits[i]._source.tcrequestid;
        idArr.push(parseInt(hitID));
      }

      isTheSameRequest = idArr.includes(keyword.tcrequestid);
    }

    return {
      isValid: isTheSameRequest,
      errorMessage:
        "Please enter at least one valid, relevant keyword in order to successfully submit this Topic Card request."
    };
  }
};
const validateDelegate = async (delegates) => {
  let isValid;
  let currentUser = localStorage.getItem("searchtools.eid");
  let modalErrorMessage = "";
  let eid = await delegates.service.GetEmployeesByEID(delegates.delegate, true);
  let totalHits = eid.data.hits.total;
 
  if (totalHits === 0) {
    modalErrorMessage = "Please enter a valid EID";
    isValid = false;
  } else {
    eid = eid.data.hits.hits[0]._source.enterpriseid;
    if (delegates.delegate === currentUser && delegates.field === "delegates") {
      modalErrorMessage = "Please enter an EID other than yours";
      isValid = false;
    } else if (delegates.delegates.includes(delegates.delegate)) {
      modalErrorMessage =
        "You have entered the same EID twice. Please enter a unique value per EID.";
      isValid = false;
    } else {
      modalErrorMessage = "";
      isValid = true;
    }
  }
  return { isValid: isValid, errorMessage: modalErrorMessage };
};

const validateComments = (comments) => {
  let regex = urlCheckerService.textRegex;

  if (comments.match(regex)) {
    return {
      isValid: false,
      errorMessage: 'Please do not include the special characters \\ and "'
    };
  } else if (comments.length <= 1000) {
    return { isValid: true, errorMessage: "" };
  } else {
    return { isValid: false, errorMessage: "Comment must be 1000 characters or less" };
  }
};
const validateFormMode = (reqData) => {
  let isAdmin = reqData.isAdmin;
    let currEid = localStorage.getItem("searchtools.eid");
    let isOwner = currEid === reqData.submittedbyenterpriseid;
    let delegateList = reqData.delegates.map((el) => {
      return el.eid;
    });
    
    let isDelegate = delegateList.includes(currEid);

    if (
      (isAdmin || isOwner || isDelegate) &&
      reqData.status !== "Inactive"
    ) {
      return false;
    } else {
      return true;
    }
};


const TopicCardValidations = {
  specialCharsValidation: specialCharsValidation,
  titleLengthValidation: titleLengthValidation,
  validateTitle: validateTitle,
  validateDuplicateTitles: validateDuplicateTitles,
  validateURLFormat: validateURLFormat,
  validateURLCheckers: validateURLCheckers,
  urlCheckerValidation: urlCheckerValidation,
  validateDescription: validateDescription,
  validateComments: validateComments,
  validateRefinerPage: validateRefinerPage,
  validateLocation: validateLocation,
  validateDelegate: validateDelegate,
  validateURL: validateURL,
  validateKeywords: validateKeywords,
  validateFormMode: validateFormMode
};
export default TopicCardValidations;
