import { useState } from "react";

const INPUT_ELEM_ID = "ctc-comments-input";

const CommentsField = (props) => {
  // Comments must be 1000 characters or less.

  const [cssState, setCssState] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const validateField = () => {
    setTimeout(async () => {
      let htmlEl = document.querySelector(`#${INPUT_ELEM_ID}`);
      let commentValue = htmlEl.value;

      let validationResult = await props.validateComment(commentValue);

      props.updateFormState(validationResult.status, "comments");

      let state = "";
      if (validationResult.status === null) {
        state = "";
      } else {
        state = validationResult.status ? " is-valid " : " is-invalid ";
      }

      setStatusMessage(validationResult.msg);
      setCssState(state);
    }, 500);
  };

  return (
    <div className={"comments_field_div"}>
      <textarea
        name="comments"
        id={INPUT_ELEM_ID}
        data-testid={"testid-" + INPUT_ELEM_ID}
        cols="30"
        rows="5"
        placeholder="If needed, provide additional comments/details to be considered as part of this Custom Topic Card request."
        className={"form-control" + cssState}
        aria-label="comments input field"
        style={{ resize: "none" }}
        onInput={validateField}
        onBlur={validateField}
      ></textarea>
      <div className={"invalid-feedback " + props.adjust_error_msg}>
        {statusMessage}
      </div>
    </div>
  );
};

export default CommentsField;
