import { useState } from "react";
import info from "../../../../assets/images/info.svg";
import { BsPlusSquare } from "react-icons/bs";
import "../SynonymsForm.css";

const AddSynonymsField = ({ onAddSynonym, disableFieldProp, tooltipFor }) => {
  const [synonym, setSynonym] = useState("");

  const handleAddClick = () => {
    if (synonym.trim() !== "") {
      onAddSynonym(synonym);
      setSynonym("");
    }
  };

  return (
    <>
      <div className="add_synonyms_label">
        <img
          id={"sy-form-tooltip-icon-" + tooltipFor}
          tabIndex={0}
          role="tooltip"
          src={info}
          alt="tooltip"
          className="synonyms_tooltip"
          data-bs-placement="left"
          data-bs-toggle="tooltip"
          title="Please enter at least one synonym to refer to your term. Max of 20 synonyms."
        />
        <label htmlFor="add-synonyms" className="add_synonyms_label required">
          Add Synonyms
        </label>
        <div className="add_synonyms_input">
          <input
            type="text"
            className="form-control synonym_input"
            placeholder="Enter your synonym here"
            id="synonymInput"
            data-testid="add-synonym"
            value={synonym}
            disabled={disableFieldProp}
            onChange={(e) => setSynonym(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleAddClick()}
          />
          {!disableFieldProp && (
            <BsPlusSquare
              tabIndex={0}
              data-testid="plus-add-syn-form"
              id="plusIconAddSyn"
              className="synonyms_plus_icon"
              onClick={handleAddClick}
              onKeyDown={(e) => e.key === "Enter" && handleAddClick()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddSynonymsField;
