import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $getNodeByKey
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  $wrapNodes,
  $isAtNodeEnd
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from "@lexical/list";
import { createPortal } from "react-dom";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import {
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages
} from "@lexical/code";

import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { INSERT_TABLE_COMMAND } from "@lexical/table";

import {
  $deleteTableColumn,
  $getElementGridForTableNode,
  $getTableCellNodeFromLexicalNode,
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $insertTableColumn,
  $insertTableRow,
  $removeTableRowAtIndex,
  getTableSelectionFromTableElement
} from "@lexical/table";

import { $getRoot } from "lexical";
import { urlFormatter } from "../../../../../URLFormatter/urlFormatter";

const LowPriority = 1;

const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "code",
  "h1",
  "h2",
  "ul",
  "ol"
]);

const blockTypeToBlockName = {
  code: "Code Block",
  h1: "Large Heading",
  h2: "Small Heading",
  h3: "Heading",
  h4: "Heading",
  h5: "Heading",
  ol: "Numbered List",
  paragraph: "Normal",
  quote: "Quote",
  ul: "Bulleted List"
};

function Divider() {
  return <div className="divider" />;
}

function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div className="link-input">
            <a href="#" target="_blank" rel="noopener noreferrer">
              {urlFormatter(linkUrl)}
            </a>
            <div
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function Select({ onChange, className, options, value }) {
  return (
    <select className={className} onChange={onChange} value={value}>
      <option hidden={true} value="" />
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
  isDarkMode,
  disableField
}) {
  const dropDownRef = useRef(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  let darkDrpdwn = isDarkMode ? "dark-dropdown-menu" : "";
  let isDisabled = disableField ? " is-disabled " : "";
  return (
    <div
      data-testid="format-options"
      id="format-options"
      className={"lexical-dropdown " + isDisabled + darkDrpdwn}
      ref={dropDownRef}
    >
      <button className="item" onClick={formatParagraph}>
        <span className="icon paragraph" data-testid="normal" />
        <span className="text">Normal</span>
        {blockType === "paragraph" && <span className="active" />}
      </button>
      <button className="item" onClick={formatLargeHeading}>
        <span className="icon large-heading" data-testid="large-heading" />
        <span className="text">Large Heading</span>
        {blockType === "h1" && <span className="active" />}
      </button>
      <button className="item" onClick={formatSmallHeading}>
        <span className="icon small-heading" data-testid="small-heading" />
        <span className="text">Small Heading</span>
        {blockType === "h2" && <span className="active" />}
      </button>
      <button className="item" onClick={formatBulletList}>
        <span className="icon bullet-list" data-testid="bullet-list" />
        <span className="text">Bullet List</span>
        {blockType === "ul" && <span className="active" />}
      </button>
      <button className="item" onClick={formatNumberedList}>
        <span className="icon numbered-list" data-testid="numbered-list" />
        <span className="text">Numbered List</span>
        {blockType === "ol" && <span className="active" />}
      </button>
    </div>
  );
}

export default function ToolbarPlugin(props) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [codeLanguage, setCodeLanguage] = useState("");
  const [isRTL, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [currRowValue, setCurrRowValue] = useState(0);
  const [currColValue, setCurrColValue] = useState(0);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    (e) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e.target.value);
          }
        }
      });
    },
    [editor, selectedElementKey]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const getNumberOf = (event, tableSection) => {
    let sectionValue = event.target.value;
    let sectionNum = parseInt(sectionValue);
    if (sectionNum > 0 && sectionNum < 30) {
      if (tableSection === "Rows") {
        setCurrRowValue(sectionValue);
      } else {
        setCurrColValue(sectionValue);
      }
    } else return;
  };

  const createNewTable = () => {
    let payload = {
      columns: currColValue,
      rows: currRowValue,
      includeHeaders: false
    };
    if (parseInt(currColValue) > 0 && parseInt(currRowValue) > 0) {
      editor.dispatchCommand(INSERT_TABLE_COMMAND, payload);
    }
  };

  const clearTableSelection = () => {
    editor.update(() => {
      try {
        const selection = $getSelection();

        const startingNode = selection.getNodes()[0];
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(startingNode);
        const tableElement = editor.getElementByKey(tableNode.getKey());

        if (!tableElement) {
          throw new Error("Expected to find tableElement in DOM");
        }

        const tableSelection = getTableSelectionFromTableElement(tableElement);
        if (tableSelection !== null) {
          tableSelection.clearHighlight();
        }

        tableNode.markDirty();
        const rootNode = $getRoot();
        rootNode.selectStart();
      } catch (error) {}
    });
  };

  const addTableRows = () => {
    if (parseInt(currRowValue) === 0) return;
    editor.update(() => {
      try {
        const selection = $getSelection();

        const startingNode = selection.getNodes()[0];
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(startingNode);
        const tableCellNode = $getTableCellNodeFromLexicalNode(startingNode);

        let tableRowIndex;
        tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

        const grid = $getElementGridForTableNode(editor, tableNode);

        let selectionCounts = { rows: currRowValue, cols: 0 };
        const shouldInsertAfter = true;
        $insertTableRow(
          tableNode,
          tableRowIndex,
          shouldInsertAfter,
          selectionCounts.rows,
          grid
        );

        clearTableSelection();
      } catch (error) {}
    });
  };

  const addTableCols = () => {
    if (parseInt(currColValue) === 0) return;
    editor.update(() => {
      try {
        const selection = $getSelection();

        const startingNode = selection.getNodes()[0];
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(startingNode);
        const tableCellNode = $getTableCellNodeFromLexicalNode(startingNode);

        let tableColumnIndex;
        tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode);
        console.log(tableColumnIndex);

        const grid = $getElementGridForTableNode(editor, tableNode);

        let selectionCounts = { rows: 0, cols: currColValue };
        const shouldInsertAfter = true;
        $insertTableColumn(
          tableNode,
          tableColumnIndex,
          shouldInsertAfter,
          selectionCounts.cols,
          grid
        );
        clearTableSelection();
      } catch (error) {}
    });
  };

  const removeTableRows = () => {
    editor.update(() => {
      try {
        const selection = $getSelection();

        const startingNode = selection.getNodes()[0];
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(startingNode);
        const tableCellNode = $getTableCellNodeFromLexicalNode(startingNode);
        const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

        $removeTableRowAtIndex(tableNode, tableRowIndex);
        clearTableSelection();
      } catch (error) {}
    });
  };

  const removeTableCols = () => {
    editor.update(() => {
      try {
        const selection = $getSelection();

        const startingNode = selection.getNodes()[0];
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(startingNode);
        const tableCellNode = $getTableCellNodeFromLexicalNode(startingNode);
        const tableColumnIndex =
          $getTableColumnIndexFromTableCellNode(tableCellNode);

        $deleteTableColumn(tableNode, tableColumnIndex);
        clearTableSelection();
      } catch (error) {}
    });
  };

  let isFormatBtnDark = "";
  let darkDrpdwnMenuCss = "";

  if (props.isDarkMode) {
    isFormatBtnDark = " format-opt-btn-dark ";
    darkDrpdwnMenuCss = " ul-dark-table-menu ";
  }

  const deleteTable = () => {
    editor.update(() => {
      try {
        const selection = $getSelection();

        const startingNode = selection.getNodes()[0];
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(startingNode);
        tableNode.remove();

        clearTableSelection();
      } catch (error) {}
    });
  };

  let isDisabled = props.disableField ? " is-disabled " : "";
  // custom menu for table actions
  const tableOptionsMenu = (
    <div className={"dropdown ctc-table-menu-selector " + isDisabled}>
      <button
        className={isFormatBtnDark + "btn dropdown-toggle"}
        type="button"
        data-testid="dropdownMenuButton1"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Table
      </button>
      <ul
        data-testid="table-options"
        id="table-options"
        className={"dropdown-menu" + darkDrpdwnMenuCss}
        aria-labelledby="dropdownMenuButton1"
      >
        <li>
          <div className={"number-of-div"}>
            <label htmlFor="insert-rows-label">Rows: </label>
            <input
              data-testid="insert-rows-label"
              type="number"
              name="0"
              id="insert-rows-label"
              onChange={(event) => getNumberOf(event, "Rows")}
            />
          </div>
        </li>
        <li>
          <div className={"number-of-div"}>
            <label htmlFor="insert-cols-label">Cols: </label>
            <input
              data-testid="insert-cols-label"
              type="number"
              name="0"
              id="insert-cols-label"
              onChange={(event) => getNumberOf(event, "Cols")}
            />
          </div>
        </li>
        <li>
          <button
            onClick={createNewTable}
            data-testid="create-table"
            id="create-table"
            className="toolbar-item create-table"
            aria-label="Insert Table"
          >
            <TablePlugin />
            <i className={"create-table-label"}>
              Create Table ({currRowValue} x {currColValue})
            </i>
          </button>
        </li>
        <li>
          <button
            onClick={addTableRows}
            data-testid="add-table-row"
            id="add-table-row"
            className="toolbar-item create-table"
            aria-label="..."
          >
            <i className={""}>Add Rows ({currRowValue})</i>
          </button>
        </li>
        <li>
          <button
            onClick={removeTableRows}
            data-testid="remove-table-row"
            id="remove-table-row"
            className="toolbar-item create-table"
            aria-label="..."
          >
            <i className={""}>Remove Row</i>
          </button>
        </li>
        <li>
          <button
            onClick={addTableCols}
            data-testid="add-table-col"
            id="add-table-col"
            className="toolbar-item create-table"
            aria-label="..."
          >
            <i className={""}>Add Cols ({currColValue})</i>
          </button>
        </li>
        <li>
          <button
            onClick={removeTableCols}
            data-testid="remove-table-col"
            id="remove-table-col"
            className="toolbar-item create-table"
            aria-label="..."
          >
            <i className={""}>Remove Col</i>
          </button>
        </li>
        <li>
          <button
            onClick={deleteTable}
            data-testid="delete-table"
            id="delete-table"
            className="toolbar-item create-table"
            aria-label="..."
          >
            <i className={""}>Delete Table</i>
          </button>
        </li>
      </ul>
    </div>
  );

  return (
    <div className="toolbar" ref={toolbarRef}>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND);
        }}
        data-testid="undo-item"
        id="undo-item"
        className="toolbar-item spaced"
        aria-label="Undo"
      >
        <i className="format undo" />
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND);
        }}
        data-testid="redo-item"
        id="redo-item"
        className="toolbar-item"
        aria-label="Redo"
      >
        <i className="format redo" />
      </button>
      <Divider />
      {supportedBlockTypes.has(blockType) && (
        <>
          <button
            className={"toolbar-item block-controls " + isFormatBtnDark}
            onClick={() =>
              setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
            }
            data-testid="format-options-btn"
            id="format-options-btn"
            aria-label="Formatting Options"
          >
            <span className={"icon block-type " + blockType} />
            <span className="text">{blockTypeToBlockName[blockType]}</span>
            <i className="chevron-down" />
          </button>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                isDarkMode={props.isDarkMode}
                disableField={props.disableField}
              />,
              document.body
            )}
          <Divider />
        </>
      )}
      {blockType === "code" ? (
        <>
          <Select
            className="toolbar-item code-language"
            onChange={onCodeLanguageSelect}
            options={codeLanguges}
            value={codeLanguage}
          />
          <i className="chevron-down inside" />
        </>
      ) : (
        <>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
            }}
            data-testid="bold-item"
            id="bold-item"
            className={"toolbar-item spaced " + (isBold ? "active" : "")}
            aria-label="Format Bold"
          >
            <i className="format bold" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
            }}
            data-testid="italic-item"
            id="italic-item"
            className={"toolbar-item spaced " + (isItalic ? "active" : "")}
            aria-label="Format Italics"
          >
            <i className="format italic" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
            }}
            data-testid="underline-item"
            id="underline-item"
            className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
            aria-label="Format Underline"
          >
            <i className="format underline" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
            }}
            data-testid="strikethrough-item"
            id="strikethrough-item"
            className={
              "toolbar-item spaced " + (isStrikethrough ? "active" : "")
            }
            aria-label="Format Strikethrough"
          >
            <i className="format strikethrough" />
          </button>
          <button
            onClick={insertLink}
            data-testid="link-item"
            id="link-item"
            className={"toolbar-item spaced " + (isLink ? "active" : "")}
            aria-label="Insert Link"
          >
            <i className="format link" />
          </button>
          {isLink &&
            createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
          <Divider />
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
            }}
            data-testid="left-align-item"
            id="left-align-item"
            className="toolbar-item spaced"
            aria-label="Left Align"
          >
            <i className="format left-align" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
            }}
            data-testid="center-align-item"
            id="center-align-item"
            className="toolbar-item spaced"
            aria-label="Center Align"
          >
            <i className="format center-align" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
            }}
            data-testid="right-align-item"
            id="right-align-item"
            className="toolbar-item spaced"
            aria-label="Right Align"
          >
            <i className="format right-align" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
            }}
            data-testid="jutify-align-item"
            id="jutify-align-item"
            className="toolbar-item"
            aria-label="Justify Align"
          >
            <i className="format justify-align" />
          </button>
          {tableOptionsMenu}
        </>
      )}
    </div>
  );
}