const LabelForSection = (props) => {
  return (
    <div
      id={props.info_section_id}
      data-testid={props.info_section_testid}
      className={props.info_section_div}
    >
      <img
        tabIndex={0}
        id={props.info_tooltip_id}
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-html={props.customTooltip}
        data-bs-title={props.tooltip_message}
        src={props.infoIcon}
      />
      <label
        htmlFor={props.html_id_for_label}
       className={`label-text ${props.is_required ? "required": ""}`} 
      >{props.section_title}</label>
    </div>
  );
};

export default LabelForSection;
