const ExcelJS = require("exceljs");

class ExcelFileDownloader {
  fileTitle = "";
  service = null;

  
  async getFileData() {
    let {data} = await this.service();

    var columnsName = [];
    var columnsList = [];
    var cannedColumnsList = [];
    var cannedDataList = [];
    var reportDataList = [];
    var sheetNameList = [];

    for(let i= 0;i<data.length;i++){

      if(data[i].fileContent.length !== 0) {
        columnsName = Object.keys(data[i].fileContent[0]).map(String);
   
      }
      
      var cannedColumns = Object.keys(data[i].cannedColumns).map(
        String
      );
      var cannedData = Object.values(data[i].cannedColumns).map(String);
      let columns = [];
      
      if(cannedColumns.length === 1) {
        cannedColumns = cannedColumns.concat(cannedData);
        cannedData = [];
      }
      for (let i = 0; i < columnsName.length; i++) {
        columns.push({ header: columnsName[i], key: columnsName[i], width: 25 });
        
      }
      columnsList.push(columns);
      cannedDataList.push(cannedData);
      reportDataList.push(data[i].fileContent);
      sheetNameList.push(data[i].sheetName);
      cannedColumnsList.push(cannedColumns);
    }
    return {
      cannedColumns: cannedColumnsList,
      reportColumns: columnsList,
      reportData: reportDataList,
      cannedData: cannedDataList,
      sheetName : sheetNameList
    };
  }

  createFileContent({ cannedColumns, reportColumns, reportData, cannedData, sheetName }) {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Search Tools";

    for( let i = 0; i < sheetName.length; i++) {
      const worksheet = workbook.addWorksheet(sheetName[i]);

      let startCell;
      let endCell;
      if(cannedColumns[0].length === 0){
        startCell = 1;
        endCell = 2;
  
      } else{
        worksheet.getCell(1, 1).value = sheetName[i];
  
        cannedColumns[i].forEach((column, index) => {
          worksheet.getColumn(index + 1).width = 20; 
          worksheet.getCell(2, index + 1).value = column;
        });
        for (let k = 0; k < cannedData[i].length; k++) {
          cannedData[i].forEach((data, index) => {
            worksheet.getColumn(index + 1).width = 20; 
            worksheet.getCell(3, index + 1).value = data;
          });
        }
         startCell = 6;
         endCell = 7;
    
      }
      if(reportData.length === 0) {
        return workbook;
      } else{
        
        reportColumns[i].forEach((column, index) => {
          worksheet.getColumn(index + 1).width = 20; 
          worksheet.getCell(startCell, index + 1).value = column.header;
        });
  
  
         for (let j = 0; j < reportData[i].length; j++) {
          Object.values(reportData[i][j]).forEach((data, index) => {
            worksheet.getColumn(index + 1).width = 20; 
            worksheet.getCell(j + endCell, index + 1).value = data;
          });
         }
      }
    }
    return workbook;
  }
  async downloadFile() {
    try {
      const fileContent = await this.getFileData();
      const workbook = this.createFileContent(fileContent);

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = `${this.fileTitle}.xlsx`;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
      return 200;
    } catch (error) {
      console.log("Error in downloading file", error);
      return 500;
    }
  }

  constructor(service, fileTitle = null) {
    this.service = service;
    this.fileTitle = fileTitle;
  }
}

export default ExcelFileDownloader;
