import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Metrics.css";

const MetricsTabs = () => {
  const [sectionActive, setSectionActive] = useState("metrics");

  useEffect(() => {
    const currentURL = window.location.pathname;
    switch (currentURL) {
      case "/metrics":
        setSectionActive("metrics");
        break;
      case "/metrics/feedback":
        setSectionActive("feedback");
        break;
      default:
        setSectionActive("metrics");
        break;
    }
  }, []);

  const resetTabs = () => {
    try {
      let myActiveTab = document.querySelector(".nav-link.active");
      myActiveTab.classList.remove("active");
    } catch (error) {}
  };

  const tabActions = (tab) => {
    resetTabs();
    if (tab === "feedback") {
      setSectionActive(tab);
    } else {
      setSectionActive("metrics");
    }
  };

  return (
    <>
      <div className="metrics-tabs">
        <div className="tabs">
          <Link
            to="/metrics"
            className={
              "nav nav-link " + (sectionActive === "metrics" ? "active" : "")
            }
            aria-current="page"
            data-testid="usage-link"
            id="metrics-st-usage-data-link"
            onClick={() => tabActions("metrics")}
          >
            Search Tools Usage
          </Link>
          <div className="metrics-st-section-tabs-separator"></div>
          <Link
            to="/metrics/feedback"
            className={
              "nav nav-link " + (sectionActive === "feedback" ? "active" : "")
            }
            data-testid="feedback-link"
            id="metrics-st-feedback-data-link"
            onClick={() => tabActions("feedback")}
          >
            Search Tools Feedback
          </Link>
        </div>
        <div className="metrics-st-menu-section-separator"></div>
      </div>
      <Outlet />
    </>
  );
};

export default MetricsTabs;
