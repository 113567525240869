import "../TCForm.css";
import { useEffect, useContext } from "react";
import { Tooltip } from "bootstrap";
import { ThemeContext } from "../../../../ThemeContext";
import { triggerToast } from "../../../Toast/TriggerToast";

const TopicCardPills = ({
  selectedTargets,
  setSelectedTarget,
  arrayForReview = [],
  hasReviewArray,
  field,
  keywords = [],
  updateGetInTouch,
  updateDelegates,
  disableField,
  revalidateForm,
  setModalMessage = null,
  setOpenKeywordsModal = null,
  setSynonyms = null,
  onCopyToClipBoard,
  requestStatus,
  isLoading = null
}) => {
  const [theme] = useContext(ThemeContext);

  let greenPillStyle = "btn btn-success pills";
  let redPill = "btn btn-danger pills-red";
  let greyPill = "btn btn-gray-class";

  if (selectedTargets === undefined) {
    selectedTargets = [];
  }

  useEffect(() => {
    if (arrayForReview.length >= 0) {
      const tooltipClass = theme === "dark" ? "tooltip-dark" : "";
      let tooltipElements = document.querySelectorAll(
        '[data-bs-toggle="red-pills"]'
      );
      [...tooltipElements].map(
        (ttp) =>
          new Tooltip(ttp, {
            tooltipElements,
            trigger: "hover",
            customClass: tooltipClass
          })
      );
    }
  }, [theme,arrayForReview]);

  const removePill = (event) => {
    const target = event.target.value.trim();
    let targetArray = [];

    if (selectedTargets.indexOf(target) !== -1) {
      targetArray = [...selectedTargets];

      if (field === "getInTouch") {
        updateGetInTouch(target);
      } else if (field === "delegates") {
        updateDelegates(target);
      } else {
        targetArray = targetArray.filter(
          (targetElement) => target !== targetElement
        );
        setSelectedTarget(field, targetArray);
        if (field === "keyword") {
          revalidateForm();
        }
      }
    } else if (field === "keyword") {
      targetArray = [...arrayForReview];
      targetArray = targetArray.filter(
        (targetElement) => target !== targetElement
      );
      setSelectedTarget("keywordsForReview", targetArray);
    }
  };
  const addSynonym = async (event) => {
    deleteOldTooltips();
    const synonym = event.target.value;
    let enteredKeywords = keywords.concat(arrayForReview);
    const allowedEvents = event.type === "click";
    if (allowedEvents && synonym !== "") {
      if (enteredKeywords.length < 20) {
        let synonyms = [...selectedTargets];
        synonyms = synonyms.filter((syn) => syn !== synonym);
        setSynonyms(synonyms);
        let newTargetList = [...keywords, synonym];
        setSelectedTarget("keyword", newTargetList.sort());
      } else {
        setModalMessage("Only 20 keywords are allowed");
        setOpenKeywordsModal(true);
      }
    }
  };

  const initToolTipForRemove = () => {
    const tooltipClass = theme === "dark" ? "tooltip-dark" : "";
    let tooltipElements = document.querySelectorAll(
      '[data-bs-toggle="remove-item"]'
    );
    [...tooltipElements].map(
      (ttp) =>
        new Tooltip(ttp, {
          tooltipElements,
          trigger: "hover",
          customClass: tooltipClass
        })
    );
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };
  const handleClick = (e) =>{
    onCopyToClipBoard(e)
    triggerToast(1000,0,"copyToClipboard");
  } 
  return (
    <div className="tc-pills-container">
      {selectedTargets.length === 0 ? (
        ""
      ) : (
        <div className="pills-group">
          {selectedTargets.map((target, index) => (
            <div key={index} className={`btn-group pill`} role="group">
              <button
                type="button"
                data-testid={`green-pill-${field}`}
                className={
                  requestStatus === "Inactive" ? greyPill : greenPillStyle
                }
                onClick={handleClick}
              >
                {target}
              </button>

              {field === "synonyms" ? (
                <button
                  role="tooltip"
                  data-bs-toggle="red-pills"
                  data-bs-placement="top"
                  data-bs-title={"Add as keyword"}
                  type="button"
                  value={target}
                  className={`btn btn-success pills`}
                  data-testid={`plus-pill`}
                  onClick={(event) => addSynonym(event)}
                >
                  +
                </button>
              ) : (
                !disableField && (
                  <button
                    type="button"
                    value={target}
                    className={`btn btn-success pills`}
                    role="tooltip"
                    data-bs-toggle="remove-item"
                    data-bs-placement="top"
                    data-bs-title={"Remove"}
                    data-testid={`cross-pill`}
                    onMouseEnter={initToolTipForRemove}
                    onMouseLeave={deleteOldTooltips}
                    onClick={(event) => removePill(event)}
                  >
                    x
                  </button>
                )
              )}
                
            </div>
          ))}
        </div>
      )}
      {hasReviewArray ? (
        arrayForReview.length === 0 ? (
          ""
        ) : (
          <div className="pills-group">
            {arrayForReview.map((target, index) => (
              <div key={index} className={`btn-group pill`} role="group">
                <button
                  role="tooltip"
                  onClick={handleClick}
                  data-bs-toggle="red-pills"
                  data-testid={`red-pill-${field}`}
                  data-bs-placement="top"
                  data-bs-title={
                    "Another Topic Card is already associated with this keyword. It will be considered, but may not be implemented."
                  }
                  type="button"
                  className={requestStatus === "Inactive" ? greyPill : redPill}
                >
                  {target}
                </button>

                {!disableField && (
                  <button
                    type="button"
                    value={target}
                    className={`btn btn-danger pills-red`}
                    data-testid={`cross-pill`}
                    onClick={(event) => removePill(event)}
                  >
                    x
                  </button>
                )}
              </div>
            ))}
          </div>
        )
      ) : (
        ""
      )}
        {(field === "delegates" || field === "getInTouch") && isLoading ? <div className="d-flex justify-content-center flex-column align-items-center">
                <div
                  className="spinner-delegates spinner-border spinner-border"
                  role="status"
                  id="delegates-spinner"
                  data-testid="delegates-spinner"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div> : <> </>}
    </div>
  );
};

export default TopicCardPills;
