import { useEffect, useState } from "react";
import info from "../../../../assets/images/info.svg";
import HowToStep from "./HowToStep";

const HowToSteps = (props) => {
  const [validInputClass, setValidInputClass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [steps, setSteps] = useState([
    { step: "" },
    { step: "" },
    { step: "" }
  ]);

  useEffect(() => {
    props.setStepsArray(steps);
  }, [steps]);

  useEffect(() => {
    setSteps(props.value);
  }, [props.value]);

  const handleStepsChange = (event, index) => {
    const newSteps = [...props.steps];

    newSteps[index]["step"] = event.target.value;
    setSteps(newSteps);
    props.onStepChange(newSteps);
  };

  const handleAddStep = () => {
    const newSteps = [...steps, { step: "" }];
    setSteps(newSteps);
    props.setNewStepState(null);
  };
  const moveLineItem = (action, index) => {
    let stepArray = [...steps];
    let itemToMove = stepArray.find((item, idx) => idx === index);
    let remainingItems = stepArray.filter((item, idx) => idx !== index);
    let reorderedItems;
    let newIndex;

    switch (action) {
      case "Up":
        newIndex = index === 0 ? stepArray.length : index - 1;

        reorderedItems = [
          ...remainingItems.slice(0, newIndex),
          itemToMove,
          ...remainingItems.slice(newIndex)
        ];

        setSteps(reorderedItems);

        break;
      case "Down":
        newIndex = index === stepArray.length - 1 ? 0 : index + 1;

        reorderedItems = [
          ...remainingItems.slice(0, newIndex),
          itemToMove,
          ...remainingItems.slice(newIndex)
        ];

        setSteps(reorderedItems);
        break;
    }
  };
  const removeStep = (index) => {
    let stepsArray = [...steps];

    stepsArray = stepsArray.filter((step) => step !== steps[index]);
    props.setStepsArray(stepsArray);
    setSteps(stepsArray);
    removeStepState(index);
  };
  const removeStepState = (index) => {
    let stateArray = [...props.state["htStep"]];

    stateArray.splice(index, 1);
    props.setState(stateArray);
  };

  return (
    <div className="mb-3 steps_section">
      <img
        id={"ht-form-tooltip-icon-" + props.tooltipFor}
        role="tooltip"
        src={info}
        alt="tooltip"
        className="ht_tooltip_steps"
        data-bs-placement="left"
        data-bs-toggle="tooltip"
        tabIndex={0}
        title="Add comprehensive steps to your How-To. A minimum of 3 steps and a maximum of 5 should be added."
      />
      <label className="ht_label ht_label_steps required">How-To Steps</label>
      <p className="steps_title_msg">You can only add between 3 to 5 steps</p>
      {steps.map((input, index) => (
        <div key={index}>
          <HowToStep
            item={input}
            index={index}
            steps={steps}
            state={props.state}
            field={props.field}
            setState={props.setState}
            setHowToStep={handleStepsChange}
            handleAddStep={handleAddStep}
            moveUp={moveLineItem}
            moveDown={moveLineItem}
            removeStep={removeStep}
            setStep={setSteps}
            setStepsArray={props.setStepsArray}
            disableField={props.disableField}
          />
        </div>
      ))}
    </div>
  );
};
export default HowToSteps;
