import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StoreContext from "../../store/storecontext";
import SearcherCard from "./SearcherCard";
import ManageRoleCss from "../ManageRoles/ManageRoles.module.css";
import { filterSearcherRequests, getFilterOptions } from "./filterMethods";
import ExcelFileDownloader from "../../helpers/FileDownloader/excelDownloader";
import Pagination from "../Pagination/Pagination";

const Searcher = () => {
  const queryString = useLocation().search;
  const queryParams = new URLSearchParams(queryString);
  const value = queryParams.get("key");
  const filter = queryParams.get("filter");
  const [apiFailed, setApiFailed] = useState(false);
  const [allRequests, setAllRequests] = useState([]);
  const [requestsDisplayed, setRequestsDisplayed] = useState([]);
  const store = useContext(StoreContext);
  const [totalRows, setTotalRows] = useState(0);
  const [typeSelected, setTypeSelected] = useState("");
  const [refinerSelected, setRefinerSelected] = useState("");
  const [locationSelected, setLocationSelected] = useState("");
  const [typeFilterOptions, setTypeFilterOptions] = useState([]);
  const [refinerFilterOptions, setRefinerFilterOptions] = useState([]);
  const [locationFilterOptions, setLocationFilterOptions] = useState([]);
  const [btnListForPages, setBtnListForPages] = useState([]);

  const theme = store.state.theme;
  const [activeFilters, setActiveFilters] = useState({
    type: { status: false, value: "" },
    page: { status: false, value: "" },
    location: { status: false, value: "" }
  });
  const [isDataLoading, setIsDataLoading] = useState(true);
  const navigate = useNavigate();
  const typeRequest = {
    BB: "Best Bet",
    TC: "Topic Card",
    HT: "How-To Card",
    SY: "Synonym",
    CTC: "Custom Topic Card"
  };
  const [keyParam, setKeyParam] = useState(value);
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 10;

  const lastCard = (currentPage + 1) * cardsPerPage;
  const firstCard = currentPage * cardsPerPage;
  const currentCards = requestsDisplayed.slice(firstCard, lastCard);

  const totalPages = Math.ceil(requestsDisplayed.length / cardsPerPage);

  const goToFirstPage = () => {
    setCurrentPage(0);
  };
  const goToPage = (pageNum) => {
    if (pageNum >= 0 && pageNum < totalPages) {
      setCurrentPage(pageNum);
    }
  };

  const loadingSpinner = (
    <div
      className={
        "spinner-border text-light-mode-purple " +
        ManageRoleCss.spinner_position
      }
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
  const exportCuratedDocuments = async () => {
    var contentSearcherService = async () =>
      await store.services.searchToolsService.ExportCuratedDocuments(
        value,
        filter,
        totalRows,
        activeFilters
      );

    const fileName = "Active Request";

    const exportCuratedDocuments = new ExcelFileDownloader(
      contentSearcherService,
      fileName
    );
    try {
      await exportCuratedDocuments.downloadFile();
    } catch (error) {
      console.log(error);
    }
  };

  const checkIfNewSearch = () => {
    // reset all selected filters
    // if the user performs a new search
    const currKey = queryParams.get("key");

    if (currKey !== keyParam) {
      setKeyParam(currKey);

      // reset state
      setActiveFilters({
        page: { status: false, value: "" },
        type: { status: false, value: "" },
        location: { status: false, value: "" }
      });

      // reset dropdown css (currently selected options)
      setLocationSelected("");
      setRefinerSelected("");
      setTypeSelected("");
    }
  };

  useEffect(() => {
    checkIfNewSearch();
  }, []);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      checkIfNewSearch();
      try {
        const res =
          await store.services.searchToolsService.SearchCuratedDocuments(
            0,
            10000,
            value,
            filter,
            activeFilters
          );
        if (res.data.hits.hits.length > 0) {
          setTotalRows(res.data.hits.total.value);
          var ret = [];
          res.data.hits.hits.forEach((req) => {
            var mapped = {};
            let mappedReqType = "";
            if (req._index.includes("bb")) {
              mappedReqType = "BB";
            } else if (
              req._index.includes("tc") &&
              req._source.template === "standard"
            ) {
              mappedReqType = "TC";
            } else if (
              req._index.includes("tc") &&
              req._source.template === "custom"
            ) {
              mappedReqType = "CTC";
            } else if (req._index.includes("howto")) {
              mappedReqType = "HT";
            } else if (req._index.includes("synonym")) {
              mappedReqType = "SY";
            }
            mapped.reqType = mappedReqType;
            mapped.tooltip = store.state.formType[mapped.reqType];

            switch (mapped.reqType) {
              case "BB":
                mapped.id = req._source.bbid;
                mapped.requestLink = "/promote/bestbet/request";
                mapped.title = req._source.cleantitle;
                mapped.refPages = req._source.aid;
                mapped.keywordsforreview = req._source.cleankeywordsforreviewraw
                  ? req._source.cleankeywordsforreviewraw
                  : req._source.cleankeywordsforreview;
                mapped.keywords = req._source.cleankeywordsraw
                  ? req._source.cleankeywordsraw
                  : req._source.cleankeywords;
                mapped.titleTooltip = "Request for Best Bet updates or removal";
                break;
              case "TC":
                let tcCleanKeywords =
                  req._source.cleankeywordsraw.length > 0
                    ? req._source.cleankeywordsraw
                    : req._source.cleankeywords;

                let tcInvalidKeywords =
                  req._source.cleaninvalidkeywordsraw.length > 0
                    ? req._source.cleaninvalidkeywordsraw
                    : req._source.cleaninvalidkeywords;

                let tcrequestLink = "/promote/topiccard/request/";

                mapped.description = req._source.cleandescriptionmmr;

                mapped.requestLink = tcrequestLink + req._source.tcrequestid;
                mapped.title = req._source.title;
                mapped.refPages = req._source.sthcAID;
                mapped.keywordsforreview = req._source.cleaninvalidkeywordsraw
                  ? tcInvalidKeywords
                  : req._source.cleaninvalidkeywords;
                mapped.keywords = req._source.cleankeywordsraw
                  ? tcCleanKeywords
                  : req._source.cleankeywords;
                mapped.titleTooltip =
                  "Request for Topic Card updates or removal";
                break;

              case "CTC":
                let ctcCleanKeywords =
                  req._source.cleankeywordsraw.length > 0
                    ? req._source.cleankeywordsraw
                    : req._source.cleankeywords;

                let ctcInvalidKeywords =
                  req._source.cleaninvalidkeywordsraw.length > 0
                    ? req._source.cleaninvalidkeywordsraw
                    : req._source.cleaninvalidkeywords;

                let ctcrequestLink = "/promote/topiccard/custom/request/";
                let descText = req._source.cleandescription.replace(/\W/g, " ");
                mapped.description = descText;

                mapped.requestLink = ctcrequestLink + req._source.tcrequestid;
                mapped.title = req._source.title;
                mapped.refPages = req._source.sthcAID;
                mapped.keywordsforreview = req._source.cleaninvalidkeywordsraw
                  ? ctcInvalidKeywords
                  : req._source.cleaninvalidkeywords;
                mapped.keywords = req._source.cleankeywordsraw
                  ? ctcCleanKeywords
                  : req._source.cleankeywords;
                mapped.titleTooltip =
                  "Request for Custom Topic Card updates or removal";
                break;
              case "HT":
                const htrequestLink = "/promote/how-to/request/";
                mapped.requestLink = htrequestLink + req._source.howto_req_id;
                mapped.refPages = req._source.aid;
                mapped.steps = req._source.cleansteps;
                mapped.keywords = req._source.cleankeywordsraw
                  ? req._source.cleankeywordsraw
                  : req._source.cleankeywords;
                mapped.titleTooltip = "Request for How-to updates or removal";
                break;

              case "SY":
                mapped.id = req._id;
                mapped.title = req._source.keyword;
                mapped.requestLink = "/promote/synonyms/request";
                mapped.keywords = req._source.synonyms;
                mapped.tooltip = "Synonym";
                mapped.titleTooltip = "Request for Synonyms updates or removal";
                break;
            }

            if (mapped.reqType !== "SY") {
              mapped.title = req._source.title
                ? req._source.title
                : req._source.cleantitle;
              mapped.description = req._source.cleandescription;
              mapped.locations = req._source.cleancountry;
              mapped.url = req._source.cleanurl;
            }

            if (mapped.keywords !== undefined) {
              ret.push(mapped);
            }
          });
          setApiFailed(false);
          setAllRequests(ret);
        }
      } catch {
        console.log("No documents found or an error was encounter");
        setApiFailed(true);
        setTotalRows(0);
      } finally {
        setIsDataLoading(false);
      }
    })();
  }, [value, filter]);

  useEffect(() => {
    (async () => await setRequestsDisplayed(allRequests))();
  }, [allRequests]);

  useEffect(() => {
    (async () => {
      var options = getFilterOptions(allRequests, typeRequest);

      setLocationFilterOptions(options.locationOptions);
      setRefinerFilterOptions(options.refinerPageOptions);
      setTypeFilterOptions(options.typeOptions);
    })();
  }, [allRequests]);

  useEffect(() => {
    setTotalRows(requestsDisplayed.length);
  }, [requestsDisplayed]);

  const filterRequests = (filter, value) => {
    let filteredRequests;
    const dataToFilter = {
      filter: filter,
      value: value,
      requests: allRequests,
      activeFilters: activeFilters
    };
    switch (filter) {
      case "type":
        setActiveFilters({
          ...activeFilters,
          type: { status: true, value: value }
        });

        break;
      case "page":
        setActiveFilters({
          ...activeFilters,
          page: { status: true, value: value }
        });
        break;
      case "location":
        setActiveFilters({
          ...activeFilters,
          location: { status: true, value: value }
        });
    }

    filteredRequests = filterSearcherRequests(dataToFilter);
    setRequestsDisplayed(filteredRequests);
  };

  const handleLocationSelect = (value) => {
    setLocationSelected(value);
    filterRequests("location", value);
  };
  const handlePageSelect = (value) => {
    setRefinerSelected(value);
    filterRequests("page", value);
  };
  const handleTypeSelect = (value) => {
    setTypeSelected(value);
    filterRequests("type", value);
  };
  return (
    <div id="searcher-main">
      <div id="buttons-row">
        <p id="rows-displayed" className="text-mute">
          {totalRows} displayed
        </p>
        <button
          id="export-button"
          className="btn btn-primary"
          data-testid="export-button"
          onClick={exportCuratedDocuments}
        >
          Export
        </button>
        <button
          id="promote-button"
          className="btn btn-primary"
          data-testid="promote-my-content-button"
          onClick={() => navigate("/promote/bestbet/request")}
        >
          Promote my content
        </button>
      </div>
      <div id="filters">
        <select
          name=""
          id="filter-type"
          className="custom-select form-control"
          value={typeSelected}
          data-testid="type-filter"
          onChange={(event) => handleTypeSelect(event.target.value)}
        >
          <option value="" disabled>
            Select type...
          </option>
          {typeFilterOptions.map((option) => {
            return (
              <option value={option} id={option} key={option}>
                {option}
              </option>
            );
          })}
        </select>

        <select
          name=""
          className="custom-select form-control"
          id="filter-refPage"
          value={refinerSelected}
          data-testid="refiner-page-filter"
          onChange={(event) => handlePageSelect(event.target.value)}
        >
          <option value="" disabled>
            Select a refiner page...
          </option>
          {refinerFilterOptions.map((option) => {
            return (
              <option value={option} id={option} key={option}>
                {option}
              </option>
            );
          })}
        </select>

        <select
          name=""
          id="filter-location"
          className="custom-select form-control"
          value={locationSelected}
          data-testid="location-filter"
          onChange={(event) => handleLocationSelect(event.target.value)}
        >
          <option value="" disabled>
            Select a location...
          </option>
          {locationFilterOptions.map((option) => {
            return (
              <option value={option} id={option} key={option}>
                {option}
              </option>
            );
          })}
        </select>
      </div>
      {isDataLoading ? (
        loadingSpinner
      ) : requestsDisplayed.length > 0 && !apiFailed ? (
        <>
          <ul className="results-displayed">
            {currentCards.map((req, index) => (
              <li key={index}>
                <SearcherCard data={req} />
              </li>
            ))}
          </ul>
          <Pagination
            requestsDisplayed={requestsDisplayed.length}
            currentPageNum={currentPage}
            totalPages={totalPages}
            goTofirstPage={goToFirstPage}
            goToPage={goToPage}
            goToLastPage={() => goToPage(totalPages - 1)}
            btnListForPages={btnListForPages}
          />
        </>
      ) : (
        <div id="no-results">No documents found</div>
      )}
    </div>
  );
};

export default Searcher;
