import { useState } from "react";
import tooltipIcon from "../../../../assets/images/info.svg";

const BBDropdown = ({
  label,
  placeholder,
  aria,
  elementId,
  tooltip,
  required,
  options,
  addSelection,
  globalIsPresent,
  validClass,
  disableFieldProp,
  field,
  cssGapClass,
  createTooltip
}) => {
  const [selected, setSelected] = useState("");
  const setSelection = {
    location: (inputValue) => addLocation(inputValue),
    "refiner-page": (inputValue) => addRefPage(inputValue)
  };
  const addLocation = (newLocation) => {
    if (!globalIsPresent) {
      addSelection(newLocation);
      setSelected("");
    }
  };
  const addRefPage = (newPage) => {
    addSelection(newPage);
    setSelected("");
  };
  const handleSelection = (event) => {
    const selection = event.target.value;
    setSelection[field](selection);
  };

  return (
    <div className={"form-group " + cssGapClass}>
      <img
        id={"bb-form-tooltip-icon-" + label}
        src={tooltipIcon}
        alt={tooltip}
        role="tooltip"
        data-bs-toggle="bb-tooltip"
        data-bs-placement="left"
        data-bs-title={tooltip}
        tabIndex="0"
        onMouseEnter={(e) => {
          createTooltip(e);
        }}
      />
      <label
        className={"label-text " + (required ? "required" : "")}
        htmlFor={label}
        data-testid="bb-field-label"
      >
        {label}
      </label>
      <select
        type="text"
        id={elementId}
        key={`${field}-selector`}
        className={`custom-select form-control ${validClass}`}
        data-testid="bb-custom-selector"
        placeholder={placeholder}
        aria-label={aria}
        onChange={handleSelection}
        defaultValue={placeholder}
        disabled={disableFieldProp}
      >
        <option disabled={true} value={null}>
          {placeholder}
        </option>
        {options.map((option) => {
          return (
            <option
              data-testid={`${field}-option`}
              value={option.value}
              id={"bb-form-dropdown-" + field + "-option-" + option.value}
              key={option.text}
            >
              {option.text}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default BBDropdown;
