import { useEffect, useState } from "react";
import TopicCardTooltips from "./TopicCardTooltips";

const TopicDescription = ({
  setValue,
  inputState,
  value,
  descriptionErrorMessage,
  disableField,
  validInputClass,
  tooltipFor
}) => {
  let description = {
    tooltip:
      "Add a comprehensive description about your topic, up to 1000 characters.",
    alt: "Please enter a description.",
    placeholder:
      "Enter a brief description of site content that will display below the Topic Card title; the description is intended to attract users to view/click this Topic Card."
  };
  const [errorMessage, setErrorMessage] = useState(descriptionErrorMessage);
  const field = "description";
  const labelText = "Description";

  const setField = (value) => {
    setValue("description", value);
  };

  const onChangeTextArea = (event) => {
    let value = event.target.value;
    setField(value);
  };

  const errorMsg = () => {
    const hideErrorMsg =
      (inputState[field] || inputState[field] === null) &&
      validInputClass === "is-valid";
    if (hideErrorMsg) {
      return <></>;
    } else {
      return (
        <div id="validate-input" className="invalid">
          {errorMessage}
        </div>
      );
    }
  };

  const onBlurHandler = (event) => {
    let value = event.target.value;
    setField(value);
  };
  useEffect(() => {
    setErrorMessage(descriptionErrorMessage);
  }, [descriptionErrorMessage]);
  return (
    <>
      <TopicCardTooltips
        tooltipFor={tooltipFor}
        tooltipAlt={description.alt}
        tooltipText={description.tooltip}
      />
      <label
        data-testid="topic-card-label"
        className={`label-text ${true ? "required" : ""}`}
      >
        {labelText}
      </label>
      <textarea
        className={`form-control ${validInputClass}`}
        data-testid="textarea-input"
        id="description"
        rows="4"
        max-rows="8"
        value={value}
        placeholder={description.placeholder}
        onChange={onChangeTextArea}
        onBlur={onBlurHandler}
        disabled={disableField}
      ></textarea>
      {errorMsg()}
    </>
  );
};

export default TopicDescription;
