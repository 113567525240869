import ReviewContent from "./ReviewContent.module.css";

const PaginationButtons = (props) => {
  const pageBtnClass = props.isDarkMode
    ? props.page_btn_dark
    : props.page_btn_light;

  const pageBtnDisabledClass = props.isDarkMode
    ? props.arrow_btn_dark + " disabled"
    : props.arrow_btn_light + " disabled";

  const currPage = parseInt(props.totalRequests / 10);
  const isFirstPage = props.currentPageNum === 0;
  const isLastPage = parseInt(props.currentPageNum / 10) === currPage;

  const backArrowsCss = isFirstPage ? pageBtnDisabledClass : pageBtnClass;

  const frontArrowsCss = isLastPage ? pageBtnDisabledClass : pageBtnClass;

  const goToLastPage = () => {
    props.goToLastPage();
  };
  return (
    <nav aria-label="Page navigation" data-testid="pagination" id="pagination" className={ReviewContent.paginationContainer} >
      <ul className="pagination justify-content-center">
        <li className="page-item ">
          <button
            id="go-to-first-page"
            aria-label="go-to-first-page"
            className={backArrowsCss}
            href="#"
            tabIndex="0"
            onClick={props.goToFirstPage}
          >
            <span>‹‹</span>
          </button>
        </li>
        <li className="page-item ">
          <button
            id="go-back-one-page"
            aria-label="go-back-one-page"
            className={backArrowsCss}
            href="#"
            tabIndex="0"
            onClick={props.goToPage}
          >
            <span>‹</span>
          </button>
        </li>
        {props.btnListForPages}
        {!isLastPage ? (
          <li className="page-item">
            <button
              id="more-results-avaliable"
              aria-label="there are results avaliable"
              className={pageBtnDisabledClass}
              href="#"
              tabIndex="0"
            >
              <span>...</span>
            </button>
          </li>
        ) : (
          <></>
        )}
        <li className="page-item">
          <button
            id="go-forth-one-page"
            aria-label="go-forth-one-page"
            className={frontArrowsCss}
            href="#"
            tabIndex="0"
            onClick={props.goToPage}
          >
            <span>›</span>
          </button>
        </li>
        <li className="page-item">
          <button
            id="go-to-last-page"
            aria-label="go-to-last-page"
            className={frontArrowsCss}
            href="#"
            tabIndex="0"
            onClick={goToLastPage}
          >
            <span>››</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default PaginationButtons;