import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import FilterOption from "./FilterOption";
import searchicon from "../../assets/images/search_icon.svg";
import { useContext } from "react";
import { ThemeContext } from "../../ThemeContext";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { CgReadme } from "react-icons/cg";
import { Tooltip } from "bootstrap";
import ValidateInputModal from "../Modal/ValidateInputModal";

const Header = (props) => {
  const [showFilters, setShowFilters] = useState(false);
  const filterListRef = useRef(null);
  const openMenuRef = useRef(null);
  const [theme] = useContext(ThemeContext);
  const [searchInput, setSearchInput] = useState("");
  const [filterBy, setFilterBy] = useState("Filter by");

  const home_hidden_id = "hidden-role-icon";

  const tooltipClass = theme === "dark" ? "bb-tooltip-dark" : "";

  const deleteOldTooltips = () => {
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const initializeTitleTooltip = () => {
    deleteOldTooltips();
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="header-icon-tooltip"]'
    );
    let tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        trigger: "hover",
        customClass: tooltipClass
      });
    });
  };

  useEffect(() => {
    if (showFilters) {
      filterListRef.current.querySelector("a").focus();
      document.addEventListener("mousedown", clickOutsideHandler);
    } else {
      document.addEventListener("mousedown", clickOutsideHandler);
    }
  }, [showFilters]);
  useEffect(() => {
    if (searchInput !== "") {
      search();
    }
  }, [filterBy]);
  const filterBtnClass =
    theme === "dark" ? "dark-mode-purple" : "light-mode-purple";

  const toggleFilterMenu = () => {
    setShowFilters(!showFilters);
  };
  const keyHandler = (event) => {
    if (event.key === "Escape" && showFilters) {
      setShowFilters(false);
    }
  };
  const clickOutsideHandler = (event) => {
    if (filterListRef.current) {
      if (
        filterListRef.current.contains(event.target) ||
        openMenuRef.current.contains(event.target)
      ) {
        return;
      }
      setShowFilters(false);
    }
  };
  const navigate = useNavigate();

  const saveInput = (event) => {
    setSearchInput(event.target.value);
  };

  const selectFilter = async (filter) => {
    await setFilterBy(filter);
    setShowFilters(false);
  };

  const search = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    if (searchInput.trim().length > 0) {
      const params = {
        key: searchInput,
        filter: filterBy === "Filter by" ? "Everything" : filterBy
      };

      try {
        let homeMenuElement = document.querySelector(".home-menu-div");
        homeMenuElement.classList.remove("extra-height");
      } catch (error) {}
      navigate({
        pathname: "/search",
        search: `?${createSearchParams(params)}`
      });
    }
  };

  const checkUserMenu = () => {
    let elem = document.getElementById(home_hidden_id);

    if (props.isSuperUser) {
      elem.classList.remove("hide-role-div");
    } else {
      elem.classList.add("hide-role-div");
    }
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      search();
    }
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      if (e.target && e.target.nodeName === "A") {
        let currentListItem = e.target.parentNode;
        let nextListItem =
          e.key === "ArrowDown"
            ? currentListItem.nextElementSibling
            : currentListItem.previousElementSibling;
        if (nextListItem) {
          nextListItem.firstChild.focus();
        }
      }
    }
  };

  useEffect(() => {
    checkUserMenu();
  }, [props.isSuperUser]);

  return (
    <div className="header-container">
      <header data-testid="header" className="search-header">
        <div role="banner" aria-label="SearchToolsHeader">
          <nav
            aria-labelledby="SearchTools Navbar"
            toggleable="lg"
            className="navbar searchtools-navbar"
          >
            <div
              className="navbar-brand acc-bdr"
              id="MainLogos"
              aria-label="Accenture and Search Tools Logos. Press Enter to go to the Home Page"
              role="banner"
              style={{visibility: window.location.pathname === "/" ? "hidden" : "visible"}}
            >
              <a
                id="header-acn-logo"
                className="acn-logo"
                data-testid="acn-logo"
                href="/"
                aria-label="home page"
              />
              <a
                id="header-searchtools-logo"
                className="searchtools-logo"
                data-testid="searchtools-logo"
                href="/"
                aria-label="home page"
              />
            </div>
            <ul className="navbar-nav ml-auto searchtools-nav">
              <li className="nav-item">
                <div role="group" className="input-group change-input-size">
                  <img
                    id="header-magnifiying-glass"
                    data-testid="search-icon"
                    role="button"
                    src={searchicon}
                    className="searchicon"
                    onClick={search}
                    alt="Search Icon"
                  />
                  <input
                    id="header-content-searcher-input"
                    type="text"
                    className="form-control searchinput"
                    aria-label="Search for Best Bet, Topic Card or HowTo Card. Enter your search term and press enter to search or press tab to select filters"
                    data-testid="search-bar"
                    value={searchInput}
                    onChange={(event) => saveInput(event)}
                    onKeyDown={(e) => e.key === "Enter" && search()}
                  />
                  <div className="input-group-append">
                    <div
                      className="dropdown b-dropdown btn-group div-filter-by"
                      onKeyUp={keyHandler}
                    >
                      <button
                        id="header-filter-by-btn"
                        aria-haspopup="true"
                        aria-label="Filter-by Button. click to open the list of filters"
                        aria-expanded="false"
                        type="button"
                        className={`btn-${filterBtnClass} btn dropdown-toggle btn-filter-by`}
                        onClick={toggleFilterMenu}
                        data-testid="filter-by-btn"
                        ref={openMenuRef}
                      >
                        <span>{filterBy}</span>
                      </button>
                      {showFilters && (
                        <ul
                          role="menu"
                          tabIndex="0"
                          className="filter-dropdown filter-options"
                          data-testid="dropdown-content"
                          ref={filterListRef}
                          onKeyDown={handleKeyDown}
                        >
                          <FilterOption
                            value="Everything"
                            selectOption={selectFilter}
                          />
                          <FilterOption
                            value="Title"
                            selectOption={selectFilter}
                          />
                          <FilterOption
                            value="Keyword"
                            selectOption={selectFilter}
                          />
                          <FilterOption
                            value="URL"
                            selectOption={selectFilter}
                          />
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              <ValidateInputModal
                onClose={props.onClickCharsErrorModal}
                searchTerm={searchInput}
                setSearchTerm={setSearchInput}
              />
            </ul>
            <div className="header-icons-with-redirect">
              <div
                id={home_hidden_id}
                aria-label="Access Manage Roles"
                className={"manage-role-btn-div"}
                data-bs-toggle={"header-icon-tooltip"}
                data-bs-placement="bottom"
                data-bs-title={"Manage Roles"}
                onMouseEnter={initializeTitleTooltip}
              >
                <a href="/managerole" data-testid="manage-roles-url">
                  <FaUsers
                    data-testid="manage-icon"
                    id="manage-icon"
                    className="manage-role-btn"
                    aria-label="Access Manage roles"
                  />
                </a>
              </div>
              <div
                id="header-help-page-div"
                aria-label="Help Page Icon. Press Enter to open Search Tools User Guide"
                className={"stguide-btn-div"}
                data-bs-toggle={"header-icon-tooltip"}
                data-bs-placement="bottom"
                data-bs-title={"Search Tools Guide"}
                onMouseEnter={initializeTitleTooltip}
              >
                <a
                  data-testid="st-guide-url"
                  href="https://go.accenture.com/searchtoolsguide"
                  target="_blank"
                  className="stguide-btn-link"
                >
                  <CgReadme
                    data-testid="st-guide-icon"
                    id="st-guide-icon"
                    className="stguide-btn"
                    aria-label="Access Searchtools guide"
                  />
                </a>
              </div>
            </div>
          </nav>
          <hr
            id="ContentSeparator"
            className="content-separator"
            data-testid="content-separator"
          />
        </div>
      </header>
    </div>
  );
};

export default Header;
