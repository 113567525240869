import { Toast } from "bootstrap";
export const triggerToast = (delayTime, index, key = null) => {
  let toastElList = [].slice.call(
    document.querySelectorAll(`.toast${key ? "." + key : ""}`)
  );
  let toastList = toastElList.map(function (toastEl) {
    return new Toast(toastEl, { delay: delayTime });
  });

  //show toast msg here!
  try {
    toastList[index]?.show();
  } catch (error) {
    let idx = index + 1;
    toastList[idx]?.show();
  }
};
