import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { useContext } from "react";
import { ThemeContext } from "../../../ThemeContext";

function BarChart({
  chartData
}) {
  const theme = useContext(ThemeContext);
  const labelColor = theme[0] === "dark" ? "#e6e6dc" : "#000";

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: labelColor
        }
      },
      x: {
        ticks: {
          color: labelColor
        }
      }
    },
    backgroundColor: [
      "rgba(161, 0, 255, 1)"
    ],
    borderColor: [
      "rgba(161, 0, 255, 1)"
    ],
    borderWidth: 1,
    plugins: {
      legend: {
        labels: {
          color: labelColor
        }
      }
    }

  };

  return <Bar data={chartData} options={chartOptions} />;
}

export default BarChart;
