import "./ToastWithClose.css";
const ToastWithClose = ({ text, toastKey="" }) => {
  const closeToast = () => {
    let toast = document.querySelectorAll(`.toast${toastKey ? "." + toastKey : ""}`);
    toast[0].remove();
  };

  return (
    <div aria-live="polite" aria-atomic="true" className="toast-with-close">
      <div data-bs-autohide="false" className={`toast toast-position ${toastKey}`}>
        <button
          type="button"
          className="ml-2 mb-1 close close-toast-button"
          data-dismiss="toast"
          aria-label="Close"
          onClick={() => closeToast()}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="toast-body">{text}</div>
      </div>
    </div>
  );
};

export default ToastWithClose;
