import "./ManageMyRequests.css";
import { useContext, useState, useEffect } from "react";
import StoreContext from "../../store/storecontext";
import MMRFilters from "./MMRFilters";
import Card from "./Card.jsx";
import { Link } from "react-router-dom";
import FeedbackModal from "../Feedback/Feedback.jsx";

const ManageMyRequest = () => {
  const [selectedType, setSelectedType] = useState([
    "All",
    "Best Bet",
    "How-To",
    "Topic Card",
    "Synonym"
  ]);
  const typeReq = {
    All: "All",
    "Best Bet": "BB",
    "How-To": "HT",
    "Topic Card": "TC",
    Synonym: "SY"
  };

  const [selectedStatus, setSelectedStatus] = useState([
    "All",
    "Cancelled",
    "Draft",
    "Implemented",
    "Inactive",
    "New",
    "Not Approved"
  ]);

  const selectedDate = [
    "All",
    "A week ago",
    "A month ago",
    "A year ago",
    "More than a year"
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [allRequests, setAllRequests] = useState("");
  const [tcRequests, setTcRequests] = useState([]);
  const [htRequests, setHtRequests] = useState([]);
  const [bbRequests, setBbRequests] = useState([]);
  const [synRequests, setSynRequests] = useState([]);
  const [typeOfReq, setTypeOfReq] = useState([]);
  const [apiFailMsg, setApiFailMsg] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    type: "",
    status: "",
    date: ""
  });
  const [displayFeedback, setDisplayFeedback] = useState(false);

  const store = useContext(StoreContext);

  const feedbackModalAfterSubmit = (
    <div
      className="overlay-spinner modal-fade more-opaque-overlay"
      id="loading"
    >
      <div className="d-flex justify-content-center mmr-feedback">
        <FeedbackModal />
      </div>
    </div>
  );

  const GetTCReq = async () => {
    setIsLoading(true);
    let eid = localStorage.getItem("searchtools.eid");

    store.services.topicCardService
      .GetMyTopicCardRequests(eid)
      .then((r) => {
        let topiccards = r.data.hits.hits;
        let allRequestsArray = [];

        for (let i = 0; i < topiccards.length; i++) {
          const template =
            r.data.hits.hits[i]._source.template === "standard"
              ? ""
              : "custom/";

          let descriptionToShow = "";
          if (template === "custom/") {
            if (topiccards[i]._source.hasOwnProperty("descriptionmmr")) {
              descriptionToShow = topiccards[i]._source.descriptionmmr;
            } else {
              descriptionToShow = topiccards[i]._source.description;
            }
          } else {
            descriptionToShow = topiccards[i]._source.description;
          }

          let req = {
            type: "TC",
            reqLink: `../promote/topiccard/${template}request/`,
            id: r.data.hits.hits[i]._id,
            title: topiccards[i]._source.title,
            url: topiccards[i]._source.url,
            description: descriptionToShow,
            status: topiccards[i]._source.status,
            date: topiccards[i]._source.lastmodificationdate
          };

          allRequestsArray = [...allRequestsArray, req];
          setTcRequests(allRequestsArray);
          setActiveFilters(activeFilters);
          setApiFailMsg(null);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setApiFailMsg("Error has been encountered. Try again later");
        console.log("error on getting tc request data");
      });
  };

  const GetHtReq = async () => {
    setIsLoading(true);
    let eid = localStorage.getItem("searchtools.eid");

    store.services.howToService
      .GetHowToRequests(eid)
      .then((r) => {
        let howto = r.data.hits.hits;
        let allRequestsArray = [];

        for (let i = 0; i < howto.length; i++) {
          let req = {
            type: "HT",
            reqLink: "../promote/how-to/request/",
            id: r.data.hits.hits[i]._id,
            title: howto[i]._source.cleantitle,
            url: howto[i]._source.cleanurl,
            description: "1-" + howto[i]._source.cleansteps[0],
            status: howto[i]._source.cleanstatus,
            date: howto[i]._source.lastmodifieddateUTC
          };
          allRequestsArray = [...allRequestsArray, req];
          setHtRequests(allRequestsArray);
          setActiveFilters(activeFilters);
          setApiFailMsg(null);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setApiFailMsg("Error has been encountered. Try again later");
        console.log("error on getting tc request data");
      });
  };

  const GetSynReq = async () => {
    setIsLoading(true);
      let eid = localStorage.getItem("searchtools.eid");

      store.services.synonymsService
        .GetSynonymsByEID(eid)
        .then((r) => {
          let syn = r.data.hits.hits;
          let allRequestsArray = [];

          for (let i = 0; i < syn.length; i++) {
            const req = {
              type: "SY",
              reqLink: "../promote/synonyms/request/",
              id: r.data.hits.hits[i]._id,
              title: syn[i]._source.term,
              description: syn[i]._source.synonyms.join(", "),
              status: syn[i]._source.status,
              date: syn[i]._source.lastmodificationdate,
              reqType: syn[i]._source.request_type
            };
            allRequestsArray = [...allRequestsArray, req];
            setSynRequests(allRequestsArray);
            setActiveFilters(activeFilters);
            setApiFailMsg(null);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setApiFailMsg("Error has been encountered. Try again later");
          console.log("error on getting syn request data");
          setIsLoading(false);
        });
  };

  const GetBbReq = async () => {
    setIsLoading(true);
    let pk = localStorage.getItem("searchtools.pk");

    store.services.bestBetService
      .getBestBetRequests(pk)
      .then((r) => {
        let bestbets = r.data.hits.hits;
        let allRequestsArray = [];

        for (let i = 0; i < bestbets.length; i++) {
          const req = {
            type: "BB",
            reqLink: "../promote/bestbet/request/",
            id: r.data.hits.hits[i]._id,
            title: bestbets[i]._source.cleantitle,
            url: bestbets[i]._source.cleanurl,
            description: bestbets[i]._source.cleandescription,
            status: bestbets[i]._source.cleanstatus,
            date: bestbets[i]._source.lastmodifieddateUTC,
            reqType: bestbets[i]._source.cleanrequesttype
          };
          allRequestsArray = [...allRequestsArray, req];
          setBbRequests(allRequestsArray);
          setActiveFilters(activeFilters);
          setApiFailMsg(null);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setApiFailMsg("Error has been encountered. Try again later");
        console.log("error on getting bb request data");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([GetBbReq(), GetSynReq(), GetHtReq(), GetTCReq()]);
      } catch (error) {
        setApiFailMsg("Error has been encountered. Try again later");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (bbRequests.length > 0 && typeOfReq.indexOf("Best Bet") === -1) {
      setTypeOfReq([...typeOfReq, "Best Bet"].sort());
    }
    if (tcRequests.length > 0 && typeOfReq.indexOf("Topic Card") === -1) {
      setTypeOfReq([...typeOfReq, "Topic Card"].sort());
    }
    if (htRequests.length > 0 && typeOfReq.indexOf("How-To") === -1) {
      setTypeOfReq([...typeOfReq, "How-To"].sort());
    }

    if (synRequests.length > 0 && typeOfReq.indexOf("Synonym") === -1) {
      setTypeOfReq([...typeOfReq, "Synonym"].sort());
    }

    let allRequestArray = bbRequests.concat(
      tcRequests,
      htRequests,
      synRequests
    );
    allRequestArray = allRequestArray.sort(
      (req1, req2) => new Date(req2.date) - new Date(req1.date)
    );

    setFilteredRequests(allRequestArray);
    setAllRequests(allRequestArray);
  }, [bbRequests, tcRequests, htRequests, synRequests]);

  const filterByDate = (reqList, selectedDate) => {
    let startDate = new Date();
    let endDate = new Date();

    switch (selectedDate) {
      case "A week ago":
        startDate.setDate(startDate.getDate() - 7);

        break;
      case "A month ago":
        startDate.setDate(startDate.getDate() - 30);

        break;
      case "A year ago":
        startDate.setDate(startDate.getDate() - 365);

        break;
      case "More than a year":
        startDate.setFullYear(1);
        endDate.setDate(endDate.getDate() - 365);

        break;
      default:
        break;
    }

    reqList = reqList.filter((req) => {
      req = new Date(req.date);
      return req >= startDate && req <= endDate;
    });
    return reqList;
  };

  const filterReqsts = async (filter, value) => {
    //update the filter state
    let newFilters = { ...activeFilters };

    if (filter === "type") {
      newFilters[filter] = typeReq[value];
    } else {
      newFilters[filter] = value;
    }

    //do filter
    let filterReq = allRequests;

    if (newFilters.type !== "" && newFilters.type !== "All") {
      filterReq = filterReq.filter((item) => item.type === newFilters.type);
    }

    if (newFilters.status !== "" && newFilters.status !== "All") {
      filterReq = filterReq.filter((item) => item.status === newFilters.status);
    }

    if (newFilters.date !== "" && newFilters.date !== "All") {
      filterReq = filterByDate(filterReq, newFilters.date);
    }
    await setFilteredRequests(filterReq);
    await setActiveFilters(newFilters);
  };

  useEffect(() => {
    try {
      let feedbackAfterSubmit = store.getShowFeedbackBox();

      if (feedbackAfterSubmit === 1) {
        let feedbackBubbleBtnEl = document.getElementById(
          "feedback-bubble-button"
        );
        feedbackBubbleBtnEl.click();
      }
    } catch (error) {
      console.log("component not enabled yet : ", error);
    }
  }, [displayFeedback]);

  useEffect(() => {
    let feedbackAfterSubmit = store.getShowFeedbackBox();
    let isRegularUser = !store.state.isSuperUser;
    if (feedbackAfterSubmit === 1 && isRegularUser) {
      let feedbackBubbleOnFooter = document.getElementById("feedback-bubble");
      feedbackBubbleOnFooter?.classList?.add("hide_feedback_bubble");
      setDisplayFeedback(true);
    } else if (feedbackAfterSubmit === 0 && !isRegularUser) {
      setDisplayFeedback(false);
    }
  }, []);

  return (
    <div className="overlay">
      <div className="manage_my_requests" id="mmr" data-testid="mmr_section">
        <div id="mmr-yellow-msg-outer-div" className="manage_banner">
          <div
            id="mmr-yellow-msg-inner-div"
            className="alert manage_banner_styles"
            role="alert"
            data-testid="banner_mmr"
          >
            <p className="manage_banner_title" data-testid="msg_mmr_banner">
              Submitted requests
            </p>
          </div>
          <>
            {isLoading && !apiFailMsg && (
              <div id="mmr-loading-spinner-outer-div" className={"mmr-loading"}>
                <div
                  id="mmr-loading-spiner-inner-div"
                  className="spinner-border text-light-mode-purple"
                  role="status"
                >
                  <span className="visually-hidden mmr-loading">
                    Loading...
                  </span>
                </div>
              </div>
            )}
          </>
          {displayFeedback && feedbackModalAfterSubmit}
          <>
            {allRequests.length === 0 && !isLoading && !apiFailMsg ? (
              <div id="mmr-no-req-info-msg-div" className="mng_no_rqsts_msg">
                <p id="mmr-no-req-info-msg-p" className="msg_no_rqsts">
                  You do not have any requests
                </p>
                <Link
                  id="mmr-promote-my-content-link"
                  to="/promote/bestbet/request"
                  className="btn_redirect_promote"
                >
                  Promote my content
                </Link>
              </div>
            ) : (
              <>
                {apiFailMsg && <p className="mng_no_rqsts_msg">{apiFailMsg}</p>}
              </>
            )}
          </>
        </div>
        <>
          {allRequests.length !== 0 && (
            <>
              <div>
                <MMRFilters
                  selectedType={selectedType}
                  selectedStatus={selectedStatus}
                  selectedDate={selectedDate}
                  filterReqsts={filterReqsts}
                />

                <div className="cards_box">
                  <>
                    {filteredRequests.length !== 0 ? (
                      filteredRequests.map((req, index) => {
                        return <Card data={req} key={index} />;
                      })
                    ) : (
                      <>
                        {activeFilters.type === "" &&
                        activeFilters.status === "" &&
                        activeFilters.date === "" ? (
                          <></>
                        ) : (
                          <p className="filter_no_req">
                            No requests were found
                          </p>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};
export default ManageMyRequest;
