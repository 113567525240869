const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January"
];

const getDateForCsvTitle = (_fromDate, _toDate) => {
  let fromDateTemp = new Date(_fromDate);
  const fromDateMonth = fromDateTemp.getMonth();
  const fromDateYear = fromDateTemp.getFullYear();

  let toDateTemp = new Date(_toDate);
  const toDateMonth = toDateTemp.getMonth();
  const toDateYear = toDateTemp.getFullYear();

  let fromDateFinal = `${monthNames[fromDateMonth]} ${fromDateYear}`;
  let toDateFinal = `${monthNames[toDateMonth]} ${toDateYear}`;

  return { from: fromDateFinal, to: toDateFinal };
};

// submitted requests
const parseDataForSubmittedRequests = (_reqArray) => {
  let parsedData = "";

  if (_reqArray.lastModDate.length <= 0) return "\n";

  _reqArray.lastModDate.map((elem) => {
    let dateObj = new Date(elem.key_as_string);
    let dateObjMonth = dateObj.getMonth();
    let monthName = monthNames[dateObjMonth + 1];
    let monthCount = elem.doc_count;
    parsedData += `${monthName},${monthCount}\n`;
  });

  return parsedData;
};

// target refiner page
const parseDataForRefinerPage = (_reqArray) => {
  let parsedData = "";
  let formatObj = {
    ALL: " ",
    STS: " ",
    IKN: " ",
    POL: " ",
    LRN: " ",
    FDSTS: " ",
    IXC: " ",
    ARC: " "
  };

  if (_reqArray.refinerPage.length <= 0) return "\n";

  _reqArray.refinerPage.map((elem) => {
    let elemIndex = elem.key;
    let docCount = elem.doc_count;
    formatObj[elemIndex] = docCount;
  });

  let obKeys = Object.keys(formatObj);

  obKeys.map((elem) => {
    parsedData += `${formatObj[elem]},`;
  });

  return parsedData;
};

// target country
const parseDataForCountry = (_reqArray) => {
  let parsedData = "";
  let formatObj = {};

  if (_reqArray.countries.length <= 0) return "\n";

  _reqArray.countries.map((elem) => {
    let elemIndex = elem.key;
    let docCount = elem.doc_count;
    formatObj[elemIndex] = docCount;
  });

  let obKeys = Object.keys(formatObj);

  obKeys.map((elem) => {
    parsedData += `${elem},`;
  });

  parsedData += `\n`;

  obKeys.map((elem) => {
    parsedData += `${formatObj[elem]},`;
  });

  return parsedData;
};

// request status
const parseDataForStatus = (_reqArray) => {
  let parsedData = "";

  if (_reqArray.length <= 0) return "\n";

  _reqArray.map((elem) => {
    parsedData += `${elem.key},${elem.doc_count}\n`;
  });

  return parsedData;
};

// unique users
const parseDataForUniqueUsers = (_reqArray) => {
  let parsedData = "";

  if (_reqArray.uniqueUser.length <= 0) return "\n";

  _reqArray.uniqueUser.map((elem) => {
    let dateObj = new Date(elem.key_as_string);
    let dateObjMonth = dateObj.getMonth();
    let monthName = monthNames[dateObjMonth + 1];
    let monthCount = elem.amount_users.value;
    parsedData += `${monthName},${monthCount}\n`;
  });

  return parsedData;
};

const generateCsvData = (
  _bbRequests,
  _tcRequests,
  _htRequests,
  _selectedOption,
  _bbOption,
  _tcOption,
  _htOption
) => {
  let reportTitle = "";
  let csvData = "";

  let bbTitleResults = "Best Bets results\n\n";
  let tcTitleResults = "Topic card results\n\n";
  let htTitleResults = "How to card results\n\n";
  let monthCountCol = "Month, Count\n";
  let endOfListSeparator = "\n\n\n";
  let endOfResultsSeparator =
    "*-----------------------------------------------------------*\n";

  switch (_selectedOption) {
    case "submitedRqsts":
      reportTitle = "Submitted Requests";

      if (_bbOption) {
        csvData += bbTitleResults;
        csvData += monthCountCol;
        let parsedBBData = parseDataForSubmittedRequests(_bbRequests);
        csvData += parsedBBData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_tcOption) {
        csvData += tcTitleResults;
        csvData += monthCountCol;
        let parsedTcData = parseDataForSubmittedRequests(_tcRequests);
        csvData += parsedTcData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_htOption) {
        csvData += htTitleResults;
        csvData += monthCountCol;
        let parsedHtData = parseDataForSubmittedRequests(_htRequests);
        csvData += parsedHtData;
        csvData += endOfListSeparator;
      }

      break;

    case "targetRefinerPage":
      reportTitle = "Target Refiner Page";

      let refinerCols = "ALL,STS,IKN,POL,LRN,FDSTS,IXC,ARC\n";

      if (_bbOption) {
        csvData += bbTitleResults;
        csvData += refinerCols;
        let parsedBBData = parseDataForRefinerPage(_bbRequests);
        csvData += parsedBBData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_tcOption) {
        csvData += tcTitleResults;
        csvData += refinerCols;
        let parsedTcData = parseDataForRefinerPage(_tcRequests);
        csvData += parsedTcData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_htOption) {
        csvData += htTitleResults;
        csvData += refinerCols;
        let parsedHtData = parseDataForRefinerPage(_htRequests);
        csvData += parsedHtData;
        csvData += endOfListSeparator;
      }

      break;

    case "targetCountry":
      reportTitle = "Target Country";

      if (_bbOption) {
        csvData += bbTitleResults;
        let parsedBBData = parseDataForCountry(_bbRequests);
        csvData += parsedBBData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_tcOption) {
        csvData += tcTitleResults;
        let parsedTcData = parseDataForCountry(_tcRequests);
        csvData += parsedTcData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_htOption) {
        csvData += htTitleResults;
        let parsedHtData = parseDataForCountry(_htRequests);
        csvData += parsedHtData;
        csvData += endOfListSeparator;
      }

      break;

    case "status":
      reportTitle = "Status";
      let statusCountCols = "Status, Count\n";

      if (_bbOption) {
        csvData += bbTitleResults;
        csvData += statusCountCols;
        let parsedBBData = parseDataForStatus(_bbRequests.bbReqStatus);
        csvData += parsedBBData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_tcOption) {
        csvData += tcTitleResults;
        csvData += statusCountCols;
        let parsedTcData = parseDataForStatus(_tcRequests.tcReqStatus);
        csvData += parsedTcData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_htOption) {
        csvData += htTitleResults;
        csvData += statusCountCols;
        let parsedHtData = parseDataForStatus(_htRequests.htReqStatus);
        csvData += parsedHtData;
        csvData += endOfListSeparator;
      }

      break;

    case "uniqueUsers":
      reportTitle = "Unique Users";

      if (_bbOption) {
        csvData += bbTitleResults;
        csvData += monthCountCol;
        let parsedBBData = parseDataForUniqueUsers(_bbRequests);
        csvData += parsedBBData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_tcOption) {
        csvData += tcTitleResults;
        csvData += monthCountCol;
        let parsedTcData = parseDataForUniqueUsers(_tcRequests);
        csvData += parsedTcData;
        csvData += endOfListSeparator;
        csvData += endOfResultsSeparator;
      }

      if (_htOption) {
        csvData += htTitleResults;
        csvData += monthCountCol;
        let parsedHtData = parseDataForUniqueUsers(_htRequests);
        csvData += parsedHtData;
        csvData += endOfListSeparator;
      }

      break;

    default:
      break;
  }

  return { csvData: csvData, reportTitle: reportTitle };
};

const downloadCsvFile = (_csvData, _reportTitle, _fromDate, _toDate) => {
  let myReportDate = getDateForCsvTitle(_fromDate, _toDate);
  let myReportFinalTitle = `${_reportTitle} --- ${myReportDate.from} - ${myReportDate.to}`;

  // create csv string with parsed data
  const csvContent = `data:text/csv;charset=utf-8,${_csvData}`;
  const encodedURI = encodeURI(csvContent);

  // download_data
  const link = document.createElement("a");
  link.href = encodedURI;
  link.download = myReportFinalTitle;
  link.click();
};

module.exports = {
  generateCsvData: generateCsvData,
  downloadCsvFile: downloadCsvFile,
  getDateForCsvTitle: getDateForCsvTitle
};
