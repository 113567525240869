import React from "react";

//-----------------
const StoreContext = React.createContext({
  services: null,
  setShowFeedbackBox: null,
  getShowFeedbackBox: null
});

//-----------------

export default StoreContext;
