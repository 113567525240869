import React, { useContext, useEffect, useState } from "react";
import "./Modal&Button.css";
import rocket1 from "../../../../../assets/images/rocket_01.svg";
import rocket2 from "../../../../../assets/images/rocket_02.svg";
import StoreContext from "../../../../../store/storecontext";
import { Tooltip } from "bootstrap";

const RocketButton = ({ url, genIADescription, isValidInput }) => {
  const store = useContext(StoreContext);
  const [isValidUrl, setValidUrl] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const rocketImage = isHovered ? rocket2 : rocket1;
  const tooltipClass = "rocket-tooltip";
  const tooltipBody = `<div class="summarize-description" data-testid="summarize-tooltip">
        <p class="tooltip-header"><b>Summarize</b></p>
        <p class="tooltip-info">
            Click this button to automatically generate the Best Bet description
        </p>
    </div>`;

  const handleClick = (event) => {
    event.preventDefault();
    genIADescription.openDescriptionModal();
  };

  const handleFocusOut = () => {
    setIsHovered(false);
    deleteOldTooltips();
  }

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const addProps = (dirtyContext, props,cleanContext) => {
    props.forEach((propName) => {
      if(dirtyContext.hasOwnProperty(propName) &&
         dirtyContext[propName] !== null &&
         Array.isArray(dirtyContext[propName])) {
          dirtyContext[propName].forEach((prop) => cleanContext.add(prop));
      } else if (!Array.isArray(dirtyContext[propName]) && 
                    dirtyContext[propName]){
        cleanContext.add(dirtyContext[propName]);
      }
    });
  }

  const cleanData=(dirtyContext)=> {
    var cleanContext = new Set();
    const props = ["cleandescriptionsearch","cleandescription","cleansemantic","cleandymsearch"]
    addProps(dirtyContext,props,cleanContext)
    var contextString = Array.from(cleanContext)
                        .join(". ");
      return contextString;

  }

  useEffect(() => {
    const bbDescriptionLimit = process.env.REACT_APP_BB_DESCRIPTION_LIMIT;
    if (store.services.urlCheckerService.urlRegex.test(url)) {
      url = url.replace(/\/+$/, '');
      store.services.genIAService
      .GetCrawledData(url)
        .then((res) => {
          const exactmatch = res.data.hits.hits.filter((hit) => hit._source.cleanurl === url);
          const isValid = exactmatch.length === 1;
          if (isValid) {
            const context = exactmatch[0]._source;
            const cleanContext = cleanData(context);
            genIADescription.storeSiteData(cleanContext);
            store.services.genIAService.GetGenIADescription(bbDescriptionLimit, "", cleanContext)
            .then((description) => {
              const showRocketBtn = description.data.result !== "There is not enough information about this site";
              setValidUrl(showRocketBtn);

              genIADescription.storeGenDescription(description.data.result)
            });
          }
        }).catch((error) => {
          console.log("Error getting site data: ", error);
        });
    } else {
      setValidUrl(false);
    }
  }, [url]);
  useEffect(() => {
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="rocket"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass,
        html: true
      });
    });
  });

  // when the user comes from review curated content or
  // content searcher
  useEffect(() => {
    deleteOldTooltips();
  }, []);


  return (
    isValidUrl && isValidInput && (
      <button
        className="rocket-button"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={handleFocusOut}
        onClick={handleClick}
        data-testid="rocket-button"
        id="rocket-button"
        aria-label="Generate Best Bet Description"
        aria-describedby="rocket-tooltip"
      >
        <img
          src={rocketImage}
          alt="Rocket"
          role="tooltip"
          data-bs-toggle="rocket"
          data-bs-placement="right"
          data-bs-title={tooltipBody}
          id="rocket-image"
        />
      </button>
    )
  );
};

export default RocketButton;
