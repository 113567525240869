import loadingCss from "./SearchLoading.module.css";

const SearchLoading = (props) => {
  const isDarkMode = props.darkMode ? " darkMode " : "";
  return (
    <div className={isDarkMode + loadingCss.loading_logo_main_div}>
      <div
        className={
          "d-flex justify-content-center " +
          " " +
          loadingCss.magnifying_glass_div
        }
      >
        <div className={loadingCss.magnifying_glass_div}>
          <div
            className={"text-light-mode-purple " + loadingCss.magnifying_glass}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className={loadingCss.magnifying_glass_handle}></div>
        </div>
      </div>
    </div>
  );
};

export default SearchLoading;
