import { useState, useEffect } from "react";

const RefinerPageField = (props) => {
  const [selectedRefiners, setSelectedRefiners] = useState([]);
  const [refinerSelectOptions, setRefinerSelectOptions] = useState([]);
  const [cssState, setCssState] = useState(" light-mode-purple ");

  useEffect(() => {
    if (sessionStorage.getItem("searchtools.config")) {
      let cfg = JSON.parse(sessionStorage.getItem("searchtools.config"));

      const refinerOptions = cfg[1].topiccard.application.map((option) => {
        return (
          <option
            id={"ctc-form-dropdown-refiner-page" + "-option-" + option.value}
            key={option.value}
            value={option.value}
          >
            {option.text}
          </option>
        );
      });

      setRefinerSelectOptions(refinerOptions);
    }
  }, []);

  const resetDropdownToDefault = () => {
    document.getElementById("ctc-refiner-select-id").selectedIndex = 0;
  };

  const AddRefiner = (event) => {
    if (selectedRefiners.indexOf(event.target.value) === -1) {
      let refinerArr = [...selectedRefiners];
      refinerArr.push(event.target.value);
      resetDropdownToDefault();
      setSelectedRefiners(refinerArr);
      setCssState(" is-valid ");
      props.updateFormState(true, "refinerPage");
      props.onLocationOrAidChange();
    } else if (selectedRefiners.indexOf(event.target.value) > -1) {
      let refinerArr = [...selectedRefiners];
      if (refinerArr.length === 0) {
        setCssState(" light-mode-purple ");
        props.updateFormState(null, "refinerPage");
        resetDropdownToDefault();
        return;
      }
      resetDropdownToDefault();
      return;
    }
  };

  const removeSelectedRefiner = (event) => {
    let refinerArr = [...selectedRefiners];
    refinerArr = refinerArr.filter((el) => {
      if (el !== event.target.value) {
        return el;
      }
    });

    if (refinerArr.length === 0) {
      setCssState(null);
      props.updateFormState(false, "refinerPage");
    }

    setSelectedRefiners(refinerArr);
    props.onLocationOrAidChange();
  };
  useEffect(() => {
    if (props.loadedvalue) {
      setSelectedRefiners(props.loadedvalue);
      setCssState(" is-valid ");
    }
  }, [props.loadedvalue]);

  const optionsSelected = selectedRefiners.map((opt) => {
    return (
      <div
        id="refiner-on-list"
        data-testid="testid-refiner-on-list"
        aria-label="refiner-on-list"
        key={opt + "_div"}
        className={"btn-group " + props.pills_style}
        role="group"
      >
        <button
          data-testid="refiner-name-on-list"
          id="refiner-name-on-list"
          aria-label={"refiner " + opt}
          key={opt + "_btn_opt_key"}
          className={"btn btn-success " + props.btn_font_weigth}
          type="button"
        >
          {opt}
        </button>

        {props.readOnlyMode ? (
          <></>
        ) : (
          <button
            data-testid="cross-pill"
            id={opt + "_remove_btn"}
            key={opt + "_remove_btn_key"}
            value={opt}
            className={"btn btn-success " + props.btn_font_weigth}
            type="button"
            aria-label={"remove " + opt + " from list"}
            onClick={removeSelectedRefiner}
          >
            x
          </button>
        )}
      </div>
    );
  });

  return (
    <div className={""}>
      <select
        id="ctc-refiner-select-id"
        data-testid="testid-ctc-refiner-select-id"
        className={"form-select " + props.refiner_select_element + cssState}
        aria-label="Refiner Page Selector"
        onChange={AddRefiner}
        defaultValue={
          "Select Search Refiner where to display the Custom Topic Card"
        }
        disabled={props.readOnlyMode}
      >
        <option disabled={true}>
          Select Search Refiner where to display the Custom Topic Card
        </option>
        {refinerSelectOptions}
      </select>

      <div
        id="ctc-selected-refiner-pages"
        data-testid="testid-ctc-selected-refiner-pages"
        className={props.selected_refiners_div}
      >
        {optionsSelected}
      </div>
    </div>
  );
};

export default RefinerPageField;
