import React, { useEffect } from "react";
import { useState } from "react";

const CompareTable = (props) => {
  let originalData = props.originalData;
  let currentData = props.currentData;
  let currentSteps = currentData.step.map((step) => step.step);
  const [stepsComparison, setStepsComparison] = useState(<></>);

  useEffect(() => {
    steps();
  }, []);

  const steps = () => {
    const stepsToMap =
      currentSteps.length > originalData.cleansteps.length
        ? currentSteps
        : originalData.cleansteps;

    let steps = (
      <>
        {stepsToMap.map((step, index) => (
          <tr tabIndex="0" key={index}>
            <td tabIndex="0">Step {index + 1}</td>
            <td tabIndex="0">
              {originalData.cleansteps[index] === undefined
                ? ""
                : originalData.cleansteps[index]}
            </td>
            <td tabIndex="0">
              {currentSteps[index] === undefined ? "" : currentSteps[index]}
            </td>
          </tr>
        ))}
      </>
    );
    setStepsComparison(steps);
  };
  const changedData = (newData, oldData) => {
    let changes = newData.filter((keyword) => !oldData.includes(keyword));
    return changes.join(",");
  };

  return (
    <div
      id="tc-comparison-table-main-div"
      className="table-holder"
      tabIndex="0"
    >
      <div
        id="tc-comparison-tables-div"
        className="container-fluid tc-comparison-tables-div"
      >
        <h2 className="tc-comparison-tables-title">How-To Comparison</h2>

        <div id="tc-comp-table-1-div" className="table_1_div">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th className={"tc-table-fields-col"}>Fields</th>
                <th className={"tc-table-before-col"}>Before</th>
                <th className={"tc-table-after-col"}>After</th>
              </tr>
            </thead>
            <tbody className={""}>
              <tr key={"title_before_after"} tabIndex="0">
                <td tabIndex="0">Title</td>
                <td tabIndex="0">{originalData.cleantitle}</td>
                <td tabIndex="0">{currentData.title}</td>
              </tr>
              <tr tabIndex="0" key={"url_before_after"}>
                <td tabIndex="0">URL</td>
                <td tabIndex="0">{originalData.cleanurl}</td>
                <td tabIndex="0">{currentData.url}</td>
              </tr>
              {stepsComparison}
              <tr tabIndex="0" key={"loc_before_after"}>
                <td tabIndex="0">Location</td>
                <td tabIndex="0">{originalData.cleancountry.join(",")}</td>
                <td tabIndex="0">{currentData.countries.join(",")}</td>
              </tr>
              <tr tabIndex="0" key={"aid_before_after"}>
                <td tabIndex="0">Refiner Page</td>
                <td tabIndex="0">{originalData.aid.join(",")}</td>
                <td tabIndex="0">{currentData.aid.join(",")}</td>
              </tr>
              <tr tabIndex="0" key={"kw_before_after"}>
                <td tabIndex="0">Keywords</td>
                <td tabIndex="0">{originalData.cleankeywords.join(",")}</td>
                <td tabIndex="0">{currentData.keyword.join(",")}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="tc-comp-table-2-div" className="table_2_div">
          <table className="table table-sm table-bordered">
            <thead>
              <tr className={""}>
                <th>Added Keywords</th>
                <th>Removed Keywords</th>
                <th>Added Locations</th>
                <th>Removed Locations</th>
                <th>Added Refiner Pages</th>
                <th>Removed Refiner Pages</th>
                <th>Added Steps</th>
                <th>Removed Steps</th>
              </tr>
            </thead>
            <tbody className={""}>
              <tr tabIndex="0">
                <td tabIndex="0">
                  {changedData(currentData.keyword, originalData.cleankeywords)}
                </td>
                <td tabIndex="0">
                  {changedData(originalData.cleankeywords, currentData.keyword)}
                </td>
                <td tabIndex="0">
                  {changedData(
                    currentData.countries,
                    originalData.cleancountry
                  )}
                </td>
                <td tabIndex="0">
                  {changedData(
                    originalData.cleancountry,
                    currentData.countries
                  )}
                </td>
                <td tabIndex="0">
                  {changedData(currentData.aid, originalData.aid)}
                </td>
                <td tabIndex="0">
                  {changedData(originalData.aid, currentData.aid)}
                </td>
                <td tabIndex="0">
                  {changedData(currentSteps, originalData.cleansteps)}
                </td>
                <td tabIndex="0">
                  {changedData(originalData.cleansteps, currentSteps)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompareTable;
