import React, { useContext } from 'react'
import { FaRegDizzy } from 'react-icons/fa';
import { ThemeContext } from "../../ThemeContext";
import '../PageNotFound/PageNotFound.css'

const PageNotFound = () => {
  const theme = useContext(ThemeContext);
  return (
    <>
    <div data-testid="page-not-found"
    id="main-content"
    aria-label="Page Not Found"
    role="main"
    className='mt15p page-not-found'
  >
    <p className="h1 mb-2 cross">
     <FaRegDizzy alt="Page Not Found-Icon" aria-label="Page Not Found-Icon" />
    </p>
    <h1 className="message">Oh no! Page not found.</h1>
  </div>
  </>
  )
}
export default PageNotFound;