import { useContext } from "react";
import StoreContext from "../../store/storecontext";
import "./Pagination.css";

const Pagination = (props) => {
  const { currentPageNum, totalPages, goToPage } = props;
  const store = useContext(StoreContext);
  const theme = store.state.theme;

  const isFirstPage = currentPageNum === 0;
  const isLastPage = currentPageNum === props.totalPages - 1;
  const pageBtnDisabledClass = theme === "dark" ? "darkMode arrow_btn disabled" : "arrow_btn disabled";
  const generatePageButtons = () => {
    const btnList = [];
    const maxButtonsToShow = 5;
    const halfRange = Math.floor(maxButtonsToShow / 2);

    let startPage = Math.max(0, currentPageNum - halfRange);
    let endPage = Math.min(totalPages - 1, currentPageNum + halfRange);

    if (currentPageNum <= halfRange) {
      endPage = Math.min(totalPages - 1, maxButtonsToShow - 1);
    } else if (currentPageNum + halfRange >= totalPages - 1) {
      startPage = Math.max(0, totalPages - maxButtonsToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      btnList.push(
        <button
          key={i}
          className={`page_btn ${
            currentPageNum === i ? "page_btn_active" : ""
          }`}
          onClick={() => goToPage(i)}
        >
          {i + 1}
        </button>
      );
    }

    return btnList;
  };

  const btnListForPages = generatePageButtons();

  return (
    <nav
      aria-label="Page navigation"
      data-testid="pagination"
      id="pagination"
      className="paginationContainer"
    >
      <ul className="pagination justify-content-center cs-pagination">
        <li className="page-item ">
          <button
            id="go-to-first-page"
            aria-label="go-to-first-page"
            className={isFirstPage ? pageBtnDisabledClass : "page_btn"}
            href="#"
            tabIndex="0"
            onClick={() => props.goToPage(0)}
            disabled={isFirstPage}
          >
            <span>‹‹</span>
          </button>
        </li>
        <li className="page-item ">
          <button
            id="go-back-one-page"
            aria-label="go-back-one-page"
            className={isFirstPage ? "arrow_btn" : "page_btn"}
            href="#"
            tabIndex="0"
            onClick={() => props.goToPage(props.currentPageNum - 1)}
            disabled={isFirstPage}
          >
            <span>‹</span>
          </button>
        </li>
        {btnListForPages.length > 0 &&
          btnListForPages.map((btn, index) => (
            <li className="page-item" key={index}>
              {btn}
            </li>
          ))}
        {!isLastPage ? (
          <li className="page-item">
            <button
              id="more-results-avaliable"
              aria-label="there are results avaliable"
              className="arrow_btn"
              href="#"
              tabIndex="0"
            >
              <span>...</span>
            </button>
          </li>
        ) : (
          <></>
        )}

        <li className="page-item">
          <button
            id="go-forth-one-page"
            aria-label="go-forth-one-page"
            className={isLastPage ? "arrow_btn" : "page_btn"}
            href="#"
            tabIndex="0"
            onClick={() => props.goToPage(props.currentPageNum + 1)}
            disabled={isLastPage}
          >
            <span>›</span>
          </button>
        </li>
        <li className="page-item">
          <button
            id="go-to-last-page"
            aria-label="go-to-last-page"
            className={isLastPage ? "arrow_btn" : "page_btn"}
            href="#"
            tabIndex="0"
            onClick={props.goToLastPage}
            disabled={isLastPage}
          >
            <span>››</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
