import "./ManageMyRequests.css";

const MMRFilters = ({
  selectedType,
  selectedStatus,
  selectedDate,
  filterReqsts
}) => {
  return (
    <div className="manage_select_group" data-testid="filters">
      <select
        data-testid="selected_type"
        id="selected_type"
        name="type"
        defaultValue="Select Type"
        onChange={(event) => filterReqsts("type", event.target.value)}
        className="form-select select_class"
        aria-label="Default select example"
      >
        <option disabled={true}>Select Type</option>
        {selectedType?.map((opt) => (
          <option
            data-testid="type_options"
            id="type_options"
            value={opt}
            key={opt}
          >
            {opt}
          </option>
        ))}
      </select>

      <select
        data-testid="selected_status"
        id="selected_status"
        defaultValue="Select Status"
        className="form-select select_class"
        aria-label="Default select example"
        onChange={(event) => filterReqsts("status", event.target.value)}
      >
        <option disabled={true}>Select Status</option>
        {selectedStatus?.map((opt) => (
          <option key={opt}>{opt}</option>
        ))}
      </select>
      <select
        data-testid="selected_date"
        id="selected_date"
        onChange={(event) => filterReqsts("date", event.target.value)}
        defaultValue="Filter By Date"
        className="form-select select_class"
        aria-label="Default select example"
      >
        <option disabled={true}>Filter By Date</option>
        {selectedDate?.map((opt) => (
          <option key={opt}>{opt}</option>
        ))}
      </select>
    </div>
  );
};
export default MMRFilters;
