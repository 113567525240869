import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../ThemeContext.jsx";
import StoreContext from "../../../../store/storecontext";
import { useNavigate } from "react-router-dom";
import * as CommentTracker from "../../Components/CommentHistory/CommentTracker.js";

const Buttons = ({
  classname,
  isAdmin,
  disabled,
  disableDraftBtn,
  form,
  setModalProps,
  loadFromRedirectTrigger,
  callCompareTable,
  isFormReadOnlyProp,
  showSpinner,
  hideSpinner,
  idFromUrl,
  dataFromIndex,
  openPreview,

  owner
}) => {
  const [theme] = useContext(ThemeContext);
  const store = useContext(StoreContext);
  const primaryBtn =
    theme === "dark" ? "dark-mode-purple" : "light-mode-purple";
  const navigate = useNavigate();

  const submitRequest = async (status, type, event) => {
    let action = event.target.innerHTML;
    event.preventDefault();
    showSpinner();
    let requestBody = [];
    let bestbetrequest = buildRequestJson(status, type);
    let bestbet = {};
    let request = {
      action: action,
      status: bestbetrequest.cleanstatus,
      reqType: bestbetrequest.cleanrequesttype,
      requestId: idFromUrl
    };
    if (!idFromUrl) {
      bestbetrequest.comments = PostCommentsInTheTracker(request);
    } else {
      if (action === "Save Changes") {
        if (formHasUpdates()) {
          bestbetrequest.comments = PostCommentsInTheTracker(request).concat(
            bestbetrequest.comments
          );
        } else {
          if (form.commentBox !== "") {
            let user = localStorage.getItem("searchtools.eid");
            bestbetrequest.comments.unshift(
              CommentTracker.SaveComment(form.commentBox, user)
            );
          }
        }
      } else {
        bestbetrequest.comments = PostCommentsInTheTracker(request).concat(
          bestbetrequest.comments
        );
      }
    }

    requestBody.push(bestbet);
    requestBody.push(bestbetrequest);

    let bbrequestid = form.requestId === null ? 0 : parseInt(form.requestId);

    if (bbrequestid <= 0) {
      //creation
      try {
        const r = await store.services.bestBetService.SaveBestBet(
          requestBody,
          0
        );
        bestbetrequest.cleanrequesttype = form.cleanrequesttype;
        const ownerYammerId = await getYammerOwnerId(owner);
        await store.services.yammerService.PostBestBetToYammer(
          bestbetrequest,
          isAdmin,
          r.data._id,
          ownerYammerId
        );
        hideSpinner();
        if (action === "Request Best Bet" && !store.state.isSuperUser) {
          store.setShowFeedbackBox(1);
        }
        setModalProps(
          "Best Bet request " +
            r.data._id +
            ' was successfully submitted. Upon clicking OK, you will be redirected to the "Submitted requests" view where you can track the status of this request.',
          () => navigate("/myrequests")
        );
      } catch {
        hideSpinner();
        setModalProps(
          "Error has been encountered. Try again later.",
          () => {} //don't do anything when closing modal
        );
      }
    } else {
      //update
      if (!isAdmin) {
        bestbetrequest.requestedby = localStorage.getItem("searchtools.eid");
      }

      if (
        (action === "Save Changes" || action === "Cancel Request") &&
        !store.state.isSuperUser
      ) {
        store.setShowFeedbackBox(1);
      }

      try {
        let r = null;
        const isActive =
          form.cleanstatus === "Implemented" || form.cleanstatus === "Active";
        if (
          isActive &&
          (form.cleanrequesttype === "Update" ||
            form.cleanrequesttype === "Removal" ||
            form.cleanrequesttype === "Creation")
        ) {
          r = await store.services.bestBetService.SaveBestBet(requestBody, 0);
        } else if (type === "Update" && status === "New") {
          r = await store.services.bestBetService.SaveBestBet(
            requestBody,
            form.requestId
          );
        } else if (type === "Removal") {
          r = await store.services.bestBetService.SaveBestBet(
            requestBody,
            form.requestId
          );
        } else if (status === "Not Approved") {
          let statusMessage =
            status === "Cancelled" || status === "Removed"
              ? status.lowercase()
              : "updated.";
          let isRequest =
            status !== "Removed" ? `request ${form.requestId}` : "";

          let msg = `Best bet ${isRequest} has been ${statusMessage}`;

          r = await store.services.bestBetService.SaveBestBet(
            requestBody,
            form.requestId
          );

          hideSpinner();
          setModalProps(msg, () => navigate("/myrequests")); //go to /myrequest when closing modal
        } else {
          r = await store.services.bestBetService.Update(
            requestBody,
            form.requestId,
            ""
          );
        }
        let lastcommentMessage;
        const ownerYammerId = await getYammerOwnerId(
          bestbetrequest.requestedby
        );
        if (status === "Not Approved") {
          lastcommentMessage = `Your request was not approved. Please review it for more details`;
        } else {
          lastcommentMessage = `This request was `;
          if (status === "New") {
            lastcommentMessage += "modified";
          } else lastcommentMessage += status.toLowerCase();
        }

        lastcommentMessage += `. ${ownerYammerId}`;

        if (type === "Update" || type === "Removal") {
          let modalMsg = `Best Bet request ID # ${r.data._id} has been updated`;

          hideSpinner();
          setModalProps(modalMsg, () => navigate("/myrequests")); //go to /myrequest when closing modal
        } else {
          hideSpinner();
          setModalProps(
            `Best Bet request ${r.data._id} has been updated.`,
            () => navigate("/myrequests")
          ); //go to /myrequest when closing modal
        }
        const doComment = action !== "Save Changes" || formHasUpdates();
        let isNewRequestFromImplement =
          isActive &&
          (bestbetrequest.cleanrequesttype === "Update" ||
            bestbetrequest.cleanrequesttype === "Removal" ||
            bestbetrequest.cleanrequesttype === "Creation");
        if (isNewRequestFromImplement) {
          store.services.yammerService.PostBestBetToYammer(
            bestbetrequest,
            isAdmin,
            r.data._id,
            ownerYammerId
          );
        } else if (form.yammerthreadid && doComment) {
          //If there is a yammer post and this is not a request update/submit for removal we want to comment the post
          store.services.yammerService.PostYammerComment(form.yammerthreadid, [
            lastcommentMessage
          ]);
        } else if (doComment) {
          //If there is no post, we have to create it and post all the system comments stating "this reques was modified"
          await postAndCommentOnYammer(
            bestbetrequest,
            type,
            ownerYammerId,
            r.data._id,
            lastcommentMessage
          );
        }
      } catch (error) {
        hideSpinner();
        store.setShowFeedbackBox(0);
        setModalProps("Error was encountered. Try again later.", () => {});
      }
    }
  };
  const postAndCommentOnYammer = async (
    bestbetrequest,
    type,
    ownerYammerId,
    reqid,
    lastcommentMessage
  ) => {
    const originalPostMsg = getPostMessage(
      bestbetrequest.comments.pop(),
      type,
      bestbetrequest.bbrequestid,
      bestbetrequest.cleantitle,
      ownerYammerId
    ); //eg: a best bet removal request was submitted...
    const changesComments = getSaveChangesComments(
      bestbetrequest.comments //This request was modified...
        .slice(1)
    ); //the first comment on the array is the last action
    const response = await store.services.yammerService.PostBestBetToYammer(
      bestbetrequest,
      isAdmin,
      reqid,
      ownerYammerId,
      originalPostMsg
    );
    const yammerthreadid = response.data;
    var promises = [];
    changesComments.forEach((comment) =>
      promises.push(
        store.services.yammerService.PostYammerComment(yammerthreadid, [
          `This request was modified. ${ownerYammerId}`
        ])
      )
    );
    await Promise.all(promises);
    await store.services.yammerService.PostYammerComment(yammerthreadid, [
      lastcommentMessage
    ]);
  };
  const getPostMessage = (comment, type, reqId, title, ownerYammerId) => {
    const commentMsg = comment.comment;
    const adminCreation = commentMsg.includes("and implemented") ? "new " : "";
    const implementedAdmin = commentMsg.includes("and implemented")
      ? " and implemented"
      : ",";
    const requesttype = type === "Creation" ? "" : `${type.toLowerCase()} `;
    const initialMsg = `A ${adminCreation}Best Bet ${requesttype}request was submitted${implementedAdmin}`;

    const msgBody = ` (${reqId} -  ${title.replace('"', "&quot;")}). ${
      process.env.REACT_APP_SEARCHTOOLS_URL
    }/promote/bestbet/request/${reqId}. `;
    return initialMsg + msgBody + ownerYammerId;
  };

  const getSaveChangesComments = (commentsArr) => {
    const filtered = commentsArr.filter(
      (comment) =>
        comment.user === "System" &&
        comment.comment.startsWith("This request was modified by")
    );
    return filtered.map((comment) => comment.comment);
  };

  const getArcDataForBestBet = async () => {
    const r = await store.services.bestBetService.getBbArcDataFromUrl(
      form.url,
      form.requestId
    );

    let bbHits = r.data.hits?.hits;

    let matchIndex = null;
    for (let i = 0; i < bbHits.length; i++) {
      const res = bbHits[i];
      if (res._source.cleanurl?.includes(form.url)) {
        matchIndex = i;
      }
      if (res._source.friendlyurl?.includes(form.url)) {
        matchIndex = i;
      }
      if (res._source.posthomeurl?.includes(form.url)) {
        matchIndex = i;
      }
    }
    let arcProps = {};
    if (matchIndex !== null) {
      let matchData = bbHits[matchIndex]._source;

      if (matchData.hasOwnProperty("cleanapproved")) {
        arcProps.cleanapproved = matchData.cleanapproved;
      }
      if (matchData.hasOwnProperty("cleanofficial")) {
        arcProps.cleanofficial = matchData.cleanofficial;
      }
      if (matchData.hasOwnProperty("cleancontentsource")) {
        arcProps.cleancontentsource = matchData.cleancontentsource;
      }
      if (matchData.hasOwnProperty("approvedby")) {
        arcProps.approvedby = matchData.approvedby;
      }
      if (matchData.hasOwnProperty("1aaa")) {
        arcProps["1aaa"] = matchData["1aaa"];
      }
    }
    return arcProps;
  };

  const addArcDataToBbBody = (_arcData, _bbBody) => {
    _bbBody.cleanapproved = "";
    _bbBody.cleanofficial = "";
    _bbBody.contentsource = "";
    _bbBody.approvedby = "";
    _bbBody["1aaa"] = "";

    if (_arcData.hasOwnProperty("cleanapproved")) {
      _bbBody.cleanapproved = _arcData.cleanapproved;
    }
    if (_arcData.hasOwnProperty("cleanofficial")) {
      _bbBody.cleanofficial = _arcData.cleanofficial;
    }

    if (_arcData.hasOwnProperty("cleancontentsource")) {
      _bbBody.contentsource = _arcData.cleancontentsource;
    }

    if (_arcData.hasOwnProperty("approvedby")) {
      _bbBody.approvedby = _arcData.approvedby;
    }

    if (_arcData.hasOwnProperty("1aaa")) {
      _bbBody["1aaa"] = _arcData["1aaa"];
    }

    return _bbBody;
  };
  const implementBestbet = async (rqstStatus, rqstType, event) => {
    event.preventDefault();

    let action = event.target.innerHTML;
    event.preventDefault();
    showSpinner();
    let requestBody = [];
    if (rqstStatus === "New" && rqstType === "Creation") {
      rqstStatus = isAdmin ? "Implemented" : "New";
      rqstType = "Update";
    } else if (rqstStatus === "New" && rqstType === "Removal") {
      rqstStatus = "Implemented";
      form.requestId = 0;
    }

    let bestbetrequest = buildRequestJson(rqstStatus, rqstType);

    let request = {
      action: action,
      status: bestbetrequest.cleanstatus,
      reqType: bestbetrequest.cleanrequesttype,
      requestId: idFromUrl
    };

    if (!idFromUrl) {
      bestbetrequest.comments = PostCommentsInTheTracker(request);
    } else {
      bestbetrequest.comments = PostCommentsInTheTracker(request).concat(
        bestbetrequest.comments
      );
    }
    if (!form.bbid) {
      //creation
      let bestbet = {};
      bestbet.cleanstatus = "Active";
      bestbet.cleantitle = form.title;
      bestbet.cleandescription = form.description;
      bestbet.cleanurl = decodeURIComponent(form.url);
      bestbet.cleancountry = form.countries;
      bestbet.showtocountry = form.countries;
      bestbet.aid = form.acronyms;
      bestbet.cleankeywords = form.keywords.map((kw) => {
        return kw.toLowerCase();
      });
      bestbet.cleangr1 = form.keywords;
      bestbet.cleankeywordsraw = form.keywords;

      bestbet.cleankeywordsforreview = form.keywordsforreview.map((kw) => {
        return kw.toLowerCase();
      });
      bestbet.cleankeywordsforreviewraw = form.keywordsforreview;

      bestbet.createdby = owner || "localhost";
      bestbet.qualityscore = parseInt(form.qualityscore);

      let bestBetArcProps = null;
      try {
        bestBetArcProps = await getArcDataForBestBet();
      } catch (error) {
        console.log("error on getting ARC for Best Bet");
      }

      if (bestBetArcProps !== null) {
        let bbTempBody = { ...bestbet };
        bestbet = addArcDataToBbBody(bestBetArcProps, bbTempBody);
      }

      requestBody.push(bestbet);
      requestBody.push(bestbetrequest);
      let lastCommentMessage = "";

      const ownerYammerId = await getYammerOwnerId(owner);
      if (rqstStatus === "Implemented") {
        lastCommentMessage =
          "Your request has been implemented. Please review it for more details. " +
          ownerYammerId;
      } else if (rqstStatus === "Waiting for customer") {
        lastCommentMessage =
          "Your request cannot be analyzed and requires more information from your end. Please review and edit it accordingly. " +
          ownerYammerId;
      }
      try {
        const r = await store.services.bestBetService.SaveBestBet(
          requestBody,
          form.requestId
        );
        if (form.yammerthreadid) {
          await store.services.yammerService
            .PostYammerComment(form.yammerthreadid, [lastCommentMessage])
            .catch((e) => {
              console.log(
                "error in implementBestbet 1 -> PostYammerComment : " + e
              );
            });
        } else if (!bestbetrequest.bbrequestid) {
          //If it's admin autoimplementation
          await store.services.yammerService.PostBestBetToYammer(
            bestbetrequest,
            isAdmin,
            r.data._id,
            ownerYammerId
          );
        } else {
          await postAndCommentOnYammer(
            bestbetrequest,
            rqstType,
            ownerYammerId,
            r.data._id,
            lastCommentMessage
          );
        }
        hideSpinner();
        setModalProps(
          "Best Bet request " +
            r.data._id +
            " has been saved and implemented. You may verify its implementation on Search.",
          () => navigate("/myrequests")
        ); //go to /myrequest when closing modal
      } catch {
        hideSpinner();
        setModalProps(
          "Error has been encountered. Try again later.",
          () => {} //don't do anything when closing modal
        );
      }
    } else {
      //  update
      var bestbetjson = {};

      bestbetjson.cleanstatus = rqstType === "Removal" ? "Inactive" : "Active";
      bestbetjson.cleantitle = form.title;
      bestbetjson.cleandescription = form.description;
      bestbetjson.cleanurl = decodeURIComponent(form.url);
      bestbetjson.cleancountry = form.countries;
      bestbetjson.showtocountry = form.countries;
      bestbetjson.aid = form.acronyms;
      bestbetjson.cleankeywords = form.keywords.map((kw) => {
        return kw.toLowerCase();
      });
      bestbetjson.cleangr1 = form.keywords;
      bestbetjson.cleankeywordsraw = form.keywords;
      bestbetjson.cleankeywordsforreview = form.keywordsforreview.map((kw) => {
        return kw.toLowerCase();
      });
      bestbetjson.cleankeywordsforreviewraw = form.keywordsforreview;

      bestbetjson.qualityscore = parseInt(form.qualityscore);

      let bestBetArcProps = null;
      try {
        bestBetArcProps = await getArcDataForBestBet();
      } catch (error) {
        console.log("error on getting ARC for Best Bet");
      }

      if (bestBetArcProps !== null) {
        let bbTempBody = { ...bestbetjson };
        bestbetjson = addArcDataToBbBody(bestBetArcProps, bbTempBody);
      }

      requestBody.push(bestbetjson);
      requestBody.push(bestbetrequest);

      let requestID = form.cleanstatus === "New" ? form.requestId : 0;

      let r = await store.services.bestBetService.Update(
        requestBody,
        requestID,
        form.bbid
      );
      let lastCommentMessage;
      const ownerYammerId = await getYammerOwnerId(owner);
      if (
        (rqstType === "Update" || rqstType === "Removal") &&
        form.cleanstatus === "New"
      ) {
        lastCommentMessage =
          "Your " +
          bestbetrequest.cleanrequesttype.toLowerCase() +
          " request has been implemented. Please review it for more details. " +
          ownerYammerId;
        if (form.yammerthreadid) {
          await store.services.yammerService
            .PostYammerComment(form.yammerthreadid, [lastCommentMessage])
            .catch((e) => {
              console.log(
                "error in implementBestbet 2 -> PostYammerComment : " + e
              );
            });
        } else {
          await postAndCommentOnYammer(
            bestbetrequest,
            rqstType,
            ownerYammerId,
            bestbetrequest.bbrequestid,
            lastCommentMessage
          );
        }
      } else if (
        form.cleanrequesttype === "Removal" ||
        form.cleanrequesttype === "Update" ||
        form.cleanstatus === "Implemented" //Autoimplement removal or update
      ) {
        const ownerYammerId = await getYammerOwnerId(owner);
        store.services.yammerService.PostBestBetToYammer(
          bestbetrequest,
          isAdmin,
          r.data._id,
          ownerYammerId
        );
      }
      let msg;

      msg =
        rqstType === "Removal"
          ? "Best Bet has been removed."
          : `Best Bet request ${r.data._id} has been updated and implemented. You may verify its implementation on Search.`;

      hideSpinner();
      setModalProps(msg, () => navigate("/myrequests"));
    }
  };

  const buildRequestJson = (status, type) => {
    let bestbetrequest = {};

    bestbetrequest.bbrequestid = form.requestId;

    if (form.bbid) {
      bestbetrequest.bbid = form.bbid;
    }

    bestbetrequest.cleanemployeenumber = idFromUrl
      ? form.cleanemployeenumber
      : localStorage.getItem("searchtools.pk");

    let dt = new Date().toISOString();

    bestbetrequest.cleancreationdate = form.cleancreationdate || dt;
    bestbetrequest.lastmodifieddateUTC = dt;
    if (form.lastmodifiedby.length === 0) {
      bestbetrequest.lastmodifiedby = [];
    } else if (form.lastmodifiedby.length > 0) {
      bestbetrequest.lastmodifiedby = form.lastmodifiedby;
    }
    bestbetrequest.lastmodifiedby.push({
      user: localStorage.getItem("searchtools.eid") || "localhost",
      dateUTC: dt
    });
    bestbetrequest.requestedby = owner || "localhost";
    bestbetrequest.cleanexpirationdate = null;
    bestbetrequest.cleansimilarurlimplemented = null; //for validations, for sites with subsites
    bestbetrequest.cleaninternalsitesstatus = null; //for validations
    bestbetrequest.cleancontributionapproved = null; //for validations
    bestbetrequest.cleanmylearningonlinetraining = null; //for validations
    bestbetrequest.cleanstatus = status;
    bestbetrequest.cleanrequesttype = type;
    bestbetrequest.cleankeywords = form.keywords.map((kw) => {
      return kw.toLowerCase();
    });
    bestbetrequest.cleankeywordsraw = form.keywords;
    bestbetrequest.cleankeywordsforreview = form.keywordsforreview.map((kw) => {
      return kw.toLowerCase();
    });
    bestbetrequest.cleankeywordsforreviewraw = form.keywordsforreview;
    bestbetrequest.cleantitle = form.title;
    bestbetrequest.cleandescription = form.description;
    bestbetrequest.cleanurl = decodeURIComponent(form.url);
    bestbetrequest.cleancountry = form.countries;
    bestbetrequest.aid = form.acronyms;
    bestbetrequest.useragent = navigator.userAgent;
    bestbetrequest.yammerthreadid = form.yammerthreadid;
    if (form.comments === undefined || form.comments.length === 0) {
      bestbetrequest.comments = [];
    } else if (form.comments.length > 0) {
      bestbetrequest.comments = form.comments;
    }

    return bestbetrequest;
  };

  const PostCommentsInTheTracker = (request) => {
    let user = localStorage.getItem("searchtools.eid");
    let comments = [];
    request.user = user;
    comments = CommentTracker.PostComments(request);

    if (form.commentBox !== "") {
      comments.unshift(CommentTracker.SaveComment(form.commentBox, user));
    }
    return comments;
  };
  const formHasUpdates = () => {
    return (
      form.title !== dataFromIndex.cleantitle ||
      form.url !== dataFromIndex.cleanurl ||
      form.description !== dataFromIndex.cleandescription ||
      JSON.stringify(form.acronyms) !== JSON.stringify(dataFromIndex.aid) ||
      JSON.stringify(form.countries) !==
        JSON.stringify(dataFromIndex.cleancountry) ||
      JSON.stringify(form.keywords) !==
        JSON.stringify(dataFromIndex.cleankeywordsraw) ||
      JSON.stringify(form.keywordsforreview) !==
        JSON.stringify(dataFromIndex.cleankeywordsforreviewraw)
    );
  };
  const [cssSaveChangesBtn, setCssSaveChangesBtn] =
    useState("btn btn-primary ");

  const showCompareTable = (e) => {
    e.preventDefault();
    callCompareTable();
  };

  const showPreview = (e) => {
    e.preventDefault();
    openPreview();
  };
  const getYammerOwnerId = (owner) => {
    return store.services.yammerService.GetOwnerUserIdForYammer(owner);
  };

  useEffect(() => {
    let cssSaveChanges = "";
    let currEID = localStorage.getItem("searchtools.eid");
    let isOwner = owner === currEID;
    let requestStatus = form.cleanstatus;
    let requestID = parseInt(form.requestId);

    if (
      requestStatus !== "Implemented" &&
      requestStatus !== "Not Approved" &&
      requestID > 0
    ) {
      if (isAdmin) {
        cssSaveChanges = `btn btn-secondary ${primaryBtn}`;
      } else if (isOwner) {
        let ownerCssSaveChangesBtn = "owner-css-save-changes-btn";
        cssSaveChanges = `btn btn-primary ${ownerCssSaveChangesBtn}`;
      } else if (store.state.requestToEditBB.contentSearcher) {
        let ownerCssSaveChangesBtn = "owner-css-save-changes-btn";
        cssSaveChanges = `btn btn-primary ${ownerCssSaveChangesBtn}`;
      } 
      setCssSaveChangesBtn(cssSaveChanges);
    }
  }, [loadFromRedirectTrigger]);

  let implementBtnRqstStatus = "New";
  let implementBtnRqstType = "Creation";

  if (isAdmin) {
    // from status "new" to "implemented"
    if (form.cleanstatus !== "Implemented" && !isFormReadOnlyProp) {
      implementBtnRqstStatus = "Implemented";
      implementBtnRqstType = form.cleanrequesttype;
    } else if (
      form.cleanrequesttype === "Update" &&
      form.cleanstatus === "Implemented"
    ) {
      // don't change status, change the request type
      implementBtnRqstStatus = "Implemented";
      implementBtnRqstType = form.cleanrequesttype;
    }
  }

  let htmlFormButtons = {
    previewBtn: (
      <button
        className="btn btn-secondary"
        data-testid="bb-preview-button"
        id="bb-preview-button"
        disabled={disabled}
        onClick={(e) => showPreview(e)}
      >
        Preview
      </button>
    ),
    saveChangesBtn: (
      <button
        className={cssSaveChangesBtn}
        data-testid="bb-form-button"
        id="bb-save-changes-btn"
        onClick={(e) =>
          submitRequest(form.cleanstatus, form.cleanrequesttype, e)
        }
        disabled={disabled}
      >
        Save Changes
      </button>
    ),
    implementBtn: (
      <button
        className={`btn btn-primary ${primaryBtn}`}
        data-testid="bb-implement-button"
        id="bb-implement-btn"
        onClick={(e) => {
          implementBestbet(implementBtnRqstStatus, implementBtnRqstType, e);
        }}
        disabled={disabled}
      >
        Implement
      </button>
    ),
    requestDraftBBBtn: (
      <button
        className="btn btn-secondary"
        data-testid="bb-save-draft-button"
        id="bb-draft-button"
        onClick={(e) => submitRequest("Draft", form.cleanrequesttype === "Creation" ? "Creation" : "Update", e)}
        disabled={disableDraftBtn}
      >
        Save Draft
      </button>
    ),

    requestBestBetBtn: (
      <button
        className={`btn btn-primary ${primaryBtn}`}
        data-testid="bb-request-btn"
        id="bb-request-button"
        onClick={(e) => submitRequest("New", form.cleanrequesttype, e)}
        disabled={disabled}
      >
        Request Best Bet
      </button>
    ),
    rejectBtn: (
      <button
        className={`btn btn-outline-danger red-form-btn`}
        data-testid="bb-form-button"
        id="bb-reject-button"
        onClick={(e) => submitRequest("Not Approved", form.cleanrequesttype, e)}
        disabled={disabled}
      >
        Reject
      </button>
    ),
    cancelRequestBtn: (
      <button
        className={`btn btn-outline-danger red-form-btn`}
        data-testid="bb-form-button"
        id="bb-cancel-request-button"
        onClick={(e) => submitRequest("Cancelled", form.cleanrequesttype, e)}
        disabled={disabled}
      >
        Cancel Request
      </button>
    ),
    submitForRemovalBtn: (
      <button
        className={`btn btn-outline-danger red-form-btn`}
        data-testid="bb-form-button"
        id="bb-submit-for-removal-button"
        onClick={(e) => submitRequest("New", "Removal", e)}
        disabled={disabled}
      >
        Submit for Removal
      </button>
    ),
    requestUpdateBtn: (
      <button
        className={`btn btn-primary ${primaryBtn}`}
        data-testid="bb-form-button"
        id="bb-request-update-button"
        onClick={(e) => submitRequest("New", "Update", e)}
        disabled={disabled}
      >
        Request Update
      </button>
    ),
    removeRequestBtn: (
      <button
        className={`btn btn-outline-danger red-form-btn`}
        data-testid="bb-form-button"
        id="bb-cancel-request-button"
        onClick={(e) => implementBestbet("New", "Removal", e)}
        disabled={disabled}
      >
        Remove Best Bet
      </button>
    ),
    compareBtn: (
      <button
        className="btn btn-secondary"
        data-testid="bb-form-button"
        id="bb-compare-table-button"
        disabled={disabled}
        onClick={(e) => showCompareTable(e)}
      >
        Compare
      </button>
    )
  };

  let saveReqChangesBtn = idFromUrl ? htmlFormButtons.saveChangesBtn : "";
  let showSaveChangesButton = false;
  let reqBestBetBtn = null;
  let requestDraftBBBtn = htmlFormButtons.requestDraftBBBtn;
  let rejectOrCancelButton = null;
  let compareChangesBtn = htmlFormButtons.compareBtn;
  let showCompareButton = false;
  let itsFromManageMyRequest =
    store.state.requestToEditBB.itsFromManageMyRequest;
  let hasRequestID = parseInt(form.requestId) > 0;
  let hasImplementedID = parseInt(form.bbid) > 0;
  let isCreationMode =
    form.cleanrequesttype === "Creation" && form.cleanstatus === "New";
  let isUpdateMode = form.cleanrequesttype === "Update";
  let isRemoveRequest = form.cleanrequesttype === "Removal";

  if (isAdmin) {
    if (isCreationMode) {
      rejectOrCancelButton = htmlFormButtons.rejectBtn;
    } else {
      if (form.cleanstatus === "Implemented" || form.cleanstatus === "Active") {
        rejectOrCancelButton = htmlFormButtons.removeRequestBtn;
      } else {
        rejectOrCancelButton = <></>;
      }
    }

    if (form.cleanrequesttype !== "Creation") {
      if (isRemoveRequest || form.cleanstatus === "New") {
        rejectOrCancelButton = htmlFormButtons.rejectBtn;
        showCompareButton = htmlFormButtons.compareBtn;
      } else if (form.cleanstatus === "Not Approved") {
        rejectOrCancelButton = true;
        showCompareButton = htmlFormButtons.compareBtn;
      } else {
        rejectOrCancelButton =
          form.cleanstatus === "Implemented" ||
          form.cleanstatus === "Active" ? (
            htmlFormButtons.removeRequestBtn
          ) : (
            <></>
          );
        showCompareButton = false;
      }
    }
    showSaveChangesButton = hasRequestID && form.cleanstatus !== "Implemented";
    reqBestBetBtn = htmlFormButtons.implementBtn;
  } else if (!isAdmin) {
    let dontDisplaySaveChanges = false;
    if (!hasRequestID && !hasImplementedID || form.cleanstatus === "Draft" && form.cleanrequesttype === "Creation") {
      reqBestBetBtn = htmlFormButtons.requestBestBetBtn;
    } else if (hasRequestID || hasImplementedID) {
      if (isCreationMode) {
        rejectOrCancelButton = htmlFormButtons.cancelRequestBtn;
      } else if (form.cleanstatus === "New") {
        if (isUpdateMode || isRemoveRequest) {
          rejectOrCancelButton = htmlFormButtons.cancelRequestBtn;
          showCompareButton = htmlFormButtons.compareBtn;
          showSaveChangesButton = htmlFormButtons.saveChangesBtn;
        } else {
          rejectOrCancelButton = htmlFormButtons.submitForRemovalBtn;
          showCompareButton = htmlFormButtons.compareBtn;
        }
      } else if (
        form.cleanstatus === "Implemented" ||
        form.cleanstatus === "Active"
      ) {
        rejectOrCancelButton = isUpdateMode
          ? htmlFormButtons.submitForRemovalBtn
          : htmlFormButtons.submitForRemovalBtn;
        dontDisplaySaveChanges = true;
      }

      showSaveChangesButton = !(
        store.state.requestToEditBB.canEdit &&
        itsFromManageMyRequest &&
        form.cleanstatus === "Implemented"
      );

      if (isUpdateMode) {
        showSaveChangesButton =
          isUpdateMode && form.cleanstatus !== "Implemented";
      } else {
        showSaveChangesButton = dontDisplaySaveChanges
          ? false
          : showSaveChangesButton;
      }

      if (isCreationMode) {
        reqBestBetBtn = <></>;
      } else if (form.cleanstatus === "New" && isUpdateMode) {
        reqBestBetBtn = <></>;
      } else if (
        form.cleanstatus === "Implemented" ||
        form.cleanstatus === "Active"
      ) {
        reqBestBetBtn = htmlFormButtons.requestUpdateBtn;
      } else {
        reqBestBetBtn = isUpdateMode ? htmlFormButtons.requestUpdateBtn : <></>;
      }
    }
  }

  return (
    <div className={`form-group ${classname}`}>
      {rejectOrCancelButton}
      {showCompareButton ? compareChangesBtn : <></>}
      {true ? htmlFormButtons.previewBtn : <></>}
      {!isAdmin ? requestDraftBBBtn : <></>}
      {showSaveChangesButton ? saveReqChangesBtn : <></>}
      {reqBestBetBtn}
    </div>
  );
};

export default Buttons;
