const MetricsButton = ({showMetrics, isMetricsBtnDisabled}) => {
  return (
    <div className="get-metrics">
      <button
        id="get-metrics"
        data-testid="get-metrics"
        aria-label="get-metrics"
        type="button"
        className="btn btn-lg btn-primary btn-metrics get-metrics-button"
        onClick={() => showMetrics()}
        disabled={isMetricsBtnDisabled}
      >
        Get Metrics
      </button>
    </div>
  );
};

export default MetricsButton;
