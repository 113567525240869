import NtCssClass from "./NotAuthorizedPage.module.css";
import WarningIcon from "../../assets/images/warning_icon.svg";
import { ThemeContext } from "../../ThemeContext";
import { useContext } from "react";

const NotAuthorizedPage = () => {
  const theme = useContext(ThemeContext);
  return (
    <div
      aria-label="Your Account is not authorized to view this page."
      id="not-authorized-page-main-div"
      className={NtCssClass.main_div}
    >
      <img
        className={NtCssClass.triangle_div}
        src={WarningIcon}
        alt="warning-icon"
      />
      <h1 className="message">
        Your Account is not authorized to view this page.
      </h1>
    </div>
  );
};

export default NotAuthorizedPage;
