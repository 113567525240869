import React, { useEffect, useState } from 'react';
import './AnnouncementModal.css';

const AnnouncementModal = () => {
    let boolDictionary = { "true": true, "false": false, "null": true}
    const [show, setShow] = useState(null);    
    const [showAgainModal, setShowAgainModal] = useState(boolDictionary[sessionStorage.getItem('showAgainModal')]);

    const close = () => {
        setShow(false);
    };

    useEffect(() => {
        //when component renders for the first time it shows the modal unless showAgainModal is set to false
        if(sessionStorage.getItem('showAgainModal') === null){ 
            setShow(true);
        } else setShow(boolDictionary[sessionStorage.getItem('showAgainModal')]);
    }, []);

    useEffect(() => { 
        sessionStorage.setItem('showAgainModal', showAgainModal);
    }, [showAgainModal]);

    return (
        <>
            { show && (
                <>
                    <div className="modal-tail" data-testid="modal-tail"></div>
                    <div className="announcement-modal" id="announcement-modal" data-testid="modal-body">
                        <div className="announcement-modal-header">
                            <h2 id="modal-title">NEW UI UPDATE</h2>
                            <button className="close-button" onClick={close} id="close-button">
                                X
                            </button>
                        </div>
                        <div className="announcement-modal-content">
                            <p id='welcome-message' data-testid="welcome-message">Welcome to <b>Search Tools</b>! Now you can navigate to the page without losing your favorite features.</p>
                        </div>
                        <div className='form-check'>
                            <input
                                type="checkbox"
                                name="show"
                                id="show-again"
                                className='form-check-input'
                                checked={showAgainModal === null ? false : !showAgainModal}
                                onChange={()=>setShowAgainModal(!showAgainModal)}
                            />
                            <label htmlFor="show-again" id="show-again-label">Do not show this again</label>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default AnnouncementModal;