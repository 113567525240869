const validateTerm = async (term, id= undefined, service) => {
    if (term.match(service.regex)) {
        return {
            state: "invalid",
            msg: 'Please do not include the special characters \\ and "'
        };
    }
    if (term.length > 65) {
        return {
            state: "invalid",
            msg: "please do not include more than 65 characters"
        };
    }if(term.length === 0){
        return {state: null};
    }
    const synonymsByKeyword = await service.GetSynonymsByKeyword(term);
    const existingTerms = synonymsByKeyword?.data?.hits?.hits?.filter(doc => doc["_source"]["keyword"].toLowerCase() === term.toLowerCase());
    if(existingTerms?.length>0 && existingTerms[0]._source.synonyms_implemented_id !== id){
        return { requestId: existingTerms[0]._source.synonyms_implemented_id, request: existingTerms[0]._source }; 
    }    
    return { state: "valid" };
};

const validateSynonym = (synonym,currentSynonyms,service) => {
    if(currentSynonyms.length >19){
        return {
            state: false,
            msg:"Only 20 synonyms are allowed",
            show:true
        }
    }
    if(synonym.match(service.regex)){
        return {
            state:false,
            msg:'Please do not include the special characters \\ and "',
            show:true
        }
    }
    const caseLoweredSynonyms = currentSynonyms.map(synonym => synonym.toLowerCase())
    if(caseLoweredSynonyms.includes(synonym.toLowerCase())){
        return { state:false, msg: "This synonym already exist" };
    }
    return { state:true };
}

  export default {
    validateTerm,
    validateSynonym
  }