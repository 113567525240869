import "../TCForm.css";
import TCLineItem from "./TCLineItem";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  BsPlusSquare,
  BsArrowDownSquare,
  BsArrowUpSquare,
  BsTrash
} from "react-icons/bs";
import { Tooltip } from "bootstrap";
import { ThemeContext } from "../../../../ThemeContext";

const TCLineItems = ({
  title,
  isDiamond,
  isChecked,
  setIsChecked,
  lineItem,
  setLineItem,
  lineItemId,
  field,
  state,
  setState,
  setNewLineItem,
  setNewLineItemState,
  disableField,
  formContainsData
}) => {
  const theme = useContext(ThemeContext);

  let { id } = useParams();
  const [idFromUrl, setIdfromUrl] = useState(id);

  useEffect(() => {
    const tooltipClass = theme[0] === "dark" ? "tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="add-line-item"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, [theme]);

  useEffect(() => {
    const tooltipClass = theme[0] === "dark" ? "tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="arrow-up"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, [theme]);

  useEffect(() => {
    const tooltipClass = theme[0] === "dark" ? "tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="arrow-down"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, [theme]);

  useEffect(() => {
    const tooltipClass = theme[0] === "dark" ? "tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="trash"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, [theme]);
  useEffect(()=>{
    if(lineItem.length===0){
        let el = document.querySelector(`#${lineItemId}`);

        if (el.checked) {
          el.checked = false;
        }
    }
  },[lineItem])
  const displayLineItemInput = (event) => {
    if (event.target.checked) {
      setIsChecked(event.target.checked);
      addLineItem(event);
    } else {
      setLineItem(field, []);
      setNewLineItemState(field, []);
      setIsChecked(event.target.checked);
    }
  };

  const addLineItem = (event, index) => {
    if (
      event.key === "Enter" ||
      event.type === "click" ||
      event.type === "change"
    ) {
      let lineItemArray = [...lineItem];
      let newLineItem = { title: "", url: "" };

      if (lineItem.length === 1 && event.type === "change") {
        lineItem.length = 0;
      }

      lineItemArray = [...lineItem, newLineItem];
      setNewLineItem(field, lineItemArray);
      let states = [...state];
      let newState = {
        title: { state: null, errorMessage: null, inputClass: "" },
        url: { state: null, errorMessage: null, inputClass: "" }
      };

      states = [...state, newState];
      setNewLineItemState(field, states);
    }
  };

  const removeLineItem = (index) => {
    let lineItemArray = [...lineItem];

    lineItemArray.splice(index, 1);
    setLineItem(field, lineItemArray);
    removeLineItemState(index);
  };
  const removeLineItemState = (index) => {
    let stateLineItem = [...state];

    stateLineItem.splice(index, 1);
    setNewLineItemState(field, stateLineItem);
  };

  const moveLineItem = (action, index) => {
    let lineItemArray = [...lineItem];
    let lineItemStateArray = [...state];
    let itemToMove = lineItemArray.find((item, idx) => idx === index);
    let remainingItems = lineItemArray.filter((item, idx) => idx !== index);
    let stateToMove = lineItemStateArray.filter(
      (item, idx) => idx === index
    )[0];
    let remainingStates = lineItemStateArray.filter(
      (item, idx) => idx !== index
    );
    let reorderedItems;
    let reorderedItemsStates;
    let newIndex;
    let newIndexState;

    switch (action) {
      case "Up":
        newIndex = index === 0 ? lineItemArray.length : index - 1;
        newIndexState = index === 0 ? lineItemStateArray.length : index - 1;

        reorderedItems = [
          ...remainingItems.slice(0, newIndex),
          itemToMove,
          ...remainingItems.slice(newIndex)
        ];
        reorderedItemsStates = [
          ...remainingStates.slice(0, newIndexState),
          stateToMove,
          ...remainingStates.slice(newIndexState)
        ];
        setLineItem(field, reorderedItems);
        setNewLineItemState(field, reorderedItemsStates);

        break;
      case "Down":
        newIndex = index === lineItemArray.length - 1 ? 0 : index + 1;
        newIndexState = index === lineItemStateArray.length - 1 ? 0 : index + 1;

        reorderedItems = [
          ...remainingItems.slice(0, newIndex),
          itemToMove,
          ...remainingItems.slice(newIndex)
        ];
        reorderedItemsStates = [
          ...remainingStates.slice(0, newIndexState),
          stateToMove,
          ...remainingStates.slice(newIndexState)
        ];
        setLineItem(field, reorderedItems);
        setNewLineItemState(field, reorderedItemsStates);
        break;
    }
  };

  const lineItemHtml = lineItem.map((item, index) => (
    <div className="line-item" key={index} data-testid={`line-item-${field}-${index}`}>
      <TCLineItem
        item={item}
        index={index}
        lineItem={lineItem}
        setLineItem={setLineItem}
        field={field}
        state={state}
        setState={setState}
        disableField={disableField}
      ></TCLineItem>
      {!disableField && (
        <div className="steps_icons">
          <BsPlusSquare
            role="tooltip"
            data-bs-toggle="add-line-item"
            data-bs-placement="top"
            data-bs-title={"Add line item"}
            tabIndex={0}
            data-testid={`plus-button-${field}-${index}`}
            onClick={(event) => addLineItem(event, index)}
            onKeyDown={(event) => addLineItem(event, index)}
            className="plus-arrow-trash"
          />
          {lineItem.length > 1  &&
            <>
              <BsArrowUpSquare
                role="tooltip"
                data-bs-toggle="arrow-up"
                data-bs-placement="top"
                data-bs-title={"Move item up"}
                tabIndex={0}
                className="plus-arrow-trash"
                data-testid={`arrow-up-button-${field}-${index}`}
                onClick={(event) => moveLineItem("Up", index)}
              />
              <BsArrowDownSquare
                role="tooltip"
                data-bs-toggle="arrow-down"
                data-bs-placement="top"
                data-bs-title={"Move item down"}
                tabIndex={0}
                data-testid={`arrow-down-button-${field}-${index}`}
                className="plus-arrow-trash"
                onClick={(event) => moveLineItem("Down", index)}
              />
            </>

          }
          <BsTrash
            role="tooltip"
            data-bs-toggle="trash"
            data-bs-placement="top"
            data-bs-title={"Remove line item"}
            id={`trash-button-${field}-${index}`}
            tabIndex={0}
            data-testid={`trash-button-${field}-${index}`}
            onClick={(event) => removeLineItem(index)}
            className="plus-arrow-trash"
          />
        </div>
      )}
    </div>
  ));

  // // toggle checkbox when loading data
  useEffect(() => {
    try {
      if (lineItem.length > 0 && parseInt(idFromUrl) > 0) {
        let el = document.querySelector(`#${lineItemId}`);

        if (!el.checked) {
          el.checked = true;
        }
      }
    } catch (error) {
      console.log("no line item to modify");
    }
  }, [formContainsData]);

  return (
    <>
      <div className="line-item">
        <div className="custom-control custom-checkbox">
          <div className="checkbox check-primary ">
            <input
              tabIndex="0"
              id={lineItemId}
              className={"form-check-input"}
              type="checkbox"
              data-testid={lineItemId}
              defaultChecked={isChecked}
              onChange={(event) => displayLineItemInput(event)}
              disabled={disableField}
            />
            <label
              data-testid="line-item-label"
              htmlFor={lineItemId}
              className="label-text"
            >
              {title}{" "}
              {isDiamond ? (
                <span className="diamond">(For Diamond Clients Only)</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>
      {isChecked ? <>{lineItemHtml}</> : <></>}
    </>
  );
};

export default TCLineItems;
