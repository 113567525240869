import React from "react";

const MetricsMenu = ({ handleMenuChange }) => {
  return (
    <>
      <div className="btn-group options_menu">
        <button
          id="metrics-st-results-submitted-req-btn"
          className={"nav nav-link menu-tab m-opt-submittedrq active-menu-tab"}
          onClick={() => handleMenuChange("submitedRqsts")}
          data-testid="chart-menu-btn"
        >
          Submitted Requests
        </button>
        <button
          id="metrics-st-results-url-btn"
          className={"nav nav-link menu-tab m-opt-url"}
          onClick={() => handleMenuChange("url")}
          data-testid="list-menu-btn"
        >
          URL List
        </button>
        <button
          id="metrics-st-results-target-refiner-btn"
          className={"nav nav-link menu-tab m-opt-targetrefiner"}
          onClick={() => handleMenuChange("targetRefinerPage")}
          data-testid="chart-menu-btn"
        >
          Target Refiner Page
        </button>
        <button
          id="metrics-st-results-target-country-btn"
          className={"nav nav-link menu-tab m-opt-targetcountry"}
          onClick={() => handleMenuChange("targetCountry")}
          data-testid="chart-menu-btn"
        >
          Target Country
        </button>
        <button
          id="metrics-st-results-status-btn"
          className={"nav nav-link menu-tab m-opt-status"}
          onClick={() => handleMenuChange("status")}
          data-testid="chart-menu-btn"
        >
          Status
        </button>
        <button
          id="metrics-st-results-unique-users-btn"
          className={"nav nav-link menu-tab m-opt-uniqueusers"}
          onClick={() => handleMenuChange("uniqueUsers")}
          data-testid="chart-menu-btn"
        >
          Unique Users
        </button>
      </div>
    </>
  );
};

export default MetricsMenu;
