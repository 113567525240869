const Checkbox = ({ option, handleChange, isChecked }) => {
  return (
    <div className="custom-control custom-checkbox">
      <div className="checkbox check-primary ">
        <input
          id={`metrics-st-${option}-checkbox`}
          aria-label={option}
          data-testid="metrics-checkbox"
          tabIndex="0"
          className="form-check-input"
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
        />
        <label
          id={`metrics-st-${option}-label`}
          className="metrics-checkbox-label-text metric-option"
          data-testid="metric-option"
        >
          {option}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
