import { useEffect } from "react";
import { homeRedirects } from "../../helpers/homeRedirects";

 const NotFoundRequest = () => {
    useEffect(() => {
      homeRedirects();
    }, [])
    
  return (
    <>
    <div data-testid="request-not-found"
    id="request-not-found"
    aria-label="Request not found"
    role="main"
    className='mt15p page-not-found'
  >
    <h3 className="message">Your request was not found</h3>
  </div>
  </>
  )
}
export default NotFoundRequest;
