import { useContext, useEffect } from "react";
import "./Table.css"; // Updated import statement
import { Tooltip } from "bootstrap";
import { ThemeContext } from "../../ThemeContext";

const CustomTable = ({ columns, data, testid }) => {
  const [theme] = useContext(ThemeContext);
  useEffect(() => {
    const tooltipClass =
      theme === "dark" ? "custom-tooltip-dark" : "custom-tooltip-light";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, [theme]);

  if (!columns || !data) {
    return <></>;
  }
  return (
      <table className="table" data-testid={testid}>
        <thead className="custom-thead">
          <tr>
            {columns.map((column, index) => (
              <th
                className="custom-th"
                key={index}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td
                    className="custom-td"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title={row[column.key]}
                    key={colIndex}
                  >
                    {row[column.key]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length}
              >
                No data found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
  );
};

export default CustomTable;
