import ModalCustom from "../../../Modal/ModalCustom";
import { ThemeContext } from "../../../../ThemeContext";
import { useContext } from "react";

const Preview = (props) => {
  const [theme] = useContext(ThemeContext);

  const closePreview = ()=>{
    props.closePreview()
  }
  return (
    <ModalCustom
      darkMode={theme === "dark"}
      onClick={closePreview}
      onClose={closePreview}
      modal_msg={props.preview}
      btn_1_class={
        theme === "dark"
          ? "btn btn-dark-mode-purple"
          : "btn btn-light-mode-purple"
      }
      modalSize={props.modalSize ? props.modalSize : ""}
      noHeader={true}
    />
  );
};
export default Preview;
