import { useParams } from "react-router";
import BBInput from "./FormComponents/BBInput";
import BBTextArea from "./FormComponents/BBTextArea";
import BBDropdown from "./FormComponents/BBDropdown";
import Buttons from "./FormComponents/Buttons";
import CompareTable from "./FormComponents/CompareTable";
import "./BBForm.css";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import BBPills from "./FormComponents/BBPills";
import { Tooltip } from "bootstrap";
import { ThemeContext } from "../../../ThemeContext";
import { formatDate } from "../../FormatDate/DateFormat";
import StoreContext from "../../../store/storecontext";
import ModalCustom from "../../Modal/ModalCustom";
import BB_Validations from "../../../validations/BB_Validations";
import BBPreview from "./FormComponents/BBPreview/BBPreview";
import CommentHistory from "../Components/CommentHistory/CommentHistory";
import Preview from "../Components/Preview/Preview";
import { copyToClipboard } from "../../CopyToClipboard/CopyToClipboard";
import Synonyms from "../../Synonyms/Synonyms.jsx";
import { triggerToast } from "../../Toast/TriggerToast";
import ToastWithClose from "../../Toast/ToastWithClose";
import Spinner from "../../Spinner/Spinner.jsx";
import NotFoundRequest from "../../PageNotFound/NotFoundRequest.jsx";
import DescriptionModal from "./FormComponents/GenAIDescription/DescriptionModal.jsx"
const BBForm = () => {
  let { id } = useParams();
  const [idFromUrl, setIdfromUrl] = useState(id);
  const [isFormAlreadyLoaded, setIsFormAlreadyLoaded] = useState(false);
  const isNotNew = isFormAlreadyLoaded && parseInt(idFromUrl) > 0;
  const [owner, setOwner] = useState(localStorage.getItem("searchtools.eid"));
  const autoFillMessage = `This form has been prepopulated based on an existing Best Bet,
   please perform updates or request its removal below. To create a new Best Bet request, click Search Tools.`;

  const cfg = JSON.parse(sessionStorage.getItem("searchtools.config"))[0][
    "bestbet"
  ];
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [aidOptions, setAidOptions] = useState([]);
  const [theme] = useContext(ThemeContext);
  const store = useContext(StoreContext);
  const [lastBestBetRequest, setlastBestBetRequest] = useState({
    lastRequestId: "",
    lastRequestedBy: ""
  });
  const [lastGeneratedDescription, setLastGeneratedDescription] = useState("");
  if (!store.state.hasOwnProperty("requestToEditBB")) {
    store.state.requestToEditBB = {
      canEdit: false,
      bbid: -1,
      contentSearcher: false,
      itsFromManageMyRequest: false
    };
  }
  const emptyForm = {
    bbid: undefined,
    requestId: 0,
    url: "",
    title: "",
    description: "",
    commentBox: "",
    comments: [],
    countries: [],
    refinerPages: [],
    acronyms: [],
    keywords: [],
    keywordsforreview: [],
    cleancreationdate: undefined,
    lastmodifieddateUTC: undefined,
    lastmodifiedby: [],
    requestedby: undefined,
    cleanstatus: undefined,
    cleanemployeenumber: undefined,
    cleanrequesttype: "Creation",
    isDraftMode: false,
    yammerthreadid: null,
    qualityscore: 0
  };
  const [form, setForm] = useState(emptyForm);
  const [isFormReadOnly, setIsFormReadOnly] = useState(false);
  
  const [originalReqData, setOriginalReqData] = useState({
    bbData: null,
    bbReqData: null
  });
  
  const [greenTakenKeywords, setGreenTakenKeywords] = useState([]);

  //MODALS
  const [noLocationsAndPagesModal, setnoLocationsAndPagesModal] =
    useState(false);
  const [repeatedKwModal, setRepeatedKwModal] = useState(false);
  const [keywordWarningModal, setKeywordWarningModal] = useState({
    show: false,
    msg: ""
  });
  const [keywordLimitModal, setKeywordLimitModal] = useState(false);
  const [autofilled, setAutofilled] = useState(false);
  const [formImplementedModal, setFormImplementedModal] = useState({
    show: false,
    msg: "",
    onClose: () => {}
  });
  const [showGenIADescModal, setShowGenIADescModal] = useState(false);

  const [validatedFields, setValidatedFields] = useState({
    //Not striclty validated, just for the color of the fields
    url: false,
    title: false,
    description: false,
    commentBox: true,
    keywords: undefined
  });
  const [notFoundMessage, setNotFoundMessage] = useState(false);

  const setKeywords = (state, action) => {
    switch (action.type) {
      case "reevaluateAll": {
        for (let index = 0; index < action.allKeywords.length; index++) {
          BB_Validations.validateKeyword(
            action.allKeywords[index],
            form.countries,
            form.acronyms
          ).then(({ isKeywordValid, location, refPage }) => {
            const keyword = action.allKeywords[index];
            if (autofilled) {
              if (isKeywordValid) {
                dispatchKeywords({
                  type: "addGreenKeyword",
                  keyword: keyword
                });
              } else if (!isKeywordValid && form.keywords.includes(keyword)) {
                //Keywords that now are taken(red) but for this particular request is green(kw was available when added for the first time)
                setGreenTakenKeywords([...greenTakenKeywords, keyword]);
              } else if (!isKeywordValid) {
                if (!isFormReadOnly) {
                  setKeywordWarningModal({
                    show: true,
                    msg: `Keyword for the current Refiner Page: ${refPage}, and Location: ${location} is repeated in more than 3 Best Bets. Consider changing the keyword to a synonym or related term and try again.`
                  });
                }
                dispatchKeywords({
                  type: "addRedKeyword",
                  keyword: keyword
                });
              }
            } else {
              if (isKeywordValid) {
                dispatchKeywords({
                  type: "addGreenKeyword",
                  keyword: keyword
                });
              } else {
                setKeywordWarningModal({
                  show: true,
                  msg: `Keyword for the current Refiner Page: ${refPage}, and Location: ${location} is repeated in more than 3 Best Bets. Consider changing the keyword to a synonym or related term and try again.`
                });
                dispatchKeywords({
                  type: "addRedKeyword",
                  keyword: keyword
                });
              }
            }
          });
        }
        return { valid: [], invalid: [] };
      }
      case "addGreenKeyword": {
        const noRepeats = new Set(state.valid);
        noRepeats.add(action.keyword);
        return { valid: Array.from(noRepeats), invalid: state.invalid };
      } //Using a Set to avoid adding the same keyword
      case "addRedKeyword": //because of the <React.StrictMode> extra render
        const noRepeats = new Set(state.invalid);
        noRepeats.add(action.keyword);
        return { valid: state.valid, invalid: Array.from(noRepeats) };
      case "removeGreenKeyword": {
        const updatedKeywords = state.valid.filter(
          (formKw) => formKw !== action.keyword
        );
        return { valid: updatedKeywords, invalid: state.invalid };
      }
      case "removeRedKeyword": {
        const updatedKeywords = state.invalid.filter(
          (formKw) => formKw !== action.keyword
        );
        return { valid: state.valid, invalid: updatedKeywords };
      }
      case "removeAllKeywords": {
        return { valid: [], invalid: [] };
      }
    }
  };

  const [keywords, dispatchKeywords] = useReducer(setKeywords, {
    valid: [],
    invalid: []
  });
  const [autoFillWarning, setAutoFillWarning] = useState(false);
  const [urlSuggestions, setURLSuggestions] = useState({
    show: false,
    requests: []
  });
  const suggestionsRef = useRef(null);
  var userInputTimeout;

  const [displayGrayPills, setDisplayGrayPills] = useState(false);
  const [genAISummaryContext,setGenAISummaryContext] = useState("");
  const tooltipClass = theme === "dark" ? "bb-tooltip-dark" : "";
  useEffect(() => {
    if (showToast()) {
      triggerToast(10000, 0, "autoFillMessage");
    }
  }, [autoFillWarning, lastBestBetRequest.lastRequestId]);
  useEffect(() => {
    //This is for the url suggestions box behavior

    // Add event listener to document
    document.addEventListener("mousedown", handleClickOutsideSuggestions);
    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSuggestions);
    };
  }, [suggestionsRef]);

  useEffect(() => {
    const locations = getLocations();
    const refPages = getRefinerPages();
    setLocationOptions(locations);
    setAidOptions(refPages);
    // homeRedirects();
  }, []);

  useEffect(() => {
    //On location or refiner page changes
    const revalidateKeywords = () => {
      const allKeywords = [...keywords.valid, ...keywords.invalid];
      if (allKeywords.length > 0) {
        if (form.countries.length !== 0 && form.acronyms.length !== 0) {
          dispatchKeywords({ type: "reevaluateAll", allKeywords: allKeywords });
        }
      }
    };
    revalidateKeywords();
  }, [form.countries, form.acronyms]);

  useEffect(() => {
    //triggers when using dispatchKeyword metohd
    (async () => {
      await setForm({
        ...form,
        keywords: keywords.valid,
        keywordsforreview: keywords.invalid
      });

      if (keywords.valid.length + keywords.invalid.length !== 0) {
        setKeywordsValidState(
          keywords.valid.length > 0 && keywords.valid.length + keywords
        );
      } else {
        setKeywordsValidState(undefined);
      }
    })();
  }, [keywords]);
  //will handle the change on the url field, but also when the page loads
  const handleUrlChange = async (
    idFromRedirect = null,
    showWarningBanner = null
  ) => {
    if (form.url.trim().length === 0 && idFromRedirect === null) return;

    setFocusOutTrigger(false);

    let request = null;
    let bbRequest = null;
    let currentOriginalData = { ...originalReqData };

    if (idFromRedirect !== null) {
      request = await store.services.bestBetService.GetRequestById(
        idFromRedirect
      );
    } else if (isUrlClean()) {
      request = await store.services.bestBetService.GetBestBetExact(form.url);
    }
    let totalHits = request.data.hits.total.value;
    if (totalHits === 0 && idFromRedirect !== null) {
      setLoadingDataTrigger(false);
      setNotFoundMessage(true);
    } else {
      let fromRequestIndex = {};
      if (request !== null) {
        const latestRequests = request.data.hits.hits;
        let lastPos = latestRequests.length - 1;
        lastPos = lastPos < 0 ? 0 : lastPos;

        let latestReq = null;

        try {
          latestReq = { ...latestRequests[lastPos]._source };
        } catch (error) {
          console.log("error on getting latest request from url");
        }

        if (latestReq === null) return;

        currentOriginalData.bbReqData = { ...latestRequests[lastPos]._source };

        let isLoadImplementedFromUrl = false;
        if (latestRequests[lastPos]._index.includes("bbsearch")) {
          fromRequestIndex =
            await store.services.bestBetService.GetRequestByURL(
              currentOriginalData.bbReqData.cleanurl
            );

          if (fromRequestIndex.data.hits.hits.length > 0) {
            latestReq.comments =
              fromRequestIndex.data.hits.hits[0]._source.comments;

            latestReq.cleanstatus =
              fromRequestIndex.data.hits.hits[0]._source.cleanstatus;

            latestReq.cleanrequesttype =
              fromRequestIndex.data.hits.hits[0]._source.cleanrequesttype;

            latestReq.cleanemployeenumber =
              fromRequestIndex.data.hits.hits[0]._source.cleanemployeenumber;

            latestReq.bbrequestid =
              fromRequestIndex.data.hits.hits[0]._source.bbrequestid;

            isLoadImplementedFromUrl = true;
          }
        }

        let bbid = latestReq.bbid;
        if (parseInt(bbid) > 0) {
          bbRequest = await store.services.bestBetService.GetBestBetByID(bbid);

          currentOriginalData.bbData = {
            ...bbRequest.data.hits.hits[0]._source
          };
        }

        let isFromUrlField = form.url === latestReq.cleanurl;

        let isIdFromRedirect = idFromUrl === latestRequests[lastPos]._id;
        if (isFromUrlField) {
          // store data for comparison and updates
          await setOriginalReqData(currentOriginalData);

          await autofillForm(
            latestReq,
            showWarningBanner,
            isLoadImplementedFromUrl
          );

          if (showWarningBanner);
          setAutoFillWarning(true);
          await setURLSuggestions({ show: false, requests: [] });
          await setTitleValidState(true);
          await setDescriptionValidState(true);
          await setUrlValidState(true);
        } else if (isIdFromRedirect) {
          // store data for comparison and updates
          await setOriginalReqData(currentOriginalData);

          await autofillForm(latestReq, showWarningBanner);
          if (showWarningBanner) setAutoFillWarning(true);
          await setURLSuggestions({ show: false, requests: [] });
          await setTitleValidState(true);
          await setDescriptionValidState(true);
          const regex = store.services.urlCheckerService.urlRegex;
            if (!latestReq.cleanurl.match(regex)) {
              setUrlValidState(false);     
            } else {
              setUrlValidState(true);
            };
        }
      }
    }
  };
  useEffect(() => {
    //triggers when using dispatchKeyword metohd
    (async () => {
      //CHANGE TO useCallback
      await setForm({
        ...form,
        keywords: keywords.valid,
        keywordsforreview: keywords.invalid
      });
    })();

    if (keywords.valid.length + keywords.invalid.length !== 0) {
      setKeywordsValidState(
        keywords.valid.length > 0 && keywords.valid.length + keywords
      );
    } else {
      setKeywordsValidState(undefined);
    }
  }, [keywords]);

  const [focusOutTrigger, setFocusOutTrigger] = useState(false);
  useEffect(() => {
    userInputTimeout = setTimeout(async () => {
      if (focusOutTrigger) {
        setFocusOutTrigger(false);
        await showSuggestions();
      }
      if (isUrlClean()) {
        await showSuggestions();
      }
    }, 650);

    return () => {
      clearTimeout(userInputTimeout);
    };
  }, [form.url]);

  // load request when user focus out of url field
  useEffect(() => {
    if (focusOutTrigger) {
      handleUrlChange(null, true).then(() => {});
    }
  }, [focusOutTrigger]);

  //METHODS
  const handleClickOutsideSuggestions = (event) => {
    // Check if click occurred outside of element
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setURLSuggestions({ show: false, requests: [] });
    }
  };

  const getLocations = () => {
    let countryOptions = cfg["countries"].map((country) => country);
    return countryOptions;
  };

  const getRefinerPages = () => {
    let refinerPageOptions = cfg["application"].map((page) => page);
    return refinerPageOptions;
  };

  const addLocation = async (location, countries = null) => {
    if (location === "Global") {
      setForm((prevForm) => ({ ...prevForm, countries: ["Global"] }));
    } else if (countries !== null && form.countries.length <= 0) {
      const currentLocations = countries;
      setForm((prevForm) => ({
        ...prevForm,
        countries: [...currentLocations]
      }));
    } else {
      const currentLocations = form.countries;
      if (!currentLocations.includes(location)) {
        setForm((prevForm) => ({
          ...prevForm,
          countries: [...currentLocations, location]
        }));
      }
    }
    document.getElementById("location-id").selectedIndex = 0;
  };

  const addRefinerPage = async (page) => {
    const currentPages = [...form.refinerPages];
    const currentAcronyms = [...form.acronyms];
    let acronym = page;

    if (!currentPages.includes(page) && !currentAcronyms.includes(acronym)) {
      setForm((prevForm) => ({
        ...prevForm,
        refinerPages: [...currentPages, page],
        acronyms: [...currentAcronyms, acronym]
      }));
    }
    document.getElementById("refiner-page-id").selectedIndex = 0;
  };
  const storeTitle = async (title) => {
    setForm((prevForm) => ({ ...prevForm, title: title }));
  };
  const storeUrl = async (url) => {
    setForm({ ...form, url: url });
  };
  const storeDescription = async (description) => {
    setForm((prevForm) => ({ ...prevForm, description: description }));
  };
  const storeComment = (comment) => {
    setForm((prevForm) => ({ ...prevForm, commentBox: comment }));
  };
  const removeLocation = async (location) => {
    const updatedLocations = form.countries.filter(
      (country) => country !== location
    );
    setForm((prevForm) => ({ ...prevForm, countries: updatedLocations }));
  };
  const removeRefinerPage = (pageToRemove) => {
    const updatedPages = form.refinerPages.filter(
      (page) => page !== pageToRemove
    );
    const updatedAcronyms = form.acronyms.filter(
      (acronym) => acronym !== pageToRemove
    );
    setForm((prevForm) => ({
      ...prevForm,
      refinerPages: updatedPages,
      acronyms: updatedAcronyms
    }));
  };

  const addKeyword = async (keywordType, keyword) => {
    if (form.keywords.length + form.keywordsforreview.length < 20) {
      if (form.countries.length !== 0 && form.acronyms.length !== 0) {
        if (keywordType == "green") {
          //add to green keywords
          dispatchKeywords({ type: "addGreenKeyword", keyword: keyword });
        } else {
          //add to red keywords
          dispatchKeywords({ type: "addRedKeyword", keyword: keyword });
        }
        return true;
      } else {
        setnoLocationsAndPagesModal(true);
      }
    } else {
      setKeywordLimitModal(true);
      return false;
    }
  };

  const setUrlValidState = (state) => {
    setValidatedFields((prevState) => ({ ...prevState, url: state }));
  };
  const setTitleValidState = (state) => {
    setValidatedFields((prevState) => ({ ...prevState, title: state }));
  };
  const setDescriptionValidState = (state) => {
    setValidatedFields((prevState) => ({ ...prevState, description: state }));
  };
  const setKeywordsValidState = (state) => {
    setValidatedFields((prevState) => ({ ...prevState, keywords: state }));
  };
  const setCommentsValidState = (state) => {
    setValidatedFields((prevState) => ({ ...prevState, commentBox: state }));
  };

  const removeKeyword = (keyword) => {
    dispatchKeywords({ type: "removeGreenKeyword", keyword: keyword });

    setKeywordsValidState(
      keywords.invalid.length == 0 ||
        (keywords.invalid.length > 0 && keywords.valid.length - 1 > 0)
    );
  };
  const removeKeywordForReview = (keyword) => {
    dispatchKeywords({ type: "removeRedKeyword", keyword: keyword });
    setKeywordsValidState(keywords.invalid.length - 1 == 0);
  };

  const disableDraftBtn = () => {
    let spChRegex = store.services.urlCheckerService.textRegex;
    let urlEmpty = form.url.trim().length === 0;
    let titleEmpty = form.title.trim().length === 0;
    let descriptionEmpty = form.description.trim().length === 0;
    let commentBoxEmpty = form.commentBox.trim().length === 0;
    let countriesEmpty = form.countries.length === 0;
    let acronymsEmpty = form.acronyms.length === 0;
    let keywordsEmpty = keywords.valid.length === 0;
    const formIsEmpty =
      urlEmpty &&
      titleEmpty &&
      descriptionEmpty &&
      commentBoxEmpty &&
      countriesEmpty &&
      acronymsEmpty &&
      keywordsEmpty;
    const InputsValidForDraft =
      form.url.match(spChRegex) === null &&
      form.title.match(spChRegex) === null &&
      form.description.match(spChRegex) === null &&
      form.commentBox.match(spChRegex) === null;
    if (formIsEmpty || !InputsValidForDraft) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (form.cleanstatus === "Draft") {
      debugger;
      const regex = store.services.urlCheckerService.urlRegex;
      if (!form.url.match(regex)) {
        setIsValidUrl({
          state: false,
          errorMsg: "This is not a valid url format"
        });

      } else {
        setIsValidUrl({ state: true, errorMsg: "" });
      }
    }
  }, [form, store.services.urlCheckerService.urlRegex]);

  const disabledButtons = () => {
    return (
      !(
        validatedFields.title &&
        validatedFields.url &&
        validatedFields.description
      ) ||
      form.countries.length == 0 ||
      form.acronyms.length == 0 ||
      keywords.valid.length == 0 ||
      (form.commentBox && !validatedFields.commentBox)
    );
  };

  const showSuggestions = async () => {
    const request = await store.services.bestBetService.GetBbSuggestions(
      decodeURIComponent(form.url)
    );

    const suggestions = request.data.hits.hits;
    setURLSuggestions({ show: true, requests: suggestions });
  };

  const selectSuggestion = async (event) => {
    await setAutofilled(true);
    event.preventDefault();
    const id = event.target.attributes.id.value;
    const request = urlSuggestions.requests.find(
      (req) => req._source.bbid == id
    );

    await storeUrl(request._source.cleanurl);
    await setFocusOutTrigger(true);
    setURLSuggestions({ show: false, requests: [] });
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const instantiateTooltips = () => {
    // tootlips get unparented when changing
    //  from curated content to the page with the form data

    try {
      deleteOldTooltips();
    } catch (error) {
      console.log("no old tooltips");
    }

    //Initialize tooltips when the data is finish loading
    let tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="bb-tooltip"]')
    );

    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl, {
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  };

  // set form to edit or read only mode
  const setFormMode = (request, isLoadImplementedFromUrl = null) => {
    let isAdmin = store.state.isAdmin;
    let currentUserPk = localStorage.getItem("searchtools.pk");
    let isOwner =
      parseInt(request.cleanemployeenumber) === parseInt(currentUserPk);
    let itsFromManageMyRequest =
      store.state.requestToEditBB.itsFromManageMyRequest;

    if (
      request.cleanstatus === "Cancelled" ||
      request.cleanstatus === "Not Approved"
    ) {
      if (parseInt(idFromUrl) <= 0) return;
      setDisplayGrayPills(true);
      setIsFormReadOnly(true);
    } else if (request.cleanstatus === "Implemented") {
      let readMode = false;
      if (isLoadImplementedFromUrl) {
        readMode = true;
      } else {
        readMode =
          itsFromManageMyRequest && store.state.requestToEditBB.canEdit;
      }
      setIsFormReadOnly(!readMode && !autofilled);
    } else if (request.cleanstatus === "New") {
      let readMode = isOwner || isAdmin;
      setIsFormReadOnly(!readMode);
    }
  };
  const autofillForm = async (
    request,
    itsFromManageMyRequest = null,
    isLoadImplementedFromUrl = null
  ) => {
    // const regex = store.services.urlCheckerService.urlRegex;
    // if (!form.url.match(regex)) {
    //   setIsValidUrl({
    //     state: false,
    //     errorMsg: "This is not a valid url format"
    //   });

    // } else {
    //   setIsValidUrl({ state: true, errorMsg: "" });
    // }
    if (isFormAlreadyLoaded && !itsFromManageMyRequest) return;

    setFormMode(request, isLoadImplementedFromUrl);
    setAutofilled(true);
    const owner =
      request.requestedby || localStorage.getItem("searchtools.eid");
    await setOwner((prev) => owner);
    //close suggestion box if opened
    await setURLSuggestions({ show: false, requests: [] });

    if (itsFromManageMyRequest) {
      await getMostRecentBestBetRequest(request.bbid);
    }
    //Fill inputs
    await dispatchKeywords({ type: "removeAllKeywords" });
    await storeUrl(request.cleanurl);
    await storeTitle(request.cleantitle);
    await storeDescription(request.cleandescription);
    await setForm((prevForm) => ({
      ...prevForm,
      countries: [...request.cleancountry]
    }));
    await setForm((prevForm) => ({ ...prevForm, acronyms: [...request.aid] }));

    await setForm((prevForm) => ({ ...prevForm, aid: [...request.aid] }));

    if (request.cleankeywordsraw) {
      await request.cleankeywordsraw.forEach((keyword) =>
        dispatchKeywords({ type: "addGreenKeyword", keyword: keyword })
      );
    } else {
      await request.cleankeywords.forEach((keyword) =>
        dispatchKeywords({ type: "addGreenKeyword", keyword: keyword })
      );
    }
    if (request.cleankeywordsforreviewraw) {
      await request.cleankeywordsforreviewraw.forEach((keyword) =>
        dispatchKeywords({ type: "addRedKeyword", keyword: keyword })
      );
    } else {
      if (request.cleankeywordsforreview) {
        await request.cleankeywordsforreview.forEach((keyword) =>
          dispatchKeywords({ type: "addRedKeyword", keyword: keyword })
        );
      }
    }
    //Set the rest of the form data
    await setForm((prevForm) => ({
      ...prevForm,
      bbid: request.bbid,
      yammerthreadid: request.yammerthreadid
    }));
    await setForm((prevForm) => ({
      ...prevForm,
      cleanstatus: request.cleanstatus
    }));
    let currReqType = "";

    if (idFromUrl) {
      currReqType = request.cleanrequesttype;
    } else {
      currReqType = "Update";
    }
    await setForm((prevForm) => ({
      ...prevForm,
      cleanrequesttype: currReqType
    }));
    await setForm((prevForm) => ({
      ...prevForm,
      requestId: request.bbrequestid
    }));
    await setForm((prevForm) => ({
      ...prevForm,
      requestedby: request.requestedby
    }));
    await setForm((prevForm) => ({
      ...prevForm,
      comments: request.comments
    }));
    await setForm((prevForm) => ({
      ...prevForm,
      lastmodifiedby: request.lastmodifiedby ? [...request.lastmodifiedby] : ""
    }));

    await setForm((prevForm) => ({
      ...prevForm,
      cleanemployeenumber: request.cleanemployeenumber
    }));

    await setForm((prevForm) => ({
      ...prevForm,
      lastmodifieddateUTC: request.lastmodifieddateUTC
    }));

    await setForm((prevForm) => ({
      ...prevForm,
      cleancreationdate: request.cleancreationdate
    }));

    setIsFormAlreadyLoaded(true);
    setLoadingDataTrigger(false);

    instantiateTooltips();
    let toggle = loadFromRedirectTrigger;
    await setLoadFromRedirectTrigger(!toggle);
  };

  const suggestionKeydown = (event) => {
    if (event.key == "Enter" || event.key == " ") {
      selectSuggestion(event);
    }
  };

  //Closing modals
  const closeKeywordWarningModal = () => {
    setKeywordWarningModal(false);
  };
  const closeNoLocationsAndPagesModal = () => {
    setnoLocationsAndPagesModal(false);
  };
  const closeRepeatedKeywordModal = () => {
    setRepeatedKwModal(false);
  };
  const closeLimitKeywordModal = () => {
    setKeywordLimitModal(false);
  };
  const closeFormImplementedModal = () => {
    setFormImplementedModal({ ...formImplementedModal, show: false }); //GO TO /myrequests;
    formImplementedModal.onClose();
  };
  //dynamic classes
  const validStateClass = () => {
    return disabledButtons() ? "disabled" : "enabled";
  };
  const isLocationValidClass = () => {
    if (form.countries.length == 0) {
      return;
    } else return "is-valid";
  };

  const isRefPageValidClass = () => {
    if (form.acronyms.length == 0) {
      return;
    } else return "is-valid";
  };

  const [compareTableTrigger, setCompareTableTrigger] = useState(false);
  const [savingRequestDataTrigger, setSavingRequestDataTrigger] =
    useState(false);
  const [loadingDataTrigger, setLoadingDataTrigger] = useState(false);
  const [showPreviewTrigger, setShowPreviewTrigger] = useState(false);

  const closeComparisonTable = () => {
    setCompareTableTrigger(false);
  };

  const showCompareTable = () => {
    setCompareTableTrigger(true);
  };

  const showToast = () => {
    return (
      autoFillWarning &&
      form.cleanstatus !== "New" &&
      form.cleanstatus !== "Draft"
    );
  };

  const closeBbPreview = () => {
    setShowPreviewTrigger(false);
  };

  const showPreview = () => {
    setShowPreviewTrigger(true);
  };

  const showSpinner = () => {
    setSavingRequestDataTrigger(true);
  };

  const hideSpinner = () => {
    setSavingRequestDataTrigger(false);
  };

  const [loadFromRedirectTrigger, setLoadFromRedirectTrigger] = useState(false);

  const loadBestBetToEdit = async (bbid, contentSearcher) => {
    let bestBetQuery = null;
    let bbData = null;
    let lastRequest = null;
    let bbReqQuery = null;
    let bbReqData = {};

    setLoadingDataTrigger(true);

    if (contentSearcher) {
      bestBetQuery = await store.services.bestBetService.GetBestBetByID(bbid);

      if (bestBetQuery.data.hits.hits.length > 1) {
        bbData = bestBetQuery.data.hits.hits.pop();
        bbData = bbData._source;
      } else {
        bbData = bestBetQuery.data.hits.hits[0]._source;
      }
      if (bestBetQuery.data.hits.hits.length > 1) {
        lastRequest = bestBetQuery.data.hits.hits.pop();
        bbReqData = lastRequest._source;
      } else {
        bbReqData = bestBetQuery.data.hits.hits[0]._source;
      }
    } else {
      bbReqQuery = await store.services.bestBetService.GetRequestById(bbid);

      if (bbReqQuery.data.hits.hits.length > 1) {
        lastRequest = bbReqQuery.data.hits.hits.pop();
        bbReqData = lastRequest._source;
      } else {
        bbReqData = bbReqQuery.data.hits.hits[0]._source;
      }

      if (
        bbReqData.cleanstatus === "Implemented" ||
        bbReqData.bbrequestid > 0 ||
        bbReqData.bbid > 0
      ) {
        bestBetQuery = await store.services.bestBetService.GetBestBetByID(
          bbReqData.bbid
        );

        if (bestBetQuery.data.hits.hits.length > 1) {
          bbData = bestBetQuery.data.hits.hits.pop();
          bbData = bbData._source;
        } else {
          try {
            bbData = bestBetQuery.data.hits.hits[0]._source;
          } catch (error) {
            bbData = {};
            setLoadingDataTrigger(false);
          }
        }
      }
    }

    // store data for comparison and updates
    let currentOriginalData = { ...originalReqData };
    currentOriginalData.bbData = bbData;
    currentOriginalData.bbReqData = bbReqData;
    await setOriginalReqData(currentOriginalData);
    await autofillForm(bbReqData, true, true);
    setAutoFillWarning(true);
    await setURLSuggestions({ show: false, requests: [] });
    await setTitleValidState(true);
    await setDescriptionValidState(true);
    await setUrlValidState(true);
  };

  const isUrlClean = () => {
    return !form.url.includes('"') && !form.url.includes("\\");
  };

  // use effect for when the user loads request via browser url, not form url
  useEffect(() => {
    if (parseInt(idFromUrl) > 0) {
      setLoadingDataTrigger(true);
      handleUrlChange(idFromUrl, false).then(() => {});
    }
  }, [idFromUrl]);

  // useEffect for when the user requests and update from manage my requests
  useEffect(() => {
    if (store.state.requestToEditBB.canEdit) {
      let isContentSearcher = store.state.requestToEditBB.contentSearcher;
      loadBestBetToEdit(store.state.requestToEditBB.bbid, isContentSearcher);
    }
  }, []);

  const getFieldGap = (field) => {
    let fieldGap = "";
    if (field === "keywords") {
      fieldGap = "keywords-field-gap";
    } else if (field === "refiner-page") {
      fieldGap = "refiner-page-gap";
    } else if (field === "comments") {
      fieldGap = "comments-field-gap";
    }
    return fieldGap;
  };

  const createNewTooltip = (event) => {
    deleteOldTooltips();
    new Tooltip(event.target, {
      trigger: "hover focus",
      customClass: tooltipClass
    });
  };

  // delete unparented tooltips
  // when the user comes from review curated content or
  // content searcher
  useEffect(() => {
    deleteOldTooltips();
  }, []);

  const getMostRecentBestBetRequest = async (bbid) => {
    let lastRequestId;
    let lastUpdatedBy;
    try {
      let lastBestBetRequest =
        await store.services.bestBetService.getMostRecentBestBet(bbid);
      lastBestBetRequest = lastBestBetRequest.data.hits.hits;
      lastRequestId = lastBestBetRequest[0]._source.bbrequestid;
      lastUpdatedBy = lastBestBetRequest[0]._source.requestedby || "";
    } catch (error) {
      lastRequestId = "";
      lastUpdatedBy = "";
    } finally {
      setlastBestBetRequest({
        lastRequestId: lastRequestId,
        lastRequestedBy: lastUpdatedBy
      });
    }
  };

  return (
    <div>
      {notFoundMessage ? (
        <NotFoundRequest />
      ) : (
        <>
          {" "}
          {loadingDataTrigger ? (
            <div className="d-flex justify-content-center loading-spinner-main-div">
              <div
                className="spinner-border text-light-mode-purple"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <form
              className={`form-bb ${validStateClass()}`}
              role="form"
              noValidate
            >
              {compareTableTrigger ? (
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={closeComparisonTable}
                  onClose={closeComparisonTable}
                  modal_msg={
                    <CompareTable
                      originalReqData={originalReqData}
                      currentData={form}
                      closeComparisonTable={closeComparisonTable}
                    />
                  }
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize={"modal-xl"}
                  noHeader={true}
                />
              ) : (
                <></>
              )}
              {savingRequestDataTrigger ? (
                <Spinner />
              ) : showToast() ? (
                <>
                  <ToastWithClose
                    text={autoFillMessage}
                    toastKey="autoFillMessage"
                  />
                  <div
                    id="request-info-div"
                    className="request-info-parent-div"
                  >
                    <div className="request-data">
                      <div className="update-info">
                        <div>
                          Best Bet Request ID{" "}
                          <strong>{lastBestBetRequest.lastRequestId}</strong>
                        </div>
                        <div>
                          Last updated by:{" "}
                          <strong>{lastBestBetRequest.lastRequestedBy}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : isNotNew ? (
                <div
                  id="request-info-div-update"
                  className="request-info-parent-div"
                >
                  <div className="request-info-title-div">
                    Best Bet Request ID {form.requestId}
                  </div>
                  <div className="request-info-child-div">
                    <div className="request-info-block">
                      Request type:
                      <p> {form.cleanrequesttype}</p>
                    </div>
                    <div className="request-info-block">
                      Status: <p>{form.cleanstatus}</p>
                    </div>
                    <div className="request-info-block">
                      Submit time: <p>{formatDate(form.cleancreationdate)}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {showPreviewTrigger && (
                <Preview
                  preview={
                    <BBPreview
                      title={form.title}
                      url={form.url}
                      description={form.description}
                    />
                  }
                  closePreview={closeBbPreview}
                />
              )}

              <BBInput
                label="URL"
                placeholder="Enter valid URL"
                aria="URL Info. Enter a valid URL, for example, https://SampleBestBetURL.com, to promote specific site content as a Best Bet within Enterprise Search."
                required={true}
                tooltip="Enter a valid URL (e.g., https://SampleBestBetURL.com) to promote specific site content as a Best Bet within Enterprise Search."
                field="url"
                inputState={{
                  input: form.url,
                  storeInput: storeUrl,
                  isValid: validatedFields.url,
                  setValid: setUrlValidState
                }}
                suggestions={{
                  show: urlSuggestions.show,
                  closeSuggestions: () =>
                    setURLSuggestions({ show: false, requests: [] })
                }}
                handleFocusOut={() => {
                  setFocusOutTrigger(true);
                  storeUrl(form.url);
                }}
                disableFieldProp={isFormReadOnly}
                createTooltip={createNewTooltip}
                genIADescription={{
                  openDescriptionModal: () => setShowGenIADescModal(true),
                  storeGenDescription: setLastGeneratedDescription,
                  storeSiteData: setGenAISummaryContext
                }}
                />
              {urlSuggestions.show && !parseInt(idFromUrl) > 0 ? (
                <ul id="suggestions" className="" ref={suggestionsRef}>
                  {urlSuggestions.requests.map((bb) => {
                    return (
                      <li
                        role="presentation"
                        className="suggestion"
                        key={bb._source.bbid}
                        tabIndex={-1}
                      >
                        <a
                          value={bb._source.cleanurl}
                          role="menuitem"
                          key={bb._source.bbid}
                          id={bb._source.bbid}
                          onClick={(e) => selectSuggestion(e)}
                          onKeyDown={suggestionKeydown}
                          href="/#"
                          target="_self"
                          className="dropdown-item"
                        >
                          {bb._source.cleanurl}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <></>
              )}
              <BBInput
                label="Title"
                placeholder="Enter site title associated with specified URL"
                aria="Title Info. For better Search results relevancy for this requested Best Bet, enter the site title as it appears when navigating to the entered URL"
                required={true}
                tooltip="For better Search results relevancy for this requested Best Bet, enter the site title as it appears when navigating to the entered URL."
                field="title"
                inputState={{
                  input: form.title,
                  storeInput: storeTitle,
                  isValid: validatedFields.title,
                  setValid: setTitleValidState
                }}
                disableFieldProp={isFormReadOnly}
                createTooltip={createNewTooltip}
              />
              <BBTextArea
                label="Description"
                placeholder="Enter a brief description of site content that will attract users to click this Best Bet."
                aria="Description input. Enter a brief description of site content that will attract users to click this Best Bet."
                tooltip={`The description must be ${process.env.REACT_APP_BB_DESCRIPTION_LIMIT} characters or less.`}
                required={true}
                field="description"
                inputState={{
                  input: form.description,
                  storeInput: storeDescription,
                  isValid: validatedFields.description,
                  setValid: setDescriptionValidState
                }}
                disableFieldProp={isFormReadOnly}
                createTooltip={createNewTooltip}
              />
              <BBDropdown
                label="Location"
                elementId={"location-id"}
                options={locationOptions}
                addSelection={addLocation}
                placeholder="Select target location(s) for Best Bet"
                aria="Select a target location(s) for Best Bet"
                tooltip="Best Bet can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations."
                required={true}
                globalIsPresent={form.countries.includes("Global")}
                field="location"
                validClass={isLocationValidClass()}
                disableFieldProp={isFormReadOnly}
                createTooltip={createNewTooltip}
              />
              {compareTableTrigger ? (
                <></>
              ) : (
                <BBPills
                  greenPills={form.countries}
                  removeGreen={removeLocation}
                  group="location"
                  disableFieldProp={isFormReadOnly}
                  displayGrayPills={displayGrayPills}
                  canEditForm={store.state.requestToEditBB.canEdit}
                  formStatus={form.cleanstatus}
                  formReqType={form.cleanrequesttype}
                />
              )}
              <BBDropdown
                label="Refiner page"
                elementId={"refiner-page-id"}
                options={aidOptions}
                addSelection={addRefinerPage}
                placeholder="Select Search Refiner page where to display Best Bet"
                aria="Refiner page dropdown. Press Enter to select Search page (refiner page) to display your Best Bet"
                tooltip="One or many Search pages (refiner pages) can be selected to display the Best Bet based on the site content and/or where the content resides (e.g., KX Documents, myLearning)."
                required={true}
                field="refiner-page"
                validClass={isRefPageValidClass()}
                disableFieldProp={isFormReadOnly}
                cssGapClass={getFieldGap("refiner-page")}
                createTooltip={createNewTooltip}
              />
              {compareTableTrigger ? (
                <></>
              ) : (
                <BBPills
                  greenPills={form.acronyms}
                  removeGreen={removeRefinerPage}
                  group="refiner-page"
                  disableFieldProp={isFormReadOnly}
                  displayGrayPills={displayGrayPills}
                  canEditForm={store.state.requestToEditBB.canEdit}
                  formStatus={form.cleanstatus}
                  formReqType={form.cleanrequesttype}
                />
              )}
              <BBInput
                label="Keywords"
                placeholder="Enter at least 1 related keyword (maximum of 20)"
                aria="Keyword input. Enter at least 1 related keyword (maximum of 20) and press Enter."
                required={true}
                tooltip='Keyword recommendations:&nbsp;
            &nbsp;&bull;&nbsp;&nbsp;At least 1 related keyword based on your site content is required; Maximum of 20 keywords can be entered.
            &nbsp;&bull;&nbsp;&nbsp;Avoid generic terms that can be associated with various site content within Accenture, such as "Accenture", "skills", "process", or "analysis"
            &nbsp;&bull;&nbsp;&nbsp;Avoid single keywords unless it is highly specific, such as an acronym
            &nbsp;&bull;&nbsp;&nbsp;Avoid keywords that have more than three words as it is very unlikely to be entered as a search term'
                keywords={true}
                classname="bb-keywords"
                field="keywords"
                inputState={{
                  storeInput: addKeyword,
                  isValid: validatedFields.keywords,
                  setValid: setKeywordsValidState,
                  form: form,
                  bbService: store.services.bestBetService,
                  setKeywordWarningModal: setKeywordWarningModal
                }}
                compareTableTrigger={compareTableTrigger}
                greenKeywords={form.keywords}
                redKeywords={form.keywordsforreview}
                showRepeatedKeywordModal={() => setRepeatedKwModal(true)}
                greenTakenKeywords={greenTakenKeywords}
                disableFieldProp={isFormReadOnly}
                cssGapClass={getFieldGap("keywords")}
                createTooltip={createNewTooltip}
              />
              {compareTableTrigger ? (
                <></>
              ) : (
                <BBPills
                  greenPills={form.keywords}
                  redPills={form.keywordsforreview}
                  removeGreen={removeKeyword}
                  removeRed={removeKeywordForReview}
                  setValid={setKeywordsValidState}
                  redPillsTooltip={tooltipClass}
                  onCopyToClipBoard={copyToClipboard}
                  group="keyword"
                  disableFieldProp={isFormReadOnly}
                  displayGrayPills={displayGrayPills}
                  canEditForm={store.state.requestToEditBB.canEdit}
                  formStatus={form.cleanstatus}
                  formReqType={form.cleanrequesttype}
                />
              )}
              <Synonyms
                keywords={form.keywords}
                keywordsForReview={form.keywordsforreview}
                isFormReadOnly={isFormReadOnly}
                countries={form.countries}
                acronyms={form.acronyms}
                service={store.services.bestBetService}
                addKeyword={(value) => addKeyword("green", value)}
                Validations={BB_Validations}
                formName={"bestbet"}
              />
              {noLocationsAndPagesModal ? (
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={closeNoLocationsAndPagesModal}
                  onClose={closeNoLocationsAndPagesModal}
                  modal_msg="Please select Location and Refiner Page first"
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize=""
                  noHeader={true}
                />
              ) : (
                <></>
              )}
              {keywordWarningModal.show ? (
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={closeKeywordWarningModal}
                  onClose={closeKeywordWarningModal}
                  modal_msg={keywordWarningModal.msg}
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize=""
                  noHeader={true}
                />
              ) : (
                <></>
              )}
              {repeatedKwModal ? (
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={closeRepeatedKeywordModal}
                  onClose={closeRepeatedKeywordModal}
                  modal_msg="You have already added this keyword"
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize=""
                  noHeader={true}
                />
              ) : (
                <></>
              )}
              {keywordLimitModal ? (
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={closeLimitKeywordModal}
                  onClose={closeLimitKeywordModal}
                  modal_msg="Only 20 keywords are allowed."
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize=""
                  noHeader={true}
                />
              ) : (
                <></>
              )}

              {!isFormReadOnly ? (
                <BBTextArea
                  label="Comments"
                  placeholder="If needed, provide additional comments/details to be considered as part of this Best Bet request."
                  aria="Comments input. If needed, provide additional comments, details, to be considered as part of this Best Bet request"
                  tooltip="This comment field can also be used to provide additional information requested by the Search CoE team as part of the Best Bet request process."
                  classname="bb-comments"
                  field="comments"
                  inputState={{
                    input: form.commentBox,
                    storeInput: storeComment,
                    isValid: validatedFields.commentBox,
                    setValid: setCommentsValidState
                  }}
                  cssGapClass={getFieldGap("comments")}
                  createTooltip={createNewTooltip}
                />
              ) : (
                <></>
              )}
              {isNotNew ? (
                <>
                  {form.comments?.map((comment, idx) => (
                    <CommentHistory
                      key={idx}
                      user={comment.user}
                      date={comment.dateUTC}
                      comment={comment.comment}
                      commentCSS={"comment"}
                    />
                  ))}
                </>
              ) : (
                <></>
              )}

              {!isFormReadOnly ? (
                <Buttons
                  isAdmin={store.state.isAdmin}
                  classname="buttons-group"
                  form={form}
                  owner={owner}
                  dataFromIndex={originalReqData.bbReqData}
                  disabled={disabledButtons()}
                  disableDraftBtn={disableDraftBtn()}
                  setModalProps={(msg, onClose) => {
                    setFormImplementedModal({
                      show: true,
                      msg: msg,
                      onClose: onClose
                    });
                  }}
                  loadFromRedirectTrigger={loadFromRedirectTrigger}
                  callCompareTable={showCompareTable}
                  openPreview={(e) => showPreview(e)}
                  isFormReadOnlyProp={isFormReadOnly}
                  showSpinner={showSpinner}
                  hideSpinner={hideSpinner}
                  idFromUrl={idFromUrl}
                />
              ) : (
                <></>
              )}
              {formImplementedModal.show ? (
                <ModalCustom
                  darkMode={theme === "dark"}
                  onClick={closeFormImplementedModal}
                  onClose={closeFormImplementedModal}
                  modal_msg={formImplementedModal.msg}
                  btn_1_class={
                    theme === "dark"
                      ? "btn btn-dark-mode-purple"
                      : "btn btn-light-mode-purple"
                  }
                  modalSize=""
                  noHeader={true}
                />
              ) : (
                <></>
              )}
              {
                showGenIADescModal && 
                  <DescriptionModal 
                    closeModal={()=>setShowGenIADescModal(false)} 
                    lastGeneratedDescription={lastGeneratedDescription} 
                    setLastGeneratedDescription={setLastGeneratedDescription}
                    replaceDescription={storeDescription}
                    url={form.url}
                    validateDescription={setDescriptionValidState}
                    context={genAISummaryContext}/>
              }
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default BBForm;
