const EidInput = (props) => {
  const purple_01 = "#a100ff";
  return (
    <div
      id="user-eid-input-section"
      data-testid="testid-user-eid-input-section"
      className={props.ManageRoleCss.input_eid_div}
    >
      <form onSubmit={props.onAddUser} style={{ width: "100%" }}>
        <input
          id="user-eid-input"
          data-testid="testid-user-eid-input"
          type="text"
          className={
            " form-control light-mode-purple " + props.ManageRoleCss.input_eid
          }
          placeholder="Enter a valid Enterprise ID..."
          disabled={props.disableElements}
        />
      </form>
      {props.disableElements ? (
        <div></div>
      ) : (
        <div
          onClick={props.onAddUser}
          className={props.ManageRoleCss.plus_icon}
        >
          <svg
            className={props.ManageRoleCss.input_eid_add_btn}
            viewBox="0 0 16 16"
            width={props.icon_width}
            height={props.icon_height}
            role="img"
            alt="icon"
            id="plus-icon"
            aria-label="Add Users(s)"
            value=""
            xmlns="http://www.w3.org/2000/svg"
            fill={purple_01}
          >
            <g>
              <path
                fillRule="evenodd"
                d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
              ></path>
              <path
                fillRule="evenodd"
                d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
              ></path>
              <path
                fillRule="evenodd"
                d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
              ></path>
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export default EidInput;
