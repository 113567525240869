import { useEffect, useState, useContext } from "react";
import { Outlet } from "react-router-dom";

import services from "./store/services";
import StoreContext from "./store/storecontext";
import ThemeProvider from "./ThemeContext";
import { ThemeContext } from "./ThemeContext";

import "@popperjs/core";
import "./custom.scss";

import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";
import { Tooltip } from "bootstrap";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header"
import Maintenance from "./components/MaintenanceBanner/Maintenance";
import HomeMenu from "./components/HomeMenu/HomeMenu";
import SearchLoading from "./components/SearchLoadingLogo/SearchLoading";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import SessionHandler from "./components/Session/SessionHandler";
import { InteractionType } from "@azure/msal-browser";

const MainApp = () => {
  const store = useContext(StoreContext);
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx[0];
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [homeSuperUser, setHomeSuperUser] = useState(false);
  const [userDataReady, setUserDataReady] = useState(false);
  
  const tooltipClass = theme === "dark" ? "bb-tooltip-dark" : "";

  const arrowIconSize = "1.2em";
  let arrowHomeIconRight = (
    <FaAngleDoubleRight
      className="double-arrow-menu-icon"
      size={arrowIconSize}
    />
  );

  let arrowHomeIconLeft = (
    <FaAngleDoubleLeft
      className="double-arrow-menu-icon arrow-l"
      size={arrowIconSize}
    />
  );

  const [isHomeMenuOpen, setIsHomeMenuOpen] = useState(false);
  const [arrowMenuIcon, setArrowMenuIcon] = useState(arrowHomeIconLeft);
  const [arrowTooltipMessage, setArrowTooltipMessage] = useState("Hide menu");

  const restoreCssClasses = (
    arrayToChange,
    isSingleElement = null,
    classToChange = null,
    isMenuOpen,
    dontAddHide = null
  ) => {
    if (isSingleElement) {
      if (isMenuOpen) {
        arrayToChange.classList.remove(classToChange);
      } else {
        arrayToChange.classList.add(classToChange);
      }
    } else {
      Array.from(arrayToChange).map((el) => {
        if (isMenuOpen) {
          if (dontAddHide === null) {
            el.classList.remove("hide-div");
          }
          if (classToChange !== null) {
            el.classList.remove(classToChange);
          }
        } else {
          if (dontAddHide === null) {
            el.classList.add("hide-div");
          }
          if (classToChange !== null) {
            el.classList.add(classToChange);
          }
        }
      });
    }
  };

  const expandOrContractMenu = (menuState) => {
    let sectionMenuLabels = document.querySelectorAll(".section-title-span");
    let homeMenuButtons = document.querySelectorAll(".home-opt-action-btn");
    let homeMenuLabels = document.querySelectorAll(".home-opt-li-label");
    let toggleButtonsDiv = document.querySelectorAll(".switch-label");
    let toggleButtons = document.querySelectorAll(".toggle-span");

    let homeMenuUl = document.querySelectorAll(".home-menu-ul");
    let homeMenuDiv = document.querySelector(".home-menu-div");
    let outletContainer = document.querySelector(".home-menu-outlet-container");

    restoreCssClasses(homeMenuDiv, true, "contract-home-menu-by", menuState);
    restoreCssClasses(
      outletContainer,
      true,
      "outler-container-resize",
      menuState
    );
    restoreCssClasses(
      outletContainer,
      true,
      "outlet-padding-for-dark-mode",
      menuState
    );
    restoreCssClasses(
      Array.from(homeMenuUl),
      null,
      "home-menu-ul-padding",
      menuState
    );
    restoreCssClasses(
      Array.from(homeMenuButtons),
      null,
      "change-menu-btn-size",
      menuState,
      true
    );
    restoreCssClasses(Array.from(sectionMenuLabels), null, null, menuState);
    restoreCssClasses(
      Array.from(homeMenuLabels),
      null,
      "change-menu-labels-visibility",
      menuState,
      true
    );
    restoreCssClasses(
      Array.from(toggleButtonsDiv),
      null,
      "hide-toggle-btns",
      menuState
    );
    restoreCssClasses(
      Array.from(toggleButtons),
      null,
      "hide-toggle-btns",
      menuState
    );
  };

  const changeArrowAndToolTip = (state) => {
    let iconToSet = state ? arrowHomeIconLeft : arrowHomeIconRight;
    let tooltipMessage = state ? "Hide Menu" : "Show Menu";
    setArrowMenuIcon(iconToSet);
    setArrowTooltipMessage(tooltipMessage);
  };

  const arrowHomeBtnActions = () => {
    try {
      let liElements = document.querySelectorAll(".home-menu-option-li");

      let currState = sessionStorage.getItem("isHomeMenuOpen");
      if (currState) {
        currState === "true"
          ? sessionStorage.setItem("isHomeMenuOpen", false)
          : sessionStorage.setItem("isHomeMenuOpen", true);
      } else {
        sessionStorage.setItem("isHomeMenuOpen", false);
      }

      let updatedState = sessionStorage.getItem("isHomeMenuOpen");

      for (let i = 0; i < liElements.length; i++) {
        let element = liElements[i];
        try {
          if (updatedState && updatedState === "true") {
            element.classList.remove("add-li-width");
          } else if (updatedState && updatedState === "false") {
            element.classList.add("add-li-width");
          }
        } catch {}
      }
    } catch (error) {}
    deleteOldTooltips();
    let currState = sessionStorage.getItem("isHomeMenuOpen");
    let currMenuState = currState === "true" ? true : false;
    setIsHomeMenuOpen(currMenuState);
    changeArrowAndToolTip(currMenuState);
    expandOrContractMenu(currMenuState);
    let searchContentInput = document.querySelector(".change-input-size");
    if (currMenuState) {
      searchContentInput.classList.remove(
        "move-input-when-menu-changes-size-in"
      );
      searchContentInput.classList.add("move-input-when-menu-changes-size-out");
    } else {
      searchContentInput.classList.remove(
        "move-input-when-menu-changes-size-out"
      );
      searchContentInput.classList.add("move-input-when-menu-changes-size-in");
    }
  };

  const deleteOldTooltips = () => {
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const initializeTitleTooltip = (event) => {
    deleteOldTooltips();
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="home-icon-tooltip"]'
    );
    let tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  };

  const toggleUserRoleButton = (state) => {
    //set the button on/off according to user current role
    // this action cannot be performed by the user
    let userElemId = "user-toggler-id";
    let swElement = document.querySelector(`#${userElemId}`);
    swElement.checked = state;
  };

  const getUserRole = async () => {
    store.services.searchToolsService
      .GetStUser()
      .then((r) => {
        let res = r.data / 3;

        if (res === parseInt(res)) {
          store.state.isSuperUser = res >= 0;
          store.state.isAdmin = res === 1;
          checkAppMode(res >= 0);
        } else {
          store.state.isSuperUser = r.data >= 0;
          store.state.isAdmin = r.data === 1;
          checkAppMode(r.data >= 0);
        }

        setHomeSuperUser(store.state.isSuperUser);
        toggleUserRoleButton(store.state.isAdmin);
      })
      .catch(() => {
        console.log("Error in getting user role ");
      });
  };

  const getConfigs = async () => {
    if (!sessionStorage.getItem("searchtools.config")) {
      try {
        const reqData = await store.services.searchToolsService.GetConfigs();
        sessionStorage.setItem(
          "searchtools.config",
          JSON.stringify(reqData.data.hits.hits[0]._source.config)
        );
      } catch (e) {
        console.log("Error in getting configs");
      }
    }
  };

  const getAppData = async () => {
    await getUserRole();
    await getConfigs();
  };

  const checkAppMode = async (isSuperUser) => {
    let r = await store.services.searchToolsService.GetAppMode();
    if (r.data === "under_maintenance") {
      if (!isSuperUser) {
        setMaintenanceMode(true);
      }
    }
  };


  useEffect(() => {
    setHomeSuperUser(store.state.isSuperUser);
  }, [store.state.isSuperUser]);

  // leaves menu open or closed
  // based on the state it was before reloading the page
  useEffect(() => {
    try {
      setTimeout(() => {
        let currMenuState = sessionStorage.getItem("isHomeMenuOpen");
        let searchContentInput = document.querySelector(".change-input-size");
        if (currMenuState === "true") {
          setIsHomeMenuOpen(true);
          changeArrowAndToolTip(true);
          // can be the case where the currMenuState is undefined
          // only check for true and false
        } else if (currMenuState === "false") {
          setIsHomeMenuOpen(true);
          expandOrContractMenu(false);
          changeArrowAndToolTip(false);
          searchContentInput.classList.add(
            "move-input-when-menu-changes-size-in"
          );
        }
      }, 300);
    } catch (error) {}
  }, []);

  
  return (
    <div className={theme + "Mode"}>
       <SessionHandler
        userDataReady={userDataReady}
        setUserDataReady={setUserDataReady} 
        getAppData={getAppData}
        store = {store}
      /> 
    { userDataReady ? 
      maintenanceMode ? (
      <Maintenance />
      ) : (
      <>
        <Header isSuperUser={homeSuperUser} />
        <div className="home-menu-container">
          <HomeMenu isSuperUser={homeSuperUser} />
          <div
            className="double-arrow-menu-div"
            onClick={arrowHomeBtnActions}
            data-bs-toggle={"home-icon-tooltip"}
            data-bs-placement="right"
            data-bs-title={arrowTooltipMessage}
            onMouseEnter={initializeTitleTooltip}
          >
            {arrowMenuIcon}
          </div>
          <div className="home-menu-outlet-container">
            <Outlet />
          </div>
        </div>
        <Footer />
      </>
      ) : (
        <SearchLoading darkMode={theme === "dark"} />
      )}
    </div>
  );
};

// ---
// ---
// ---
const store = {
  services: services,
  state: {
    isSuperUser: false,
    isAdmin: false,
    formType: {
      BB: "Best Bet",
      TC: "Topic Card",
      CTC: "Custom Topic Card",
      HT: "How-To",
      SY: "Synonyms"
    }
  },
  setShowFeedbackBox: function (_value) {
    if (typeof _value !== "number") return;
    sessionStorage.setItem("showFeedbackAfterRequest", _value);
  },
  getShowFeedbackBox: function () {
    let tempVal = sessionStorage.getItem("showFeedbackAfterRequest");
    tempVal = parseInt(tempVal);
    return tempVal;
  }
};

const MsalAuth = () => {
  const { login } = useMsalAuthentication();
  const { accounts } = useMsal();
  login(InteractionType.Redirect).then((res) => {
    accounts.setActiveAccount(res.account);
  });

  return <div></div>;
};

const App = () => {
  return (
    <div>
      <UnauthenticatedTemplate>
        <MsalAuth />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <ThemeProvider>
          <StoreContext.Provider value={store}>
            <MainApp />
          </StoreContext.Provider>
        </ThemeProvider>
      </AuthenticatedTemplate>
    </div>
  );
};

export default App;
