// MSAL configuration
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ID,
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID,
    redirectUri: process.env.REACT_APP_SEARCHTOOLS_URL
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    tokenRenewalOffsetSeconds: 300 //(5 minutes) Tolerance time before token expires to allow token renewal
  } 
};
