import "./ContentReports.css";
import { useState, useContext, useEffect } from "react";
import { BsPlusSquare } from "react-icons/bs";
import StoreContext from "../../store/storecontext";
import ModalCustom from "../Modal/ModalCustom";
import { ThemeContext } from "../../ThemeContext";
import ValidateInputModal from "../Modal/ValidateInputModal";
import ExcelFileDownloader from "../../helpers/FileDownloader/excelDownloader";

const ContentReports = (props) => {
  const [selectedSource, setSelectedSource] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [darkModeString, setDarkModeString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const store = useContext(StoreContext);
  const theme = useContext(ThemeContext);
  const reportsOptions = [
    { text: "Internal Sites", index: process.env.REACT_APP_INTERNAL_SITES_IDX },
    {
      text: "KX Documents - Junk",
      index: process.env.REACT_APP_KX_DOCUMENTS_JUNK_IDX
    },
    {
      text: "Learning Boards",
      index: process.env.REACT_APP_LEARNING_BOARDS_IDX
    },
    { text: "Methods", index: process.env.REACT_APP_METHODS_IDX },
    { text: "myLearning", index: process.env.REACT_APP_MY_LEARNING_IDX },
    { text: "Policies", index: process.env.REACT_APP_POLICES_IDX },
    { text: "Videos", index: process.env.REACT_APP_VIDEOS_IDX }
  ];
  const onChangeSource = (event, report) => {
    setSelectedSource(report);
    if (report.text === "KX Documents - Junk") {
      setSearchTerms([
        "Strategy",
        "Customer Service",
        "Innovation Client Transformation Implementation",
        "Business Strategy",
        "Marketing and Communications",
        "Human Resources",
        "CIO CFO BPO Analytics Cloud ERP",
        "Business Intelligence",
        "Mobility IOT",
        "Supply Chain",
        "Technology Strategy",
        "Accounting Audit",
        "Business Operations",
        "Finance Trading Governance Payroll Recruiting",
        "Help Desk",
        "Legislation Benchmarking",
        "Client Satisfaction",
        "R&D Negotiation Manufacturing Contract",
        "Internet of Things",
        "Business Process Outsourcing",
        "Research and development",
        "junk"
      ]);
    } else {
      setSearchTerms([]);
    }
  };

  const onChangeSearchTerm = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const addSearchTerm = (event) => {
    let allowedEvent = event.key === "Enter" || event.type === "click";
    if (searchTerms.indexOf(searchTerm) !== -1) {
      setSearchTerm("");
    } else if (allowedEvent && searchTerm !== "") {
      setSearchTerms([...searchTerms, searchTerm]);
      setSearchTerm("");
    }
  };
  const removeSearchTerm = (event) => {
    const term = event.target.value.trim();
    let searchTermArray = [];

    if (searchTerms.indexOf(term) !== -1) {
      searchTermArray = [...searchTerms];
      searchTermArray = searchTermArray.filter(
        (searchTerm) => term !== searchTerm
      );
      setSearchTerms(searchTermArray);
    }
  };
  const downloadReport = async () => {
    let responseCode = 0;
    let downloadAttempts = 3;

    setIsLoading(true);
    const { index, text } = selectedSource;

    var reportingDownloadService = async () =>
      await store.services.reportingService.DownloadReport(
        searchTerms,
        index,
        text
      );

    var reportExcelFile = new ExcelFileDownloader(
      reportingDownloadService,
      getFileName()
    );

    responseCode = await reportExcelFile.downloadFile();
    downloadAttempts--;

    if (responseCode !== 200) {
      while (downloadAttempts !== 0 && responseCode !== 200) {
        responseCode = await reportExcelFile.downloadFile();
        downloadAttempts--;
      }
      if (downloadAttempts === 0) {
        setIsLoading(false);
        setOpenReportModal(true);
      }
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let source = document.getElementById(
      `report-option ${selectedSource.text}`
    );
    if (source !== null) {
      source.checked = true;
    }
  }, [isLoading]);

  const getFileName = () => {
    let hour = new Date();
    let min = hour.getMinutes();
    let h = hour.getHours();
    let sec = hour.getSeconds();

    let todayTime = h + "_" + min + "_" + sec;

    return `${selectedSource.text}Report_${new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "/")}_${todayTime}`;
  };

  useEffect(() => {
    let currModeString = theme[0] === "dark" ? "dark-mode" : "";
    setDarkModeString(currModeString);
  }, [theme]);

  const onClickReportModal = () => {
    setOpenReportModal(false);
  };

  return (
    <div className="overlay">
      {isLoading ? (
        <div>
          <div className="overlay-spinner" id="loading">
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div
                className="spinner-border text-light-mode-purple"
                role="status"
              ></div>
              <strong className="creating-report-message">
                We're preparing your report.
              </strong>
              <strong className="creating-report-message">Please wait</strong>
            </div>
          </div>
        </div>
      ) : (
        <>
          {openReportModal ? (
            <ModalCustom
              darkMode={theme === "dark"}
              onClose={downloadReport}
              onClick={downloadReport}
              modal_msg={"We couldn't download your report. Please try again."}
              btn_1_class={
                theme === "dark"
                  ? "btn btn-dark-mode-purple"
                  : "btn btn-light-mode-purple"
              }
              btn_2_class={
                theme === "dark"
                  ? "btn btn-dark-mode-purple"
                  : "btn btn-light-mode-purple"
              }
              modalSize=""
              secondOption={true}
              firstOptText={"Try again"}
              secondOptText={"Close"}
              onClickSecondOpt={onClickReportModal}
              noHeader={true}
            />
          ) : (
            <div></div>
          )}
          <div
            id="content-reports-main-div"
            className="content-reports"
            data-testid="testid-content-reports-main-div"
          >
            <div cols="12">
              <h3
                id="select-source-title"
                data-testid="testid-select-source-title"
                aria-label="select one source for the report"
                className={`select-source-title report-head ${darkModeString}`}
              >
                Select one Source
              </h3>
              <div className="reports-options">
                {reportsOptions.map((report, index) => (
                  <div
                    id={`report-opt-div-${report.text.replaceAll(" ", "-")}`}
                    data-testid={`testid-report-opt-div-${report.text.replaceAll(
                      " ",
                      "-"
                    )}`}
                    aria-label={report.text}
                    className="form-check"
                    key={index}
                  >
                    <input
                      aria-label={report.text}
                      className="form-check-input"
                      type="radio"
                      value={JSON.stringify(report)}
                      id={`report-option ${report.text}`}
                      name="option"
                      onChange={(event) => onChangeSource(event, report)}
                    />
                    <label
                      id={`report-label-text-${report.text}`}
                      className="form-check-label"
                      htmlFor="report-option"
                    >
                      {report.text}
                    </label>
                  </div>
                ))}
              </div>
              <form
                className="search-term"
                onSubmit={(event) => event.preventDefault()}
              >
                <h3
                  id="enter-search-term"
                  data-testid="testid-enter-search-term"
                  aria-label="Enter a search term"
                  className="select-source-title report-head"
                >
                  Enter a Search Term
                </h3>
                <input
                  autoComplete="off"
                  aria-label="Search Term input. Enter at least 1 related keyword"
                  id="search-term-input"
                  data-testid="testid-search-term-input"
                  type="text"
                  className="form-control light-mode-purple"
                  placeholder="Enter at least 1 related keyword"
                  value={searchTerm}
                  onChange={(event) => onChangeSearchTerm(event)}
                  onKeyDown={(event) => addSearchTerm(event)}
                />
                <BsPlusSquare
                  tabIndex={0}
                  className="plus-icon-search-term"
                  onClick={(event) => addSearchTerm(event)}
                  onKeyDown={(event) => addSearchTerm(event)}
                />
                {searchTerms.length === 0 ? (
                  ""
                ) : (
                  <div className="pills-group-content-searcher">
                    {searchTerms.map((searchTerm, index) => (
                      <div
                        key={index}
                        className={`btn-group pill`}
                        role="group"
                      >
                        <button
                          type="button"
                          className={`btn btn-success pills`}
                        >
                          {searchTerm}
                        </button>
                        <button
                          type="button"
                          value={searchTerm}
                          className={`btn btn-success pills`}
                          onClick={(event) => removeSearchTerm(event)}
                          aria-label="remove keyword from list"
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                <div className="download-report">
                  <button
                    id="download-report"
                    data-testid="testid-download-report"
                    aria-label="download report button"
                    type="button"
                    disabled={selectedSource === "" || searchTerms.length === 0}
                    className="btn btn-lg primary-btn-colour primary-btn"
                    onClick={(event) => downloadReport(event)}
                  >
                    Download Report
                  </button>
                </div>
              </form>
            </div>
          </div>
          <ValidateInputModal
            onClose={props.onClickCharsErrorModal}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </>
      )}
    </div>
  );
};

export default ContentReports;
