import BaseService from "./BaseService";

class ReportingService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }
  DownloadReport(keywords, source, reportType) {
    let self = this;
    return self.axios({
      url: `${self.baseUrl}report?keywords=${encodeURIComponent(
        keywords
      )}&source=${source}&reportType=${reportType}`,
      method: "GET",
      headers: this.config.headers
    });
  }
}
export default ReportingService;
