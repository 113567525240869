import info from "../../../../assets/images/info.svg";
import "../HowToForm.css";
import HowToPills from "./HowToPills";
import { useState, useContext, useEffect } from "react";
import HowToValidations from "../../../../validationClasses/HowToValidations";
import ModalCustom from "../../../Modal/ModalCustom";
import { ThemeContext } from "../../../../ThemeContext";
import StoreContext from "../../../../store/storecontext";
import { Tooltip } from "bootstrap";
import {
  EidSuggestion,
  selectSuggestionResult
} from "../../../EidSuggestion/EidSuggestion";
import { copyToClipboard } from "../../../CopyToClipboard/CopyToClipboard";

const HowToDelegateField = ({
  selectedDelegates,
  placeholderText,
  field,
  updateValue,
  setInputState,
  contactArray,
  disableField,
  requestStatus,
  searchToolsService,
  changeClipboardPillType,
  tooltipFor
}) => {
  const delegates = {
    alt: "Enter an Enterprise ID to search someone on your team that can contribute to edit your Topic Card request and will receive the notifications."
  };
  const [validInputClass, setValidInputClass] = useState("");
  const [openDelegatesModal, setDelegatesModal] = useState(false);
  const [delegatesErrorMessage, setDelegatesErrorMessage] = useState("");
  const [delegatesModalMessage, setDelegatesModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const theme = useContext(ThemeContext);
  const store = useContext(StoreContext);
  const urlCheckerService = store.services.urlCheckerService;
  const [eidSearchTrigger, setEidSearchTrigger] = useState(false);
  const INPUT_ELEM_ID = "ht-delegate-input";

  const onChangeDelegate = () => {
    setEidSearchTrigger(!eidSearchTrigger);
  };

  const getContactsData = async (delegInput) => {
    let newContact;
    let newDelegateArr = [];
    try {
      let delegateUserID = await store.services.yammerService.GetUserIdYammer(
        delegInput
      );
      delegateUserID = delegateUserID.data;
      newContact = {
        eid: delegInput,
        userid: delegateUserID
      };
    } catch {
      console.log("Error in getting userid");
      newContact = {
        eid: delegInput,
        userid: null
      };
    } finally {
      contactArray.map((el) => {
        newDelegateArr.push(el);
      });
      newDelegateArr.push(newContact);
    }
    return newDelegateArr;
  };

  const validateDelegates = async (delegInput, selectedDelegates) => {
    try {
      const isInputValid = await HowToValidations.validateDelegate(
        delegInput,
        selectedDelegates,
        field
      );

      setInputState(field, isInputValid.isValid);
      if (!isInputValid.isValid) {
        setDelegatesModalMessage(isInputValid.errorMessage);
        setDelegatesModal(true);
      } else {
        let delegates = await getContactsData(delegInput);
        setValidInputClass("is-valid");
        updateValue("delegates", [...delegates]);
      }
    } catch (error) {
      console.log("Error in validateDelegates");
    } finally {
      await setIsLoading(false);
    }
  };

  const addDelegate = async (event, eidToAdd = null) => {
    let delegate = "";
    let elemRef = {};
    elemRef = document.getElementById(`${INPUT_ELEM_ID}`);

    if (eidToAdd) {
      delegate = eidToAdd;
    } else {
      delegate = elemRef.value.trim();
    }
    if (delegate !== "") {
      await setIsLoading(true);
    }
    elemRef.value = "";
    setEidSearchTrigger(!eidSearchTrigger);

    const allowedEvents = event.key === "Enter" || event.type === "click";
    let regex = urlCheckerService.textRegex;

    if (allowedEvents && delegate !== "") {
      event.preventDefault();
      if (delegate.match(regex)) {
        setValidInputClass("is-invalid");
        setDelegatesModalMessage(
          'Please do not include the special characters \\ and "'
        );
        setDelegatesModal(true);
      } else {
        validateDelegates(delegate, selectedDelegates);
      }
    }
  };

  const onClickDelegatesModal = () => {
    setDelegatesModal(!openDelegatesModal);
  };

  const updateValueOnForm = (target) => {
    let updatedData = [];
    contactArray.map((el, i) => {
      if (el.eid !== target) {
        updatedData.push(el);
      }
    });
    updateValue(field, [...updatedData]);
  };

  useEffect(() => {
    try {
      if (selectedDelegates.length > 0) {
        setValidInputClass("is-valid");
      } else if (selectedDelegates.length === 0) {
        setValidInputClass("delegate", null);
      }
    } catch (error) {}
  }, [selectedDelegates]);

  const errorMsg = () => {
    return (
      <div id="validate-input" className="invalid_char_ht">
        {delegatesErrorMessage}
      </div>
    );
  };

  const checkInputAction = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      addDelegate(event);
    } else {
      selectSuggestionResult(event, INPUT_ELEM_ID);
    }
  };

  const initToolTipForField = () => {
    const tooltipClass = theme[0] === "dark" ? "ht-tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover",
        customClass: tooltipClass
      });
    });
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const copyToClipboardHandler = (event) => {
    changeClipboardPillType("EID");
    copyToClipboard(event);
  };

  const plusButton = (
    <div
      onClick={addDelegate}
      onKeyDown={addDelegate}
      className={"plus_icon_kw_ht_div"}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-title={"Add Delegate"}
      onMouseEnter={initToolTipForField}
      onMouseLeave={deleteOldTooltips}
    >
      <svg
        className={"plus_icon_kw_ht"}
        viewBox="0 0 16 16"
        width={"1.4em"}
        height={"1.4em"}
        role="img"
        alt="icon"
        data-testid="ht-del-plus-icon"
        id="plus-icon"
        aria-label="Add Users(s)"
        value=""
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "2px" }}
        tabIndex="0"
      >
        <g>
          <path
            fillRule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
          ></path>
          <path
            fillRule="evenodd"
            d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
          ></path>
          <path
            fillRule="evenodd"
            d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
          ></path>
        </g>
      </svg>
    </div>
  );

  return (
    <div className="mb-3">
      <img
        id={"ht-form-tooltip-icon-" + tooltipFor}
        role="tooltip"
        src={info}
        alt="tooltip"
        tabIndex={0}
        className="ht_tooltip"
        data-bs-placement="left"
        data-bs-toggle="tooltip"
        title="If needed, enter at least one Enterprise ID that can serve as a delegate to edit the How-To and receive related notifications."
      />

      <>
        <label className="ht_label">Delegates</label>
      </>

      {openDelegatesModal ? (
        <ModalCustom
          darkMode={theme[0] === "dark"}
          onClose={onClickDelegatesModal}
          modal_msg={delegatesModalMessage}
          btn_1_class={
            theme[0] === "dark"
              ? "btn btn-dark-mode-purple"
              : "btn btn-light-mode-purple"
          }
          modalSize=""
          noHeader={true}
        />
      ) : (
        <div></div>
      )}
      <div className="kw_del_ht_field">
        <input
          type="text"
          data-testid="ht-delegate-field"
          id={INPUT_ELEM_ID}
          className={`form-control ${validInputClass}`}
          placeholder={placeholderText}
          aria-label="Enterprise ID input."
          onChange={onChangeDelegate}
          onKeyDown={checkInputAction}
          disabled={disableField}
        />
        {!disableField && plusButton}
      </div>
      <EidSuggestion
        inputID={INPUT_ELEM_ID}
        eidSearchTrigger={eidSearchTrigger}
        searchToolsService={searchToolsService}
        addUserMethod={addDelegate}
      />
      {errorMsg()}
      <HowToPills
        selectedTargets={selectedDelegates}
        setSelectedTarget={updateValue}
        field={field}
        arrayForReview={[]}
        updateDelegates={updateValueOnForm}
        disableField={disableField}
        requestStatus={requestStatus}
        onCopyToClipBoard={copyToClipboardHandler}
        isLoading={isLoading}
      />
    </div>
  );
};

export default HowToDelegateField;
