import { useState, useEffect } from "react";
import TopicCardTooltips from "./TopicCardTooltips";

const TopicURL = ({
  value,
  setValue,
  urlErrorMessage,
  inputState,
  validateURL,
  disableField,
  tooltipFor
}) => {
  let titleURL = {
    tooltip: "Provide a main URL source to display (optional).",
    alt: "Title Info. For better Search results relevancy for this requested Topic Card, enter the site title as it appears when navigating to the entered URL"
  };

  const [validInputClass, setValidInputClass] = useState("base");
  const [oldValue, setOldValue] = useState();

  useEffect(() => {
    var finalClass;
    if (value === "") {
      finalClass = "";
    } else {
      finalClass = inputState["url"] ? "is-valid" : "is-invalid";
    }
    setValidInputClass(finalClass);
  }, [inputState]);

  const errorMsg = () => {
    if (validInputClass === "is-invalid") {
      return (
        <div id="validate-input" className="invalid">
          {urlErrorMessage}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const onUrlChangeHandler = async (event) => {
    let value = event.target.value;
    setValue("url", value);
    await setTimeout(() => {
      validateURL(value);
    }, 450);
    setOldValue(value);
  };

  const onBlurChangeHandler = () => {
    validateURL(value);
  };

  return (
    <>
      <div className="mb-3 url">
        <TopicCardTooltips
          tooltipFor={tooltipFor}
          tooltipAlt={titleURL.alt}
          tooltipText={titleURL.tooltip}
        />
        <label data-testid="topic-card-label" className="label-text">
          Topic URL
        </label>
        <input
          id="tc-form-url-input"
          type="text"
          data-testid="tc-input"
          className={`form-control ${validInputClass} tc-url-tag`}
          value={value}
          placeholder={"Enter a URL for this Topic Card (optional)"}
          onChange={onUrlChangeHandler}
          onBlur={onBlurChangeHandler}
          disabled={disableField}
        />
        {errorMsg()}
      </div>
    </>
  );
};

export default TopicURL;
