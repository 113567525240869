import { useState, useEffect } from "react";
import "../HowToForm.css";
import info from "../../../../assets/images/info.svg";
import HowToDropdowns from "./HowToDropdowns";
import HowToPills from "./HowToPills";

const HowToRefinerField = ({
  refinerPageInput,
  selectedRefinerPage,
  selectedKeywords,
  selectedLocation,
  keywordsForReview,
  updateValue,
  setInputState,
  disableField,
  requestStatus,
  tooltipFor
}) => {
  let refinerPage = {
    tooltip:
      "One or many Search pages (refiner pages) can be selected to display the Topic Card based on the site content and/or where the content resides (e.g., KX Documents, myLearning).",
    alt: "Refiner Page Info. One or many Search pages Refiner Page's can be selected to display the Topic Card based on the site content and or where the content resides for example, KX Documents, myLearning",
    defaultOption: "Select Search Refiner page where to display How-To",
    ariaLabel:
      "Refiner Page dropdown. Press Enter to select Refiner Page to display your How-To"
  };
  const [refinerOption, setRefinerOption] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("searchtools.config")) {
      let cfg = JSON.parse(sessionStorage.getItem("searchtools.config"));

      const htRefiner = cfg[1].topiccard.application.map((option) => {
        return (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        );
      });
      setRefinerOption(htRefiner);
    }
  }, []);

  const addRefinerPage = (event) => {
    const refinerPage = event.target.value.trim();
    if (selectedRefinerPage.indexOf(refinerPage) === -1) {
      updateValue("aid", [...selectedRefinerPage, refinerPage]);
    }
    document.getElementById("ht_refiner").selectedIndex = 0;
  };

  return (
    <div className="mb-3">
      <img
        id={"ht-form-tooltip-icon-" + tooltipFor}
        role="tooltip"
        src={info}
        alt="tooltip"
        className="ht_tooltip"
        data-bs-placement="left"
        tabIndex={0}
        data-bs-toggle="tooltip"
        title="One or many Search pages (Refiner Page) can be selected to display the How-To Card based on the site content and/or where the content resides (e.g., KX Documents, myLearning)."
      />
      <label htmlFor="ht_location" className=" ht_label required">
        Refiner Page
      </label>
      <div className="ht_drops">
        <HowToDropdowns
          elementId="ht_refiner"
          optionDefault={refinerPage.defaultOption}
          options={refinerPageInput}
          ariaLabel={refinerPage.ariaLabel}
          labelText="Refiner Page"
          onChangeDropdown={addRefinerPage}
          field="aid"
          setState={setInputState}
          updateValue={updateValue}
          inputValue={selectedRefinerPage}
          disableField={disableField}
        >
          <option>{refinerOption}</option>
        </HowToDropdowns>

        <HowToPills
          selectedTargets={selectedRefinerPage}
          setSelectedTarget={updateValue}
          arrayForReview={keywordsForReview}
          field="aid"
          keywords={selectedKeywords}
          selectedLocation={selectedLocation}
          hasReviewArray={false}
          disableField={disableField}
          requestStatus={requestStatus}
        />
      </div>
    </div>
  );
};

export default HowToRefinerField;
