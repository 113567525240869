import ReviewCuratedClasses from "./ReviewContent.module.css";

const TableDisplaySection = (props) => {
  const tableClassName = props.isDarkMode ? "table-dark" : "table-secondary";
  const tBodyClassName = props.isDarkMode ? props.tbody_dark : "";
  const arrowButtonColor = props.isDarkMode ? props.table_filter_button : "";
  const filterMenuStyle = props.isDarkMode
    ? props.status_filter_menu_dark
    : props.status_filter_menu;

  let showRequestTypeCol = props.tabStates.bb;

  let isSynonymsTab = props.tabStates.syn;

  return (
    <>
      {props.isDataLoading ? (
        <div
          className={
            "d-flex justify-content-center " +
            ReviewCuratedClasses.loading_spinner_margin
          }
        >
          <div className="spinner-border text-light-mode-purple" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <table
          data-testid="table_rcc"
          className={"table " + ReviewCuratedClasses.set_table_container}
          id="curated-content-table-display-section"
          aria-label="curated-content-table-display-section"
        >
          <thead>
            <tr className={tableClassName}>
              <th id="curated-content-id-table-header">
                ID
                <button
                  data-testid="table_id"
                  id="filter-by-id"
                  aria-label="filter by id"
                  className={props.table_filter_arrow}
                  onClick={() => props.orderBy("id")}
                >
                  {props.downFilterArrow}
                </button>
              </th>
              {isSynonymsTab && (
                <>
                  <th id="table_term" data-testid="table-term">
                    Terms
                  </th>
                  <th id="table_synonym" data-testid="table-synonym">
                    Synonyms
                  </th>
                </>
              )}
              {!isSynonymsTab && (
                <>
                  <th id="table_title" data-testid="table_title">
                    Title
                  </th>
                  <th id="table_url" data-testid="table_url">
                    URL
                  </th>
                </>
              )}
              {(isSynonymsTab || showRequestTypeCol) && (
                <th>
                  <div className={"dropdown " + arrowButtonColor}>
                    Type
                    <button
                      data-testid="table_type"
                      id="filter-by-type"
                      aria-label="filter by type"
                      className={
                        props.table_filter_status_arrow + " dropdown-toggle "
                      }
                      data-bs-toggle="dropdown"
                    ></button>
                    <ul
                      className={"dropdown-menu " + filterMenuStyle}
                      aria-labelledby="filter by type menu"
                    >
                      {props.currentReqTypeList}
                    </ul>
                  </div>
                </th>
              )}
              <th id="curated-content-status-table-header">
                <div className={"dropdown " + arrowButtonColor}>
                  Status
                  <button
                    data-testid="table_status"
                    id="filter-by-status"
                    aria-label="filter by status"
                    className={
                      props.table_filter_status_arrow + " dropdown-toggle "
                    }
                    data-bs-toggle="dropdown"
                  ></button>
                  <ul
                    className={"dropdown-menu " + filterMenuStyle}
                    aria-labelledby="filter by status menu"
                  >
                    {props.currentStatusList}
                  </ul>
                </div>
              </th>
              {!isSynonymsTab && (
                <>
                  <th id="curated-content-owner-table-header">
                    Owner
                    <button
                      data-testid="table_owner"
                      id="filter-by-owner"
                      aria-label="filter by owner"
                      className={props.table_filter_arrow}
                      onClick={() => props.orderBy("owner")}
                    >
                      {props.downFilterArrow}
                    </button>
                  </th>
                  {!showRequestTypeCol && (
                    <th id="curated-content-delegate-table-header">
                      Delegate
                      <button
                        data-testid="table_del"
                        id="filter-by-owner"
                        aria-label="filter by owner"
                        className={props.table_filter_arrow}
                        onClick={() => props.orderBy("delegate")}
                      >
                        {props.downFilterArrow}
                      </button>
                    </th>
                  )}
                  <th
                    id="curated-content-last-updated-table-header"
                    className={ReviewCuratedClasses.date_rcc_column}
                  >
                    Last Updated
                    <button
                      data-testid="table_lastdate"
                      id="filter-by-last-updated"
                      aria-label="filter by last updated"
                      className={props.table_filter_arrow}
                      onClick={() => props.orderBy("date")}
                    >
                      {props.downFilterArrow}
                    </button>
                  </th>
                </>
              )}
            </tr>
          </thead>
          {!props.apiFailed && (
            <tbody className={tBodyClassName}>{props.currentPageList}</tbody>
          )}
        </table>
      )}
      {props.apiFailed && !props.isDataLoading ? (
        <p className="api-failure-message">
          {process.env.REACT_APP_API_FAILURE_MESSAGE}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default TableDisplaySection;
