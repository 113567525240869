import CustomTable from "../../Table";

export const UrlTable = ({ urls, title, testid }) => {
  return (
    <div className="url-table-metrics">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#E7E7ED",
          padding: "8px",
          marginBottom: "15px"
        }}
      >
        <h5 className="total-col-heading">Total: {urls?.length ?? 0}</h5>
        <h5 className="date-col-heading">{title}</h5>
      </div>
      <div className="table-metrics-container">
        <CustomTable
          className="table-metrics-content"
          columns={[
            { key: "url", label: "URL" },
            { key: "submittedDate", label: "Submitted Date" }
          ]}
          data={urls}
          testid={testid}
        />
      </div>
    </div>
  );
};