import BaseService from "./BaseService";

class URLCheckerService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }
  URLRegExp = /^(http:\/\/|https:\/\/)/;
  URLRegExp2 =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  internalSiteRx =
    /(http|https):\/\/(in.accenture.com+|in.ciostage.accenture.com+)/;
  kxContributionRx =
    /(http|https):\/\/(kxdocuments.accenture.com|kxdocuments.ciostage.accenture.com)\/(contribution)/;
  collectionsRx =
    /(http|https):\/\/(collections.accenture.com|collections.ciostage.accenture.com)/;
  myLearningRx = /(https?):\/\/(mylearning(-pi)?.accenture.com)/;
  textRegex = new RegExp(/["\\]/);
  urlRegex = new RegExp(
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,256}\b(?:[-a-zA-Z0-9()@!*$:%_\+.~#?&\/=]*)$/
  );

  GetInternalSitesData(url, internalSiteHome) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}internalsites?url=${url}&internalSiteHome=${internalSiteHome}`,
      self.config
    );
  }
  GetKXContributionHits(url) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}kxdocument/contribution?url=${url}`,
      self.config
    );
  }
  GetCollectionHits(url) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}urlchecker/collection?url=${url}`,
      self.config
    );
  }
  GetMyLearningHits(url) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}urlchecker/mylearning?url=${url}`,
      self.config
    );
  }
  GetUrlDates(urlsArr) {
    let self = this;
    return self.axios.post(`${self.baseUrl}urldate`, urlsArr, self.config);
  }
}
export default URLCheckerService;
