import { useContext, useEffect, useState } from "react";
import StoreContext from "../../store/storecontext";
import { ThemeContext } from "../../ThemeContext";

import "./HomeMenu.css";
import HomeMenuOption from "./HomeMenuOption";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import {
  AiOutlineUnorderedList,
  AiOutlineFileSearch,
  AiOutlinePieChart,
  AiOutlineQuestionCircle
} from "react-icons/ai";

import { ImSun } from "react-icons/im";
import { MdSupportAgent, MdOutlineDarkMode } from "react-icons/md";
import { BsGear } from "react-icons/bs";
import { GrHomeRounded } from "react-icons/gr";
import { triggerToast } from "../Toast/TriggerToast";
import Toast from "../Toast/Toast";

const HomeMenu = (props) => {
  let gearIconSize = "1.3em";
  let homeIconSize = "1.3em";
  let optionIconSize = "1.7em";
  let optionMoonSize = "1.8em";
  let darkThemeElemId = "dark-theme-toggler-id";
  let userElemId = "user-toggler-id";
  let home_hidden_id = "home-menu-hidden";
  const [theme, setTheme] = useContext(ThemeContext);
  const store = useContext(StoreContext);
  const [themeToggleIcon, setThemeToggleIcon] = useState(
    <ImSun className="opt-icon-class-v2" size={optionIconSize} />
  );
  const [sendRequest, setSendRequest] = useState(false);

  const changeRoleMsg = "Role changed successfully.";

  const metricsIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="opt-icon-class"
      fill="none"
      width={optionIconSize}
    >
      <path
        d="M17 15V22H21V15C21 13.9 20.6 13 19.4 13H18.6C17.4 13 17 13.9 17 15Z"
        stroke="#A100FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 10V22H7V10C7 8.9 6.6 8 5.4 8H4.6C3.4 8 3 8.9 3 10Z"
        stroke="#A100FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 4V22H14.25V4C14.25 2.9 13.8 2 12.45 2H11.55C10.2 2 9.75 2.9 9.75 4Z"
        stroke="#A100FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const changeIschecked = (value) => {
    let swElement = document.querySelector(`#${darkThemeElemId}`);
    swElement.checked = value;
  };

  const toggleTheme = (changeButtonOnly = null) => {
    const stored = localStorage.getItem("theme");
    let pageBg = document.querySelector(":root");
    if (stored === "dark") {
      let newIcon = (
        <ImSun className="opt-icon-class-v2" size={optionIconSize} />
      );
      if (!pageBg.classList.contains("darkBg")) {
        pageBg.classList.add("darkBg");
      }
      if (changeButtonOnly) {
        changeIschecked(true);
        newIcon = (
          <MdOutlineDarkMode
            className="opt-icon-class-v2"
            size={optionMoonSize}
          />
        );
        setThemeToggleIcon(newIcon);
      } else {
        pageBg.classList.remove("darkBg");
        setThemeToggleIcon(newIcon);
        localStorage.setItem("theme", "light");
        setTheme("light");
      }
    } else {
      let newIcon = (
        <MdOutlineDarkMode
          className="opt-icon-class-v2"
          size={optionMoonSize}
        />
      );

      if (changeButtonOnly) {
        changeIschecked(false);
        newIcon = <ImSun className="opt-icon-class-v2" size={optionIconSize} />;
        setThemeToggleIcon(newIcon);
      } else {
        pageBg.classList.add("darkBg");
        setThemeToggleIcon(newIcon);
        localStorage.setItem("theme", "dark");
        setTheme("dark");
      }
    }
  };

  const getsupportUrl =
    "https://ts.accenture.com/sites/EnterpriseSearch/SearchCustomer/_layouts/15/listform.aspx?PageType=8&ListId=%7B77BD8FEE-B1E7-453F-9250-A19A205B0060%7D&RootFolder=%2fsites%2fEnterpriseSearch%2fSearchCustomer%2fLists%2fSearch%20Tools%20Support&Source=https%3A%2f%2fts.accenture.com%2fsites%2fEnterpriseSearch%2fSearchCustomer%2fLists%2fSearch%2520Tools%2520Support%2fAllItems.aspx&ContentTypeId=0x010085B94A658827D34AB7853C855B5E2286";

  const aboutSearchUrl =
    "https://in.accenture.com/digitalworker/toolkit/search/";

  const checkUserMenu = () => {
    let elem = document.getElementById(home_hidden_id);

    if (props.isSuperUser) {
      elem.classList.remove("hide-role-div");
    } else {
      elem.classList.add("hide-role-div");
    }
  };

  const resetPromoteMyContentTab = () => {
    try {
      let myActiveTab = document.querySelector(".nav-link.active");
      myActiveTab.classList.remove("active");

      let myBBTab = document.querySelector("#bb_link");
      myBBTab.classList.add("active");
    } catch (error) {}
  };

  const clearEditState = () => {
    try {
      if (store.state.requestToEditBB.canEdit) {
        store.state.requestToEditBB.canEdit = false;
        setTimeout(() => {
          window.location.reload();
        }, 700);
      }
    } catch (error) {}
  };

  const PromoteMyContentActions = () => {
    clearEditState();
    resetPromoteMyContentTab();
  };

  const contentReportActions = () => {
    clearEditState();
  };

  const changeUserRoleTrigger = () => {
    setSendRequest(true);
    triggerToast(1000, 0, "changeRoleToast");
  };

  const changeUserRole = async () => {
    if (sendRequest) {
      // disable button before query
      let liBtn = document.querySelector("#home-li-Admin");
      liBtn.style.pointerEvents = "none";
      let nodeList = liBtn.childNodes;
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].disabled = true;
        nodeList[i].style.pointerEvents = "none";
      }

      // change user role
      let currEid = localStorage.getItem("searchtools.eid");
      let reqBody = { eid: currEid };
      await store.services.searchToolsService.ChangeStUserRole(reqBody);

      setTimeout(() => {
        // reload page
        window.location.reload(true);
      }, 1200);
    }
  };

  useEffect(() => {
    checkUserMenu();
  }, [props.isSuperUser]);

  useEffect(() => {
    toggleTheme(true);
  }, []);

  useEffect(() => {
    changeUserRole();
  }, [sendRequest]);

  return (
    <div
      id="home-menu-div-main"
      data-testid="testid-home-menu-div-main"
      aria-label="Home menu container"
      className="home-menu-div"
    >
      <ul className="home-menu-ul">
        <HomeMenuOption
          href="/"
          optionIcon={
            <GrHomeRounded
              aria-label="Home menu section"
              className="opt-icon-class opt-icon-class-home"
              size={homeIconSize}
            />
          }
          ariaLabel={"Home"}
          optionText={"Home"}
          testId={"menu-opt-home-btn"}
          toggleOptions={{
            isToggle: false,
            toggleType: "",
            action: clearEditState,
            toggleName: ""
          }}
          redirectWithRouter={true}
          containsAction={true}
          theme={theme}
          pageSection={"home"}
        />
        <HomeMenuOption
          href="/promote/bestbet/request"
          optionIcon={
            <HiOutlineSpeakerphone
              className="opt-icon-class"
              size={optionIconSize}
            />
          }
          ariaLabel={"Promote my content"}
          optionText={"Promote my content"}
          testId={"menu-opt-promote-my-content"}
          toggleOptions={{
            isToggle: false,
            toggleType: "",
            action: PromoteMyContentActions,
            toggleName: ""
          }}
          redirectWithRouter={true}
          containsAction={true}
          theme={theme}
          pageSection={"promote"}
        />
        <HomeMenuOption
          href="/myrequests"
          optionIcon={
            <AiOutlineUnorderedList
              className="opt-icon-class-v2"
              size={optionIconSize}
            />
          }
          ariaLabel={"Manage my Requests"}
          optionText={"Manage my Requests"}
          testId={"menu-opt-my-requests"}
          toggleOptions={{
            isToggle: false,
            toggleType: "",
            action: clearEditState,
            toggleName: ""
          }}
          redirectWithRouter={true}
          containsAction={true}
          theme={theme}
          pageSection={"myrequests"}
        />
        <HomeMenuOption
          href="/requests"
          optionIcon={
            <AiOutlineFileSearch
              className="opt-icon-class-v2"
              size={optionIconSize}
            />
          }
          ariaLabel={"Review Curated Content"}
          optionText={"Review Curated Content"}
          testId={"menu-opt-curated-content"}
          toggleOptions={{
            isToggle: false,
            toggleType: "",
            action: clearEditState,
            toggleName: ""
          }}
          redirectWithRouter={true}
          containsAction={true}
          theme={theme}
          pageSection={"requests"}
        />
        <HomeMenuOption
          href=""
          optionIcon={themeToggleIcon}
          ariaLabel={"toggle your page theme (light-dark)"}
          optionText={"Dark Theme"}
          testId={"menu-opt-toggle-theme"}
          toggleOptions={{
            isToggle: true,
            toggleType: "DarkThemeToggle",
            action: toggleTheme,
            toggleName: "dark-theme-toggle"
          }}
          redirectWithRouter={true}
          elemID={darkThemeElemId}
          theme={theme}
        />
        <HomeMenuOption
          href="/reports"
          optionIcon={
            <AiOutlinePieChart
              className="opt-icon-class-v2"
              size={optionIconSize}
            />
          }
          ariaLabel={"Content Reports"}
          optionText={"Content Reports"}
          testId={"menu-opt-content-reports"}
          toggleOptions={{
            isToggle: false,
            toggleType: "",
            action: contentReportActions,
            toggleName: ""
          }}
          redirectWithRouter={true}
          containsAction={true}
          theme={theme}
          pageSection={"reports"}
        />
        <HomeMenuOption
          href={getsupportUrl}
          optionIcon={
            <MdSupportAgent
              className="opt-icon-class-v2"
              size={optionIconSize}
            />
          }
          ariaLabel={"Get Support"}
          optionText={"Support"}
          testId={"menu-opt-get-support"}
          toggleOptions={{
            isToggle: false,
            toggleType: "",
            action: () => {},
            toggleName: ""
          }}
          redirectWithRouter={false}
          theme={theme}
        />
        <HomeMenuOption
          href={aboutSearchUrl}
          optionIcon={
            <AiOutlineQuestionCircle
              className="opt-icon-class-v2"
              size={optionIconSize}
            />
          }
          ariaLabel={"About Search"}
          optionText={"About Search"}
          testId={"menu-opt-about-search"}
          toggleOptions={{
            isToggle: false,
            toggleType: "",
            action: () => {},
            toggleName: ""
          }}
          redirectWithRouter={false}
          theme={theme}
        />
      </ul>
      <div id={home_hidden_id} data-testid={`testid-${home_hidden_id}`}>
        <div className="home-menu-separator"></div>
        <ul className="home-menu-ul">
          <HomeMenuOption
            href="/metrics"
            optionIcon={metricsIcon}
            ariaLabel={"Metrics"}
            optionText={"Metrics"}
            testId={"menu-opt-metrics-reports"}
            toggleOptions={{
              isToggle: false,
              toggleType: "",
              action: () => {},
              toggleName: ""
            }}
            redirectWithRouter={true}
            theme={theme}
            pageSection={"metrics"}
          />
          <HomeMenuOption
            href=""
            optionIcon={
              <BsGear
                className="opt-icon-class-v2 opt-icon-gear"
                size={gearIconSize}
              />
            }
            ariaLabel={"change user role.From admin to regular user"}
            optionText={"Admin"}
            testId={"menu-opt-toggle-user"}
            toggleOptions={{
              isToggle: true,
              toggleType: "userToggle",
              action: changeUserRoleTrigger,
              toggleName: "user-toggle"
            }}
            redirectWithRouter={true}
            elemID={userElemId}
            theme={theme}
          />
          <Toast toastText={changeRoleMsg} toastKey="changeRoleToast" />
        </ul>
      </div>
    </div>
  );
};

export default HomeMenu;