import "../TCForm.css";
import TopicCardValidations from "../../../../validationClasses/TopicCardValidations";
import { useState } from "react";

const TCLineItem = ({
  item,
  index,
  lineItem,
  setLineItem,
  field,
  state,
  setState,
  disableField
}) => {
  const [canChangeField, setCanChangeField] = useState(false);

  if (state !== undefined &&
    lineItem.length > 0 &&
    (state[index] === undefined || state[index] === null)
  ) {
    state[index] = {
      title: { errorMessage: "", inputClass: "is-valid", state: true },
      url: { errorMessage: "", inputClass: "is-valid", state: true }
    };
  }

  const onChangeTitle = (event) => {
    if (lineItem.length > 0 && canChangeField) {
      let usrInput = event.target.value;
      let lineItemArray = [...lineItem];

      lineItemArray[index].title = usrInput;
      setLineItem(field, lineItemArray);

      validateTitle(usrInput);
    }
  };
  const onChangeURL = (event) => {
    if (lineItem.length > 0 && canChangeField) {
      let usrInput = event.target.value;
      let lineItemArray = [...lineItem];

      lineItemArray[index].url = usrInput;
      setLineItem(field, lineItemArray);

      validateURL(usrInput);
    }
  };
  const validateURL = async (url) => {
    if (url.length === 0) {
      setState(
        field,
        { state: null, errorMessage: null, inputClass: "" },
        index,
        "url"
      );
    } else {
      const isInputValid = await TopicCardValidations.validateURL(url);
      if (isInputValid.isValid) {
        setState(
          field,
          {
            state: isInputValid.isValid,
            errorMessage: null,
            inputClass: "is-valid"
          },
          index,
          "url"
        );
      } else {
        setState(
          field,
          {
            state: isInputValid.isValid,
            errorMessage: isInputValid.errorMessage,
            inputClass: "is-invalid"
          },
          index,
          "url"
        );
      }
    }
  };
  const validateTitle = async (title) => {
    if (title.length === 0) {
      setState(
        field,
        { state: null, errorMessage: null, inputClass: "" },
        index,
        "title"
      );
    } else {
      const isInputValid = await TopicCardValidations.validateTitle(
        title,
        true
      );
      if (isInputValid.isValid) {
        setState(
          field,
          {
            state: isInputValid.isValid,
            errorMessage: null,
            inputClass: "is-valid"
          },
          index,
          "title"
        );
      } else {
        setState(
          field,
          {
            state: isInputValid.isValid,
            errorMessage: isInputValid.errorMessage,
            inputClass: "is-invalid"
          },
          index,
          "title"
        );
      }
    }
  };

  const enableChangeItem = () => {
    setCanChangeField(true);
  };

  const disableChangeItem = () => {
    setCanChangeField(false);
  };

  return (
    <>
      <div className="mb-3 line-item-title">
        <input
          value={item.title}
          id={`title ${field} ${index}`}
          data-testid={`title-${field}-${index}`}
          type="text"
          className={`form-control ${
            item.title.length === 0 ||
            state === undefined ? "" : state[index].title.inputClass
          }`}
          placeholder="Title"
          onChange={onChangeTitle}
          disabled={disableField}
          onFocus={enableChangeItem}
          onBlur={disableChangeItem}
        />
        {state === undefined ||
          state[index].title.state ||
          state[index].title.state === null ? (
          <></>
        ) : (
          <div id="validate-input" className="invalid">
            {state !== undefined ? state[index].title.errorMessage : ""}
          </div>
        )}
      </div>
      <div className="mb-3 line-item-url">
        <input
          value={item.url}
          id={`url ${field} ${index}`}
          data-testid={`url-${field}-${index}`}
          type="text"
          className={`form-control ${
            item.url.length === 0 ||
            state === undefined ? "" : state[index].url.inputClass
          }`}
          placeholder="URL"
          onChange={(event) => onChangeURL(event)}
          disabled={disableField}
          onFocus={enableChangeItem}
          onBlur={disableChangeItem}
        />
        { state === undefined ||
            (state[index].url.state || 
             state[index].url.state === null) ? (
          <></>
        ) : (
          <div id="validate-input" className="invalid">
            {state[index].url.errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
export default TCLineItem;
