import { useEffect, useContext, useState } from "react";
import { json, useParams } from "react-router-dom";

import "./CTCForm.css";
import InfoIcon from "../../../assets/images/info.svg";
import LabelForSection from "./FormFields/LabelForSection";
import TitleField from "./FormFields/TitleField";
import UrlField from "./FormFields/UrlField";
import DescriptionField from "./FormFields/DescriptionField/DescriptionField";
import RefinerPageField from "./FormFields/RefinerPageField";
import LocationField from "./FormFields/LocationField";
import KeywordsField from "./FormFields/KeywordsField";
import DelegateField from "./FormFields/DelegateField";
import CommentsField from "./FormFields/CommentsField";
import NotAuthorizedPage from "../../NotAuthorizedPage/NotAuthorizedPage";
import Preview from "../Components/Preview/Preview";
import CTCPreview from "./CtcPreview/CTCPreview";
import CTCFormValidations from "../../../validationClasses/CTCFormValidations";
import { copyToClipboard } from "../../CopyToClipboard/CopyToClipboard";
import CommentHistory from "../Components/CommentHistory/CommentHistory";
import { ThemeContext } from "../../../ThemeContext";
import StoreContext from "../../../store/storecontext";

import { Tooltip, Toast } from "bootstrap";
import CTCButtons from "./CTCButtons";
import { formatDate } from "../../FormatDate/DateFormat";
import ToastElem from "../../Toast/Toast";
import NotFoundRequest from "../../PageNotFound/NotFoundRequest";

const CTCForm = () => {
  const themeCtx = useContext(ThemeContext);
  const store = useContext(StoreContext);

  let { id } = useParams();
  const [idFromUrl, setIdfromUrl] = useState(id);

  const [ctcValidationInstance] = useState(new CTCFormValidations());

  const [infoSectionCss, setInfoSectionCss] = useState();

  const [canSubmitForm, setCanSubmitForm] = useState(false);
  const [currentFormStatus, setCurrentFormStatus] = useState();

  const [checkKwStatus, setCheckKwStatus] = useState(false);
  const [reRenderKwTooltip, setReRenderKwTooltip] = useState(false);
  const [userHasEnteredOneKw, setUserHasEnteredOneKw] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [formContainsData, setFormContainsData] = useState(false);

  // for when the form is not ready yet to be submitted
  const [disableFormButtons, setDisableFormButtons] = useState(true);

  const [isAuthorized, setIsAuthorized] = useState(true);

  const [disableDescriptionField, setDisableDescriptionField] = useState(true);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [previewContent, setPreviewContent] = useState(false);
  const [notFoundMessage, setNotFoundMessage] = useState(false);

  const [originalReqData, setOriginalReqData] = useState({
    tcData: null,
    tcReqData: null,
    formData: null
  });

  // only for loading data values, set values on dom
  const emptyForm = {
    comments: "",
    arrComments: [],
    history: [],
    organization: [],
    status: "New",
    creationdate: null,
    tcid: 0,
    tcrequestid: 0,
    yammerthreadid: null,
    submittedbyuserid: null,
    submittedbyenterpriseid: null
  };

  const [formObjData, setFormObjData] = useState({});

  if (Object.keys(formObjData).length === 0) {
    setFormObjData(emptyForm);
  }
  const [formDelegatesData, setFormDelegatesData] = useState([]);
  const [descriptionData, setDescriptionData] = useState("");
  const [descriptionDataMMR, setDescriptionDataMMR] = useState("");
  const [descriptionStatus, setDescriptionStatus] = useState(false);
  const [editorRef, setEditorRef] = useState();

  const [formStatusDisplay, setFormStatusDisplay] = useState();
  const [tcIdDisplay, setTcIdDisplay] = useState();

  const [loadedDescriptionData, setLoadedDescriptionData] = useState();
  const [loadedSelectedKw, setLoadedSelectedKw] = useState();

  const [showPreviewTrigger, setShowPreviewTrigger] = useState();
  const [clipboardPillType, setClipboardPillType] = useState("Keyword");

  const changeClipboardPillType = (pillType) => {
    setClipboardPillType(pillType);
  };

  // important to load data on the description field
  // it can't be done on the dom
  const saveEditorRef = (instance) => {
    setEditorRef(instance);
  };

  const reValidateKw = () => {
    let currStatus = !checkKwStatus;
    // the boolean is only to trigger a useEffect call
    setCheckKwStatus(currStatus);
  };

  const onValidationResult = (result, fieldName) => {
    let formStatus = { ...currentFormStatus };
    if (formStatus && formStatus !== undefined) {
      formStatus[fieldName] = result;
      setCurrentFormStatus(formStatus);
    }
  };

  const changeCssForKwInput = (validationStatus, errorMsg) => {
    let kwInputEl = document.querySelector("#ctc-keyword-input");
    if (validationStatus) {
      kwInputEl.classList.remove("is-invalid");
      kwInputEl.classList.add("is-valid");
    } else {
      kwInputEl.classList.remove("is-valid");
      kwInputEl.classList.add("is-invalid");
    }
    let kwInputFeedbackEl = document.querySelector("#ctc-kw-error-feedback");
    kwInputFeedbackEl.innerText = errorMsg;
  };
  const setValidClass = (selector, state) => {
    let field = document.querySelector(selector);
    let statusStr = "";
    if (state) {
      statusStr = "is-valid";
    } else if (!state) {
      statusStr = "is-invalid";
    }
    field.classList.add(statusStr);
  };

  const removeKw = (event) => {
    try {
      event.target.parentNode.remove();
    } catch (error) {
      let parentEl = event.target.parentElement;
      let pillsList = parentEl.childNodes;
      for (let i = 0; i < pillsList.length; i++) {
        pillsList[i].remove();
      }
    }
    onLocationOrAidChange();
  };

  const kwRevalidation = () => {
    onLocationOrAidChange();
  };

  const deleteOldTooltips = () => {
    //delete unparented tooltips from the dom
    var oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const createNewTooltip = (event) => {
    deleteOldTooltips();

    let isDarkMode = themeCtx[0] === "dark" ? "darkMode_tooltip" : "";

    new Tooltip(event.target, {
      trigger: "hover focus",
      customClass: isDarkMode
    });
  };

  const userEnteredKw = () => {
    setUserHasEnteredOneKw(true);
  };

  // check if kw is from own request
  const checkIfOwnKeyword = (resArr) => {
    let reqCounter = 0;
    for (let cnt = 0; cnt < resArr.length; cnt++) {
      const reqID = resArr[cnt]._source.tcrequestid;
      if (parseInt(reqID) === parseInt(idFromUrl)) {
        reqCounter++;
      }
    }

    return reqCounter;
  };

  const revalidateGreenKeywords = async (
    currRefinersSelected,
    currLocationsSelected
  ) => {
    let greenKwHTMLlist = document.querySelectorAll(".green-kw-class");
    let greenKwList = [];
    for (let r = 0; r < greenKwHTMLlist.length; r++) {
      const kw = greenKwHTMLlist[r].innerHTML;
      greenKwList.push(kw);
    }

    for (let i = 0; i < greenKwList.length; i++) {
      let kwValue = greenKwList[i];

      let lowercaseKw = kwValue.trim().toLowerCase();

      let res = await store.services.topicCardService.GetTopicCardsByKeyword(
        lowercaseKw,
        currRefinersSelected,
        currLocationsSelected
      );

      let data = res.data.hits.hits;

      // check if kw is from own request
      let reqCounter = checkIfOwnKeyword(data);

      if (data.length > 0 && reqCounter === 0) {
        for (let r = 0; r < greenKwHTMLlist.length; r++) {
          const kw = greenKwHTMLlist[r].innerHTML;
          if (kw === kwValue) {
            let newBtn = document.createElement("button");
            newBtn.ariaLabel = "keyword " + kw;
            newBtn.classList.add(
              "btn",
              "btn-danger",
              "red-kw-class",
              "btn_font_weigth"
            );
            newBtn.innerHTML = kw;

            newBtn.setAttribute("data-bs-toggle", "tooltip");
            newBtn.setAttribute("data-bs-placement", "left");
            newBtn.setAttribute(
              "data-bs-title",
              "Another Custom Topic Card is already associated with this keyword. It will be considered, but may not be implemented."
            );

            newBtn.onclick = copyToClipboard;
            newBtn.onmouseenter = createNewTooltip;

            let newXBtn = document.createElement("button");
            newXBtn.ariaLabel = "remove " + kw + " from list";
            newXBtn.classList.add("btn", "btn-danger", "btn_font_weigth");
            newXBtn.innerHTML = "x";
            newXBtn.onclick = removeKw;

            greenKwHTMLlist[r].nextSibling.replaceWith(newXBtn);
            greenKwHTMLlist[r].replaceWith(newBtn);
          }
        }
      }
    }
  };

  const revalidateRedKeywords = async (
    currRefinersSelected,
    currLocationsSelected
  ) => {
    let redKwHTMLlist = document.querySelectorAll(".red-kw-class");
    let redKwList = [];
    for (let q = 0; q < redKwHTMLlist.length; q++) {
      const kw = redKwHTMLlist[q].innerHTML;
      redKwList.push(kw);
    }

    for (let i = 0; i < redKwList.length; i++) {
      let kwValue = redKwList[i];

      let lowercaseKw = kwValue.trim().toLowerCase();

      let res = await store.services.topicCardService.GetTopicCardsByKeyword(
        lowercaseKw,
        currRefinersSelected,
        currLocationsSelected
      );

      // check if kw is from own request
      let reqCounter = checkIfOwnKeyword(res.data.hits.hits);

      if (res.data.hits.hits.length === 0 && reqCounter === 0) {
        for (let r = 0; r < redKwHTMLlist.length; r++) {
          const kw = redKwHTMLlist[r].innerHTML;
          if (kw === kwValue) {
            let newBtn = document.createElement("button");
            newBtn.ariaLabel = "keyword " + kw;
            newBtn.classList.add(
              "btn",
              "btn-success",
              "green-kw-class",
              "btn_font_weigth"
            );
            newBtn.innerHTML = kw;
            newBtn.onclick = copyToClipboard;

            let newXBtn = document.createElement("button");
            newXBtn.ariaLabel = "remove " + kw + " from list";
            newXBtn.classList.add("btn", "btn-success", "btn_font_weigth");
            newXBtn.innerHTML = "x";
            newXBtn.onclick = removeKw;

            redKwHTMLlist[r].nextSibling.replaceWith(newXBtn);
            redKwHTMLlist[r].replaceWith(newBtn);
          }
        }
      }
    }
  };

  const setKeywordFormStatus = () => {
    let greenKwHTMLlist = document.querySelectorAll(".green-kw-class");
    let greenKwList = [];
    for (let r = 0; r < greenKwHTMLlist.length; r++) {
      const kw = greenKwHTMLlist[r].innerHTML;
      greenKwList.push(kw);
    }

    let redKwHTMLlist = document.querySelectorAll(".red-kw-class");
    let redKwList = [];
    for (let q = 0; q < redKwHTMLlist.length; q++) {
      const kw = redKwHTMLlist[q].innerHTML;
      redKwList.push(kw);
    }

    //change status of kw field if the only remaining keyword is invalid
    if (greenKwList.length === 0 && redKwList.length > 0) {
      onValidationResult(false, "keywords");
      changeCssForKwInput(
        false,
        "Please enter at least one valid, relevant keyword in order to successfully submit this Topic Card request."
      );
    } else if (greenKwList.length > 0 && redKwList.length === 0) {
      onValidationResult(true, "keywords");
      changeCssForKwInput(true, "");
    } else if (greenKwList.length > 0 && redKwList.length > 0) {
      onValidationResult(true, "keywords");
      changeCssForKwInput(true, "");
    } else if (
      greenKwList.length === 0 &&
      redKwList.length === 0 &&
      userHasEnteredOneKw
    ) {
      onValidationResult(false, "keywords");
      changeCssForKwInput(false, "");
    }

    let newBool = !reRenderKwTooltip;
    setReRenderKwTooltip(newBool);
  };

  const onLocationOrAidChange = async () => {
    // get data from DOM
    let refinersHtmlList = document.querySelectorAll("#refiner-on-list");
    let currRefinersSelected = [];
    for (let i = 0; i < refinersHtmlList.length; i++) {
      const refiner = refinersHtmlList[i].textContent.trim().replace("x", "");
      currRefinersSelected.push(refiner);
    }

    let countryHtmlList = document.querySelectorAll("#country-on-list");
    let currLocationsSelected = [];
    for (let j = 0; j < countryHtmlList.length; j++) {
      const location = countryHtmlList[j].children[0].innerHTML;
      currLocationsSelected.push(location);
    }

    if (
      (currRefinersSelected.length === 0 ||
        currLocationsSelected.length === 0) &&
      currentFormStatus &&
      Object.keys(currentFormStatus).length > 2
    ) {
      let updateRefiner = currRefinersSelected.length > 0;
      let updateLocations = currLocationsSelected.length > 0;
      onValidationResult(updateRefiner, "refinerPage");
      onValidationResult(updateLocations, "locations");
      return;
    }

    // revalidate green  keywords
    await revalidateGreenKeywords(
      [...currRefinersSelected],
      [...currLocationsSelected]
    );

    // revalidate red keywords
    await revalidateRedKeywords(
      [...currRefinersSelected],
      [...currLocationsSelected]
    );

    // // check hmtl's final status to set kw field state
    setKeywordFormStatus();
  };

  const saveDescriptionData = async (data, status) => {
    if (data !== null) {
      await setDescriptionData(data);
      await setDescriptionStatus(status);
    } else {
      await setDescriptionStatus(status);
    }
  };

  const saveDescriptionDataMMR = async (data) => {
    await setDescriptionDataMMR(data);
  };

  const saveDelegatesData = (data, removeDelegate = null) => {
    if (removeDelegate) {
      let currData = [...formDelegatesData];
      let newData = [];

      for (let i = 0; i < currData.length; i++) {
        const el = currData[i];
        if (el !== data) {
          newData.push(el);
        }
      }

      setFormDelegatesData(newData);
    } else {
      let currData = [...formDelegatesData];
      currData.push(data);
      setFormDelegatesData(currData);
    }
  };

  // overall form validation
  const isFormValid = (descStatus = null) => {
    let currStatus = { ...currentFormStatus };

    const COUNTRY_ID = "country-name-on-list";
    let countriesHtml = document.querySelectorAll(`#${COUNTRY_ID}`);

    let isValid =
      currStatus.title &&
      (currStatus.url || currStatus.url === null) &&
      currStatus.refinerPage &&
      countriesHtml.length > 0 &&
      currStatus.keywords &&
      currStatus.delegates &&
      (currStatus.comments || currStatus.comments === null);

    if (descStatus !== null) {
      isValid = isValid && descStatus;
    } else {
      isValid = isValid && descriptionStatus;
    }
    return isValid;
  };

  const setFormData = async (json) => {
    if (formContainsData) return;

    let isReadOnly = json.status === "Inactive";
    setReadOnlyMode(isReadOnly);

    try {
      let formDiv = document.getElementById("custom-topic-card-form");
      if (isReadOnly) {
        formDiv.classList.add("form-margin-bottom");
      } else {
        formDiv.classList.remove("form-margin-bottom");
      }
    } catch (error) {}

    const TITLE_ID = "ctc-title-input";
    const URL_ID = "ctc-url-input";
    let valResult = {};
    try {
      // title
      let htmltTitle = document.querySelector(`#${TITLE_ID}`);
      htmltTitle.value = json.title;
      setValidClass(`#${TITLE_ID}`, true);

      // url
      let htmlUrl = document.querySelector(`#${URL_ID}`);
      htmlUrl.value = json.url;

      if (json.url.length === 0) {
        valResult.status = null;
      } else {
        valResult = await ctcValidationInstance.ValidateUrl(
          json.url,
          store.services.urlCheckerService
        );
        setValidClass(`#${URL_ID}`, valResult.status);
      }
    } catch (error) {
      console.log("error on load data : elements not loaded on dom!");
    }

    // description field
    // (triggers useEffect on description component)
    setLoadedDescriptionData(json.description);

    // header display config (Custom Topic Card n°...)
    setTcIdDisplay(json.tcrequestid);
    let statusStr = json.status === "Inactive" ? "Not Active" : json.status;
    setFormStatusDisplay(statusStr);

    const formToDisplay = {
      loadedtitle: json.title,
      loadedurl: json.url,
      loadeddescription: json.description,
      loadedcountries: json.country,
      loadedrefiners: json.aid,
      loadedgreenKw: json.keywordsraw,
      loadedredKw: json.invalidkeywordsraw,
      loadeddelegates: json.contacts,
      arrComments: [...json.comments],
      comments: "",
      history: [...json.history],
      status: json.status,
      creationDate: json.creationdate,
      loadedTcID: json.tcid,
      loadedTcReqID: json.tcrequestid,
      submittedbyenterpriseid: json.submittedbyenterpriseid,
      submittedbypeoplekey: json.submittedbypeoplekey,
      yammerthreadid: null,
      submittedbyuserid: null
    };

    setFormDelegatesData(json.contacts);

    setFormObjData(formToDisplay);

    const formStatusTemplate = {
      title: true,
      url: valResult.status,
      refinerPage: true,
      locations: true,
      keywords: true,
      delegates: true,
      comments: null
    };

    setCurrentFormStatus(formStatusTemplate);

    let loadedKws = [...json.keywordsraw, ...json.invalidkeywordsraw];
    setLoadedSelectedKw(loadedKws);

    // check if user is delegate
    // to enable or disable description field
    let currEid = localStorage.getItem("searchtools.eid");
    let isDelegate = json.contacts.includes(currEid);
    setDisableDescriptionField(isDelegate);

    return formToDisplay;
  };

  const loadFormHandler = async (tcReqID) => {
    if (formContainsData) return;

    let tcQuery = null;
    let tcData = null;
    let lastRequest = null;
    let tcReqQuery = null;
    let tcReqData = {};

    setIsLoading(true);
    setDisableFormButtons(true);

    tcReqQuery = await store.services.topicCardService.GetTopicCardRequest(
      tcReqID
    );

    let totalHits = tcReqQuery.data.hits.total.value;

    if (totalHits === 0) {
      setIsLoading(false);
      setNotFoundMessage(true);
    } else {
      if (tcReqQuery.data.hits.hits.length > 1) {
        lastRequest = tcReqQuery.data.hits.hits.pop();
        tcReqData = lastRequest._source;
      } else {
        tcReqData = tcReqQuery.data.hits.hits[0]._source;
      }

      if (tcReqData.status === "Implemented" || tcReqData.tcid > 0) {
        tcQuery = await store.services.topicCardService.GetTopicCardByID(
          tcReqData.tcid
        );

        if (tcQuery.data.hits.hits.length > 1) {
          tcData = tcQuery.data.hits.hits.pop();
          tcData = tcData._source;
        } else {
          tcData = tcQuery.data.hits.hits[0]._source;
        }
      }

      let currentOriginalData = { ...originalReqData };
      let deepCopyTcData = JSON.parse(JSON.stringify(tcData));
      let deepCopyTcReqData = JSON.parse(JSON.stringify(tcReqData));

      let currEid = localStorage.getItem("searchtools.eid");
      let isOwner = deepCopyTcReqData.submittedbyenterpriseid === currEid;
      let isDelegate = deepCopyTcReqData.contacts.includes(currEid);
      let canSeeForm = store.state.isAdmin || isOwner || isDelegate;

      setIsAuthorized(canSeeForm);

      let reqDataObj = await setFormData(deepCopyTcReqData);

      currentOriginalData.tcData = deepCopyTcData;
      currentOriginalData.tcReqData = deepCopyTcReqData;
      currentOriginalData.formData = JSON.parse(JSON.stringify(reqDataObj));

      await setOriginalReqData({ ...currentOriginalData });

      if (parseInt(idFromUrl) > 0 && !formContainsData) {
        setFormContainsData(true);
        setIsLoading(false);
      }
    }
  };

  const openCtcPreview = () => {
    setShowPreviewTrigger(true);
  };

  const closeCtcPreview = () => {
    setShowPreviewTrigger(false);
  };

  useEffect(() => {
    // disable this useEffect when loading data from index
    const formStatusTemplate = {
      title: false,
      url: null,
      refinerPage: false,
      locations: false,
      keywords: false,
      delegates: false,
      comments: null
    };

    setCurrentFormStatus(formStatusTemplate);
  }, []);

  useEffect(() => {
    let info_section_div_base =
      themeCtx[0] === "dark" ? " info_section_div_dark" : " info_section_div";

    setInfoSectionCss(info_section_div_base);
  }, [themeCtx[0]]);

  // initalize tooltips (requires importing the Tooltip class from bootstrap)
  useEffect(() => {
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );

    let isDarkMode = themeCtx[0] === "dark" ? " darkMode_tooltip " : "";

    let tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
      if (tooltipTriggerEl.id === "keywords-field-tooltip") {
        new Tooltip(tooltipTriggerEl, {
          trigger: "hover focus",
          customClass: isDarkMode,
          html: true,
          template:
            '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner">',
          title: `Keyword recommendations:
          <ul>
            <li>
              At least 1 related keyword based on your site content is
              required; Maximum of 20 keywords can be entered.
            </li>
            <li>
              Avoid generic terms that can be associated with various site
              content within Accenture, such as 'Accenture',
              'skills', 'process', or
              'analysis'
            </li>
            <li>
              Avoid single keywords unless it is highly specific, such as an
              acronym
            </li>
            <li>
              Avoid keywords that have more than three words as it is very
              unlikely to be entered as a search term
            </li>
          </ul>`
        });
      } else {
        new Tooltip(tooltipTriggerEl, {
          trigger: "hover focus",
          customClass: isDarkMode
        });
      }
    });
  }, [themeCtx[0]]);

  useEffect(() => {
    onLocationOrAidChange();
  }, [checkKwStatus]);

  useEffect(() => {
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );

    let isDarkMode = themeCtx[0] === "dark" ? "darkMode_tooltip" : "";

    let tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        trigger: "hover focus",
        customClass: isDarkMode
      });
    });
  }, [reRenderKwTooltip]);

  useEffect(() => {
    let formStatus = isFormValid();
    setCanSubmitForm(formStatus);
  }, [currentFormStatus]);

  useEffect(() => {
    let formStatus = isFormValid(descriptionStatus);
    setCanSubmitForm(formStatus);
  }, [descriptionStatus]);

  // delete unparented tooltips
  // when loading data from manage my request
  //  or curated content section
  useEffect(() => {
    deleteOldTooltips();
  }, []);

  // use effect for when the user loads request via browser url
  useEffect(() => {
    if (parseInt(idFromUrl) > 0) {
      loadFormHandler(idFromUrl);
    } else {
      setIsAuthorized(store.state.isAdmin);
    }
  }, [idFromUrl]);

  return (
    <div>
      {notFoundMessage ? (
        <NotFoundRequest />
      ) : (
        <>
          {isAuthorized ? (
            <div
              id="main-custom-topic-card-container"
              data-testid="testid-main-custom-topic-card-container"
              className={"container-fluid " + "ctc_main_container_div"}
            >
              <form
                id="custom-topic-card-form"
                data-testid="testid-custom-topic-card-form"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                style={{ width: "100%" }}
              >
                {isLoading ? (
                  <div className="overlay-spinner modal-fade" id="loading">
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border text-light-mode-purple"
                        role="status"
                      ></div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {formContainsData && parseInt(idFromUrl) > 0 ? (
                  <div id="request-info-div">
                    <div
                      className={"ctc_request_info_title_div font-size-dot-one"}
                    >
                      Custom Topic Card Request ID {tcIdDisplay}
                    </div>
                    <div className="request-info-child-div font-size-dot-one">
                      <div className="request-info-block">
                        Last Modified Date:{" "}
                        <p>
                          {formatDate(
                            originalReqData.tcReqData.lastmodificationdate
                          )}
                        </p>
                      </div>
                      <div className="request-info-block">
                        Status: <p>{formStatusDisplay}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {showPreviewTrigger && (
                  <Preview
                    preview={<CTCPreview descriptionData={previewContent} />}
                    closePreview={closeCtcPreview}
                    modalSize="modal-ctc-size"
                  />
                )}
                <div className="row">
                  <div className="col">
                    <LabelForSection
                      info_section_id={"title-info-section"}
                      info_section_testid={"testid-title-info-section"}
                      info_section_div={infoSectionCss}
                      info_tooltip_id="ctc-form-tooltip-icon-title-input"
                      tooltip_message="Topic Titles must be unique, make sure your topic is not already created"
                      infoIcon={InfoIcon}
                      html_id_for_label={"ctc-title-input"}
                      input_label={"input_label"}
                      redColor={"#ff3246"}
                      section_title={"Topic Title"}
                      is_required={true}
                    />
                    <TitleField
                      updateFormState={onValidationResult}
                      validateTitle={ctcValidationInstance.ValidateTitle}
                      tcService={store.services.topicCardService}
                      adjust_error_msg={"adjust_error_msg"}
                      readOnlyMode={readOnlyMode}
                      IDFromUrl={idFromUrl}
                    />
                  </div>
                  <div className="col">
                    <LabelForSection
                      info_section_id={"url-info-section"}
                      info_section_testid={"testid-url-info-section"}
                      info_section_div={infoSectionCss}
                      info_tooltip_id="ctc-form-tooltip-icon-url-input"
                      tooltip_message="Every topic must provide a main URL source to display."
                      infoIcon={InfoIcon}
                      html_id_for_label={"ctc-url-input"}
                      input_label={"input_label"}
                      redColor={"#ff3246"}
                      section_title={"Topic URL"}
                      is_required={false}
                    />
                    <UrlField
                      updateFormState={onValidationResult}
                      validateUrl={ctcValidationInstance.ValidateUrl}
                      urlService={store.services.urlCheckerService}
                      adjust_error_msg={"adjust_error_msg"}
                      readOnlyMode={readOnlyMode}
                    />
                  </div>
                </div>
                <div className={"row " + "section_separator"}></div>
                <div className="row">
                  <LabelForSection
                    info_section_id={"desc-info-section"}
                    info_section_testid={"testid-desc-info-section"}
                    info_section_div={infoSectionCss}
                    info_tooltip_id="ctc-form-tooltip-icon-description-input"
                    tooltip_message="Add a comprehensive description about your topic, up to 1000 characters.
                To create a table, first enter the row and column values, and then press the table button"
                    infoIcon={InfoIcon}
                    html_id_for_label={"ctc-desc-input"}
                    input_label={"input_label"}
                    redColor={"#ff3246"}
                    section_title={"Topic Description"}
                    is_required={true}
                  />
                  <DescriptionField
                    isDarkMode={themeCtx[0] === "dark"}
                    validateDescription={
                      ctcValidationInstance.ValidateDescription
                    }
                    adjust_error_msg_description={
                      "adjust_error_msg_description"
                    }
                    saveDescriptionData={saveDescriptionData}
                    saveDescriptionDataMMR={saveDescriptionDataMMR}
                    saveEditorRef={saveEditorRef}
                    disableDescriptionField={disableDescriptionField}
                    readOnlyMode={readOnlyMode}
                    loadedDescriptionData={loadedDescriptionData}
                    IDFromUrl={idFromUrl}
                  />
                </div>
                <div className={"row " + "section_separator"}></div>
                <div className="row">
                  <LabelForSection
                    info_section_id={"country-info-section"}
                    info_section_testid={"testid-country-info-section"}
                    info_section_div={infoSectionCss}
                    info_tooltip_id="ctc-form-tooltip-icon-location-dropdown"
                    tooltip_message="Custom Topic Card can be implemented as 'Global' to be visible by all Accenture employees or targeted to one or many locations."
                    infoIcon={InfoIcon}
                    html_id_for_label={"country-select-id"}
                    input_label={"input_label"}
                    redColor={"#ff3246"}
                    section_title={"Location"}
                    is_required={true}
                  />
                  <LocationField
                    updateFormState={onValidationResult}
                    onLocationOrAidChange={reValidateKw}
                    selected_locations_div={"selected_locations_div"}
                    pills_style={"pills_style"}
                    btn_font_weigth={"btn_font_weigth"}
                    adjust_error_msg={"adjust_error_msg"}
                    loadedvalue={formObjData.loadedcountries}
                    readOnlyMode={readOnlyMode}
                  />
                </div>
                <div className={"row " + "section_separator"}></div>
                <div className="row">
                  <LabelForSection
                    info_section_id={"refiner-page-info-section"}
                    info_section_testid={"testid-refiner-page-info-section"}
                    info_section_div={infoSectionCss}
                    info_tooltip_id="ctc-form-tooltip-icon-refiner-page-dropdown"
                    tooltip_message="One or many Search pages (AIDs) can be selected to display the Custom Topic Card based on the site content and/or where the content resides (e.g., KX Documents, myLearning)."
                    infoIcon={InfoIcon}
                    html_id_for_label={"refiner-select-id"}
                    input_label={"input_label"}
                    redColor={"#ff3246"}
                    section_title={"Refiner Page"}
                    is_required={true}
                  />
                  <RefinerPageField
                    updateFormState={onValidationResult}
                    onLocationOrAidChange={reValidateKw}
                    selected_refiners_div={"selected_refiners_div"}
                    pills_style={"pills_style"}
                    btn_font_weigth={"btn_font_weigth"}
                    adjust_error_msg={"adjust_error_msg"}
                    loadedvalue={formObjData.loadedrefiners}
                    readOnlyMode={readOnlyMode}
                  />
                </div>
                <div className={"row " + "section_separator"}></div>
                <div className="row">
                  <LabelForSection
                    info_section_id={"keywords-info-section"}
                    info_section_testid={"testid-keywords-info-section"}
                    info_section_div={infoSectionCss}
                    info_tooltip_id="ctc-form-tooltip-icon-keywords-input"
                    tooltip_message='Keyword recommendations:&nbsp;
                &nbsp;&bull;&nbsp;&nbsp;At least 1 related keyword based on your site content is required; Maximum of 20 keywords can be entered.
                &nbsp;&bull;&nbsp;&nbsp;Avoid generic terms that can be associated with various site content within Accenture, such as "Accenture", "skills", "process", or "analysis"
                &nbsp;&bull;&nbsp;&nbsp;Avoid single keywords unless it is highly specific, such as an acronym
                &nbsp;&bull;&nbsp;&nbsp;Avoid keywords that have more than three words as it is very unlikely to be entered as a search term'
                    infoIcon={InfoIcon}
                    html_id_for_label={"ctc-keyword-input"}
                    input_label={"input_label"}
                    redColor={"#ff3246"}
                    section_title={"Keywords"}
                    is_required={true}
                  />
                  <KeywordsField
                    isDarkMode={themeCtx[0] === "dark"}
                    updateFormState={onValidationResult}
                    userEnteredKw={userEnteredKw}
                    kwRevalidation={kwRevalidation}
                    changeCssForKwInput={changeCssForKwInput}
                    createNewTooltip={createNewTooltip}
                    darkMode_tooltip={"darkMode_tooltip"}
                    tcService={store.services.topicCardService}
                    selected_keywords_div={"selected_keywords_div"}
                    pills_style={"pills_style"}
                    input_and_button_div={"input_and_button_div"}
                    plus_icon_css={"plus_icon_css"}
                    btn_font_weigth={"btn_font_weigth"}
                    adjust_error_msg={"adjust_error_msg"}
                    hidden_delegate_div={"hidden_delegate_div"}
                    loadedvalue={loadedSelectedKw}
                    readOnlyMode={readOnlyMode}
                    changeClipboardPillType={changeClipboardPillType}
                  />
                </div>
                <div className={"row " + "section_separator"}></div>
                <div className="row">
                  <LabelForSection
                    info_section_id={"delegates-info-section"}
                    info_section_testid={"testid-delegates-info-section"}
                    info_section_div={infoSectionCss}
                    info_tooltip_id="ctc-form-tooltip-icon-delegates-input"
                    tooltip_message="If needed, enter at least one Enterprise ID that can serve as a delegate to edit the Topic Card and receive related notifications."
                    infoIcon={InfoIcon}
                    html_id_for_label={"ctc-delegates-input"}
                    input_label={"input_label"}
                    redColor={"#ff3246"}
                    section_title={"Delegates"}
                    is_required={true}
                  />
                  <DelegateField
                    selected_delegates_div={"selected_delegates_div"}
                    pills_style={"pills_style"}
                    input_and_button_div={"input_and_button_div"}
                    plus_icon_css={"plus_icon_css"}
                    btn_font_weigth={"btn_font_weigth"}
                    hidden_delegate_div={"hidden_delegate_div"}
                    updateFormState={onValidationResult}
                    saveDelegatesData={saveDelegatesData}
                    validateDelegate={ctcValidationInstance.ValidateDelegate}
                    adjust_error_msg={"adjust_error_msg"}
                    loadedvalue={formObjData.loadeddelegates}
                    readOnlyMode={readOnlyMode}
                    searchToolsService={store.services.searchToolsService}
                    isDarkMode={themeCtx[0] === "dark"}
                    copyToClipboard={copyToClipboard}
                    changeClipboardPillType={changeClipboardPillType}
                  />
                </div>
                <div className={"row " + "section_separator"}></div>
                <div className="row">
                  {readOnlyMode ? (
                    <></>
                  ) : (
                    <>
                      <LabelForSection
                        info_section_id={"comments-info-section"}
                        info_section_testid={"testid-comments-info-section"}
                        info_section_div={infoSectionCss}
                        info_tooltip_id="ctc-form-tooltip-icon-comments-input"
                        tooltip_message="This comment field can also be used to provide additional information requested by the Search CoE team as part of the Topic Card request process."
                        infoIcon={InfoIcon}
                        html_id_for_label={"ctc-comments-input"}
                        input_label={"input_label"}
                        redColor={"#ff3246"}
                        section_title={"Comments"}
                        is_required={false}
                      />
                      <CommentsField
                        updateFormState={onValidationResult}
                        validateComment={ctcValidationInstance.ValidateComment}
                        adjust_error_msg={"adjust_error_msg"}
                      />
                    </>
                  )}
                  {formContainsData && parseInt(idFromUrl) > 0 ? (
                    <>
                      {formObjData.arrComments.map((comment, idx) => (
                        <div className="comments" key={idx}>
                          <CommentHistory
                            user={comment.user}
                            date={comment.dateUTC}
                            comment={comment.comment}
                            commentCSS={"comment-tc"}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {readOnlyMode ? (
                  <></>
                ) : (
                  <div>
                    <div className={"row " + "section_separator"}></div>
                    <CTCButtons
                      ctc_form_buttons_div={"ctc_form_buttons_div"}
                      isDarkMode={themeCtx[0] === "dark"}
                      disableButton={canSubmitForm}
                      tcService={store.services.topicCardService}
                      formDelegates={formDelegatesData}
                      formDescription={descriptionData}
                      formDescriptionForMMR={descriptionDataMMR}
                      formObjData={formObjData}
                      setIsLoading={setIsLoading}
                      originalReqData={originalReqData}
                      openCtcPreview={openCtcPreview}
                      idFromUrl={idFromUrl}
                      isAdmin={store.state.isAdmin}
                      setPreviewContent={setPreviewContent}
                    />
                  </div>
                )}
              </form>
              <ToastElem
                toastText={clipboardPillType + " copied to the clipboard!"}
              />
            </div>
          ) : (
            <NotAuthorizedPage />
          )}
        </>
      )}
    </div>
  );
};

export default CTCForm;
