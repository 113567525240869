import { useEffect, useState } from "react";

const CuratedTableTabs = (props) => {
  const [isBBTabActive, setIsBBTabActive] = useState(true);
  const [isTcTabActive, setIsTcTabActive] = useState(false);
  const [isHtTabActive, setIsHtTabActive] = useState(false);
  const [isSynTabActive, setIsSynTabActive] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (props.tabStates.bb) {
      setIsBBTabActive(true);
      setIsTcTabActive(false);
      setIsHtTabActive(false);
      setIsSynTabActive(false);
    } else if (props.tabStates.tc) {
      setIsTcTabActive(true);
      setIsBBTabActive(false);
      setIsHtTabActive(false);
      setIsSynTabActive(false);
    } else if (props.tabStates.ht) {
      setIsHtTabActive(true);
      setIsTcTabActive(false);
      setIsBBTabActive(false);
      setIsSynTabActive(false);
    } else if (props.tabStates.syn) {
      setIsSynTabActive(true);
      setIsHtTabActive(false);
      setIsTcTabActive(false);
      setIsBBTabActive(false);
    }
  }, [props.tabStates]);

  const displayTable = (event) => {
    let tableToShow = event.target.dataset.onclickbtnvalue;
    props.displayTable(tableToShow);
  };

  let bbTabState = "";
  let tcTabState = "";
  let htTabState = "";
  let synTabState = "";

  let defaultBBClass = props.isDarkMode
    ? props.best_bet_tab_dark
    : props.best_bet_tab;

  let defaultTCClass = props.isDarkMode
    ? props.topic_card_tab_dark
    : props.topic_card_tab;

  let defaultHTClass = props.isDarkMode
    ? props.how_to_tab_dark
    : props.how_to_tab;

  let defaultSYNClass = props.isDarkMode
    ? props.how_to_tab_dark
    : props.how_to_tab;

  let activeBBClass = props.isDarkMode
    ? props.active_bb_tab_dark
    : props.active_bb_tab;

  let activeTCClass = props.isDarkMode
    ? props.active_tc_tab_dark
    : props.active_tc_tab;

  let activeHTClass = props.isDarkMode
    ? props.active_ht_tab_dark
    : props.active_ht_tab;

  let activeSYNClass = props.isDarkMode
    ? props.active_syn_tab_dark
    : props.active_syn_tab;

  bbTabState = isBBTabActive ? activeBBClass : defaultBBClass;
  tcTabState = isTcTabActive ? activeTCClass : defaultTCClass;
  htTabState = isHtTabActive ? activeHTClass : defaultHTClass;
  synTabState = isSynTabActive ? activeSYNClass : defaultSYNClass;

  useEffect(() => {
    let defaultBBClass = props.isDarkMode
      ? props.best_bet_tab_dark
      : props.best_bet_tab;

    let defaultTCClass = props.isDarkMode
      ? props.topic_card_tab_dark
      : props.topic_card_tab;

    let defaultHTClass = props.isDarkMode
      ? props.how_to_tab_dark
      : props.how_to_tab;

    let defaultSYNClass = props.isDarkMode
      ? props.syn_tab_dark
      : props.how_to_tab;

    let activeBBClass = props.isDarkMode
      ? props.active_bb_tab_dark
      : props.active_bb_tab;

    let activeTCClass = props.isDarkMode
      ? props.active_tc_tab_dark
      : props.active_tc_tab;

    let activeHTClass = props.isDarkMode
      ? props.active_ht_tab_dark
      : props.active_ht_tab;

    let activeSYNClass = props.isDarkMode
      ? props.active_syn_tab_dark
      : props.active_syn_tab;

    bbTabState = isBBTabActive ? activeBBClass : defaultBBClass;
    tcTabState = isTcTabActive ? activeTCClass : defaultTCClass;
    htTabState = isHtTabActive ? activeHTClass : defaultHTClass;
    synTabState = isSynTabActive ? activeSYNClass : defaultSYNClass;
  }, [props.isDarkMode]);

  useEffect(() => {
    setTimeout(() => {
      setDisableButton(props.disableTabsTrigger);
    }, 700);
  }, [props.disableTabsTrigger]);

  return (
    <div
      data-testid="rcc_tabs"
      id="request-table-tabs"
      aria-label="request-table-tabs"
      className={props.request_table_tabs}
    >
      <button
        id="show-best-bet-table"
        data-testid="bb_tab"
        aria-label="show-best-bet-table"
        className={bbTabState}
        onClick={displayTable}
        data-onclickbtnvalue={props.tableStrings.bb}
        disabled={disableButton}
      >
        Best Bets
      </button>
      <button
        id="show-tc-table"
        data-testid="tc_tab"
        aria-label="show-topic-card-table"
        className={tcTabState}
        onClick={displayTable}
        data-onclickbtnvalue={props.tableStrings.tc}
        disabled={disableButton}
      >
        Topic Cards
      </button>
      <button
        id="show-ht-table"
        data-testid="ht_tab"
        aria-label="show-howto-card-table"
        className={htTabState}
        onClick={displayTable}
        data-onclickbtnvalue={props.tableStrings.ht}
        disabled={disableButton}
      >
        How-To Cards
      </button>
      <button
        id="show-syn-table"
        data-testid="syn-tab"
        aria-label="show-synonyms-table"
        className={synTabState}
        onClick={displayTable}
        data-onclickbtnvalue={props.tableStrings.syn}
        disabled={disableButton}
      >
        Synonyms
      </button>
    </div>
  );
};

export default CuratedTableTabs;
