import React, { useEffect, useState } from "react";
import { $getRoot, $getNodeByKey } from "lexical";
import editorTheme from "./EditorTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";

import ListMaxIndentLevelPlugin from "../../../../../vendor/lexical/plugins/ListMaxIndentLevelPlugin";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";

import "./CtcDescriptionField.css";

const INPUT_ELEM_ID = "ctc-desc-text-editor-div";
const INPUT_DIV_ID = "ctc-desc-div";

const DescriptionField = (props) => {
  const [cssState, setCssState] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [timeoutID, setTimeoutID] = useState();
  const [validationFeedbackCss, setValidationFeedbackCss] = useState("");
  const [editorInstance, setEditorInstance] = useState();

  const Placeholder = () => {
    return (
      <div className="editor-placeholder">
        Enter a brief description of site content that will display below the
        Custom Topic Card title; the description is intended to attract users to
        view/click this Custom Topic Card.
      </div>
    );
  };

  const editorConfig = {
    // The editor theme
    theme: editorTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  };

  const validateFieldOnInput = (descValue) => {
    let validationResult = props.validateDescription(descValue);
    let state = "";
    if (validationResult.status === null) {
      state = "";
    } else {
      state = validationResult.status
        ? " valid-field-css "
        : " invalid-field-css ";
    }
    setStatusMessage(validationResult.msg);
    setValidationFeedbackCss(state);
    setCssState(validationResult.status);

    const generatedHtml = $generateHtmlFromNodes(editorInstance, null);

    props.saveDescriptionData(generatedHtml, validationResult.status);
  };

  function onInputChange() {
    editorInstance.update(() => {
      const root = $getRoot();
      let textContent = root.getTextContent();
      props.saveDescriptionDataMMR(textContent);

      clearTimeout(timeoutID);

      let newTimeoutID = setTimeout(validateFieldOnInput(textContent), 1500);
      setTimeoutID(newTimeoutID);
    });
  }

  // handler to avoid double ref on function OnInitPlugin
  const saveEditorHandler = (editorRef) => {
    setEditorInstance(editorRef);
    props.saveEditorRef(editorRef);
  };
  //custom plugin to save editor reference on parent
  // (for data loading)
  const OnInitPlugin = () => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
      saveEditorHandler(editor);
    }, []);

    return <></>;
  };

  const setDescriptionFieldMode = () => {
    let isNotEditable = props.readOnlyMode || props.disableDescriptionField;
    if (isNotEditable) {
      let mainEditor = document.querySelector(".editor-container");
      editorInstance.setEditable(false);

      // disable toolbar
      let toolbarEl = mainEditor.children[0];
      let toolbarElements = toolbarEl.children;
      for (let i = 0; i < toolbarElements.length; i++) {
        if (toolbarElements[i].children.length > 0) {
          toolbarElements[i].setAttribute("disabled", true);
          for (let j = 0; j < toolbarElements[i].children.length; j++) {
            const el = toolbarElements[i].children[j];
            el.setAttribute("disabled", true);
          }
        } else {
          toolbarElements[i].setAttribute("disabled", true);
        }
      }
    }
  };

  const loadDescriptionFieldData = (htmlString) => {
    editorInstance.update(() => {
      const parser = new DOMParser();
      let removedQuotes = htmlString.replaceAll('"', "");
      const dom = parser.parseFromString(removedQuotes, "text/html");
      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editorInstance, dom);
      // Select the root
      const root = $getRoot();

      // get default blank node
      let blankNode = $getNodeByKey(root.__first);

      //insert all the data, and leave the blank node at the botton
      nodes.forEach((n) => blankNode.insertBefore(n));

      //delete blank node, and leave only the data
      blankNode.remove();

      // validate data
      onInputChange();

      //set description field mode
      setDescriptionFieldMode();
    });

    // there can be 2 empty nodes after the insert
    editorInstance.update(() => {
      const root = $getRoot();
      let blankNode = $getNodeByKey(root.__first);
      if (blankNode.isEmpty()) {
        blankNode.remove();
      }
      blankNode = $getNodeByKey(root.__first);
      if (blankNode.isEmpty()) {
        blankNode.remove();
      }
    });
  };

  useEffect(() => {
    try {
      loadDescriptionFieldData(props.loadedDescriptionData);
    } catch (error) {}
  }, [props.loadedDescriptionData]);

  let disableToolbar = false;

  if (parseInt(props.IDFromUrl) > 0) {
    disableToolbar = props.readOnlyMode || props.disableDescriptionField;
  }

  return (
    <div>
      <div
        id={INPUT_DIV_ID}
        data-testid={"testid-" + INPUT_DIV_ID}
        className={"ctc-desc-div-base " + validationFeedbackCss}
      >
        <LexicalComposer initialConfig={editorConfig}>
          <div
            className="editor-container"
            data-testid="ctc-description-editor"
          >
            <ToolbarPlugin
              isDarkMode={props.isDarkMode}
              disableField={disableToolbar}
            />

            <div className="editor-inner">
              <RichTextPlugin
                contentEditable={<ContentEditable className="editor-input" />}
                placeholder={<Placeholder />}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <OnInitPlugin />
              <OnChangePlugin onChange={onInputChange} />
              <HistoryPlugin />
              <ListPlugin />
              <LinkPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
            </div>
          </div>
        </LexicalComposer>
      </div>
      {cssState ? (
        <></>
      ) : (
        <div
          className={
            "ctc-desc-feedback-msg " +
            validationFeedbackCss +
            " " +
            props.adjust_error_msg_description
          }
        >
          {statusMessage}
        </div>
      )}
    </div>
  );
};

export default DescriptionField;
