import info from "../../../../assets/images/info.svg";
import "../SynonymsForm.css";

const AddTermField = ({
  onTermChange,
  isValid,
  value,
  disableFieldProp,
  tooltipFor
}) => {
  const isValidClass = isValid.state !== null ? isValid.state : "";

  const showError = () => {
    if (disableFieldProp) {
      return false;
    } else {
      return isValid.state === "invalid";
    }
  };
  return (
    <div className="add_term_label">
      <img
        id={"sy-form-tooltip-icon-" + tooltipFor}
        tabIndex={0}
        role="tooltip"
        src={info}
        alt="tooltip"
        className="synonyms_tooltip"
        data-bs-placement="left"
        data-bs-toggle="tooltip"
        title="Enter only one term to add multiple synonyms related to it."
      />
      <label htmlFor="termInput" className="add_term_label required">
        Add Term
      </label>
      <div className="add_term_input">
        <input
          type="text"
          className={`form-control is-${isValidClass} `}
          placeholder="Enter your term here"
          id="termInput"
          data-testid="term-input"
          onChange={onTermChange}
          value={value}
          disabled={disableFieldProp}
        />
        {showError() && (
          <div className="invalid" data-testid="invalid-input-msg">
            {isValid.msg}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddTermField;
