const Spinner = () =>{

    return(
            <div className="overlay-spinner">
              <div className="d-flex justify-content-center flex-column align-items-center">
                <div
                  className="spinner-border text-light-mode-purple"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
    )
}

export default Spinner;