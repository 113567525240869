const Toast = ({ toastText, toastKey="" }) => {
  return (
    <>
      <div
        className={`position-fixed top-50 end-0 p-3 toast align-items-center text-black bg-success border-0 ${toastKey} `}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="d-flex">
          <div className="toast-body">{toastText}</div>
        </div>
      </div>
    </>
  );
};

export default Toast;
