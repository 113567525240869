export const formatDate = (datetime) => {
  if (datetime) {
    let dt = new Date(datetime);
    return (
      dt.getMonth() +
      1 +
      "/" +
      dt.getDate() +
      "/" +
      dt.getFullYear() +
      " " +
      (dt.getHours() > 9 ? dt.getHours() : "0" + dt.getHours()) +
      ":" +
      (dt.getMinutes() > 9 ? dt.getMinutes() : "0" + dt.getMinutes())
    );
  } else {
    return "";
  }
};

export const dateFormaterMetrics = (date) => {
  date = new Date(date);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  date = year + "-" + month + "-" + day;
  return date;
};
