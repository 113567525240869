import { useContext, useEffect, useState } from "react";
import StoreContext from "../../store/storecontext";
import "./Feedback.css";
import "../../App.css";
import searchicon from "../../assets/images/search_icon.svg";
import {
  FaRegThumbsDown,
  FaRegThumbsUp,
  FaTimes,
  FaRegCommentDots
} from "react-icons/fa";

function Feedback() {
  const store = useContext(StoreContext);
  const [mounted, setMounted] = useState(true); //onMount, avoid using setMounted
  const [showBubble, setShowBubble] = useState(true);
  const [showBox, setShowBox] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [rating, setRating] = useState(undefined);
  const [postOnVaCheck, setPostOnVaCheck] = useState(false);
  const [yammerUserId, setYammerUserId] = useState(undefined);
  const succesfulPostMsg = "Thank you for your feedback!";
  const [submitionMsg, setSubmitionMsg] = useState(succesfulPostMsg);
  const [submitionModal, showSubmitionModal] = useState(false);
  const characterCheckRegex = store.services.urlCheckerService.textRegex;
  const invalidUserInput = userInput.match(characterCheckRegex);
  const invalidInputMsg = 'Please avoid using \\ and ".';
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const eid = localStorage.getItem("searchtools.eid");
        const res = await store.services.yammerService.GetUserIdYammer(eid);
        const yammerId = res.data;
        setYammerUserId(yammerId);
      } catch (e) {
        console.log("Couldn't retrieve Viva Engage id");
      }
    })();
  }, [mounted]);
  const disableSubmitBtn = () => {
    const disable = rating === undefined || invalidUserInput;
    return disable;
  };
  const postFeedback = (e) => {
    e.preventDefault();
    try {
      const eid = localStorage.getItem("searchtools.eid");
      const json = {
        eid: eid,
        rating: rating,
        feedback: userInput === "" ? null : userInput,
        useryammerid: yammerUserId,
        yammerthreadid: null
      };
      store.services.searchToolsService.PostUserFeedback(json).then((r) => {
        let feedbackId = r.data._id;
        let feedbackBubbleOnFooter = document.getElementById("feedback-bubble");
        feedbackBubbleOnFooter?.classList?.remove("hide_feedback_bubble");
        store.setShowFeedbackBox(0);
        try {
          // remove overlay if the user is on manage my request feedback dialog
          let overlayElem = document.querySelector(
            ".overlay-spinner.modal-fade"
          );
          let feedbackDiv = document.querySelector(
            "d-flex.justify-content-center.mmr-feedback"
          );
          overlayElem.remove();
          feedbackDiv.remove();
        } catch (error) {
          console.log("overlay not present");
        }

        if (isChecked) {
          store.services.yammerService.PostFeedbackOnVivaEngage(
            json,
            feedbackId
          );
        }
      });
    } catch (e) {
      console.log("The feedback couldn't be posted");
      throw e;
    } finally {
      setShowBox(false);
      store.setShowFeedbackBox(0);
      showSubmitionModal(true);
      setTimeout(() => {
        showSubmitionModal(false);
      }, 1500);
    }
  };
  const closeBubble = (e) => {
    if (isValidInputEvent(e)) {
      setShowBubble(false);
    }
  };
  const closeBox = () => {
    setShowBox(false);
  };
  const minimizeBox = (e) => {
    if (isValidInputEvent(e)) {
      try {
        // remove overlay if the user is on manage my request feedback dialog
        let overlayElem = document.querySelector(".overlay-spinner.modal-fade");
        let feedbackDiv = document.querySelector(
          "d-flex.justify-content-center.mmr-feedback"
        );
        overlayElem.remove();
        feedbackDiv.remove();
      } catch (error) {
        console.log("overlay not present");
      }
      closeBox();
      //check if bubble must be shown :
      // case when user comes from submitting a request or not
      let feedbackAfterSubmit = store.getShowFeedbackBox();
      if (feedbackAfterSubmit === 1) {
        store.setShowFeedbackBox(0);
        let feedbackBubbleOnFooter = document.getElementById("feedback-bubble");
        feedbackBubbleOnFooter?.classList?.remove("hide_feedback_bubble");
        setShowBubble(false);
      } else {
        setShowBubble(true);
      }
    }
  };
  const openFeedbackBox = (e) => {
    if (isValidInputEvent(e)) {
      setShowBubble(false);
      setShowBox(true);
    }
  };
  const setGoodRating = (e) => {
    if (isValidInputEvent(e)) {
      e.preventDefault();
      setRating("good");
    }
  };
  const setBadRating = (e = null) => {
    if (isValidInputEvent(e)) {
      setRating("bad");
    }
  };
  const isValidInputEvent = (e) => {
    //it's a click or pressed enter
    return !e.key || e.key == "Enter";
  };
  const selectedClass = (rated) => {
    return rating === rated ? "selected" : "";
  };
  const handleInputChange = (e) => {
    const newInput = e.target.value;
    if (newInput.length <= 500) {
      setUserInput(newInput);
    }
  };
  const feedbackBubble = () => {
    return (
      <div id="feedback-bubble" tabIndex="-1">
        {closeModalButton("bubble", closeBubble)}
        <button
          id="open-feedback-dialog-btn"
          tabIndex="0"
          onClick={openFeedbackBox}
          onKeyDown={openFeedbackBox}
          aria-label="Feedback bubble. Please, press enter if you would like to provide feedback."
          data-testid="feedback-button"
        >
          {
            <FaRegCommentDots
              className="comment-icon"
              aria-hidden="true"
            ></FaRegCommentDots>
          }
          Feedback
        </button>
      </div>
    );
  };
  const feedbackBox = () => {
    return (
      <div id="feedback-box" data-testid="feedback-box">
        <div className="feedback-header">
          <div
            id="feedback-dialog-header-msg"
            tabIndex="0"
            className="feedback-header-body"
          >
            How was your experience with the app?
          </div>
          {closeModalButton("feedback-box", minimizeBox)}
        </div>
        <form className="feedback-box-body">
          <div className="rating-row form-group">
            <div className="rating-container">
              <div
                className="rating-logo"
                onClick={setGoodRating}
                onKeyDown={setGoodRating}
                role="application"
                aria-label="Press enter to rate the results as good"
                rel="postfeedback"
                data-title="Post Feedback"
                data-option="1"
                tabIndex="0"
                data-testid="rating-button"
              >
                <FaRegThumbsUp
                  id="feedback-thumbs-up-btn"
                  className={`thumbs-up ${selectedClass("good")}`}
                ></FaRegThumbsUp>
                <div id="feedback-rating-text-good" className="rating-label">
                  Good
                </div>
              </div>
            </div>
            <div className="rating-container">
              <div
                className="rating-logo"
                onClick={setBadRating}
                onKeyDown={setBadRating}
                role="application"
                aria-label="Press enter to rate the results as bad"
                rel="postfeedback"
                data-title="Post Feedback"
                data-option="2"
                tabIndex="0"
                data-testid="rating-button"
              >
                <FaRegThumbsDown
                  id="feedback-thumbs-down-btn"
                  className={`thumbs-down ${selectedClass("bad")}`}
                ></FaRegThumbsDown>
                <div id="feedback-rating-text-bad" className="rating-label">
                  Bad
                </div>
              </div>
            </div>
          </div>
          <div className="form-group feedback-msg">
            <textarea
              id="feedback-experience-input"
              aria-label="Text area, maximum 500 characters"
              placeholder="Describe your experience using Search Tools."
              onChange={handleInputChange}
              value={userInput}
              className={`form-control ${invalidUserInput ? "is-invalid" : ""}`}
              data-testid="feedback-textarea"
            ></textarea>
            <div>
              {invalidUserInput ? (
                <div className="invalid-feedback-text">{invalidInputMsg}</div>
              ) : (
                <i
                  id="feedback-experience-char-counter"
                  className="char-counter"
                  style={{ fontSize: "0.7em" }}
                  data-testid="remaining-chars"
                >{`${500 - userInput.length} character(s) remaining.`}</i>
              )}
            </div>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              id="feedback-checkbox"
              data-testid="post-va-checkbox"
            />
            <div
              id="feedback-post-on-viva-msg-div"
              className="follow-me-viva-engage-msg"
            >
              Post my feedback on Viva Engage.
            </div>
          </div>
          <div className="form-group feedback-buttons">
            <button
              type="button"
              id="submit-feedback"
              onClick={postFeedback}
              disabled={disableSubmitBtn()}
              aria-label="Submit. Press enter to submit your feedback"
              data-testid="submit-feedback"
            >
              Submit
            </button>
            <button
              id="maybe-later"
              type="button"
              onClick={minimizeBox}
              aria-label="Maybe later. Press enter to close feedback box"
              data-testid="maybe-later"
            >
              Maybe Later
            </button>
          </div>
        </form>
      </div>
    );
  };

  // feedback elements for the case when user is redirected
  // to manage my request after submitting a request
  const feedbackBubbleAfterSubmit = () => {
    return (
      <div id="feedback-bubble" tabIndex="-1">
        {closeModalButton("bubble", closeBubble)}
        <button
          id="feedback-bubble-button"
          tabIndex="0"
          onClick={openFeedbackBox}
          onKeyDown={openFeedbackBox}
          aria-label="Feedback bubble. Please, press enter if you would like to provide feedback."
          data-testid="feedback-button"
        >
          {
            <FaRegCommentDots
              className="comment-icon"
              aria-hidden="true"
            ></FaRegCommentDots>
          }
          Feedback
        </button>
      </div>
    );
  };
  const feedbackBoxAfterSubmit = () => {
    return (
      <div data-testid="feedback-box-2" className="feedback-box-centered">
        <div className="feedback-header">
          <div tabIndex="0" className="feedback-header-body">
            How was your experience with the app?
          </div>
          {closeModalButton("feedback-box", minimizeBox)}
        </div>
        <form className="feedback-box-body">
          <div className="rating-row form-group">
            <div className="rating-container">
              <div
                className="rating-logo"
                onClick={setGoodRating}
                onKeyDown={setGoodRating}
                role="application"
                aria-label="Press enter to rate the results as good"
                rel="postfeedback"
                data-title="Post Feedback"
                data-option="1"
                tabIndex="0"
                data-testid="rating-button"
              >
                <FaRegThumbsUp
                  className={`thumbs-up ${selectedClass("good")}`}
                ></FaRegThumbsUp>
                <div className="rating-label">Good</div>
              </div>
            </div>
            <div className="rating-container">
              <div
                className="rating-logo"
                onClick={setBadRating}
                onKeyDown={setBadRating}
                role="application"
                aria-label="Press enter to rate the results as bad"
                rel="postfeedback"
                data-title="Post Feedback"
                data-option="2"
                tabIndex="0"
                data-testid="rating-button"
              >
                <FaRegThumbsDown
                  className={`thumbs-down ${selectedClass("bad")}`}
                ></FaRegThumbsDown>
                <div className="rating-label">Bad</div>
              </div>
            </div>
          </div>
          <div className="form-group feedback-msg">
            <textarea
              aria-label="Text area, maximum 500 characters"
              placeholder="Describe your experience using Search Tools."
              onChange={handleInputChange}
              value={userInput}
              className={`form-control ${invalidUserInput ? "is-invalid" : ""}`}
              data-testid="feedback-textarea"
            ></textarea>
            <div>
              {invalidUserInput ? (
                <div className="invalid-feedback-text">{invalidInputMsg}</div>
              ) : (
                <i
                  className="char-counter"
                  style={{ fontSize: "0.7em" }}
                  data-testid="remaining-chars"
                >{`${500 - userInput.length} character(s) remaining.`}</i>
              )}
            </div>
          </div>
          <div className="no-post-on-viva-separator"></div>
          <div className="form-group feedback-buttons">
            <button
              type="button"
              id="submit-feedback"
              onClick={postFeedback}
              disabled={disableSubmitBtn()}
              aria-label="Submit. Press enter to submit your feedback"
              data-testid="submit-feedback"
            >
              Submit
            </button>
            <button
              id="maybe-later"
              type="button"
              onClick={minimizeBox}
              aria-label="Maybe later. Press enter to close feedback box"
              data-testid="maybe-later"
            >
              Maybe Later
            </button>
          </div>
        </form>
      </div>
    );
  };
  const submittedModal = () => {
    return (
      <div className="feedback-submitted">
        <div className="feedback-header">
          Search Tools
          <img aria-hidden="true" src={searchicon} className="searchicon" />
        </div>
        <div className="feedback-submitted-body">
          <div className="check-icon">
            <span>&#10003;</span>
          </div>
          <p tabIndex="0">{submitionMsg}</p>
        </div>
      </div>
    );
  };
  const closeModalButton = (modal, onClick) => {
    return (
      <button
        id={"feedback-close-" + modal + "-btn"}
        className={`close-btn close-${modal}-btn`}
        aria-label={`'x' button. Press enter to close ${modal}.`}
        onClick={onClick}
        onKeyDown={onClick}
        data-title={`Close ${modal}`}
        role="button"
        tabIndex="0"
        data-testid={`close-${modal}-button`}
      >
        <FaTimes className="close-btn"></FaTimes>
      </button>
    );
  };
  const feedbackModals = () => {
    if (showBubble) {
      let showFeedbackAfterSubmit = false;
      let isRegularUser = !store.state.isSuperUser;
      let feedbackAfterSubmit = store.getShowFeedbackBox();
      if (feedbackAfterSubmit === 1) {
        showFeedbackAfterSubmit = true;
      } else {
        showFeedbackAfterSubmit = false;
      }
      let bubbleToReturn =
        showFeedbackAfterSubmit && isRegularUser
          ? feedbackBubbleAfterSubmit()
          : feedbackBubble();

      return bubbleToReturn;
    } else if (showBox) {
      let showFeedbackAfterSubmit = false;
      let isRegularUser = !store.state.isSuperUser;
      let feedbackAfterSubmit = store.getShowFeedbackBox();

      if (feedbackAfterSubmit === 1) {
        showFeedbackAfterSubmit = true;
      } else {
        showFeedbackAfterSubmit = false;
      }
      let boxToReturn =
        showFeedbackAfterSubmit && isRegularUser
          ? feedbackBoxAfterSubmit()
          : feedbackBox();

      return boxToReturn;
    } else if (submitionModal) {
      return submittedModal();
    }
  };
  return feedbackModals();
}

export default Feedback;
