import { GrDocumentCsv } from "react-icons/gr";
import { Tooltip } from "bootstrap";
import { useEffect, useState } from "react";
import * as csvHelper from "./csvHelper.js";

const CsvDownloadBtn = (props) => {
  const csvIconSize = "2em";

  const [tooltipClass, setTooltipClass] = useState("");
  const [csvIconClass, setCsvIconClass] = useState("");

  const deleteOldTooltips = () => {
    let oldTooltipTriggerList = [].slice.call(
      document.querySelectorAll(".tooltip.bs-tooltip-auto.fade.show")
    );

    oldTooltipTriggerList.map(function (tooltipTriggerEl) {
      tooltipTriggerEl.remove();
    });
  };

  const initTooltip = () => {
    deleteOldTooltips();
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="metrics-download-csv"]'
    );
    let newTooltips = [];
    let tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
      let t = new Tooltip(tooltipTriggerEl, {
        trigger: "hover",
        customClass: tooltipClass
      });
      newTooltips.push(t);
    });
  };

  const downloadCsvFile = () => {
    // parse data from query into row and cols
    let csvObj = csvHelper.generateCsvData(
      props.bbRequests,
      props.tcRequests,
      props.htRequests,
      props.selectedOption,
      props.bbOption,
      props.tcOption,
      props.htOption
    );

    //creates csv file and opens dowload window
    csvHelper.downloadCsvFile(
      csvObj.csvData,
      csvObj.reportTitle,
      props.fromDate,
      props.toDate
    );
  };

  useEffect(() => {
    let currTooltipClass =
      props.currTheme[0] === "dark" ? "bb-tooltip-dark" : "";
    let currIconClass = props.currTheme[0] === "dark" ? "csv-icon-dark" : "";

    setTooltipClass(currTooltipClass);
    setCsvIconClass(currIconClass);
  }, [props.currTheme]);

  return (
    <div className="download-csv-report-div" data-testid="csv-button">
      <button
        id="download-csv-report"
        data-testid="testid-download-csv-report"
        aria-label="Download Csv Report"
        className={"download-csv-report-btn " + csvIconClass}
        data-bs-toggle={"metrics-download-csv"}
        data-bs-placement="right"
        data-bs-title={"Download Report"}
        onMouseEnter={initTooltip}
        onMouseLeave={deleteOldTooltips}
        onClick={downloadCsvFile}
      >
        <GrDocumentCsv size={csvIconSize} />
      </button>
    </div>
  );
};

export default CsvDownloadBtn;
