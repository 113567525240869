import { useContext, useState } from "react";
import StoreContext from "../../store/storecontext";
import ReviewCuratedClasses from "./ReviewContent.module.css";
import ExcelFileDownloader from "../../helpers/FileDownloader/excelDownloader";

const ExportRequestBtns = (props) => {
  const store = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const totalResultsCss = props.isDarkMode
    ? props.total_results_color_dark
    : "";

  let redirectValue = "";

  let formToRequest = "";
  let currentTabColor = "";

  let isLoadingcolor = props.isDarkMode
    ? "overlay-spinner"
    : ReviewCuratedClasses.overlay_rcc;

  if (props.tabStates.bb) {
    formToRequest = "Best Bet";
    currentTabColor = props.isDarkMode
      ? props.req_bb_btn_color_dark
      : props.req_bb_btn_color;
    redirectValue = props.tableStrings.bb;
  } else if (props.tabStates.tc) {
    formToRequest = "Topic Card";
    currentTabColor = props.isDarkMode
      ? props.req_tc_btn_color_dark
      : props.req_tc_btn_color;
    redirectValue = props.tableStrings.tc;
  } else if (props.tabStates.ht) {
    formToRequest = "How-To Card";
    currentTabColor = props.isDarkMode
      ? props.req_ht_btn_color_dark
      : props.req_ht_btn_color;
    redirectValue = props.tableStrings.ht;
  } else if (props.tabStates.syn) {
    formToRequest = "Synonyms";
    currentTabColor = props.isDarkMode
      ? props.req_syn_btn_color_dark
      : props.req_syn_btn_color;
    redirectValue = props.tableStrings.syn;
  }

  let currentBtnCss = `btn ${currentTabColor} ${props.font_weight_button}`;

  const redirectTo = () => {
    props.redirectToPromoteMyContent(redirectValue);
  };
  const exportRequests = async (sortField, sortBy) => {
    var reqtype;
    var filterBy = "";
    var fileName = `${formToRequest} All Request`;
    if (formToRequest === "Best Bet") {
      reqtype = "bestbet";
      const typefilter = props.filterBy.type;
      const statusFilter = props.filterBy.status;
        if (statusFilter) {
          filterBy += statusFilter;
        }
        if (typefilter) {
          if (statusFilter) {
            filterBy += `,${typefilter}`;
          } else {
            filterBy += typefilter;
          }
        }
    }
    if (formToRequest === "Topic Card") {
      reqtype = "topiccard";
      filterBy.concat(props.filterBy.status);
    }
    if (formToRequest === "How-To Card") {
      reqtype = "howto";
      filterBy.concat(props.filterBy.status);
    }
    if (formToRequest === "Synonyms") {
      reqtype = "synonyms";
      filterBy.concat(props.filterBy.status);
    }
    setIsLoading(!isLoading);
    var exportRequestsService = async () =>
      await store.services.searchToolsService
    .ExportRequests(filterBy, sortField, sortBy, reqtype);

    const exportRequestsFile = new ExcelFileDownloader(
      exportRequestsService,
      fileName
    );
        try {
          await exportRequestsFile.downloadFile();
       } catch (error) {
         console.log(error)
       }
       finally{
         setIsLoading(false);
       }
  };

  const exportActiveContent = async () => {
    setIsLoading(true);
    var activeContentService = async () =>
      await store.services.activeContentService.GetActiveContent(formToRequest)

    let fileName = formToRequest === "Synonyms" ? "Synonyms List" : `Active ${formToRequest}`;
    const activeContent = new ExcelFileDownloader(
      activeContentService,
      fileName
    );
    try {
       await activeContent.downloadFile();
    } catch (error) {
      console.log(error)
    }
    finally{
      setIsLoading(false);
    }
  };

  return (
    <div id="export-requests-btns" data-testid="export-requests-btns">
      {isLoading && (
        <div className={isLoadingcolor} id="loading">
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div
              className="spinner-border text-light-mode-purple"
              role="status"
            ></div>
            <strong className="creating-report-message">
              We're preparing your report.
            </strong>
            <strong className="creating-report-message">Please wait</strong>
          </div>
        </div>
      )}

      <div id="export-requests-div" className={props.export_requests_div}>
        <div className={props.div_separator}>
          <span
            className={props.total_amount_span + " " + totalResultsCss}
            id="total_req"
            data-testid="total_req"
          >
            {props.totalAmountOfRequests + " total"}
          </span>
        </div>
        <div className={props.div_separator}>
          <button
            className={currentBtnCss}
            onClick={redirectTo}
            data-testid="reqst_btn"
            id="reqst_btn"
          >
            Request {formToRequest}
          </button>
        </div>
    
            <div className={props.div_separator}>
              <button
                data-testid={`export_btn_${formToRequest.replaceAll(' ', '').toLowerCase()}`}
                className={
                  "btn btn-light-mode-purple " + props.font_weight_button
                }
                id={`export_btn_${formToRequest.replaceAll(' ', '').toLowerCase()}`}
                onClick={() => exportRequests(props.sortField, props.sortBy)}
              >
                Export All Request
              </button>
            </div>
            <div className={props.div_separator}>
              <button
                data-testid={`export_active_${formToRequest.replaceAll(' ', '').toLowerCase()}`}
                id={`export_active_${formToRequest.replaceAll(' ', '').toLowerCase()}`}
                className={
                  "btn btn-light-mode-purple " + props.font_weight_button
                }
                onClick={() => exportActiveContent()}
              >
                Export Active {formToRequest}
              </button>
            </div>
          
      </div>
    </div>
  );
};

export default ExportRequestBtns;
