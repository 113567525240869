import { useContext, useState, useEffect } from "react";
import "../Metrics.css";
import MetricsDate from "../MetricsDate";
import PieChart from "../charts/PieChart";
import StoreContext from "../../../store/storecontext";
import { ThemeContext } from "../../../ThemeContext";
import { monthsAvg } from "./months";
import { Tooltip } from "bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";

const FeedbackMetricsSection = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showGraphic, setShowGraphic] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [feedbackData, setFeedbackData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const store = useContext(StoreContext);
  const theme = useContext(ThemeContext);

  const isDarkMode = () => {
    return theme[0] === "dark";
  };

  const formatDate = (date, type) => {
    if (!date) return "";
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${type === "from" ? "01" : 31}`;
  };

  const minDate = new Date("2023-09-01");
  const badFeedColorChart = isDarkMode() ? "#D07CE8" : "#C95DF6";

  const maxDate = !fromDate ? new Date() : new Date(fromDate + 12);

  if (fromDate) {
    let yearAfter = fromDate.getMonth() + 12;
    maxDate.setMonth(yearAfter);
  }
  useEffect(() => {
    const isValid = fromDate && toDate;
    setIsBtnDisabled(!isValid);
  }, [fromDate, toDate]);

  const getDataFeedback = async () => {
    setIsLoading(true);
    await store.services.searchToolsService
      .GetFeedback(formatDate(fromDate, "from"), formatDate(toDate, "to"))
      .then((r) => {
        const feedbackData = r.data.aggregations;
        setFeedbackData(feedbackData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error on getting feedback data");
        throw error;
      });
  };

  const onClickMetrics = () => {
    getDataFeedback();
    if (fromDate && toDate && totalValue !== 0) {
      setShowGraphic(true);
    }
  };
  const downloadMetricsFeedback = async () => {
    setIsLoading(true);
    try {
      const metricsFeedbackData =
        await store.services.metricsFeedbackService.DownloadMetricsFeedback(
          formatDate(fromDate, "from"),
          formatDate(toDate, "to")
        );

      const url = window.URL.createObjectURL(
        new Blob([metricsFeedbackData.data])
      );
      const link = document.createElement("a");

      link.href = url;
      let fromDatePeriod = new Date(fromDate);
      let toDatePeriod = new Date(toDate);
      fromDatePeriod = `${
        monthsAvg[fromDatePeriod.getMonth()]
      }${fromDatePeriod.getFullYear()}`;
      toDatePeriod = `${
        monthsAvg[toDatePeriod.getMonth()]
      }${toDatePeriod.getFullYear()}`;

      link.setAttribute(
        "download",
        `Feedback__${fromDatePeriod}_${toDatePeriod}.csv`
      );
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    } catch (error) {
      console.log("Your report couldn't be downloaded");
    }
  };

  const totalValue = feedbackData?.total?.value;

  let goodFeedbackData = feedbackData?.good_rating?.total_good?.value;

  let badFeedbackData = feedbackData?.bad_rating?.total_bad?.value;

  const goodPercentage = Math.round((goodFeedbackData / totalValue) * 100);
  const badPercentage = Math.round((badFeedbackData / totalValue) * 100);

  useEffect(() => {
    const tooltipClass = theme === "dark" ? "tooltip-dark" : "";
    let tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
        tooltipTriggerList,
        trigger: "hover focus",
        customClass: tooltipClass
      });
    });
  }, [theme]);

  return (
    <div className="feedback-metrics">
      {isLoading ? (
        <>
          <div className="overlay-spinner" id="loading">
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div
                className="spinner-border text-light-mode-purple"
                role="status"
              ></div>
              <strong className="loading-message">Processing data</strong>
            </div>
          </div>
        </>
      ) : (
        <>
        <div id="metrics-feedback-menu-div" className="feedback-menu-div">
          <div
            id="metrics-feedback-select-range-p"
            className="label-text metric-section required info-icon-space-div"
            data-testid="calendar"
          >
            <AiOutlineInfoCircle
              id={"metrics-menu-tooltip-icon"}
              role="tooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              className="info-icon info-icon-space"
              data-testid="metrics-menu-tooltip-icon-reqtype"
              data-bs-title={"Select a date range in months, choose start and end."}
            />
            Select Range Date:
          </div>
          <div className="select-date">
            <MetricsDate
              sectionName={"st-feedback-from"}
              dateLabelTitle={"From:"}
              name={"fromDate"}
              minDate={minDate}
              handleChange={setFromDate}
              selected={fromDate}
              className="date-picker"
            />
            <MetricsDate
              sectionName={"st-feedback-to"}
              dateLabelTitle={"To:"}
              name={"toDate"}
              maxDate={maxDate}
              handleChange={setToDate}
              selected={toDate}
              className="date-picker"
            />
          </div>
          <div className="get-feedback-btn">
            <button
              data-testid="metrics-btn-feed"
              id="metrics-btn-feed"
              disabled={isBtnDisabled}
              onClick={() => onClickMetrics()}
              className="btn btn-primary btn-metrics feedback-metrics-btn"
            >
              Get Metrics
            </button>
          </div>
        </div>
        <div id="metrics-feedback-chart-div" className="feedback-chart-div">
          {totalValue === 0 ? (
            <p className="no_data_msg">
              No records available for the selected dates.
            </p>
          ) : (
            <>
              {showGraphic && (
                <>
                  <div
                    className="chart-section"
                    data-testid="pie-chart"
                    id="pie-chart"
                  >
                    <PieChart
                      feedData={{
                        labels: ["Good", "Bad"],
                        datasets: [
                          {
                            label: " %",
                            data: [goodPercentage, badPercentage],
                            backgroundColor: ["#a100ff", badFeedColorChart],
                            hoverBackgroundColor: ["#a100ff", badFeedColorChart]
                          }
                        ]
                      }}
                    />
                  </div>
                  <div className="export-feedback-btn">
                    <button
                      id="export-feedback"
                      data-testid="export-feedback"
                      onClick={() => downloadMetricsFeedback()}
                      className="btn btn-primary btn-metrics export-feedback added-width"
                    >
                      Export Feedback
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        </>
      )}
    </div>
  );
};

export default FeedbackMetricsSection;
