import { useState, useEffect } from "react";

const LocationField = (props) => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countrySelectOptions, setCountrySelectOptions] = useState([]);
  const [cssState, setCssState] = useState(" light-mode-purple ");

  useEffect(() => {
    if (sessionStorage.getItem("searchtools.config")) {
      let cfg = JSON.parse(sessionStorage.getItem("searchtools.config"));

      const ctcCountries = cfg[1].topiccard.countries.map((option) => {
        return (
          <option
            id={"ctc-form-dropdown-location" + "-option-" + option.value}
            key={option.value}
            value={option.value}
          >
            {option.text}
          </option>
        );
      });

      setCountrySelectOptions(ctcCountries);
    }
  }, []);
  useEffect(() => {
    if (props.loadedvalue) {
      setSelectedCountries(props.loadedvalue);
      setCssState(" is-valid ");
    }
  }, [props.loadedvalue]);

  const resetDropdownToDefault = () => {
    document.getElementById("ctc-country-select-id").selectedIndex = 0;
  };

  const AddCountries = (event) => {
    if (selectedCountries.indexOf(event.target.value) === -1) {
      if (event.target.value === "Global") {
        setSelectedCountries([event.target.value]);
        setCssState(" is-valid ");
        props.updateFormState(true, "locations");
        props.onLocationOrAidChange();
        resetDropdownToDefault();
      } else {
        let countriesArr = [...selectedCountries];
        if (countriesArr.indexOf("Global") === -1) {
          countriesArr.push(event.target.value);
          resetDropdownToDefault();
          setSelectedCountries(countriesArr);
          setCssState(" is-valid ");
          props.updateFormState(true, "locations");
          props.onLocationOrAidChange();
        } else {
          resetDropdownToDefault();
          return;
        }
      }
    } else if (selectedCountries.indexOf(event.target.value) > -1) {
      resetDropdownToDefault();
      return;
    }
  };

  const removeSelectedCountry = (event) => {
    let countriesArr = [...selectedCountries];
    countriesArr = countriesArr.filter((el) => {
      if (el !== event.target.value) {
        return el;
      }
    });

    if (countriesArr.length === 0) {
      setCssState(null);
      props.updateFormState(false, "locations");
    }

    setSelectedCountries(countriesArr);
    props.onLocationOrAidChange();
  };

  const optionsSelected = selectedCountries.map((opt) => {
    return (
      <div
        id="country-on-list"
        data-testid="testid-country-on-list"
        aria-label="country-on-list"
        key={opt + "_div"}
        className={"btn-group " + props.pills_style}
        role="group"
      >
        <button
          data-testid="country-name-on-list"
          id="country-name-on-list"
          aria-label={"country " + opt}
          key={opt + "_btn_opt_key"}
          className={"btn btn-success " + props.btn_font_weigth}
          type="button"
        >
          {opt}
        </button>
        {props.readOnlyMode ? (
          <></>
        ) : (
          <button
            data-testid="cross-pill"
            id={opt + "_remove_btn"}
            key={opt + "_remove_btn_key"}
            value={opt}
            className={"btn btn-success " + props.btn_font_weigth}
            type="button"
            aria-label={"remove " + opt + " from list"}
            onClick={removeSelectedCountry}
          >
            x
          </button>
        )}
      </div>
    );
  });

  return (
    <div className={""}>
      <select
        id="ctc-country-select-id"
        data-testid="testid-ctc-country-select-id"
        className={"form-select " + props.refiner_select_element + cssState}
        aria-label="Country Selector"
        onChange={AddCountries}
        defaultValue={"Select target location(s) for Custom Topic Card"}
        disabled={props.readOnlyMode}
      >
        <option disabled={true} data-testid="location-options">
          Select target location(s) for Custom Topic Card
        </option>
        {countrySelectOptions}
      </select>

      <div
        id="ctc-selected-countries"
        data-testid="testid-ctc-selected-countries"
        className={props.selected_locations_div}
      >
        {optionsSelected}
      </div>
    </div>
  );
};

export default LocationField;
