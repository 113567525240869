import services from "../../store/services"

let urlCheckerService = services.urlCheckerService;

export const urlFormatter = (input) => {
    let urlRegex= urlCheckerService.urlRegex;
    let basicURLRegex = urlCheckerService.URLRegExp;

    if (urlRegex.test(input) || basicURLRegex.test(input)) {
        return input;        
    } else {
        return `https://${input}` 
    } 
}
