import BaseService from "./BaseService";

class BestBetService extends BaseService {
  constructor(axios, apiUrl) {
    super(axios, apiUrl);
  }
  GetRequests(from, size, filterBy, sortField, sortBy) {
    return this.axios.get(
      `${this.baseUrl}bestbet/requests?from=${from}&size=${size}&filterBy=${filterBy}&sortField=${sortField}&sortBy=${sortBy}`,
      this.config
    );
  }
  GetBBDataMetrics(fromDate, toDate) {
    return this.axios.get(
      `${this.baseUrl}bb/metrics?fromDate=${fromDate}&toDate=${toDate}`,
      this.config
    );
  }
  GetBestBetsByKeyword(keyword, abortController = null) {
    if (abortController !== null) {
      this.config = {
        ...this.config,
        signal: abortController.signal
      };
    }
    let self = this;
    return self.axios.get(
      `${self.baseUrl}bestbets?keyword=${encodeURIComponent(keyword)}`,
      this.config
    );
  }
  SaveBestBet(bbrequest, id) {
    let self = this;
    if (id) {
      return self.axios.post(
        `${self.baseUrl}bestbet/request/${id}`,
        bbrequest,
        this.config
      );
    } else {
      return self.axios.post(
        `${self.baseUrl}bestbet/request`,
        bbrequest,
        this.config
      );
    }
  }
  GetBbSuggestions(url) {
    let self = this;
    return self.axios.post(
      `${self.baseUrl}bestbetsuggestion`,
      { url: url },
      this.config
    );
  }
  GetBestBetExact(url) {
    let self = this;
    return self.axios.post(
      `${self.baseUrl}bestbetexact`,
      { url: url },
      this.config
    );
  }
  GetBestBetByID(id) {
    return this.axios.get(`${this.baseUrl}bestbet/${id}`, this.config);
  }
  GetRequestById(id) {
    return this.axios.get(`${this.baseUrl}bestbet/request/${id}`, this.config);
  }
  GetRequestByURL(url) {
    return this.axios.post(
      `${this.baseUrl}bestbetrequest`,
      { url: url },
      this.config
    );
  }
  Update(bbrequest, id, bbid) {
    if (bbid) {
      return this.axios.post(
        `${this.baseUrl}bestbet/request/${id}?bbid=${bbid}`,
        bbrequest,
        this.config
      );
    } else {
      return this.axios.post(
        `${this.baseUrl}bestbet/request/${id}`,
        bbrequest,
        this.config
      );
    }
  }
  ChangeStatus(body, id, bbid) {
    return this.axios.put(
      `${this.baseUrl}bestbet/request/${id}?bbid=${bbid}`,
      body,
      this.config
    );
  }
  getBestBetRequests(pk) {
    let self = this;
    return self.axios.get(
      `${self.baseUrl}bestbet/requests/owner/${pk}`,
      self.config
    );
  }
  getMostRecentBestBet(bbid) {
    let self = this;
    return self.axios.get(`${self.baseUrl}bestbetrequest/${bbid}`, self.config);
  }
  getBbArcDataFromUrl(_bbUrl) {
    return this.axios.post(
      `${this.baseUrl}bestbetarcdata`,
      { bb_url: _bbUrl },
      this.config
    );
  }
}
export default BestBetService;
